import { createSelector } from "@reduxjs/toolkit";
import * as im from "immutable";
import { useMemo } from "react";

import {
	type CellID,
	type CellInfo,
	type CellMap,
	getCellName,
	isComputedCell,
} from "~/models/cell";
import { useAppSelector } from "~/store/hooks";
import { type RootState } from "~/store/store";

export type CellNamesMap = {
	[name: string]: {
		[id: string]: CellInfo;
	};
};

export const selectCell = (id: CellID) => {
	return (state: RootState) => {
		const cell = state.cells.store[id];
		if (cell === undefined) {
			return undefined;
		}
		return state.cells.store[id];
	};
};

export const selectCellIndex = (id?: string) => {
	return (state: RootState) => {
		if (id === undefined) {
			return undefined;
		}

		const orderedList = state.cells.orderedList;

		for (let i = 0; i < orderedList.length; ++i) {
			const cellID = orderedList[i];
			if (cellID === id) {
				return i;
			}
		}

		return undefined;
	};
};

export const selectCellsOrder = (state: RootState): CellID[] => state.cells.orderedList;
export const selectCellsOrderSet = createSelector([selectCellsOrder], (ordering) =>
	im.Set(ordering)
);

export const selectAllCells = (state: RootState): CellMap => state.cells.store;
export const selectCells = createSelector(
	[selectAllCells, selectCellsOrderSet],
	(cells: CellMap, ordering) => {
		const init: CellMap = {};
		return Object.keys(cells).reduce((acc, key) => {
			if (ordering.contains(key)) {
				const cell = cells[key];
				if (cell) {
					acc[key] = cell;
				}
			}
			return acc;
		}, init);
	}
);

export const selectCellsEmpty = createSelector([selectCellsOrder], (order): boolean => {
	return order.length === 0;
});

export const selectOrderedCells = createSelector(
	[selectCells, selectCellsOrder],
	(cellsMap, order): CellInfo[] => {
		return order.flatMap((id) => {
			const cell = cellsMap[id];
			if (cell === undefined) {
				// If we don't have the cell in redux yet that's okay
				// we'll assume a process will come fill it in later
				// skip for now.
				return [];
			}
			return [cell];
		});
	}
);

export const selectOrderedComputeCells = createSelector(
	[selectCells, selectCellsOrder],
	(cellsMap, order): CellInfo[] => {
		return order.flatMap((id) => {
			const cell = cellsMap[id];
			if (cell === undefined || !isComputedCell(cell.cell)) {
				return [];
			}
			return [cell];
		});
	}
);

export const selectCellNamesMap = createSelector(
	[selectCells, selectCellsOrder],
	(cellsMap, order): { [name: string]: { [id: string]: CellInfo } } => {
		// Multiple cells can have the same name (though this will be rendered in the UI as an error).
		const cellNameMap: CellNamesMap = {};

		order.map((id) => {
			const cellInfo = cellsMap[id];
			if (cellInfo === undefined) {
				throw Error(`selectOrderedCells: Cell doesn't exist for id, ${id}`);
			}
			const { cell } = cellInfo;
			if (cell.type !== "JavaScriptFunctionBodyCell") {
				// only compute cells have names
				return;
			}

			const inspect = cellInfo.analysis?.inspectInfo;
			if (inspect?.kind === "NamespacedCanvasImportInfo") {
				for (const [localName] of Object.entries(inspect.specifiers)) {
					const nameIndex = cellNameMap[localName] || {};
					cellNameMap[localName] = { ...nameIndex, [cellInfo.cell.id]: cellInfo };
				}
			}
			if (inspect?.kind === "PageImportInfo") {
				for (const [localName] of Object.entries(inspect.specifiers)) {
					const nameIndex = cellNameMap[localName] || {};
					cellNameMap[localName] = { ...nameIndex, [cellInfo.cell.id]: cellInfo };
				}
			}
			if (cell.cellName !== "") {
				const nameIndex = cellNameMap[cell.cellName] || {};
				cellNameMap[cell.cellName] = { ...nameIndex, [cellInfo.cell.id]: cellInfo };
			}
		});

		return cellNameMap;
	}
);

export const selectAllCellsReady = (state: RootState): boolean => {
	return Object.values(state.cells.store).every((cellEntry) => {
		return cellEntry.status !== "initializing";
	});
};

export const selectCellsDirty = (state: RootState) => state.cells.dirty;

export const selectCellNames = createSelector([selectOrderedCells], (cells) => {
	const newNames = cells
		.map((c) => getCellName(c.cell))
		.filter((n): n is string => n !== undefined);

	return newNames;
});

export const selectLastSelectedCellID = (state: RootState): CellInfo | undefined => {
	const id = state.cells.lastSelectedCellID;
	if (id === undefined) {
		return undefined;
	}

	const cell = state.cells.store[id];
	if (cell === undefined) {
		return undefined;
	}
	return state.cells.store[id];
};

export const selectSelectedComputeCellID = (state: RootState) => state.cells.selectedComputeCellIDs;

export const useSelectedComputeCellIds = (): Set<CellID> => {
	const selectedComputeCellID = useAppSelector(selectSelectedComputeCellID);
	return useMemo(() => new Set(selectedComputeCellID), [selectedComputeCellID]);
};
