import {
	type PayloadAction,
	createAsyncThunk,
	createSelector,
	createSlice,
} from "@reduxjs/toolkit";
import Fuse from "fuse.js";

import { CANVASES, PUBLIC_CANVASES } from "~/api";
import {
	type CanvasesQuery,
	type CanvasesQueryVariables,
	type PublicCanvasesQuery,
	type PublicCanvasesQueryVariables,
} from "~/api/generated/graphql";
import { type CanvasListingItem, fromGQL } from "~/models/CanvasListingItem";
import { type Canvas, type CanvasID } from "~/models/canvas";
import { type RootState, type ThunkArgs } from "~/store/store";
import { Logger } from "~/utils/logging";

import { setEnv } from "../client/slice";

type Status = "loading" | "complete" | "error";

const logger = new Logger("store/canvases/canvases");

export type CanvasesState = {
	canvases: { [key: string]: CanvasListingItem };
	// only public canvases
	publicCanvases: { [key: string]: CanvasListingItem };
	recentlyViewed: Array<Canvas["id"]>;
	status: Status;
	publicCanvasesStatus: Status;
};

const initialState: CanvasesState = {
	canvases: {},
	publicCanvases: {},
	recentlyViewed: [],
	status: "loading",
	publicCanvasesStatus: "loading",
};

export const canvasesSlice = createSlice({
	name: "canvases",
	initialState,
	reducers: {
		setCanvases: (
			state: CanvasesState,
			action: PayloadAction<{ canvases: CanvasListingItem[] }>
		) => {
			const { canvases } = action.payload;

			state.canvases = {};
			canvases.forEach((canvas) => {
				state.canvases[canvas.id] = canvas;
			});
			state.status = "complete";
		},
		setCanvasesError: (state: CanvasesState) => {
			state.status = "error";
		},
		setPublicCanvases: (
			state: CanvasesState,
			action: PayloadAction<{ publicCanvases: CanvasListingItem[] }>
		) => {
			const { publicCanvases } = action.payload;

			state.publicCanvases = {};
			publicCanvases.forEach((canvas) => {
				state.publicCanvases[canvas.id] = canvas;
			});
			state.publicCanvasesStatus = "complete";
		},
		setPublicCanvasesError: (state: CanvasesState) => {
			state.publicCanvasesStatus = "error";
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setEnv, (state, _action) => {
			state.canvases = {};
			state.publicCanvases = {};
			state.publicCanvasesStatus = "loading";
			state.status = "loading";
		});
	},
});

const sortCanvases = (canvases: { [key: string]: CanvasListingItem }): CanvasListingItem[] => {
	return Object.values(canvases).sort((c1, c2) => (c1.updatedAt > c2.updatedAt ? 0 : 1));
};

export const canvases = canvasesSlice.reducer;

export const { setCanvases, setPublicCanvases, setCanvasesError, setPublicCanvasesError } =
	canvasesSlice.actions;

export const fetchCanvases = createAsyncThunk<CanvasListingItem[] | undefined, void, ThunkArgs>(
	"canvases/fetchCanvases",
	async (_, { dispatch, extra }) => {
		const { client } = extra;

		if (client === undefined) {
			return;
		}

		try {
			const results = await client.query<CanvasesQuery, CanvasesQueryVariables>({
				query: CANVASES,
				fetchPolicy: "no-cache",
			});

			if (results.error) {
				logger.error("Failed to fetch canvases", { errors: results.errors });
				dispatch(setCanvasesError());
				return [];
			}

			const canvases = results.data.canvases.canvases.map(fromGQL);

			dispatch(setCanvases({ canvases }));
			return canvases;
		} catch (e) {
			logger.error("Failed to fetch canvases", { errors: `${e}` });
			dispatch(setCanvasesError());
		}
	}
);

export const fetchPublicCanvases = createAsyncThunk<
	CanvasListingItem[] | undefined,
	void,
	ThunkArgs
>("canvases/fetchPublicCanvases", async (_, { dispatch, extra }) => {
	const { client } = extra;

	if (client === undefined) {
		return;
	}

	try {
		const results = await client.query<PublicCanvasesQuery, PublicCanvasesQueryVariables>({
			query: PUBLIC_CANVASES,
			fetchPolicy: "no-cache",
		});

		if (results.error) {
			logger.error("Failed to fetch public canvases", { errors: results.errors });
			dispatch(setPublicCanvasesError());
			return [];
		}

		const publicCanvases = results.data.publicCanvases.canvases.map(fromGQL);
		dispatch(setPublicCanvases({ publicCanvases }));

		return publicCanvases;
	} catch (e) {
		logger.error("Failed to fetch public canvases", { errors: `${e}` });
		dispatch(setPublicCanvasesError());
	}
});

export const selectCanvases = (state: RootState) => {
	return state.canvases.canvases;
};

export const selectCanvasesLoadingStatus = (state: RootState) => {
	return state.canvases.status;
};

export const selectCanvas = (id: CanvasID) => {
	return (state: RootState) => state.canvases.canvases[id];
};

export const selectPublicCanvases = (state: RootState) => {
	return state.canvases.publicCanvases;
};

export const selectPublicCanvasesLoadingStatus = (state: RootState) => {
	return state.canvases.publicCanvasesStatus;
};

export const selectPublicCanvas = (id: CanvasID) => {
	return (state: RootState) => state.canvases.publicCanvases[id];
};

export const selectSortedPublicCanvases = createSelector(
	[selectPublicCanvases],
	(publicCanvases) => {
		return sortCanvases(publicCanvases);
	}
);

//
// In order to deduplicate public canvases and Moment canvases that are shared with the entire organization,
// we check to see if the canvas is not also in the public canvases list. This is only possible internally.
//

export const selectPinnedCanvases = createSelector(
	[selectCanvases, selectPublicCanvases],
	(canvases, publicCanvases) => {
		return sortCanvases(canvases).filter(
			(canvas) => canvas.pinnedAt !== undefined && publicCanvases[canvas.id] === undefined
		);
	}
);

export const selectUnpinnedCanvases = createSelector(
	[selectCanvases, selectPublicCanvases],
	(canvases, publicCanvases) => {
		return sortCanvases(canvases).filter(
			(canvas) => canvas.pinnedAt === undefined && publicCanvases[canvas.id] === undefined
		);
	}
);

export const selectCanvasesFromSearchQuery = (query: string) =>
	createSelector([selectCanvases], (canvases) => {
		// do not run fuse on empty search query
		if (typeof query !== "string" || query.trim() === "") {
			return undefined;
		}

		const cleanQuery = query.trim();

		const list = Object.values(canvases);
		const options = {
			keys: ["title"],
			threshold: 0.2,
		};
		const fuse = new Fuse(list, options);

		const output = fuse.search(cleanQuery);

		return output.length > 0 ? output.map((i) => i.item) : undefined;
	});
