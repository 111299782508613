import type { ActiveUser } from "@moment/api-collab/api-types";
import { createSelector } from "@reduxjs/toolkit";
import im from "immutable";

import { Permission } from "~/api/generated/graphql";
import { type CellInfo } from "~/models/cell";
import { findPage } from "~/models/page";
import { type InviteInput, type Role } from "~/models/roles";
import { type InspectInfo } from "~/runtime/cell-compiler/analysis";
import {
	selectAuthState,
	selectIsMomentUser,
	selectOrgID,
	selectUserID,
} from "~/store/auth/selectors";
import { selectCell, selectCellNamesMap } from "~/store/cells/selectors";
import {
	selectGlobalPermissions,
	selectInviteInputs,
	selectOrgPermissions,
	selectUserPermissions,
} from "~/store/permissions/selectors";
import { Logger } from "~/utils/logging";

import { AuthStates } from "../auth/types";
import { type NamespacedImportedCanvases } from "../imported-canvases/types";
import { type RootState } from "../store";
import { selectActiveCanvas } from "./selectActiveCanvas";
import { type InspectingCell, type Modes, NoActiveCanvasError } from "./types";

type UserPermissions = { [userID: string]: Role };

export type InspectingCellWithOptionalRoot = Omit<InspectingCell, "rootJsxCellInfo"> & {
	rootJsxCellInfo?: CellInfo;
};

const logger = new Logger("canvas-selectors");

export { selectActiveCanvas } from "./selectActiveCanvas";

export const selectActiveCanvasID = createSelector(
	[selectActiveCanvas],
	(activeCanvas) => activeCanvas?.id
);

export const selectNamespacedCanvases = (state: RootState) =>
	state.importedCanvases.namespacedCanvases;

// This returns true if the canvas is public, whether if the user is logged in or not.
// AKA the canvas has a public permission.
export const selectIsPublic = createSelector(
	[selectGlobalPermissions, selectActiveCanvas, selectOrgID, selectIsMomentUser],
	(globalPermissions, canvas, orgID, isMomentUser) => {
		if (canvas === undefined) {
			return false;
		}

		const permissions = globalPermissions[canvas.id];

		if (permissions === undefined) {
			return false;
		}

		// Return true if this is a "public" canvas (shared with all orgs) and
		// the user is not a moment employee.
		//
		// We still want moment employees to be able to do things that other
		// users wouldn't be able to do, like view share permissions.
		return permissions && !isMomentUser;
	}
);

// Return true if the user is not logged in and is viewing a public canvas.
export const selectUserViewingPublicCanvas = createSelector(
	[selectIsPublic, selectAuthState],
	(isPublic, authState) => {
		return isPublic && authState !== AuthStates.LoggedIn;
	}
);

export const selectCanvasOrgPermissions = createSelector(
	[selectOrgPermissions, selectActiveCanvas],
	(orgPermissions, canvas) => {
		if (canvas === undefined) {
			return;
		}
		return orgPermissions[canvas.id];
	}
);

export const selectAllCanvasUserPermissions = createSelector(
	[selectUserPermissions, selectActiveCanvas],
	(userPermissions, canvas): UserPermissions | undefined => {
		if (canvas === undefined) {
			return;
		}

		return userPermissions[canvas.id];
	}
);

export const selectCanvasUserPermissions = createSelector(
	[selectUserPermissions, selectActiveCanvas, selectUserID],
	(userPermissions, canvas, userID): Role | undefined => {
		if (canvas === undefined || userID == undefined) {
			return;
		}

		const permissions = userPermissions[canvas.id];

		if (permissions === undefined) {
			return;
		}

		return permissions[userID];
	}
);

export const selectCanvasInviteInputs = createSelector(
	[selectInviteInputs, selectActiveCanvas],
	(inviteInputs, canvas): { [email: string]: InviteInput } => {
		if (canvas === undefined) {
			return {};
		}

		return inviteInputs[canvas.id] ?? {};
	}
);

export const selectActivePermissions = createSelector(
	[selectCanvasUserPermissions, selectCanvasOrgPermissions],
	(userPermissions, orgPermissions) => {
		return im
			.Set(userPermissions?.permissions || [])
			.concat(orgPermissions?.role?.permissions || []);
	}
);

export const selectActiveUsers = (
	selfID: string,
	tabID: string
): ((state: RootState) => ActiveUser[] | undefined) => {
	return (state: RootState) => {
		if (state.canvas.active === undefined) {
			return undefined;
		}

		return state.canvas.activeUsers.filter((activeUser) => activeUser.tabID !== tabID);
	};
};

export const selectCanvasHasWritePermissions = createSelector(
	[selectActivePermissions],
	(permissions) => {
		return permissions.has(Permission.Write);
	}
);

export const selectCanvasHasDeletePermissions = createSelector(
	[selectActivePermissions],
	(permissions) => {
		return permissions.has(Permission.Delete);
	}
);

export const selectCanvasHasSharePermissions = createSelector(
	[selectActivePermissions],
	(permissions) => {
		return permissions.has(Permission.Share);
	}
);

const COMMENT_PERMISSIONS = new Set([
	Permission.CommentRead,
	Permission.CommentWrite,
	Permission.CommentManage,
]);

export const selectCommentPermissions = createSelector([selectActivePermissions], (permissions) =>
	permissions.filter((p) => COMMENT_PERMISSIONS.has(p))
);

export const selectCanvasHasCommentPermissions = createSelector(
	[selectActivePermissions],
	(permissions) => {
		return permissions.has(Permission.CommentWrite);
	}
);

export const selectCanvasWritable = createSelector(
	[
		(state: RootState) => state.canvas,
		(state: RootState) => state.versions,
		selectCanvasHasWritePermissions,
	],
	(canvas, versions, hasWritePermissions) => {
		/**
		 * if there is no active canvas being looked at
		 * then throw an error
		 */
		if (canvas.active === undefined) {
			throw new NoActiveCanvasError();
		}

		/**
		 * returns true if
		 *   ✓  canvas is not in versions preview
		 *   ✓  canvas is not in archived preview
		 *   ✓  user has write permissions for canvas
		 */
		if (versions.originalCanvas === undefined && !canvas.active.archived) {
			return hasWritePermissions;
		}
		return false;
	}
);

export const selectCanvasDeletable = createSelector(
	[
		(state: RootState) => state.canvas,
		(state: RootState) => state.versions,
		selectCanvasHasDeletePermissions,
	],
	(canvas, versions, hasDeletePermissions) => {
		/**
		 * if there is no active canvas being looked at
		 * then throw an error
		 */
		if (canvas.active === undefined) {
			throw new NoActiveCanvasError();
		}

		/**
		 * returns true if
		 *   ✓  user has delete permissions for canvas
		 *   ✓  canvas is not in versions preview
		 *   ✓  canvas is not in archived preview
		 */
		return (
			hasDeletePermissions && versions.originalCanvas === undefined && !canvas.active.archived
		);
	}
);

export const selectActivePageID = (state: RootState) => {
	return state.canvas.activePageID;
};

export const selectActivePage = createSelector(
	[selectActiveCanvas, selectActivePageID],
	(canvas, pageID) => {
		if (canvas === undefined) {
			logger.warn("Canvas is undefined", { pageID });
			return {
				page: undefined,
				idx: -1,
			};
		}

		const firstPage = canvas.pages[0];
		if (firstPage === undefined) {
			logger.error("Canvas first page is undefined", { canvasID: canvas.id });
		}

		if (pageID === undefined) {
			if (firstPage === undefined) {
				return {
					page: undefined,
					idx: -1,
				};
			}

			return {
				page: firstPage,
				idx: 0,
			};
		}

		const page = findPage(canvas.pages, { id: pageID });

		if (page.page === undefined) {
			if (firstPage === undefined) {
				return {
					page: undefined,
					idx: -1,
				};
			}

			return {
				page: firstPage,
				idx: 0,
			};
		}

		return page;
	}
);

export const selectPages = createSelector([selectActiveCanvas], (canvas) => {
	if (canvas === undefined) {
		return [];
	}
	return canvas.pages;
});

export const selectPageByID = (pageID: string) =>
	createSelector([selectPages], (pages) => findPage(pages, { id: pageID }));

export const selectPageMap = createSelector([selectPages], (pages) => {
	const pageMap = {};
	pages.forEach((p) => {
		pageMap[p.slug] = p;
	});
	return pageMap;
});

export const selectInspectPaneLoading = (state: RootState) => {
	if (state.canvas.active === undefined) {
		throw new NoActiveCanvasError();
	}

	return state.canvas.propertiesPaneLoading;
};

export const selectSaveState = (state: RootState) => {
	if (state.canvas.active === undefined) {
		throw new NoActiveCanvasError();
	}

	return state.canvas.saveState;
};

export const selectDraftSaveState = (state: RootState) => {
	return state.canvas.draftSaveState;
};

export const selectEditingCellID = (state: RootState): string | undefined => {
	return state.canvas.editingCell;
};

export const selectEditingCell = (state: RootState): CellInfo | undefined => {
	if (state.canvas.active === undefined) {
		throw new NoActiveCanvasError();
	}

	const id = state.canvas.editingCell;
	if (id !== undefined) {
		return state.cells.store[id];
	}
	return undefined;
};

export const selectCanvasMode = (state: RootState): Modes => {
	if (state.canvas.active === undefined) {
		throw new NoActiveCanvasError();
	}

	return state.canvas.mode;
};

export const selectInspectingCellID = (state: RootState): string | undefined => {
	if (state.canvas.active === undefined) {
		throw new NoActiveCanvasError();
	}

	return state.canvas.inspectingCell;
};

const getParamFromJsxComponentMeta = (
	namespacedCanvases: NamespacedImportedCanvases,
	jsxComponentMeta?: InspectInfo,
	inspectorInfo?: InspectInfo
) => {
	if (inspectorInfo?.kind !== "RenderCallExpressionInfo") {
		return;
	}

	if (jsxComponentMeta?.kind === "FunctionComponentDefinition") {
		return Object.entries(jsxComponentMeta.params);
	} else if (jsxComponentMeta?.kind === "NamespacedCanvasImportInfo") {
		const { namespace, canvasName } = jsxComponentMeta.argument;
		const canvas = namespacedCanvases[namespace]?.[canvasName];
		if (canvas?.status === "analyzed") {
			const [, { imported }] = Object.entries(jsxComponentMeta.specifiers).filter(
				([localName]) => localName === inspectorInfo.rootJsxTag.name
			)[0] || [{}, {}];

			if (!imported) {
				return;
			}

			const inspect = canvas.cells[imported]?.analysis?.inspectInfo;
			if (inspect?.kind === "FunctionComponentDefinition") {
				return Object.entries(inspect.params);
			}
		}
	}
};

export const selectRawInspectingCell = (state: RootState) => {
	const id = selectInspectingCellID(state);
	if (id === undefined) {
		return undefined;
	}

	const inspectingCell = selectCell(id)(state);
	if (inspectingCell === undefined) {
		return undefined;
	}

	return inspectingCell;
};

export const selectInspectingCell = createSelector(
	[selectRawInspectingCell, selectNamespacedCanvases, selectCellNamesMap],
	(inspectingCell, namespacedCanvases, cellNamesMap) => {
		try {
			const inspectorInfo = inspectingCell?.analysis?.inspectInfo;

			if (inspectorInfo?.kind !== "RenderCallExpressionInfo") {
				return {
					cellInfo: inspectingCell,
					rootJsxCellInfo: undefined,
					params: [],
				};
			}

			const rootJsxCellInfos = cellNamesMap[inspectorInfo.rootJsxTag.name];

			if (!rootJsxCellInfos) {
				return;
			}
			const keys = Object.keys(rootJsxCellInfos);

			const key = keys[0];

			if (!key) {
				return;
			}

			const rootJsxCellInfo = rootJsxCellInfos[key];
			const jsxComponentMeta = rootJsxCellInfo?.analysis?.inspectInfo;
			const params = getParamFromJsxComponentMeta(
				namespacedCanvases,
				jsxComponentMeta,
				inspectorInfo
			);
			if (jsxComponentMeta?.kind === "NamespacedCanvasImportInfo") {
				const { namespace, canvasName } = jsxComponentMeta.argument;
				const canvas = namespacedCanvases[namespace]?.[canvasName];
				if (canvas?.status === "analyzed") {
					const [, { imported }] = Object.entries(jsxComponentMeta.specifiers).filter(
						([localName]) => localName === inspectorInfo.rootJsxTag.name
					)[0] || [{}, {}];

					if (!imported) {
						return;
					}
				}
			} else if (jsxComponentMeta?.kind === "PageImportInfo") {
				/** TODO(maddie): incomplete */
				const [, { imported }] = Object.entries(jsxComponentMeta.specifiers).filter(
					([localName]) => localName === inspectorInfo.rootJsxTag.name
				)[0] || [{}, {}];

				if (!imported) {
					return;
				}
			}

			return {
				cellInfo: inspectingCell,
				rootJsxCellInfo,
				params: params || [],
			};
		} catch (e) {
			return undefined;
		}
	}
);
