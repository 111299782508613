import type { ActiveUser } from "@moment/api-collab/api-types";

import { type Canvas } from "~/models/canvas";
import { type CellID, type CellInfo } from "~/models/cell";
import { type JsxParameterInfo } from "~/runtime/cell-compiler/analysis/JsxParameterInfo";
import { type AutoImportActions } from "~/store/cells/types";
import { type CodeEditorState } from "~/store/code-editor/types";

export type SavingState = {
	status: "waiting" | "dirty" | "saving" | "saved" | "error";
	message: string;
};

export type DraftSavingState = {
	status: "waiting" | "saving";
};

export class NoActiveCanvasError extends Error {
	constructor(message?: string) {
		super(message);
		this.message = message === undefined ? "No Active Canvas" : message;
		this.name = "NoActiveCanvasError";
	}
}

export interface InspectingCell {
	cellInfo: CellInfo;
	rootJsxCellInfo: CellInfo;
	params: [string, JsxParameterInfo][];
}

export const PropertiesPaneStateValues = [
	"inspect",
	"components",
	"integrations",
	"comments",
	"serviceDetail",
	"history",
	"releases",
	"cellDependencies",
	"help",
] as const;

export type Pane = (typeof PropertiesPaneStateValues)[number] | undefined;
export const PANE_LABELS: Record<Exclude<Pane, undefined>, string> = {
	inspect: "Inspect Tab",
	components: "Components Tab",
	integrations: "Integrations Tab",
	comments: "Comments Tab",
	serviceDetail: "Service Detail Tab",
	history: "History Tab",
	releases: "Releases Tab",
	cellDependencies: "Cell Dependencies Tab",
	help: "Help Tab",
} as const;

export const isPane = (value: unknown): value is Pane => {
	if (value === undefined) {
		return true;
	}

	if (typeof value !== "string") {
		return false;
	}

	return PropertiesPaneStateValues.includes(value as (typeof PropertiesPaneStateValues)[number]);
};

export type PropertiesPaneState = {
	pane: Pane;
	metadata?: {
		addComment?: {
			id: string;
			// Callback to execute after comments are added
			cb?: (commentThreadId: string) => void;
		};
		unHighlightCommentMark?: {
			cb: () => void;
		};
		isInitiatedByUser?: boolean;
	};
};

export type Modes = "document" | "grid";

export interface CanvasState {
	active?: Canvas;
	activePageID?: string;
	saveState: SavingState;
	draftSaveState: DraftSavingState;
	editor: CodeEditorState;
	inspectingCell?: string;
	propertiesPaneLoading: boolean;
	editingCell: CellID | undefined;
	mode: Modes;
	previewAs: "Viewer" | "Collaborator" | "Commenter" | undefined;
	activeUsers: ActiveUser[];
}
export type InsertActions = {
	autoImports?: AutoImportActions[];
};
