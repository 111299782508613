import { type DOMOutputSpec, type NodeSpec } from "prosemirror-model";

// :: NodeSpec An image (`<image>`)

export const image_placeholder: NodeSpec = {
	inline: true,
	group: "inline",
	toDOM(): DOMOutputSpec {
		return ["image_placeholder", { class: "image_placeholder" }];
	},
};
