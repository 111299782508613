import { type DOMOutputSpec, type MarkSpec } from "prosemirror-model";

const strikeDOM: DOMOutputSpec = ["s", 0];

export const strike: MarkSpec = {
	parseDOM: [{ tag: "s" }, { style: "text-decoration=line-through" }],
	toDOM() {
		return strikeDOM;
	},
};
