import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "~/lib/prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "~/lib/prosemirror-utils/makeTextCellDefaultToDOMAttrs";

// :: NodeSpec A horizontal rule (`<hr>`).

export const horizontal_rule: NodeSpec = {
	group: "block",
	attrs: {
		...CELL_SPEC_ATTRS,
	},
	parseDOM: [{ tag: "hr" }],
	toDOM(node: Node) {
		const attrs: Attrs = makeTextCellDefaultToDOMAttrs(node, "hr");

		const hrDOM: DOMOutputSpec = ["p", attrs, ["hr"]];
		return hrDOM;
	},
};
