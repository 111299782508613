// defaults to localStorage for web
import { type ApolloClient, type NormalizedCacheObject } from "@apollo/client";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { apolloClient } from "~/api/client";

import { defaultAppEnv } from "./client/slice";
import { exceptionHandler } from "./middlewares";
import { reducer } from "./reducer";

type StoreConfig = {
	logErrors: boolean;
	client?: ApolloClient<NormalizedCacheObject>;
};

const persistConfig = {
	key: "moment_v1",
	storage,
	// change to "allowlist" when redux-persist enables it
	// https://github.com/rt2zz/redux-persist/issues?q=is%3Aissue+is%3Aopen+allowlist
	whitelist: ["client", "auth", "recentlyViewed"],
};

const IS_PRODUCTION = process.env.APP_ENV === "production";

export const makeStore = ({ logErrors, client: clientInput }: StoreConfig) => {
	const client = clientInput === undefined ? apolloClient(defaultAppEnv) : clientInput;
	const persistedReducer = persistReducer(persistConfig, reducer);

	return configureStore({
		reducer: persistedReducer,
		devTools: !IS_PRODUCTION,
		middleware: (getDefaultMiddlware) =>
			getDefaultMiddlware({
				serializableCheck: false,
				thunk: {
					extraArgument: { client },
				},
			}).concat(exceptionHandler(logErrors)),
	});
};

export const store = makeStore({ logErrors: true });

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type ExtraThunkArgs = {
	client?: ApolloClient<NormalizedCacheObject>;
};
export type ThunkArgs = {
	state: RootState;
	extra: ExtraThunkArgs;
};

export type AppDispatch = typeof store.dispatch;
