/* eslint-env node */
/* eslint-disable @typescript-eslint/no-var-requires */
const plugin = require("tailwindcss/plugin");

module.exports = plugin(function ({ matchUtilities, theme }) {
	matchUtilities(
		{
			"inset-border": (value) => {
				return {
					boxShadow: `inset 0 0 0 1px ${value}`,
				};
			},
			"inset-border-top": (value) => {
				return {
					boxShadow: `inset 0 1px 0 0 ${value}`,
				};
			},
			"inset-border-right": (value) => {
				return {
					boxShadow: `inset -1px 0 0 0 ${value}`,
				};
			},
			"inset-border-bottom": (value) => {
				return {
					boxShadow: `inset 0 -1px 0 0 ${value}`,
				};
			},
			"inset-border-left": (value) => {
				return {
					boxShadow: `inset 1px 0 0 0 ${value}`,
				};
			},
		},
		{
			values: {
				...theme("borderColor"),
			},
		}
	);
});
