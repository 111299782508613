import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "~/lib/prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "~/lib/prosemirror-utils/makeTextCellDefaultToDOMAttrs";

export const bullet_list: NodeSpec = {
	content: "list_item+",
	group: "block",
	attrs: {
		...CELL_SPEC_ATTRS,
		tight: { default: false },
		bullet: { default: "-" },
	},
	parseDOM: [
		{
			tag: "ul",
			getAttrs: (p: string | HTMLElement) => {
				if (!(p instanceof HTMLElement)) {
					return false;
				}

				const attrs: Attrs = {
					tight: p.hasAttribute("data-tight"),
					bullet: p.getAttribute("data-bullet") || "-",
				};

				return attrs;
			},
		},
	],
	toDOM(node: Node): DOMOutputSpec {
		const attrs = {
			...makeTextCellDefaultToDOMAttrs(node),
			"data-tight": node.attrs["tight"] ? "true" : null,
			"data-bullet": node.attrs["bullet"],
		};

		return ["ul", attrs, 0];
	},
};
