import { log } from "./datadog/datadog";

export const notImplementedFn =
	<T>(name: string, providerName: string, defaultReturn: T) =>
	() => {
		log(
			`${name} not implemented. Make sure that you are calling this function within <${providerName} />`,
			{ name, providerName },
			"error"
		);
		return defaultReturn;
	};
