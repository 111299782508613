import { type NodeSpec } from "prosemirror-model";

export const document_placeholder: NodeSpec = {
	draggable: false,
	selectable: true,
	attrs: {
		checksum_hash: { default: undefined },
		checksum_version: { default: undefined },
	},
	toDOM() {
		return ["div", { "data-placeholder": "" }, ""];
	},
};
