import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type DesktopAppState } from "./types";

const initialState: DesktopAppState = {
	isOutdatedAppVersion: false,
	isAppUpdateReady: false,
};

export const desktopAppSlice = createSlice({
	name: "desktopApp",
	initialState,
	reducers: {
		setIsOutdatedAppVersion: (
			state: DesktopAppState,
			action: PayloadAction<{ isOutdatedAppVersion: boolean }>
		) => {
			state.isOutdatedAppVersion = action.payload.isOutdatedAppVersion;
		},

		setIsUpdateReady: (
			state: DesktopAppState,
			action: PayloadAction<{ isAppUpdateReady: boolean }>
		) => {
			state.isAppUpdateReady = action.payload.isAppUpdateReady;
		},
	},
});

export const desktopApp = desktopAppSlice.reducer;
export const { setIsOutdatedAppVersion, setIsUpdateReady } = desktopAppSlice.actions;
