import * as Form from "@radix-ui/react-form";
import cx from "classnames";
import { type FC, type PropsWithChildren, type ReactNode, cloneElement, useState } from "react";

import { Button } from "../Button";
import { Dialog } from "./Dialog";

export interface BasicDialogProps {
	// Dialog State
	isOpen: boolean;
	openDialog?: () => void;
	closeDialog: () => void;

	// Visual Variation
	variant?: "normal" | "negative";
	triggerClassName?: string;
	wide?: boolean;

	// Title
	title: ReactNode;
	titleIcon: JSX.Element;

	// Description/Content
	description?: ReactNode;
	content?: ReactNode;
	noContentPadding?: boolean;
	onOpenAutoFocus?: ((event: Event) => void) | undefined;
	preventDismissal?: boolean;

	// Confirm/Cancel
	confirmText?: ReactNode;
	onConfirm?: () => unknown;
	confirmDisabled?: boolean;
	cancelText?: ReactNode;
	onCancel?: () => unknown;
	hideCancel?: boolean;
}
export const BasicDialog: FC<PropsWithChildren<BasicDialogProps>> = ({
	cancelText = "Cancel",
	children,
	closeDialog,
	confirmDisabled,
	confirmText,
	content,
	description,
	hideCancel,
	isOpen,
	noContentPadding,
	onOpenAutoFocus,
	onCancel,
	onConfirm,
	openDialog,
	preventDismissal,
	title,
	titleIcon,
	triggerClassName,
	variant = "normal",
	wide,
}) => {
	if (children && !openDialog) {
		throw new Error('BasicDialog: "openDialog" prop is required when using "children" prop');
	}

	if (confirmText && !onConfirm) {
		throw new Error('BasicDialog: "onConfirm" prop is required when using "confirmText" prop');
	}

	const [loading, setLoading] = useState(false);

	const Icon = titleIcon
		? cloneElement(titleIcon, {
				className: cx({
					"fill-icon-default": variant === "normal",
					"fill-icon-negative-default": variant === "negative",
				}),
				size: 16,
				["aria-hidden"]: "true",
				["data-testid"]: "basic-dialog-title-icon",
			})
		: null;

	const handleConfirmClick = async () => {
		if (onConfirm === undefined) {
			// Prevent closing the dialog if there is no confirm handler, implies there is no confirm button
			// If you need to submit a form contained in BasicDialog, add a confirm handler
			return;
		}

		setLoading(true);

		try {
			await onConfirm();
			closeDialog();
		} catch (e) {
			//pass, do not close dialog
		}

		setLoading(false);
	};

	const handleCancelClick = async () => {
		try {
			await onCancel?.();
			// Still close the dialog if there is no cancel handler, this is the default behavior
			closeDialog();
		} catch (e) {
			//pass, do not close dialog
		}
	};

	const onEscapeKeyDown = async (e: Event) => {
		if (!preventDismissal) {
			await handleCancelClick();
			return;
		}

		e.preventDefault();
	};

	const onPointerDownOutside = async (e: Event) => {
		if (!preventDismissal) {
			await handleCancelClick();
			return;
		}

		e.preventDefault();
	};

	const onInteractOutside = (e: Event) => {
		if (!preventDismissal) {
			return;
		}

		e.preventDefault();
	};

	return (
		<Dialog.Root open={isOpen}>
			{children && openDialog && (
				<Dialog.Trigger onClick={openDialog} className={triggerClassName}>
					{children}
				</Dialog.Trigger>
			)}
			<Dialog.Portal>
				<Dialog.Overlay>
					<Dialog.Content
						onEscapeKeyDown={onEscapeKeyDown}
						wide={wide}
						onOpenAutoFocus={onOpenAutoFocus}
						onPointerDownOutside={onPointerDownOutside}
						onInteractOutside={onInteractOutside}
					>
						<Dialog.Title
							onCloseClick={handleCancelClick}
							noMargin={noContentPadding}
							hideClose={preventDismissal}
						>
							<div className="flex items-center space-x-2">
								<div>{Icon}</div>
								<div>{title}</div>
							</div>
						</Dialog.Title>

						{/* Screen Readers will announce this when the modal appears */}
						{description && <Dialog.Description>{description}</Dialog.Description>}

						<Form.Root
							onSubmit={async (e) => {
								e.preventDefault();
								await handleConfirmClick();
							}}
						>
							{content && (
								<Dialog.Section className={noContentPadding ? "!p-0" : ""}>
									{content}
								</Dialog.Section>
							)}

							{confirmText && (
								<Dialog.Footer>
									<div className="flex space-x-2">
										{!hideCancel && (
											<Button size="sm" onClick={handleCancelClick}>
												{cancelText}
											</Button>
										)}

										<Form.Submit asChild>
											<Button
												type="submit"
												variant={variant}
												level="primary"
												disabled={confirmDisabled}
												loading={loading}
											>
												{confirmText}
											</Button>
										</Form.Submit>
									</div>
								</Dialog.Footer>
							)}
						</Form.Root>
					</Dialog.Content>
				</Dialog.Overlay>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
