import z from "zod";

import { patch } from "./Patch";

export const stateTransaction = z.object({
	id: z.string(),
	lastVersionSeen: z.number(),
	patches: z.array(patch),
});

export type StateTransaction = z.infer<typeof stateTransaction>;
