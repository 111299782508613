import { type FeatureFlag, Flags } from "./FeatureFlag";

// Only add override values here. Do not duplicate defaults in ../index.ts
export const Overrides: { [key in NonNullable<MomentAppEnv>]: Partial<FeatureFlag> } = {
	local: {
		[Flags.CanvasLayoutVisualDebug]: true,
		[Flags.CollabServiceConnectionCalloutBanner]: true,
		[Flags.InspectPaneHelpMenu]: true,
		[Flags.SpookyOwl]: true,
	},
	staging: {
		[Flags.CollabServiceConnectionCalloutBanner]: true,
	},
	production: {},
	testing: {},
};
