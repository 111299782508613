import NextHead from "next/head";
import { type FC } from "react";

import { getWebBaseURL } from "~/auth/interop/common";
import { toTitleCase } from "~/utils/format/string";

import { BaseSEO } from "./BaseSEO";
import { seoConfig } from "./constants";

export type OpenGraphProps = {
	title: string | null;
	description: string | null;
	url: string | null;
};

const ENV = process.env.APP_ENV;

export const Head: FC<OpenGraphProps> = ({ title, description, url }) => {
	const prefix = ENV === "production" ? "" : `[${ENV}] `;
	// TODO add organizationName to OpenGraphProps, setting to null for now
	const organizationName: string | null = null;
	const baseTitle = `${prefix}${title ?? seoConfig.baseTitle}${
		organizationName ? ` • ${toTitleCase(organizationName)}` : ""
	}`;

	return (
		<>
			<BaseSEO
				config={{
					...seoConfig,
					baseTitle,
					url: `${getWebBaseURL(process.env.APP_ENV)}${url !== null ? url : ""}}`,
					description: description ?? seoConfig.description,
				}}
			/>
			<NextHead>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<script
					key="Intercom"
					id="Intercom"
					dangerouslySetInnerHTML={{
						__html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/j5hcdbw2';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
					}}
				></script>
			</NextHead>
		</>
	);
};
