import { fetchService } from "~/models/serviceCatalog";
import { type ServiceType } from "~/models/serviceCatalog/schema";
import { type Env } from "~/utils/common";
import { log } from "~/utils/datadog/datadog";

export type GetServiceDetailsOptions = {
	env: Env;
	name: string;
};
export type GetServiceDetails = (options: GetServiceDetailsOptions) => Promise<{
	service?: ServiceType;
	error?: string;
}>;

export const getServiceDetails: GetServiceDetails = async ({ env, name }) => {
	if (typeof name !== "string") {
		throw new Error("Expected name to be strings");
	}

	const notFoundErrorMessage = `Unable to find service details for ${name}`;

	try {
		const service = await fetchService(env, name);

		if (!service) {
			return { error: notFoundErrorMessage };
		}

		return { service };
	} catch (e) {
		log(notFoundErrorMessage, { e });
		return { error: notFoundErrorMessage };
	}
};
