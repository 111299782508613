import NextHead from "next/head";
import { type FC } from "react";

const ENV = process.env.APP_ENV;

const HREF_MAP: Record<
	Exclude<MomentAppEnv, undefined | "testing">,
	{ favicon: string; icon: string; appleTouchIcon: string; manifest: string }
> = {
	production: {
		favicon: "/production/favicon.ico",
		icon: "/production/icon.svg",
		appleTouchIcon: "/production/apple-touch-icon.png",
		manifest: "/production/manifest.webmanifest",
	},
	staging: {
		favicon: "/staging/favicon.ico",
		icon: "/staging/icon.svg",
		appleTouchIcon: "/staging/apple-touch-icon.png",
		manifest: "/staging/manifest.webmanifest",
	},
	local: {
		favicon: "/local/favicon.ico",
		icon: "/local/icon.svg",
		appleTouchIcon: "/local/apple-touch-icon.png",
		manifest: "/local/manifest.webmanifest",
	},
};
export const Favicons: FC = () => {
	const hrefs = ENV && ENV !== "testing" ? HREF_MAP[ENV] : HREF_MAP.production;
	const { favicon, icon, appleTouchIcon, manifest } = hrefs;

	return (
		<NextHead>
			<link rel="shortcut icon" key="favicon" href={favicon} sizes="any" />
			<link rel="icon" key="icon" href={icon} type="image/svg+xml" />
			<link rel="apple-touch-icon" key="apple-touch-icon" href={appleTouchIcon} />
			<link rel="manifest" key="manifest" href={manifest} />
		</NextHead>
	);
};
