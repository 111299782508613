import { type LogLevel } from "~/lib/logger-types";

export const log = (
	service: string,
	message: string,
	content?: Record<string, unknown>,
	level: LogLevel = "info"
) => {
	const record: Record<string, unknown> = content ?? {};
	const processedContent = {};
	for (const [k, v] of Object.entries(record)) {
		if (v instanceof Error) {
			// Use the error message if the value type is Error.
			processedContent[k] = `[${v.name}] ${v.message}`;
		} else if (k === "message") {
			// `message` is a CF specific key name. Using `moment_message` instead.
			processedContent["moment_message"] = v;
		} else if (v !== undefined) {
			// Only add the value if it exists.
			processedContent[k] = v;
		}
	}
	console.log(
		`${message} `,
		{
			isMomentMetadata: true,
			level,
			service,
		},
		processedContent
	);
};
