/**
 * All the classes for the button component are defined here
 * to prevent the button component from becoming too large.
 */
import {
	type ButtonLevel,
	type ButtonSegment,
	type ButtonSize,
	type ButtonState,
	type ButtonVariant,
} from ".";

export const buttonClassBase = ({
	fullWidth,
	level,
}: {
	fullWidth: boolean;
	level: ButtonLevel;
}) => [
	// shrink-0 intentionally left out to allow truncation
	"relative",
	"flex",
	"items-center",
	"justify-center",
	"text-center",
	"font-normal",
	"transition-all",
	"outline-none",
	"disabled:cursor-not-allowed",
	fullWidth && "flex-1",
	level !== "quaternary" && "focus:ring",
];

// TODO why not text-base?
export const buttonClassSizes = (
	size: ButtonSize,
	level: ButtonLevel,
	iconOnly: boolean,
	mono: boolean
) => {
	switch (size) {
		case "xs":
			return [
				"gap-1",
				"text-xs",
				mono ? "font-mono" : "font-normal",
				iconOnly || level === "quaternary" ? ["p-1.5"] : ["px-2", "py-1"],
				iconOnly ? "rounded-full" : "rounded-sm",
			];
		case "sm":
			return [
				"gap-1",
				mono ? ["font-mono", "text-xs"] : ["font-normal", "text-sm"],
				!mono && (iconOnly || level === "quaternary") ? ["p-2"] : ["px-3", "py-1.5"],
				mono && !iconOnly && level !== "quaternary" ? "py-2" : undefined,
				iconOnly ? "rounded-full" : "rounded",
			];
		case "md":
		default:
			return [
				"gap-1.5",
				mono ? ["font-mono", "text-sm"] : ["font-normal", "text-md"],
				!mono && (iconOnly || level === "quaternary") ? ["p-3"] : ["px-4", "py-2"],
				mono && !iconOnly && level !== "quaternary" ? "py-2.5" : undefined,
				iconOnly ? "rounded-full" : "rounded-md",
			];
		case "lg":
			return [
				"gap-2",
				mono ? ["font-mono", "text-md"] : ["font-normal", "text-lg"],
				iconOnly || level === "quaternary" ? ["p-4"] : ["px-5", "py-3"],
				iconOnly ? "rounded-full" : "rounded-lg",
			];
	}
};

export const buttonClassSegments = (segment: ButtonSegment) => {
	switch (segment) {
		case "left":
			return ["inline-flex", "rounded-r-none", "-mr-px"];
		case "center":
			return ["inline-flex", "rounded-none"];
		case "right":
			return ["inline-flex", "rounded-l-none", "-ml-px"];
		case "none":
		default:
			break;
	}
};

/**
 * This switch statement is for toggled buttons across all button variants and levels
 */
export const buttonClassToggled = (
	toggled: boolean,
	variant: ButtonVariant,
	level: ButtonLevel
) => {
	if (toggled) {
		switch (variant) {
			case "normal":
			default:
				switch (level) {
					case "primary":
						return [
							"bg-button-normal-primary-focus",
							"hover:!bg-button-normal-primary-focus",
						];
					case "secondary":
						return [
							"bg-button-normal-secondary-focus",
							"hover:!bg-button-normal-secondary-focus",
						];
					case "tertiary":
					default:
						return [
							"bg-button-normal-tertiary-focus",
							"hover:!bg-button-normal-tertiary-focus",
						];
					case "quaternary":
						return [
							"bg-button-normal-tertiary-focus",
							"hover:!bg-button-normal-tertiary-focus",
						];
				}
			case "negative":
				switch (level) {
					case "primary":
						return [
							"bg-button-negative-primary-focus",
							"hover:!bg-button-negative-primary-focus",
						];
					case "secondary":
						return [
							"bg-button-negative-secondary-focus",
							"hover:!bg-button-negative-secondary-focus",
						];
					case "tertiary":
					default:
						return [
							"bg-button-negative-tertiary-focus",
							"hover:!bg-button-negative-tertiary-focus",
						];
					case "quaternary":
						return [
							"bg-button-negative-tertiary-focus",
							"hover:!bg-button-negative-tertiary-focus",
						];
				}
			case "positive":
				switch (level) {
					case "primary":
						return [
							"bg-button-positive-primary-focus",
							"hover:!bg-button-positive-primary-focus",
						];
					case "secondary":
						return [
							"bg-button-positive-secondary-focus",
							"hover:!bg-button-positive-secondary-focus",
						];
					case "tertiary":
					default:
						return [
							"bg-button-positive-tertiary-focus",
							"hover:!bg-button-positive-tertiary-focus",
						];
					case "quaternary":
						return [
							"bg-button-positive-tertiary-focus",
							"hover:!bg-button-positive-tertiary-focus",
						];
				}
		}
	} else {
		// If toggle is undefined, then we need to reset the icon color when the button is focused
		switch (variant) {
			case "normal":
			default:
				switch (level) {
					case "primary":
						return "[&>svg]:focus:!fill-icon-button-normal-primary-default";
					case "secondary":
					default:
						return "[&>svg]:focus:!fill-icon-button-normal-secondary-default";
					case "tertiary":
						return "[&>svg]:focus:!fill-icon-button-normal-tertiary-default";
					case "quaternary":
						return "[&>svg]:focus:!fill-icon-button-normal-quaternary-default";
				}
			case "negative":
				switch (level) {
					case "primary":
						return "[&>svg]:focus:!fill-icon-button-negative-primary-default";
					case "secondary":
					default:
						return "[&>svg]:focus:!fill-icon-button-negative-secondary-default";
					case "tertiary":
						return "[&>svg]:focus:!fill-icon-button-negative-tertiary-default";
					case "quaternary":
						return "[&>svg]:focus:!fill-icon-button-negative-quaternary-default";
				}
			case "positive":
				switch (level) {
					case "primary":
						return "[&>svg]:focus:!fill-icon-button-positive-primary-default";
					case "secondary":
					default:
						return "[&>svg]:focus:!fill-icon-button-positive-secondary-default";
					case "tertiary":
						return "[&>svg]:focus:!fill-icon-button-positive-tertiary-default";
					case "quaternary":
						return "[&>svg]:focus:!fill-icon-button-positive-quaternary-default";
				}
		}
	}
};

const commonHoverClasses = [];
const commonFocusClasses = ["ring"];
const commonActiveClasses = ["ring"];
const commonDisabledClasses = ["ring-0", "focus:ring-0", "active:ring-0", "cursor-not-allowed"];

/**
 * This is an epic switch statement that is necessary because we can't use string interpolation
 * for tailwind classes. Things like `bg-button-${variant}-${level}-${state}` are not possible
 * due to Tailwind's JIT compiler.
 *
 * Thus, we are left with this monstrosity.
 *
 * Level 1 loops through variant classes (normal, negative, positive).
 * Level 2 loops through level classes (primary, secondary, tertiary, quaternary).
 * Level 3 loops through state classes (default, hover, focus, active, disabled).
 *    - Note that the default case for state is all that is actually used in the app.
 * 		The other cases are used to visualize the button in storybook, which helps us
 * 		ensure that the button is styled correctly across all themes and styles.
 *
 * To navigate the switch statement, I've included smaller comments that indicate the variant
 * and level, like so:
 *
 * *** NORMAL / PRIMARY **
 *
 */
export const buttonClassStyles = (
	variant: ButtonVariant,
	level: ButtonLevel,
	state: ButtonState,
	loading: boolean
) => {
	switch (variant) {
		case "normal":
		default:
			switch (level) {
				case "primary":
					/*** NORMAL / PRIMARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-normal",
								// BG
								"bg-button-normal-primary-default",
								"hover:bg-button-normal-primary-hover",
								"focus:bg-button-normal-primary-focus",
								"active:bg-button-normal-primary-active",
								"disabled:bg-button-normal-primary-disabled",
								// Text
								"text-button-normal-primary-default",
								"hover:text-button-normal-primary-hover",
								"focus:text-button-normal-primary-focus",
								"active:text-button-normal-primary-active",
								"disabled:text-button-normal-primary-disabled",
								// Border
								"shadow-border-normal-default-raised",
								"hover:shadow-border-normal-hover-raised",
								"focus:shadow-border-normal-focus",
								"active:shadow-border-normal-active",
								"disabled:shadow-border-normal-disabled",
								// Icon
								"[&>svg]:fill-icon-button-normal-primary-default",
								"[&>svg]:hover:fill-icon-button-normal-primary-hover",
								"[&>svg]:focus:fill-icon-button-normal-primary-focus",
								"[&>svg]:active:fill-icon-button-normal-primary-active",
								"[&>svg]:disabled:fill-icon-button-normal-primary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-normal-primary-hover",
								"text-button-normal-primary-hover",
								"[&>svg]:fill-icon-button-normal-primary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-normal",
								"bg-button-normal-primary-focus",
								"text-button-normal-primary-focus",
								"[&>svg]:fill-icon-button-normal-primary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-normal",
								"bg-button-normal-primary-active",
								"text-button-normal-primary-active",
								"[&>svg]:fill-icon-button-normal-primary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-normal-primary-disabled",
								"text-button-normal-primary-disabled",
								"[&>svg]:fill-icon-button-normal-primary-disabled",
							];
					}
				case "secondary":
				default:
					/*** NORMAL / SECONDARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-normal",
								// BG
								"bg-button-normal-secondary-default",
								"hover:bg-button-normal-secondary-hover",
								"focus:bg-button-normal-secondary-focus",
								"active:bg-button-normal-secondary-active",
								"disabled:bg-button-normal-secondary-disabled",
								// Text
								"text-button-normal-secondary-default",
								"hover:text-button-normal-secondary-hover",
								"focus:text-button-normal-secondary-focus",
								"active:text-button-normal-secondary-active",
								"disabled:text-button-normal-secondary-disabled",
								// Border
								"shadow-border-dropshadow-default",
								"hover:shadow-border-dropshadow-hover",
								"focus:shadow-border-dropshadow-focus",
								"active:shadow-border-dropshadow-active",
								"disabled:shadow-border-dropshadow-disabled",
								// Icon
								"[&>svg]:fill-icon-button-normal-secondary-default",
								"[&>svg]:hover:fill-icon-button-normal-secondary-hover",
								"[&>svg]:focus:fill-icon-button-normal-secondary-focus",
								"[&>svg]:active:fill-icon-button-normal-secondary-active",
								"[&>svg]:disabled:fill-icon-button-normal-secondary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-normal-secondary-hover",
								"text-button-normal-secondary-hover",
								"shadow-border-normal-hover",
								"[&>svg]:fill-icon-button-normal-secondary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-normal",
								"bg-button-normal-secondary-focus",
								"text-button-normal-secondary-focus",
								"shadow-border-normal-focus",
								"[&>svg]:fill-icon-button-normal-secondary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-normal",
								"bg-button-normal-secondary-active",
								"text-button-normal-secondary-active",
								"shadow-border-normal-active",
								"[&>svg]:fill-icon-button-normal-secondary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-normal-secondary-disabled",
								"text-button-normal-secondary-disabled",
								"shadow-border-normal-disabled",
								"[&>svg]:fill-icon-button-normal-secondary-disabled",
							];
					}
				case "tertiary":
					/*** NORMAL / TERTIARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-normal",
								// BG
								"bg-button-normal-tertiary-default",
								"hover:bg-button-normal-tertiary-hover",
								"focus:bg-button-normal-tertiary-focus",
								"active:bg-button-normal-tertiary-active",
								"disabled:bg-button-normal-tertiary-disabled",
								// Text
								"text-button-normal-tertiary-default",
								"hover:text-button-normal-tertiary-hover",
								"focus:text-button-normal-tertiary-focus",
								"active:text-button-normal-tertiary-active",
								"disabled:text-button-normal-tertiary-disabled",
								// Border
								"focus:shadow-border-normal-focus",
								"active:shadow-border-normal-active",
								// Icon
								"[&>svg]:fill-icon-button-normal-tertiary-default",
								"[&>svg]:hover:fill-icon-button-normal-tertiary-hover",
								"[&>svg]:focus:fill-icon-button-normal-tertiary-focus",
								"[&>svg]:active:fill-icon-button-normal-tertiary-active",
								"[&>svg]:disabled:fill-icon-button-normal-tertiary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-normal-tertiary-hover",
								"text-button-normal-tertiary-hover",
								"[&>svg]:fill-icon-button-normal-tertiary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-normal",
								"bg-button-normal-tertiary-focus",
								"text-button-normal-tertiary-focus",
								"shadow-border-normal-focus",
								"[&>svg]:fill-icon-button-normal-tertiary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-normal",
								"bg-button-normal-tertiary-active",
								"text-button-normal-tertiary-active",
								"shadow-border-normal-active",
								"[&>svg]:fill-icon-button-normal-tertiary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-normal-tertiary-disabled",
								"text-button-normal-tertiary-disabled",
								"[&>svg]:fill-icon-button-normal-tertiary-disabled",
							];
					}
				case "quaternary":
					/*** NORMAL / QUATERNARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-normal",
								// BG
								"bg-button-normal-quaternary-default",
								"hover:bg-button-normal-quaternary-hover",
								"focus:bg-button-normal-quaternary-focus",
								"active:bg-button-normal-quaternary-active",
								"disabled:bg-button-normal-quaternary-disabled",
								// Text
								"text-button-normal-quaternary-default",
								"hover:text-button-normal-quaternary-hover",
								"focus:text-button-normal-quaternary-focus",
								"active:text-button-normal-quaternary-active",
								"disabled:text-button-normal-quaternary-disabled",
								...(loading ? [] : ["underline", "hover:no-underline"]),
								// Icon
								"[&>svg]:fill-icon-button-normal-quaternary-default",
								"[&>svg]:hover:fill-icon-button-normal-quaternary-hover",
								"[&>svg]:focus:fill-icon-button-normal-quaternary-focus",
								"[&>svg]:active:fill-icon-button-normal-quaternary-active",
								"[&>svg]:disabled:fill-icon-button-normal-quaternary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-normal-quaternary-hover",
								"text-button-normal-quaternary-hover",
								"[&>svg]:fill-icon-button-normal-quaternary-hover",
							];
						case "focus":
							return [
								"bg-button-normal-quaternary-focus",
								"text-button-normal-quaternary-focus",
								"[&>svg]:fill-icon-button-normal-quaternary-focus",
							];
						case "active":
							return [
								"bg-button-normal-quaternary-active",
								"text-button-normal-quaternary-active",
								"[&>svg]:fill-icon-button-normal-quaternary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-normal-quaternary-disabled",
								"text-button-normal-quaternary-disabled",
								"[&>svg]:fill-icon-button-normal-quaternary-disabled",
							];
					}
			}
		case "negative":
			switch (level) {
				case "primary":
					/*** NEGATIVE / PRIMARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-negative",
								// BG
								"bg-button-negative-primary-default",
								"hover:bg-button-negative-primary-hover",
								"focus:bg-button-negative-primary-focus",
								"active:bg-button-negative-primary-active",
								"disabled:bg-button-negative-primary-disabled",
								// Text
								"text-button-negative-primary-default",
								"hover:text-button-negative-primary-hover",
								"focus:text-button-negative-primary-focus",
								"active:text-button-negative-primary-active",
								"disabled:text-button-negative-primary-disabled",
								// Border
								"shadow-border-negative-default-raised",
								"hover:shadow-border-negative-hover-raised",
								"focus:shadow-border-negative-focus",
								"active:shadow-border-negative-active",
								"disabled:shadow-border-negative-disabled",
								// Icon
								"[&>svg]:fill-icon-button-negative-primary-default",
								"[&>svg]:hover:fill-icon-button-negative-primary-hover",
								"[&>svg]:focus:fill-icon-button-negative-primary-focus",
								"[&>svg]:active:fill-icon-button-negative-primary-active",
								"[&>svg]:disabled:fill-icon-button-negative-primary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-negative-primary-hover",
								"text-button-negative-primary-hover",
								"[&>svg]:fill-icon-button-negative-primary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-negative",
								"bg-button-negative-primary-focus",
								"text-button-negative-primary-focus",
								"[&>svg]:fill-icon-button-negative-primary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-negative",
								"bg-button-negative-primary-active",
								"text-button-negative-primary-active",
								"[&>svg]:fill-icon-button-negative-primary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-negative-primary-disabled",
								"text-button-negative-primary-disabled",
								"[&>svg]:fill-icon-button-negative-primary-disabled",
							];
					}
				case "secondary":
				default:
					/*** NEGATIVE / SECONDARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-negative",
								// BG
								"bg-button-negative-secondary-default",
								"hover:bg-button-negative-secondary-hover",
								"focus:bg-button-negative-secondary-focus",
								"active:bg-button-negative-secondary-active",
								"disabled:bg-button-negative-secondary-disabled",
								// Text
								"text-button-negative-secondary-default",
								"hover:text-button-negative-secondary-hover",
								"focus:text-button-negative-secondary-focus",
								"active:text-button-negative-secondary-active",
								"disabled:text-button-negative-secondary-disabled",
								// Border
								"shadow-border-negative-default",
								"hover:shadow-border-negative-hover",
								"focus:shadow-border-negative-focus",
								"active:shadow-border-negative-active",
								"disabled:shadow-border-negative-disabled",
								// Icon
								"[&>svg]:fill-icon-button-negative-secondary-default",
								"[&>svg]:hover:fill-icon-button-negative-secondary-hover",
								"[&>svg]:focus:fill-icon-button-negative-secondary-focus",
								"[&>svg]:active:fill-icon-button-negative-secondary-active",
								"[&>svg]:disabled:fill-icon-button-negative-secondary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-negative-secondary-hover",
								"text-button-negative-secondary-hover",
								"shadow-border-negative-hover",
								"[&>svg]:fill-icon-button-negative-secondary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-negative",
								"bg-button-negative-secondary-focus",
								"text-button-negative-secondary-focus",
								"shadow-border-negative-focus",
								"[&>svg]:fill-icon-button-negative-secondary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-negative",
								"bg-button-negative-secondary-active",
								"text-button-negative-secondary-active",
								"shadow-border-negative-active",
								"[&>svg]:fill-icon-button-negative-secondary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-negative-secondary-disabled",
								"text-button-negative-secondary-disabled",
								"shadow-border-negative-disabled",
								"[&>svg]:fill-icon-button-negative-secondary-disabled",
							];
					}
				case "tertiary":
					/*** NEGATIVE / TERTIARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-negative",
								// BG
								"bg-button-negative-tertiary-default",
								"hover:bg-button-negative-tertiary-hover",
								"focus:bg-button-negative-tertiary-focus",
								"active:bg-button-negative-tertiary-active",
								"disabled:bg-button-negative-tertiary-disabled",
								// Text
								"text-button-negative-tertiary-default",
								"hover:text-button-negative-tertiary-hover",
								"focus:text-button-negative-tertiary-focus",
								"active:text-button-negative-tertiary-active",
								"disabled:text-button-negative-tertiary-disabled",
								// Border
								"focus:shadow-border-negative-focus",
								"active:shadow-border-negative-active",
								// Icon
								"[&>svg]:fill-icon-button-negative-tertiary-default",
								"[&>svg]:hover:fill-icon-button-negative-tertiary-hover",
								"[&>svg]:focus:fill-icon-button-negative-tertiary-focus",
								"[&>svg]:active:fill-icon-button-negative-tertiary-active",
								"[&>svg]:disabled:fill-icon-button-negative-tertiary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-negative-tertiary-hover",
								"text-button-negative-tertiary-hover",
								"[&>svg]:fill-icon-button-negative-tertiary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-negative",
								"bg-button-negative-tertiary-focus",
								"text-button-negative-tertiary-focus",
								"shadow-border-negative-focus",
								"[&>svg]:fill-icon-button-negative-tertiary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-negative",
								"bg-button-negative-tertiary-active",
								"text-button-negative-tertiary-active",
								"shadow-border-negative-active",
								"[&>svg]:fill-icon-button-negative-tertiary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-negative-tertiary-disabled",
								"text-button-negative-tertiary-disabled",
								"[&>svg]:fill-icon-button-negative-tertiary-disabled",
							];
					}
				case "quaternary":
					/*** NEGATIVE / QUATERNARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-negative",
								// BG
								"bg-button-negative-quaternary-default",
								"hover:bg-button-negative-quaternary-hover",
								"focus:bg-button-negative-quaternary-focus",
								"active:bg-button-negative-quaternary-active",
								"disabled:bg-button-negative-quaternary-disabled",
								// Text
								"text-button-negative-quaternary-default",
								"hover:text-button-negative-quaternary-hover",
								"focus:text-button-negative-quaternary-focus",
								"active:text-button-negative-quaternary-active",
								"disabled:text-button-negative-quaternary-disabled",
								...(loading ? [] : ["underline", "hover:no-underline"]),
								// Icon
								"[&>svg]:fill-icon-button-negative-quaternary-default",
								"[&>svg]:hover:fill-icon-button-negative-quaternary-hover",
								"[&>svg]:focus:fill-icon-button-negative-quaternary-focus",
								"[&>svg]:active:fill-icon-button-negative-quaternary-active",
								"[&>svg]:disabled:fill-icon-button-negative-quaternary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-negative-quaternary-hover",
								"text-button-negative-quaternary-hover",
								"[&>svg]:fill-icon-button-negative-quaternary-hover",
							];
						case "focus":
							return [
								"bg-button-negative-quaternary-focus",
								"text-button-negative-quaternary-focus",
								"[&>svg]:fill-icon-button-negative-quaternary-focus",
							];
						case "active":
							return [
								"bg-button-negative-quaternary-active",
								"text-button-negative-quaternary-active",
								"[&>svg]:fill-icon-button-negative-quaternary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-negative-quaternary-disabled",
								"text-button-negative-quaternary-disabled",
								"[&>svg]:fill-icon-button-negative-quaternary-disabled",
							];
					}
			}
		case "positive":
			switch (level) {
				case "primary":
					/*** POSITIVE / PRIMARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-positive",
								// BG
								"bg-button-positive-primary-default",
								"hover:bg-button-positive-primary-hover",
								"focus:bg-button-positive-primary-focus",
								"active:bg-button-positive-primary-active",
								"disabled:bg-button-positive-primary-disabled",
								// Text
								"text-button-positive-primary-default",
								"hover:text-button-positive-primary-hover",
								"focus:text-button-positive-primary-focus",
								"active:text-button-positive-primary-active",
								"disabled:text-button-positive-primary-disabled",
								// Border
								"shadow-border-positive-default-raised",
								"hover:shadow-border-positive-hover-raised",
								"focus:shadow-border-positive-focus",
								"active:shadow-border-positive-active",
								"disabled:shadow-border-positive-disabled",
								// Icon
								"[&>svg]:fill-icon-button-positive-primary-default",
								"[&>svg]:hover:fill-icon-button-positive-primary-hover",
								"[&>svg]:focus:fill-icon-button-positive-primary-focus",
								"[&>svg]:active:fill-icon-button-positive-primary-active",
								"[&>svg]:disabled:fill-icon-button-positive-primary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-positive-primary-hover",
								"text-button-positive-primary-hover",
								"[&>svg]:fill-icon-button-positive-primary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-positive",
								"bg-button-positive-primary-focus",
								"text-button-positive-primary-focus",
								"[&>svg]:fill-icon-button-positive-primary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-positive",
								"bg-button-positive-primary-active",
								"text-button-positive-primary-active",
								"[&>svg]:fill-icon-button-positive-primary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-positive-primary-disabled",
								"text-button-positive-primary-disabled",
								"[&>svg]:fill-icon-button-positive-primary-disabled",
							];
					}
				case "secondary":
				default:
					/*** POSITIVE / SECONDARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-positive",
								// BG
								"bg-button-positive-secondary-default",
								"hover:bg-button-positive-secondary-hover",
								"focus:bg-button-positive-secondary-focus",
								"active:bg-button-positive-secondary-active",
								"disabled:bg-button-positive-secondary-disabled",
								// Text
								"text-button-positive-secondary-default",
								"hover:text-button-positive-secondary-hover",
								"focus:text-button-positive-secondary-focus",
								"active:text-button-positive-secondary-active",
								"disabled:text-button-positive-secondary-disabled",
								// Border
								"shadow-border-positive-default",
								"hover:shadow-border-positive-hover",
								"focus:shadow-border-positive-focus",
								"active:shadow-border-positive-active",
								"disabled:shadow-border-positive-disabled",
								// Icon
								"[&>svg]:fill-icon-button-positive-secondary-default",
								"[&>svg]:hover:fill-icon-button-positive-secondary-hover",
								"[&>svg]:focus:fill-icon-button-positive-secondary-focus",
								"[&>svg]:active:fill-icon-button-positive-secondary-active",
								"[&>svg]:disabled:fill-icon-button-positive-secondary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-positive-secondary-hover",
								"text-button-positive-secondary-hover",
								"shadow-border-positive-hover",
								"[&>svg]:fill-icon-button-positive-secondary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-positive",
								"bg-button-positive-secondary-focus",
								"text-button-positive-secondary-focus",
								"shadow-border-positive-focus",
								"[&>svg]:fill-icon-button-positive-secondary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-positive",
								"bg-button-positive-secondary-active",
								"text-button-positive-secondary-active",
								"shadow-border-positive-active",
								"[&>svg]:fill-icon-button-positive-secondary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-positive-secondary-disabled",
								"text-button-positive-secondary-disabled",
								"shadow-border-positive-disabled",
								"[&>svg]:fill-icon-button-positive-secondary-disabled",
							];
					}
				case "tertiary":
					/*** POSITIVE / TERTIARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-positive",
								// BG
								"bg-button-positive-tertiary-default",
								"hover:bg-button-positive-tertiary-hover",
								"focus:bg-button-positive-tertiary-focus",
								"active:bg-button-positive-tertiary-active",
								"disabled:bg-button-positive-tertiary-disabled",
								// Text
								"text-button-positive-tertiary-default",
								"hover:text-button-positive-tertiary-hover",
								"focus:text-button-positive-tertiary-focus",
								"active:text-button-positive-tertiary-active",
								"disabled:text-button-positive-tertiary-disabled",
								// Border
								"focus:shadow-border-positive-focus",
								"active:shadow-border-positive-active",
								// Icon
								"[&>svg]:fill-icon-button-positive-tertiary-default",
								"[&>svg]:hover:fill-icon-button-positive-tertiary-hover",
								"[&>svg]:focus:fill-icon-button-positive-tertiary-focus",
								"[&>svg]:active:fill-icon-button-positive-tertiary-active",
								"[&>svg]:disabled:fill-icon-button-positive-tertiary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-positive-tertiary-hover",
								"text-button-positive-tertiary-hover",
								"[&>svg]:fill-icon-button-positive-tertiary-hover",
							];
						case "focus":
							return [
								commonFocusClasses,
								"ring-positive",
								"bg-button-positive-tertiary-focus",
								"text-button-positive-tertiary-focus",
								"shadow-border-positive-focus",
								"[&>svg]:fill-icon-button-positive-tertiary-focus",
							];
						case "active":
							return [
								commonActiveClasses,
								"ring-positive",
								"bg-button-positive-tertiary-active",
								"text-button-positive-tertiary-active",
								"shadow-border-positive-active",
								"[&>svg]:fill-icon-button-positive-tertiary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-positive-tertiary-disabled",
								"text-button-positive-tertiary-disabled",
								"[&>svg]:fill-icon-button-positive-tertiary-disabled",
							];
					}
				case "quaternary":
					/*** POSITIVE / QUATERNARY ***/
					switch (state) {
						case "default":
						default:
							return [
								// Ring
								"ring-positive",
								// BG
								"bg-button-positive-quaternary-default",
								"hover:bg-button-positive-quaternary-hover",
								"focus:bg-button-positive-quaternary-focus",
								"active:bg-button-positive-quaternary-active",
								"disabled:bg-button-positive-quaternary-disabled",
								// Text
								"text-button-positive-quaternary-default",
								"hover:text-button-positive-quaternary-hover",
								"focus:text-button-positive-quaternary-focus",
								"active:text-button-positive-quaternary-active",
								"disabled:text-button-positive-quaternary-disabled",
								...(loading ? [] : ["underline", "hover:no-underline"]),
								// Icon
								"[&>svg]:fill-icon-button-positive-quaternary-default",
								"[&>svg]:hover:fill-icon-button-positive-quaternary-hover",
								"[&>svg]:focus:fill-icon-button-positive-quaternary-focus",
								"[&>svg]:active:fill-icon-button-positive-quaternary-active",
								"[&>svg]:disabled:fill-icon-button-positive-quaternary-disabled",
							];
						case "hover":
							return [
								commonHoverClasses,
								"bg-button-positive-quaternary-hover",
								"text-button-positive-quaternary-hover",
								"[&>svg]:fill-icon-button-positive-quaternary-hover",
							];
						case "focus":
							return [
								"bg-button-positive-quaternary-focus",
								"text-button-positive-quaternary-focus",
								"[&>svg]:fill-icon-button-positive-quaternary-focus",
							];
						case "active":
							return [
								"bg-button-positive-quaternary-active",
								"text-button-positive-quaternary-active",
								"[&>svg]:fill-icon-button-positive-quaternary-active",
							];
						case "disabled":
							return [
								commonDisabledClasses,
								"bg-button-positive-quaternary-disabled",
								"text-button-positive-quaternary-disabled",
								"[&>svg]:fill-icon-button-positive-quaternary-disabled",
							];
					}
			}
	}
};
