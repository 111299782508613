import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type User } from "../../models/user";
import { userLoaded } from "../auth/actions";
import { type UsersState } from "./types";

const initialState: UsersState = {
	users: {},
	usersByEmail: {},
};

export const usersSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		setUser: (state: UsersState, action: PayloadAction<{ user: User }>) => {
			const { user } = action.payload;
			state.users[user.id] = user;
			const email = user.email;
			if (email !== "") {
				state.usersByEmail[email] = user.id;
			}
		},

		setUsers: (state: UsersState, action: PayloadAction<{ users: User[] }>) => {
			const { users } = action.payload;

			users.forEach((user) => {
				state.users[user.id] = user;
				const email = user.email;
				if (email !== "") {
					state.usersByEmail[email] = user.id;
				}
			});
		},
	},
	extraReducers: (builder) => {
		builder.addCase(userLoaded, (state, action) => {
			const { user } = action.payload;
			state.users[user.id] = user;
			const email = user.email;
			if (email !== "") {
				state.usersByEmail[email] = user.id;
			}
		});
	},
});

export const users = usersSlice.reducer;

export const { setUser, setUsers } = usersSlice.actions;
