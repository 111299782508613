import z from "zod";

import {
	type BroadcastTransactionProcessedEvent,
	transactionProcessedSuccess,
} from "~/lib/api-canvas-metadata/TransactionProcessedEvent";
import { type Checksum, checksum } from "~/lib/api-types/checksum";
import { metadataMessageActiveUsersBroadcast } from "~/lib/api-types/messageActiveUsersBroadcast";
import { messageSelectionsUpdatedBroadcast } from "~/lib/api-types/messageSelectionsUpdatedBroadcast";
import { persistenceError } from "~/lib/api-types/persistenceError";
import { pongMessage } from "~/lib/api-types/pingpong";

import { type ProseMirrorClientId, prosemirrorClientId } from "./prosemirrorClientId";

//
// Transaction Broadcast for collab-server.
//

export const messageTransactionBroadcast = z.object({
	type: z.literal("transaction-broadcast"),
	version: z.number(),
	steps: z.array(z.unknown()),
	prosemirrorClientId,
	checksum: checksum.optional(),
});
export type MessageTransactionBroadcast = z.infer<typeof messageTransactionBroadcast>;

export function makeMessageTransactionBroadcast(
	version: number,
	steps: unknown[],
	prosemirrorClientId: ProseMirrorClientId,
	checksum?: Checksum | undefined
): MessageTransactionBroadcast {
	return {
		type: "transaction-broadcast",
		version,
		steps,
		prosemirrorClientId,
		checksum,
	};
}

export const metadataMessageTransactionBroadcast = z.object({
	type: z.literal("transaction-broadcast"),
	version: z.number(),
	transactionProcessedEvent: transactionProcessedSuccess,
});
export const disconnectMessageBroadcast = z.object({
	type: z.literal("disconnect"),
	message: z.optional(z.string()),
});
export const makeDisconnectMessage = (message: string) => {
	return {
		type: "disconnect",
		message,
	};
};

export type MetadataMessageTransactionBroadcast = z.infer<
	typeof metadataMessageTransactionBroadcast
>;

export function makeMetadataMessageTransactionBroadcast(
	version: number,
	transactionProcessedEvent: BroadcastTransactionProcessedEvent
): MetadataMessageTransactionBroadcast {
	return { type: "transaction-broadcast", version, transactionProcessedEvent };
}

/**
 * Broadcast message unions. These are primarily used for the types and not for the schema validator.
 */
export const pageLocalStorageMessageTransactionBroadcast = metadataMessageTransactionBroadcast;

const collabBroadcastMessage = z.union([
	messageTransactionBroadcast,
	persistenceError,
	messageSelectionsUpdatedBroadcast,
	disconnectMessageBroadcast,
	pongMessage,
]);
export type CollabBroadcastMessage = z.infer<typeof collabBroadcastMessage>;

const metadataBroadcastMessage = z.union([
	metadataMessageTransactionBroadcast,
	disconnectMessageBroadcast,
	pongMessage,
	persistenceError,
	metadataMessageActiveUsersBroadcast,
]);
export type MetadataBroadcastMessage = z.infer<typeof metadataBroadcastMessage>;
