import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type CanvasID } from "~/models/canvas";
import { type InviteInput, type OrgRole, type Role } from "~/models/roles";

import { type PermissionsState } from "./types";

const initialState: PermissionsState = {
	userPermissions: {},
	inviteInputs: {},
	orgPermissions: {},
	globalPermissions: {},
	userPermissionsDraft: {},
	orgPermissionsDraft: {},
};

export const permissionsSlice = createSlice({
	name: "permissions",
	initialState,
	reducers: {
		addUserPermissions: (
			state: PermissionsState,
			action: PayloadAction<{
				canvasID: CanvasID;
				permissions: { [userID: string]: Role };
			}>
		) => {
			const { canvasID, permissions } = action.payload;
			state.userPermissions[canvasID] = permissions;
		},

		addInviteInputs: (
			state: PermissionsState,
			action: PayloadAction<{
				canvasID: CanvasID;
				inviteInputs: { [email: string]: InviteInput };
			}>
		) => {
			const { canvasID, inviteInputs } = action.payload;
			state.inviteInputs[canvasID] = inviteInputs;
		},

		addOrgPermissions: (
			state: PermissionsState,
			action: PayloadAction<{
				canvasID: CanvasID;
				permissions?: OrgRole;
			}>
		) => {
			const { canvasID, permissions } = action.payload;

			if (!permissions) {
				delete state.orgPermissions[canvasID];
				return;
			}

			state.orgPermissions[canvasID] = permissions;
		},

		addGlobalPermissions: (
			state: PermissionsState,
			action: PayloadAction<{
				canvasID: CanvasID;
				visibleToAllOrgs: boolean;
			}>
		) => {
			const { canvasID, visibleToAllOrgs } = action.payload;
			state.globalPermissions[canvasID] = visibleToAllOrgs;
		},

		//
		// Draft actions.
		//

		clearPermissionDrafts: (
			state: PermissionsState,
			action: PayloadAction<{ canvasID: string }>
		) => {
			const { canvasID } = action.payload;

			delete state.userPermissionsDraft[canvasID];
			delete state.orgPermissionsDraft[canvasID];
		},

		clearUserPermissionsDraft: (
			state: PermissionsState,
			action: PayloadAction<{ canvasID: string }>
		) => {
			const { canvasID } = action.payload;

			delete state.userPermissionsDraft[canvasID];
		},

		clearOrgPermissionsDraft: (
			state: PermissionsState,
			action: PayloadAction<{ canvasID: string }>
		) => {
			const { canvasID } = action.payload;

			delete state.orgPermissionsDraft[canvasID];
		},
	},
});

export const permissions = permissionsSlice.reducer;
export const {
	addUserPermissions,
	addInviteInputs,
	addOrgPermissions,
	addGlobalPermissions,
	clearPermissionDrafts,
	clearUserPermissionsDraft,
	clearOrgPermissionsDraft,
} = permissionsSlice.actions;
