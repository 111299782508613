import { atlasProxyFetchWithEnv } from "~/models/atlas";
import type { Env } from "~/utils/common";

import { ModelCache } from "../cache";
import {
	type PagerDutyIncident,
	type PagerDutyOnCall,
	getIncidents,
	getOncallsForService,
} from "../pagerduty";
import { Service, ServiceCatalog, type ServiceCatalogType, type ServiceType } from "./schema";

const serviceCatalogCache = new ModelCache<ServiceCatalogType>();
const serviceCache = new ModelCache<ServiceType | undefined>();

export const dedupeArray = <T>(items: T[], getMapKey: (T) => string) => {
	const map: Record<string, boolean> = {};
	return items.filter((item) => {
		const key = getMapKey(item);
		if (map[key]) {
			return false;
		} else {
			map[key] = true;
			return true;
		}
	});
};

export const fetchServiceCatalog = (env: Env) =>
	serviceCatalogCache.useCache(async () => {
		const response = await atlasProxyFetchWithEnv(env, "/v1/apis/moment/catalog");
		const json = await response.json();

		// Validate with zod
		return ServiceCatalog.parse(json);
	}, "serviceCatalog");

export const fetchService = (env: Env, name: string) =>
	serviceCache.useCache(async () => {
		const serviceCatalog = await fetchServiceCatalog(env);
		const service = serviceCatalog.services?.find((s) => s.metadata.name === name);

		if (!service) {
			return;
		}

		service.spec.oncall = service.spec.oncall || [];
		for (const oncall of service.spec.oncall) {
			const { serviceId } = oncall;
			if (oncall.type === "pagerduty" && serviceId) {
				const pagerdutyOnCalls = await getOncallsForService(env, serviceId);
				oncall.members = dedupeArray<PagerDutyOnCall>(
					oncall.members ? oncall.members.concat(pagerdutyOnCalls) : pagerdutyOnCalls,
					(member: PagerDutyOnCall) => member.user.email
				);

				const incidents = await getIncidents(env, serviceId);
				oncall.incidents = dedupeArray<PagerDutyIncident>(
					oncall.incidents ? oncall.incidents.concat(incidents) : incidents,
					(incident: PagerDutyIncident) => incident.id
				);
			}
		}

		return Service.parse(service);
	}, name);
