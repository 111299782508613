import { useCallback, useEffect, useState } from "react";

export const useNetworkStatus = () => {
	const [status, setStatus] = useState<"offline" | "online">("online");
	const [prevStatus, setPrevStatus] = useState<"offline" | "online" | null>(null);

	const statusChanged = useCallback(
		(input: "offline" | "online") => {
			setPrevStatus(() => status);
			setStatus(() => input);
		},
		[status]
	);

	useEffect(() => {
		// set initial value
		setStatus(() => (navigator.onLine ? "online" : "offline"));
	}, []);

	useEffect(() => {
		const offlineHandler = () => statusChanged("offline");
		const onlineHandler = () => statusChanged("online");

		window.addEventListener("offline", offlineHandler);
		window.addEventListener("online", onlineHandler);

		return () => {
			window.removeEventListener("offline", offlineHandler);
			window.removeEventListener("online", onlineHandler);
		};
	}, [statusChanged]);

	return { status, prevStatus };
};
