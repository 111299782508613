import { type DOMOutputSpec, type MarkSpec } from "prosemirror-model";

// :: MarkSpec Code font mark. Represented as a `<code>` element.

const codeDOM: DOMOutputSpec = ["code", 0];

export const code: MarkSpec = {
	parseDOM: [{ tag: "code" }],
	inclusive: false,
	toDOM() {
		return codeDOM;
	},
};
