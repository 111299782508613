import { type NodeSpec } from "prosemirror-model";

import { blockquote } from "./blockquote";
import { bullet_list } from "./bullet_list";
import { callout } from "./callout";
import { code_block } from "./code_block";
import { compute_cell } from "./compute_cell";
import { doc } from "./doc";
import { document_placeholder } from "./document_placeholder";
import { hard_break } from "./hard_break";
import { heading } from "./heading";
import { horizontal_rule } from "./horizontal_rule";
import { image } from "./image";
import { image_placeholder } from "./image_placeholder";
import { list_item } from "./list_item";
import { ordered_list } from "./ordered_list";
import { paragraph } from "./paragraph";
import { text } from "./text";
import { text_cell } from "./text_cell";
import { to_do_item } from "./to_do_item";
import { to_do_list } from "./to_do_list";

type NodeSpecNames =
	| "doc"
	| "document_placeholder"
	| "compute_cell"
	| "text_cell"
	| "blockquote"
	| "callout"
	| "paragraph"
	| "horizontal_rule"
	| "heading"
	| "image"
	| "image_placeholder"
	| "code_block"
	| "text"
	| "hard_break"
	| "ordered_list"
	| "bullet_list"
	| "list_item"
	| "to_do_item"
	| "to_do_list";

export const nodes: {
	[val in NodeSpecNames]: NodeSpec;
} = {
	doc,
	document_placeholder,
	compute_cell,
	text_cell,
	paragraph,
	blockquote,
	callout,
	horizontal_rule,
	heading,
	image,
	image_placeholder,
	code_block,
	text,
	hard_break,
	ordered_list,
	bullet_list,
	list_item,
	to_do_item,
	to_do_list,
};
