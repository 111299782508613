import { type LoggerInterface } from "~/lib/logger-types";

export interface Timer {
	timeFunction<T>(name: string, fn: () => T): T;

	timeAsyncFunction<T>(name: string, fn: () => Promise<T>): Promise<T>;
}

const DURATION_MILLIS_THRESHOLD_FOR_LOGS = 10;

export class LogTimer implements Timer {
	constructor(private logger: LoggerInterface) {}

	async timeAsyncFunction<T>(name: string, fn: () => Promise<T>): Promise<T> {
		const start = performance.now();
		let errorOccurred = false;
		try {
			return await fn();
		} catch (err) {
			errorOccurred = true;
			throw err;
		} finally {
			const end = performance.now();
			const completion = end - start;
			// In order to avoid a nosy timers which are basically an instant.
			// Might bias our data.
			if (completion > 0) {
				this.logger.info(`[${name}] timed function`, {
					functionName: name,
					completion,
					errorOccurred,
				});
			}
		}
	}

	timeFunction<T>(name: string, fn: () => T): T {
		const start = performance.now();
		let errorOccurred = false;
		try {
			return fn();
		} catch (err) {
			errorOccurred = true;
			throw err;
		} finally {
			const end = performance.now();
			const completion = end - start;
			// In order to avoid a nosy timers which are basically an instant.
			// Might bias our data.
			if (completion > DURATION_MILLIS_THRESHOLD_FOR_LOGS) {
				this.logger.info(`[${name}] timed function`, {
					functionName: name,
					completion,
					errorOccurred,
				});
			}
		}
	}
}
