import z from "zod";

export const loggingRequestSchema = z.object({
	logLevel: z.string(),
	service: z.string(),
	blob: z.unknown(),
	message: z.string(),
});

export type LoggingRequestSchema = z.infer<typeof loggingRequestSchema>;
