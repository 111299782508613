export type LogFunc = (message: string, content?: Record<string, unknown>) => void;
export type LogLevel = "debug" | "info" | "warn" | "error";

export type BaseLogFunc = (
	level: LogLevel,
	message: string,
	content?: Record<string, unknown>
) => void;

export interface LoggerInterface {
	resetRequestHeader?: () => void;
	initializeMetadata?: (metadata: Record<string, unknown>) => void;
	debug: LogFunc;
	info: LogFunc;
	warn: LogFunc;
	error: LogFunc;
}

class NoOpLogger implements LoggerInterface {
	public initializeMetadata = (_: Record<string, unknown>) => {
		// Do nothing.
	};

	public debug: LogFunc = (_message, _content) => {
		// Do nothing.
	};

	public info: LogFunc = (_message, _content) => {
		// Do nothing.
	};

	public warn: LogFunc = (_message, _content) => {
		// Do nothing.
	};

	public error: LogFunc = (_message, _content) => {
		// Do nothing.
	};

	public resetRequestHeader() {
		// Do nothing.
	}
}

export const noopLogger = new NoOpLogger();
