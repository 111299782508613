/* eslint-disable react/jsx-max-depth */
import { ThemeProvider } from "@moment/design-system/ThemeProvider";
import { type FC, type PropsWithChildren } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { AnalyticsProvider } from "~/components/analytics/AnalyticsProvider";
import { ConfirmModalWrapper } from "~/components/common/confirm-modal/ConfirmModalContext";
import { TopBarProvider } from "~/components/topbar/TopBarContext";
import { Flags, isEnabled } from "~/features";
import { persistor, store } from "~/store/store";

import { CollabClientsProvider } from "./components/canvas/easel/collab/CollabClientsProvider";
import { PropertiesPaneProvider } from "./components/canvas/easel/properties-pane/PropertiesPaneProvider";
import { ConfigProvider } from "./components/config/ConfigProvider";
import { OutdatedAppModalWrapper } from "./components/outdated-app/OutdatedAppModalContext";

export const Providers: FC<PropsWithChildren<{ cookies?: string }>> = ({ cookies, children }) => {
	return (
		<ReduxProvider store={store}>
			<AnalyticsProvider>
				{/* ThemeProvider must be above PersistGate to render on the server */}
				<ThemeProvider isEnabled={isEnabled(Flags.ThemeingAllowDarkMode, cookies)}>
					<PersistGate loading={null} persistor={persistor}>
						<ConfigProvider>
							<CollabClientsProvider>
								<TopBarProvider>
									<PropertiesPaneProvider>
										<ConfirmModalWrapper>
											<OutdatedAppModalWrapper>
												{children}
											</OutdatedAppModalWrapper>
										</ConfirmModalWrapper>
									</PropertiesPaneProvider>
								</TopBarProvider>
							</CollabClientsProvider>
						</ConfigProvider>
					</PersistGate>
				</ThemeProvider>
			</AnalyticsProvider>
		</ReduxProvider>
	);
};
