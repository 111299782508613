export const toTitleCase = (s: string) =>
	s.replace(/(\w)([^\s]*)/g, (match, p1, p2) => `${p1.toUpperCase()}${p2}`);

export const camelCaseToSnakeCase = (str: string) => str.replace(/([A-Z])/g, "_$1");

export const strToSnakeCase = (str: string) => str.replace(/\s/g, "_").toLowerCase();

export const strToCamelCase = (str: string) => {
	return str
		.toLowerCase()
		.split(" ")
		.map((word, idx) => (idx > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
		.join("");
};

export const truncate = (str: string, n: number) => {
	return str.length > n ? str.slice(0, n - 1) + "..." : str;
};
