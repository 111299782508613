import { useRouter } from "next/router";
import { useEffect } from "react";

import { selectCurrentUserEmailAddress, selectProfile, selectUserID } from "~/store/auth/selectors";
import { useAppSelector } from "~/store/hooks";
import { selectUser } from "~/store/users/selectors";

export const Intercom = () => {
	// user
	let currentUserEmail = useAppSelector(selectCurrentUserEmailAddress);
	const currentUserId = useAppSelector(selectUserID);
	const user = useAppSelector(selectUser(currentUserId || ""));

	// Sometimes the email is not set in the claims.
	// Unsure why this would happen, but check the user redux when it does.
	if (currentUserEmail === "") {
		currentUserEmail = user?.email || "";
	}

	const authProfile = useAppSelector(selectProfile);
	const router = useRouter();

	useEffect(() => {
		window["Intercom"]?.("boot", {
			api_base: "https://api-iam.intercom.io",
			app_id: "j5hcdbw2",
			user_id: currentUserId,
			email: currentUserEmail,
			name: authProfile?.name,
			user_hash: authProfile?.intercomUserHash,
		});
	}, [currentUserId]);

	useEffect(() => {
		const handleRouteChange = () => {
			window["Intercom"]?.("update");
		};

		router.events.on("routeChangeComplete", handleRouteChange);

		return () => {
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, [router.events]);

	return null;
};
