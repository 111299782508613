// This is a generated file, do not edit directly
/* eslint-disable @typescript-eslint/no-explicit-any */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
};

export type ArchivedCanvasItem = {
  __typename?: 'ArchivedCanvasItem';
  archivedByID: Scalars['ID']['output'];
  canvasID: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  organizationID: Scalars['ID']['output'];
  ownerID: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type ArchivedCanvasesInput = {
  direction: PagingDirection;
  lastKey: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};

export type ArchivedCanvasesOutput = {
  __typename?: 'ArchivedCanvasesOutput';
  canvases: Array<ArchivedCanvasItem>;
  lastKey: Maybe<Scalars['String']['output']>;
};

export type AuthorizeInput = {
  canvasID: Scalars['ID']['input'];
  permissionNeeded: Permission;
};

export type AuthorizeResponse = {
  __typename?: 'AuthorizeResponse';
  canvasID: Scalars['ID']['output'];
  hasPermission: Scalars['Boolean']['output'];
  userID: Scalars['ID']['output'];
  userPermissions: Array<Permission>;
};

export type Canvas = {
  __typename?: 'Canvas';
  apiVersion: Scalars['String']['output'];
  archived: Scalars['Boolean']['output'];
  atlasUserToken: Maybe<Scalars['String']['output']>;
  channel: Scalars['String']['output'];
  created: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isDraft: Scalars['Boolean']['output'];
  isTemplate: Scalars['Boolean']['output'];
  organization: Scalars['ID']['output'];
  organizationID: Scalars['ID']['output'];
  owner: Scalars['ID']['output'];
  pages: Array<Page>;
  pinnedAt: Scalars['Date']['output'];
  sharedName: Maybe<SharedName>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  version: Version;
};

export type CanvasActiveUser = {
  __typename?: 'CanvasActiveUser';
  tabID: Maybe<Scalars['String']['output']>;
  user: User;
};

export type CanvasDraftItem = {
  __typename?: 'CanvasDraftItem';
  authorID: Scalars['ID']['output'];
  canvasID: Scalars['ID']['output'];
  created: Scalars['String']['output'];
  updated: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type CanvasListingItem = {
  __typename?: 'CanvasListingItem';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationID: Scalars['ID']['output'];
  ownerID: Scalars['ID']['output'];
  pinnedAt: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type CanvasMetadata = {
  __typename?: 'CanvasMetadata';
  archived: Scalars['Boolean']['output'];
  archivedAt: Scalars['Date']['output'];
  atlasUserToken: Maybe<Scalars['String']['output']>;
  created: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isTemplate: Scalars['Boolean']['output'];
  latestVersion: Scalars['Int']['output'];
  organizationID: Scalars['ID']['output'];
  owner: Scalars['ID']['output'];
  pagesMetadata: Array<PageMetadata>;
  pinnedAt: Scalars['Date']['output'];
  sharedName: Maybe<SharedName>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type CanvasMetadataInput = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isTemplate: InputMaybe<Scalars['Boolean']['input']>;
  organizationID: Scalars['ID']['input'];
  owner: Scalars['ID']['input'];
  pages: Array<PageMetadataInput>;
  title: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};

export type CanvasRole = {
  __typename?: 'CanvasRole';
  externalOrganizationID: Maybe<Scalars['ID']['output']>;
  inviteToken: Maybe<Scalars['String']['output']>;
  recipient: Maybe<Scalars['String']['output']>;
  role: Role;
  serviceAccount: Scalars['ID']['output'];
  serviceAccountType: ServiceAccountType;
  sharedName: Scalars['String']['output'];
  unlisted: Maybe<Scalars['Boolean']['output']>;
};

export type CanvasRoles = {
  __typename?: 'CanvasRoles';
  canvasID: Scalars['ID']['output'];
  roles: Array<CanvasRole>;
  sharedName: Scalars['String']['output'];
};

export type CanvasRolesByShareType = {
  __typename?: 'CanvasRolesByShareType';
  external: Array<Role>;
  internal: Array<Role>;
};

export type CanvasVersionItem = {
  __typename?: 'CanvasVersionItem';
  authorID: Scalars['ID']['output'];
  canvasID: Scalars['ID']['output'];
  created: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CanvasVersionsPaginatedInput = {
  canvasID: Scalars['ID']['input'];
  lastKey: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};

export type CanvasVersionsPaginatedOutput = {
  __typename?: 'CanvasVersionsPaginatedOutput';
  lastKey: Maybe<Scalars['String']['output']>;
  versions: Array<CanvasVersionItem>;
};

export type CanvasWithRole = {
  __typename?: 'CanvasWithRole';
  canvas: Canvas;
  role: Role;
};

export type CanvasesOutput = {
  __typename?: 'CanvasesOutput';
  canvases: Array<CanvasListingItem>;
  lastKey: Maybe<Scalars['String']['output']>;
};

export type Cell = {
  __typename?: 'Cell';
  cellName: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  id: Maybe<Scalars['String']['output']>;
  nodeAttrs: Maybe<Scalars['String']['output']>;
  pinned: Maybe<Scalars['Boolean']['output']>;
  signoffRequired: Maybe<Scalars['Boolean']['output']>;
  type: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type CellInput = {
  cellName: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  id: InputMaybe<Scalars['String']['input']>;
  nodeAttrs: InputMaybe<Scalars['String']['input']>;
  pinned: InputMaybe<Scalars['Boolean']['input']>;
  signoffRequired: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  body: Scalars['String']['output'];
  canvasId: Scalars['ID']['output'];
  commentThreadId: Scalars['ID']['output'];
  created: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updated: Maybe<Scalars['Date']['output']>;
  updatedBy: Maybe<Scalars['ID']['output']>;
};

export type CommentResponse = {
  __typename?: 'CommentResponse';
  commentID: Scalars['ID']['output'];
  commentThreadID: Scalars['ID']['output'];
};

export type CommentThread = {
  __typename?: 'CommentThread';
  canvasId: Scalars['ID']['output'];
  cellId: Maybe<Scalars['ID']['output']>;
  comments: Array<Comment>;
  created: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  pageId: Scalars['ID']['output'];
  position: Maybe<Scalars['String']['output']>;
  status: CommentThreadStatus;
  updated: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export enum CommentThreadStatus {
  Deleted = 'DELETED',
  Open = 'OPEN',
  Resolved = 'RESOLVED'
}

export type CreateAtlasSecretTokenResponse = {
  __typename?: 'CreateAtlasSecretTokenResponse';
  token: SecretToken;
};

export type DuplicatePageInput = {
  canvasID: Scalars['ID']['input'];
  pageID: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCanvasInvite: Scalars['ID']['output'];
  addCommentThread: CommentResponse;
  addCommentToThread: CommentResponse;
  addUserCurrentlyActive: Scalars['Boolean']['output'];
  archiveCanvas2: Scalars['ID']['output'];
  archivePage: PageMetadata;
  createAtlasSecretToken: CreateAtlasSecretTokenResponse;
  deleteArchivedCanvas: Scalars['ID']['output'];
  deleteCanvas: Canvas;
  deleteComment: CommentResponse;
  deleteCommentThread: CommentResponse;
  discardCanvasDraft: Canvas;
  duplicateCanvas: Scalars['ID']['output'];
  duplicateCanvasV2: Scalars['ID']['output'];
  duplicatePage: Page;
  generateSignedUploadUrl: Scalars['String']['output'];
  inviteToCanvas: CanvasRoles;
  makeCanvasNotPublic: CanvasRoles;
  makeCanvasPublic: CanvasRoles;
  makeCanvasPublicUnlisted: CanvasRoles;
  newCanvas: Scalars['ID']['output'];
  newCanvasMetadata: CanvasMetadata;
  newPage: Page;
  pinCanvas: Canvas;
  resolveCommentThread: CommentResponse;
  revert: Canvas;
  revokeAtlasSecretToken: SecretToken;
  revokeInviteForCanvas: CanvasRoles;
  saveCanvas: Canvas;
  saveCanvasDraft: Scalars['ID']['output'];
  saveCanvasMetadata: CanvasMetadata;
  saveCommentMarkInCanvasForCommenterRole: Canvas;
  savePage: Page;
  setTemplate: Canvas;
  shareCanvasWithOrganization: CanvasRoles;
  shareCanvasWithUser: CanvasRoles;
  unarchiveCanvas2: Scalars['ID']['output'];
  unarchivePage: PageMetadata;
  unpinCanvas: Canvas;
  unsetTemplate: Canvas;
  unshareCanvasWithOrganization: CanvasRoles;
  unshareCanvasWithUser: CanvasRoles;
  updateComment: CommentResponse;
  updateOnboardingStatus: OnboardingState;
  updateUserInfo: User;
  updateVersionFields: Canvas;
};


export type MutationAcceptCanvasInviteArgs = {
  id: Scalars['ID']['input'];
  inviteToken: Scalars['String']['input'];
};


export type MutationAddCommentThreadArgs = {
  body: Scalars['String']['input'];
  canvasId: Scalars['ID']['input'];
  cellId: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
};


export type MutationAddCommentToThreadArgs = {
  body: Scalars['String']['input'];
  canvasId: Scalars['ID']['input'];
  commentThreadId: Scalars['ID']['input'];
};


export type MutationAddUserCurrentlyActiveArgs = {
  canvasID: Scalars['ID']['input'];
  tabID: InputMaybe<Scalars['String']['input']>;
  userID: Scalars['ID']['input'];
};


export type MutationArchiveCanvas2Args = {
  id: Scalars['ID']['input'];
};


export type MutationArchivePageArgs = {
  pageID: Scalars['ID']['input'];
};


export type MutationCreateAtlasSecretTokenArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteArchivedCanvasArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCanvasArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCommentArgs = {
  canvasId: Scalars['ID']['input'];
  commentId: Scalars['ID']['input'];
};


export type MutationDeleteCommentThreadArgs = {
  canvasId: Scalars['ID']['input'];
  commentThreadId: Scalars['ID']['input'];
};


export type MutationDiscardCanvasDraftArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDuplicateCanvasArgs = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  version: Scalars['Int']['input'];
};


export type MutationDuplicateCanvasV2Args = {
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};


export type MutationDuplicatePageArgs = {
  input: InputMaybe<DuplicatePageInput>;
};


export type MutationGenerateSignedUploadUrlArgs = {
  contentType: Scalars['String']['input'];
};


export type MutationInviteToCanvasArgs = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  role: Scalars['String']['input'];
};


export type MutationMakeCanvasNotPublicArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMakeCanvasPublicArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMakeCanvasPublicUnlistedArgs = {
  id: Scalars['ID']['input'];
};


export type MutationNewCanvasArgs = {
  input: InputMaybe<NewCanvas>;
};


export type MutationNewCanvasMetadataArgs = {
  title: Scalars['String']['input'];
};


export type MutationNewPageArgs = {
  page: NewPageInput;
};


export type MutationPinCanvasArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResolveCommentThreadArgs = {
  canvasId: Scalars['ID']['input'];
  commentThreadId: Scalars['ID']['input'];
};


export type MutationRevertArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


export type MutationRevokeAtlasSecretTokenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRevokeInviteForCanvasArgs = {
  id: Scalars['ID']['input'];
  inviteToken: Scalars['String']['input'];
};


export type MutationSaveCanvasArgs = {
  id: Scalars['ID']['input'];
  pages: InputMaybe<Array<PageInput>>;
  title: Scalars['String']['input'];
};


export type MutationSaveCanvasDraftArgs = {
  id: Scalars['ID']['input'];
  pages: InputMaybe<Array<PageInput>>;
  title: Scalars['String']['input'];
  version: InputMaybe<Scalars['Int']['input']>;
};


export type MutationSaveCanvasMetadataArgs = {
  metadata: CanvasMetadataInput;
};


export type MutationSaveCommentMarkInCanvasForCommenterRoleArgs = {
  id: Scalars['ID']['input'];
  pages: InputMaybe<Array<PageInput>>;
};


export type MutationSavePageArgs = {
  page: SavePageInput;
};


export type MutationSetTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationShareCanvasWithOrganizationArgs = {
  id: Scalars['ID']['input'];
  role: Scalars['String']['input'];
  sharedName: Scalars['String']['input'];
};


export type MutationShareCanvasWithUserArgs = {
  id: Scalars['ID']['input'];
  role: Scalars['String']['input'];
  userID: Scalars['ID']['input'];
};


export type MutationUnarchiveCanvas2Args = {
  id: Scalars['ID']['input'];
};


export type MutationUnarchivePageArgs = {
  pageID: Scalars['ID']['input'];
};


export type MutationUnpinCanvasArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnsetTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnshareCanvasWithOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnshareCanvasWithUserArgs = {
  id: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
};


export type MutationUpdateCommentArgs = {
  body: Scalars['String']['input'];
  canvasId: Scalars['ID']['input'];
  commentId: Scalars['ID']['input'];
};


export type MutationUpdateOnboardingStatusArgs = {
  featureName: OnboardingFeature;
  status: OnboardingStatus;
  userID: Scalars['String']['input'];
};


export type MutationUpdateUserInfoArgs = {
  id: Scalars['ID']['input'];
  input: UserInfoInput;
};


export type MutationUpdateVersionFieldsArgs = {
  canvasID: Scalars['ID']['input'];
  versionDescription: Scalars['String']['input'];
  versionID: Scalars['Int']['input'];
  versionName: Scalars['String']['input'];
};

export type Namespace = {
  __typename?: 'Namespace';
  ServiceAccount: Scalars['String']['output'];
  ServiceAccountType: ServiceAccountType;
  name: Scalars['String']['output'];
};

export type NewCanvas = {
  apiVersion: Scalars['String']['input'];
  channel: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type NewPageInput = {
  canvasID: Scalars['ID']['input'];
  parentPage: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export enum OnboardingFeature {
  FirstLogin = 'FIRST_LOGIN'
}

export type OnboardingState = {
  __typename?: 'OnboardingState';
  createdAt: Scalars['String']['output'];
  featureName: OnboardingFeature;
  onboardingCanvasID: Scalars['ID']['output'];
  status: OnboardingStatus;
  updatedAt: Scalars['String']['output'];
  userID: Scalars['ID']['output'];
};

export enum OnboardingStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export type OpenGraphImage = {
  __typename?: 'OpenGraphImage';
  height: Scalars['Int']['output'];
  secureURL: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type OpenGraphMetadata = {
  __typename?: 'OpenGraphMetadata';
  description: Maybe<Scalars['String']['output']>;
  images: Array<OpenGraphImage>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  domainNames: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: Scalars['ID']['output'];
};

export type Page = {
  __typename?: 'Page';
  archived: Scalars['Boolean']['output'];
  authorID: Scalars['ID']['output'];
  canvasID: Scalars['ID']['output'];
  cells: Array<Cell>;
  created: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  latestVersion: Scalars['Int']['output'];
  parentPageID: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated: Scalars['Date']['output'];
};

export type PageInput = {
  authorID: Scalars['String']['input'];
  cells: Array<CellInput>;
  id: Scalars['String']['input'];
  parentPageInput: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type PageMetadata = {
  __typename?: 'PageMetadata';
  archived: Scalars['Boolean']['output'];
  authorID: Scalars['ID']['output'];
  canvasID: Scalars['ID']['output'];
  created: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  parentPageID: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated: Scalars['Date']['output'];
};

export type PageMetadataInput = {
  archived: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  parentPageID: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export enum PagingDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum Permission {
  CommentManage = 'COMMENT_MANAGE',
  CommentRead = 'COMMENT_READ',
  CommentWrite = 'COMMENT_WRITE',
  CreateTemplate = 'CREATE_TEMPLATE',
  Delete = 'DELETE',
  Execute = 'EXECUTE',
  Pin = 'PIN',
  Read = 'READ',
  Share = 'SHARE',
  Write = 'WRITE'
}

export type PersonalOrganization = {
  __typename?: 'PersonalOrganization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  archivedCanvases: ArchivedCanvasesOutput;
  atlasUserToken: Scalars['String']['output'];
  authorized: AuthorizeResponse;
  canvas: Canvas;
  canvasActiveUsers: Maybe<Array<CanvasActiveUser>>;
  canvasAllVersions2: Array<CanvasVersionItem>;
  canvasBySharedName: Canvas;
  canvasByVersion: Canvas;
  canvasDraft: Canvas;
  canvasDrafts: Array<Canvas>;
  canvasDraftsMetadata: Array<CanvasDraftItem>;
  canvasMetadata: CanvasMetadata;
  canvasMetadataBySharedName: CanvasMetadata;
  canvasRoles: CanvasRoles;
  canvasRolesSchema: Array<Role>;
  canvasRolesSchemaByShareType: CanvasRolesByShareType;
  canvasVersionsPaginated: CanvasVersionsPaginatedOutput;
  canvases: CanvasesOutput;
  canvasesWithPermission: Array<CanvasWithRole>;
  commentThreads: Array<CommentThread>;
  commentThreadsByCell: Array<CommentThread>;
  commentsByCanvas: Array<Comment>;
  commentsByThread: Array<Comment>;
  currentOrganization: Organization;
  listAtlasTokens: Maybe<Array<SecretToken>>;
  onboardingState: Maybe<OnboardingState>;
  onboardingStates: Array<Maybe<OnboardingState>>;
  organization: Organization;
  organizationIDByDomain: Scalars['String']['output'];
  organizationIDByName: Scalars['String']['output'];
  pageByID: Page;
  pageBySharedName: Page;
  publicCanvases: CanvasesOutput;
  search: CanvasesOutput;
  urlMetadata: UrlMetadata;
  user: User;
  userByEmailExactly: User;
  usersByEmail: Array<User>;
  usersCurrentlyActive: Maybe<Array<User>>;
  usersInOrganization: Array<User>;
};


export type QueryArchivedCanvasesArgs = {
  input: InputMaybe<ArchivedCanvasesInput>;
};


export type QueryAuthorizedArgs = {
  input: AuthorizeInput;
};


export type QueryCanvasArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCanvasActiveUsersArgs = {
  canvasID: Scalars['ID']['input'];
};


export type QueryCanvasAllVersions2Args = {
  id: Scalars['ID']['input'];
};


export type QueryCanvasBySharedNameArgs = {
  namespace: Scalars['String']['input'];
  sharedName: Scalars['String']['input'];
};


export type QueryCanvasByVersionArgs = {
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
};


export type QueryCanvasDraftArgs = {
  id: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
};


export type QueryCanvasDraftsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCanvasDraftsMetadataArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCanvasMetadataArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCanvasMetadataBySharedNameArgs = {
  namespace: Scalars['String']['input'];
  sharedName: Scalars['String']['input'];
};


export type QueryCanvasRolesArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCanvasVersionsPaginatedArgs = {
  input: CanvasVersionsPaginatedInput;
};


export type QueryCommentThreadsArgs = {
  canvasId: Scalars['ID']['input'];
};


export type QueryCommentThreadsByCellArgs = {
  canvasId: Scalars['ID']['input'];
  cellId: Scalars['ID']['input'];
};


export type QueryCommentsByCanvasArgs = {
  canvasId: Scalars['ID']['input'];
};


export type QueryCommentsByThreadArgs = {
  canvasId: Scalars['ID']['input'];
  commentThreadId: Scalars['ID']['input'];
};


export type QueryOnboardingStateArgs = {
  featureName: OnboardingFeature;
  userID: Scalars['ID']['input'];
};


export type QueryOnboardingStatesArgs = {
  userID: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrganizationIdByDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryOrganizationIdByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryPageByIdArgs = {
  pageID: Scalars['ID']['input'];
};


export type QueryPageBySharedNameArgs = {
  namespace: Scalars['String']['input'];
  pageSlug: Scalars['String']['input'];
  sharedName: Scalars['String']['input'];
};


export type QuerySearchArgs = {
  query: Scalars['String']['input'];
};


export type QueryUrlMetadataArgs = {
  url: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserByEmailExactlyArgs = {
  email: Scalars['String']['input'];
};


export type QueryUsersByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUsersCurrentlyActiveArgs = {
  canvasID: Scalars['ID']['input'];
};

export type Role = {
  __typename?: 'Role';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  inviteDescription: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export type SavePageInput = {
  authorID: Scalars['String']['input'];
  canvasID: Scalars['String']['input'];
  cells: Array<CellInput>;
  pageID: Scalars['String']['input'];
  parentPageID: InputMaybe<Scalars['String']['input']>;
  version: Scalars['Int']['input'];
};

export type SecretToken = {
  __typename?: 'SecretToken';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  revoked: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export enum ServiceAccountType {
  Invite = 'INVITE',
  Organization = 'ORGANIZATION',
  PersonalOrganization = 'PERSONAL_ORGANIZATION',
  Public = 'PUBLIC',
  User = 'USER',
  Wildcard = 'WILDCARD'
}

export type SharedName = {
  __typename?: 'SharedName';
  namespace: Scalars['String']['output'];
  organizationID: Scalars['ID']['output'];
  sharedName: Scalars['String']['output'];
};

export type UrlMetadata = {
  __typename?: 'URLMetadata';
  faviconURL: Scalars['String']['output'];
  openGraphMetadata: OpenGraphMetadata;
  pageTitle: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  alternateIDs: Maybe<UserAlternateIDs>;
  email: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  intercomUserHash: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nickname: Scalars['String']['output'];
  organizationID: Scalars['String']['output'];
  profilePicture: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type UserAlternateIDs = {
  __typename?: 'UserAlternateIDs';
  github: Maybe<Scalars['String']['output']>;
  google: Maybe<Scalars['String']['output']>;
};

export type UserInfoInput = {
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nickname: Scalars['String']['input'];
  profilePicture: Scalars['String']['input'];
};

export type UserUpload = {
  __typename?: 'UserUpload';
  bucket: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  organizationID: Scalars['String']['output'];
  owner: Scalars['ID']['output'];
  region: Scalars['String']['output'];
};

export type Version = {
  __typename?: 'Version';
  author: User;
  autosave: Scalars['Boolean']['output'];
  created: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SecretTokenFieldsFragment = { __typename?: 'SecretToken', id: string, description: string, name: string, value: string, createdAt: any, createdBy: string };

export type ListAtlasTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAtlasTokensQuery = { __typename?: 'Query', listAtlasTokens: Array<{ __typename?: 'SecretToken', id: string, description: string, name: string, value: string, createdAt: any, createdBy: string }> | null };

export type CreateAtlasSecretTokenMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateAtlasSecretTokenMutation = { __typename?: 'Mutation', createAtlasSecretToken: { __typename?: 'CreateAtlasSecretTokenResponse', token: { __typename?: 'SecretToken', id: string, description: string, name: string, value: string, createdAt: any, createdBy: string } } };

export type RevokeAtlasSecretTokenMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RevokeAtlasSecretTokenMutation = { __typename?: 'Mutation', revokeAtlasSecretToken: { __typename?: 'SecretToken', id: string } };

export type CanvasArchiveListingFragment = { __typename?: 'ArchivedCanvasesOutput', lastKey: string | null, canvases: Array<{ __typename?: 'ArchivedCanvasItem', canvasID: string, organizationID: string, ownerID: string, archivedByID: string, title: string, createdAt: string }> };

export type ArchivedCanvasesQueryVariables = Exact<{
  input: InputMaybe<ArchivedCanvasesInput>;
}>;


export type ArchivedCanvasesQuery = { __typename?: 'Query', archivedCanvases: { __typename?: 'ArchivedCanvasesOutput', lastKey: string | null, canvases: Array<{ __typename?: 'ArchivedCanvasItem', canvasID: string, organizationID: string, ownerID: string, archivedByID: string, title: string, createdAt: string }> } };

export type ArchiveCanvas2MutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ArchiveCanvas2Mutation = { __typename?: 'Mutation', archiveCanvas2: string };

export type UnarchiveCanvas2MutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UnarchiveCanvas2Mutation = { __typename?: 'Mutation', unarchiveCanvas2: string };

export type CanvasVersionsPaginatedQueryVariables = Exact<{
  input: CanvasVersionsPaginatedInput;
}>;


export type CanvasVersionsPaginatedQuery = { __typename?: 'Query', canvasVersionsPaginated: { __typename?: 'CanvasVersionsPaginatedOutput', lastKey: string | null, versions: Array<{ __typename?: 'CanvasVersionItem', id: number, canvasID: string, name: string, description: string, created: string, authorID: string }> } };

export type CommentThreadFieldsFragment = { __typename?: 'CommentThread', id: string, canvasId: string, pageId: string, cellId: string | null, status: CommentThreadStatus, created: any, createdBy: string, updated: any, updatedBy: string, comments: Array<{ __typename?: 'Comment', id: string, commentThreadId: string, body: string, created: any, createdBy: string, updated: any | null, updatedBy: string | null }> };

export type CommentResponseFieldsFragment = { __typename?: 'CommentResponse', commentThreadID: string, commentID: string };

export type CommentThreadsQueryVariables = Exact<{
  canvasId: Scalars['ID']['input'];
}>;


export type CommentThreadsQuery = { __typename?: 'Query', commentThreads: Array<{ __typename?: 'CommentThread', id: string, canvasId: string, pageId: string, cellId: string | null, status: CommentThreadStatus, created: any, createdBy: string, updated: any, updatedBy: string, comments: Array<{ __typename?: 'Comment', id: string, commentThreadId: string, body: string, created: any, createdBy: string, updated: any | null, updatedBy: string | null }> }> };

export type AddCommentThreadMutationVariables = Exact<{
  canvasId: Scalars['ID']['input'];
  pageId: Scalars['ID']['input'];
  cellId: Scalars['ID']['input'];
  body: Scalars['String']['input'];
}>;


export type AddCommentThreadMutation = { __typename?: 'Mutation', addCommentThread: { __typename?: 'CommentResponse', commentThreadID: string, commentID: string } };

export type AddCommentToThreadMutationVariables = Exact<{
  canvasId: Scalars['ID']['input'];
  commentThreadId: Scalars['ID']['input'];
  body: Scalars['String']['input'];
}>;


export type AddCommentToThreadMutation = { __typename?: 'Mutation', addCommentToThread: { __typename?: 'CommentResponse', commentThreadID: string, commentID: string } };

export type UpdateCommentMutationVariables = Exact<{
  canvasId: Scalars['ID']['input'];
  commentId: Scalars['ID']['input'];
  body: Scalars['String']['input'];
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: { __typename?: 'CommentResponse', commentThreadID: string, commentID: string } };

export type DeleteCommentMutationVariables = Exact<{
  canvasId: Scalars['ID']['input'];
  commentId: Scalars['ID']['input'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: { __typename?: 'CommentResponse', commentThreadID: string, commentID: string } };

export type DeleteCommentThreadMutationVariables = Exact<{
  canvasId: Scalars['ID']['input'];
  commentThreadId: Scalars['ID']['input'];
}>;


export type DeleteCommentThreadMutation = { __typename?: 'Mutation', deleteCommentThread: { __typename?: 'CommentResponse', commentThreadID: string, commentID: string } };

export type ResolveCommentThreadMutationVariables = Exact<{
  canvasId: Scalars['ID']['input'];
  commentThreadId: Scalars['ID']['input'];
}>;


export type ResolveCommentThreadMutation = { __typename?: 'Mutation', resolveCommentThread: { __typename?: 'CommentResponse', commentThreadID: string, commentID: string } };

export type SaveCommentMarkInCanvasForCommenterRoleMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  pages: InputMaybe<Array<PageInput> | PageInput>;
}>;


export type SaveCommentMarkInCanvasForCommenterRoleMutation = { __typename?: 'Mutation', saveCommentMarkInCanvasForCommenterRole: { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } } } };

export type CellFieldsFragment = { __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null };

export type UserFieldsFragment = { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null };

export type CanvasFieldsFragment = { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } } };

export type CanvasFieldsWithVersionFragment = { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } }, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null };

export type CanvasRolesFieldsFragment = { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> };

export type OrganizationFieldsFragment = { __typename?: 'Organization', id: string, name: string, domainNames: Array<string>, owner: string };

export type UserOrgQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserOrgQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, organizationID: string } };

export type UserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } };

export type UsersByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type UsersByEmailQuery = { __typename?: 'Query', usersByEmail: Array<{ __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null }> };

export type UpdateUserInfoMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UserInfoInput;
}>;


export type UpdateUserInfoMutation = { __typename?: 'Mutation', updateUserInfo: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } };

export type NewCanvasMutationVariables = Exact<{
  title: Scalars['String']['input'];
}>;


export type NewCanvasMutation = { __typename?: 'Mutation', newCanvas: string };

export type DuplicateCanvasMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  version: Scalars['Int']['input'];
}>;


export type DuplicateCanvasMutation = { __typename?: 'Mutation', duplicateCanvas: string };

export type DuplicateCanvasV2MutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
}>;


export type DuplicateCanvasV2Mutation = { __typename?: 'Mutation', duplicateCanvasV2: string };

export type MakeCanvasPublicMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MakeCanvasPublicMutation = { __typename?: 'Mutation', makeCanvasPublic: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type MakeCanvasNotPublicMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MakeCanvasNotPublicMutation = { __typename?: 'Mutation', makeCanvasNotPublic: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type MakeCanvasPublicUnlistedMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type MakeCanvasPublicUnlistedMutation = { __typename?: 'Mutation', makeCanvasPublicUnlisted: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type CanvasBySharedNameQueryVariables = Exact<{
  namespace: Scalars['String']['input'];
  sharedName: Scalars['String']['input'];
}>;


export type CanvasBySharedNameQuery = { __typename?: 'Query', canvasBySharedName: { __typename?: 'Canvas', title: string, type: string, apiVersion: string, channel: string, id: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } } } };

export type CanvasByVersionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
}>;


export type CanvasByVersionQuery = { __typename?: 'Query', canvasByVersion: { __typename?: 'Canvas', title: string, type: string, apiVersion: string, channel: string, id: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } } } };

export type SaveCanvasMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  pages: Array<PageInput> | PageInput;
}>;


export type SaveCanvasMutation = { __typename?: 'Mutation', saveCanvas: { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } }, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null } };

export type SaveCanvasDraftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  pages: Array<PageInput> | PageInput;
  version: InputMaybe<Scalars['Int']['input']>;
}>;


export type SaveCanvasDraftMutation = { __typename?: 'Mutation', saveCanvasDraft: string };

export type CanvasListingFragment = { __typename?: 'CanvasesOutput', lastKey: string | null, canvases: Array<{ __typename?: 'CanvasListingItem', id: string, organizationID: string, ownerID: string, title: string, createdAt: string, updatedAt: string, pinnedAt: string, version: number }> };

export type CanvasesQueryVariables = Exact<{ [key: string]: never; }>;


export type CanvasesQuery = { __typename?: 'Query', canvases: { __typename?: 'CanvasesOutput', lastKey: string | null, canvases: Array<{ __typename?: 'CanvasListingItem', id: string, organizationID: string, ownerID: string, title: string, createdAt: string, updatedAt: string, pinnedAt: string, version: number }> } };

export type PublicCanvasesQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicCanvasesQuery = { __typename?: 'Query', publicCanvases: { __typename?: 'CanvasesOutput', lastKey: string | null, canvases: Array<{ __typename?: 'CanvasListingItem', id: string, organizationID: string, ownerID: string, title: string, createdAt: string, updatedAt: string, pinnedAt: string, version: number }> } };

export type CanvasQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CanvasQuery = { __typename?: 'Query', canvas: { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } }, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null } };

export type PermanentlyDeleteCanvasMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PermanentlyDeleteCanvasMutation = { __typename?: 'Mutation', deleteArchivedCanvas: string };

export type PinCanvasMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PinCanvasMutation = { __typename?: 'Mutation', pinCanvas: { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } } } };

export type UnpinCanvasMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UnpinCanvasMutation = { __typename?: 'Mutation', unpinCanvas: { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } } } };

export type CanvasAllVersions2QueryVariables = Exact<{
  id: Scalars['ID']['input'];
  ascending: Scalars['Boolean']['input'];
}>;


export type CanvasAllVersions2Query = { __typename?: 'Query', canvasAllVersions2: Array<{ __typename?: 'CanvasVersionItem', id: number, canvasID: string, created: string, name: string, description: string, authorID: string }> };

export type RevertMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  version: Scalars['Int']['input'];
}>;


export type RevertMutation = { __typename?: 'Mutation', revert: { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } }, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null } };

export type UpdateVersionFieldsMutationVariables = Exact<{
  canvasID: Scalars['ID']['input'];
  versionID: Scalars['Int']['input'];
  versionName: Scalars['String']['input'];
  versionDescription: Scalars['String']['input'];
}>;


export type UpdateVersionFieldsMutation = { __typename?: 'Mutation', updateVersionFields: { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } }, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null } };

export type CanvasRolesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CanvasRolesQuery = { __typename?: 'Query', canvasRoles: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type CanvasRolesSchemaQueryVariables = Exact<{ [key: string]: never; }>;


export type CanvasRolesSchemaQuery = { __typename?: 'Query', canvasRolesSchema: Array<{ __typename?: 'Role', name: string, displayName: string }> };

export type CanvasRolesSchemaByShareTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type CanvasRolesSchemaByShareTypeQuery = { __typename?: 'Query', canvasRolesSchemaByShareType: { __typename?: 'CanvasRolesByShareType', external: Array<{ __typename?: 'Role', name: string, displayName: string }>, internal: Array<{ __typename?: 'Role', name: string, displayName: string }> } };

export type ShareCanvasWithOrganizationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  role: Scalars['String']['input'];
  sharedName: Scalars['String']['input'];
}>;


export type ShareCanvasWithOrganizationMutation = { __typename?: 'Mutation', shareCanvasWithOrganization: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type UnshareCanvasWithOrganizationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UnshareCanvasWithOrganizationMutation = { __typename?: 'Mutation', unshareCanvasWithOrganization: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, role: { __typename?: 'Role', name: string, permissions: Array<Permission> } }> } };

export type ShareCanvasWithUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
  role: Scalars['String']['input'];
}>;


export type ShareCanvasWithUserMutation = { __typename?: 'Mutation', shareCanvasWithUser: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type UnshareCanvasWithUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
}>;


export type UnshareCanvasWithUserMutation = { __typename?: 'Mutation', unshareCanvasWithUser: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type InviteToCanvasMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;


export type InviteToCanvasMutation = { __typename?: 'Mutation', inviteToCanvas: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type RevokeInviteForCanvasMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  inviteToken: Scalars['String']['input'];
}>;


export type RevokeInviteForCanvasMutation = { __typename?: 'Mutation', revokeInviteForCanvas: { __typename?: 'CanvasRoles', canvasID: string, sharedName: string, roles: Array<{ __typename?: 'CanvasRole', serviceAccount: string, serviceAccountType: ServiceAccountType, sharedName: string, recipient: string | null, inviteToken: string | null, unlisted: boolean | null, externalOrganizationID: string | null, role: { __typename?: 'Role', name: string, description: string, displayName: string, permissions: Array<Permission>, inviteDescription: string } }> } };

export type AcceptCanvasInviteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  inviteToken: Scalars['String']['input'];
}>;


export type AcceptCanvasInviteMutation = { __typename?: 'Mutation', acceptCanvasInvite: string };

export type UsersCurrentlyActiveQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UsersCurrentlyActiveQuery = { __typename?: 'Query', canvasActiveUsers: Array<{ __typename?: 'CanvasActiveUser', tabID: string | null, user: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } }> | null };

export type AddUserCurrentlyActiveMutationVariables = Exact<{
  userID: Scalars['ID']['input'];
  canvasID: Scalars['ID']['input'];
  tabID: Scalars['String']['input'];
}>;


export type AddUserCurrentlyActiveMutation = { __typename?: 'Mutation', addUserCurrentlyActive: boolean };

export type GetCurrentOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentOrganizationQuery = { __typename?: 'Query', currentOrganization: { __typename?: 'Organization', id: string, name: string, domainNames: Array<string>, owner: string } };

export type GetOrgByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOrgByIdQuery = { __typename?: 'Query', organization: { __typename?: 'Organization', id: string, name: string, domainNames: Array<string>, owner: string } };

export type GetUsersInOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersInOrganizationQuery = { __typename?: 'Query', usersInOrganization: Array<{ __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null }> };

export type GetAllCanvasDraftsMetadataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAllCanvasDraftsMetadataQuery = { __typename?: 'Query', canvasDraftsMetadata: Array<{ __typename?: 'CanvasDraftItem', version: number, canvasID: string, created: string, updated: string, authorID: string }> };

export type GetCanvasDraftQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
}>;


export type GetCanvasDraftQuery = { __typename?: 'Query', canvasDraft: { __typename?: 'Canvas', updatedBy: string, type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } }, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null } };

export type DiscardCanvasDraftMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DiscardCanvasDraftMutation = { __typename?: 'Mutation', discardCanvasDraft: { __typename?: 'Canvas', type: string, apiVersion: string, channel: string, id: string, title: string, created: any, updated: any, updatedBy: string, owner: string, organization: string, archived: boolean, pinnedAt: any, isTemplate: boolean, isDraft: boolean, atlasUserToken: string | null, version: { __typename?: 'Version', id: number, name: string, created: any, autosave: boolean, description: string, author: { __typename?: 'User', id: string, organizationID: string, name: string, nickname: string, givenName: string, familyName: string, profilePicture: string, intercomUserHash: string, email: string, updatedAt: string, alternateIDs: { __typename?: 'UserAlternateIDs', google: string | null, github: string | null } | null } }, pages: Array<{ __typename?: 'Page', id: string, title: string, slug: string, authorID: string, canvasID: string, created: any, updated: any, archived: boolean, latestVersion: number, parentPageID: string | null, cells: Array<{ __typename?: 'Cell', type: string | null, cellName: string | null, code: string, signoffRequired: boolean | null, pinned: boolean | null, id: string | null, version: number, nodeAttrs: string | null }> }>, sharedName: { __typename?: 'SharedName', sharedName: string, namespace: string, organizationID: string } | null } };

export type GenerateSignedUploadUrlMutationVariables = Exact<{
  contentType: Scalars['String']['input'];
}>;


export type GenerateSignedUploadUrlMutation = { __typename?: 'Mutation', generateSignedUploadUrl: string };

export type AtlasUserTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type AtlasUserTokenQuery = { __typename?: 'Query', atlasUserToken: string };

export type OnboardingStateQueryVariables = Exact<{
  userID: Scalars['ID']['input'];
  featureName: OnboardingFeature;
}>;


export type OnboardingStateQuery = { __typename?: 'Query', onboardingState: { __typename?: 'OnboardingState', userID: string, featureName: OnboardingFeature, status: OnboardingStatus, updatedAt: string, onboardingCanvasID: string } | null };

export type OnboardingStatesQueryVariables = Exact<{
  userID: Scalars['ID']['input'];
}>;


export type OnboardingStatesQuery = { __typename?: 'Query', onboardingStates: Array<{ __typename?: 'OnboardingState', userID: string, featureName: OnboardingFeature, status: OnboardingStatus, updatedAt: string, createdAt: string } | null> };

export type UpdateOnboardingStatusMutationVariables = Exact<{
  userID: Scalars['String']['input'];
  featureName: OnboardingFeature;
  status: OnboardingStatus;
}>;


export type UpdateOnboardingStatusMutation = { __typename?: 'Mutation', updateOnboardingStatus: { __typename?: 'OnboardingState', userID: string, featureName: OnboardingFeature, status: OnboardingStatus, updatedAt: string, createdAt: string } };

export type SearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;


export type SearchQuery = { __typename?: 'Query', search: { __typename?: 'CanvasesOutput', lastKey: string | null, canvases: Array<{ __typename?: 'CanvasListingItem', id: string, organizationID: string, ownerID: string, title: string, createdAt: string, updatedAt: string, pinnedAt: string, version: number }> } };

export const SecretTokenFieldsFragmentDoc = gql`
    fragment SecretTokenFields on SecretToken {
  id
  description
  name
  value
  createdAt
  createdBy
}
    `;
export const CanvasArchiveListingFragmentDoc = gql`
    fragment CanvasArchiveListing on ArchivedCanvasesOutput {
  lastKey
  canvases {
    canvasID
    organizationID
    ownerID
    archivedByID
    title
    createdAt
  }
}
    `;
export const CommentThreadFieldsFragmentDoc = gql`
    fragment CommentThreadFields on CommentThread {
  id
  canvasId
  pageId
  cellId
  status
  created
  createdBy
  updated
  updatedBy
  comments {
    id
    commentThreadId
    body
    created
    createdBy
    updated
    updatedBy
  }
}
    `;
export const CommentResponseFieldsFragmentDoc = gql`
    fragment CommentResponseFields on CommentResponse {
  commentThreadID
  commentID
}
    `;
export const CellFieldsFragmentDoc = gql`
    fragment CellFields on Cell {
  type
  cellName
  code
  signoffRequired
  pinned
  id
  version
  nodeAttrs
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  organizationID
  name
  nickname
  givenName
  familyName
  profilePicture
  intercomUserHash
  email
  updatedAt
  alternateIDs {
    google
    github
  }
}
    `;
export const CanvasFieldsFragmentDoc = gql`
    fragment CanvasFields on Canvas {
  type
  apiVersion
  channel
  id
  title
  created
  updated
  updatedBy
  pages {
    id
    title
    slug
    authorID
    canvasID
    created
    updated
    archived
    cells {
      ...CellFields
    }
    latestVersion
    parentPageID
  }
  owner
  organization
  archived
  pinnedAt
  isTemplate
  isDraft
  sharedName {
    sharedName
    namespace
    organizationID
  }
  version {
    id
    name
    created
    autosave
    description
    author {
      ...UserFields
    }
  }
  atlasUserToken
}
    ${CellFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export const CanvasFieldsWithVersionFragmentDoc = gql`
    fragment CanvasFieldsWithVersion on Canvas {
  ...CanvasFields
  version {
    id
    name
    created
    autosave
    description
    author {
      ...UserFields
    }
  }
}
    ${CanvasFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export const CanvasRolesFieldsFragmentDoc = gql`
    fragment CanvasRolesFields on CanvasRoles {
  canvasID
  roles {
    role {
      name
      description
      displayName
      permissions
      inviteDescription
    }
    serviceAccount
    serviceAccountType
    sharedName
    recipient
    inviteToken
    unlisted
    externalOrganizationID
  }
  sharedName
}
    `;
export const OrganizationFieldsFragmentDoc = gql`
    fragment OrganizationFields on Organization {
  id
  name
  domainNames
  owner
}
    `;
export const CanvasListingFragmentDoc = gql`
    fragment CanvasListing on CanvasesOutput {
  lastKey
  canvases {
    id
    organizationID
    ownerID
    title
    createdAt
    updatedAt
    pinnedAt
    version
  }
}
    `;
export const ListAtlasTokensDocument = gql`
    query ListAtlasTokens {
  listAtlasTokens {
    ...SecretTokenFields
  }
}
    ${SecretTokenFieldsFragmentDoc}`;

/**
 * __useListAtlasTokensQuery__
 *
 * To run a query within a React component, call `useListAtlasTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAtlasTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAtlasTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAtlasTokensQuery(baseOptions?: Apollo.QueryHookOptions<ListAtlasTokensQuery, ListAtlasTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAtlasTokensQuery, ListAtlasTokensQueryVariables>(ListAtlasTokensDocument, options);
      }
export function useListAtlasTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAtlasTokensQuery, ListAtlasTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAtlasTokensQuery, ListAtlasTokensQueryVariables>(ListAtlasTokensDocument, options);
        }
export function useListAtlasTokensSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListAtlasTokensQuery, ListAtlasTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAtlasTokensQuery, ListAtlasTokensQueryVariables>(ListAtlasTokensDocument, options);
        }
export type ListAtlasTokensQueryHookResult = ReturnType<typeof useListAtlasTokensQuery>;
export type ListAtlasTokensLazyQueryHookResult = ReturnType<typeof useListAtlasTokensLazyQuery>;
export type ListAtlasTokensSuspenseQueryHookResult = ReturnType<typeof useListAtlasTokensSuspenseQuery>;
export type ListAtlasTokensQueryResult = Apollo.QueryResult<ListAtlasTokensQuery, ListAtlasTokensQueryVariables>;
export const CreateAtlasSecretTokenDocument = gql`
    mutation CreateAtlasSecretToken($name: String!) {
  createAtlasSecretToken(name: $name) {
    token {
      ...SecretTokenFields
    }
  }
}
    ${SecretTokenFieldsFragmentDoc}`;
export type CreateAtlasSecretTokenMutationFn = Apollo.MutationFunction<CreateAtlasSecretTokenMutation, CreateAtlasSecretTokenMutationVariables>;

/**
 * __useCreateAtlasSecretTokenMutation__
 *
 * To run a mutation, you first call `useCreateAtlasSecretTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAtlasSecretTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAtlasSecretTokenMutation, { data, loading, error }] = useCreateAtlasSecretTokenMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateAtlasSecretTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateAtlasSecretTokenMutation, CreateAtlasSecretTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAtlasSecretTokenMutation, CreateAtlasSecretTokenMutationVariables>(CreateAtlasSecretTokenDocument, options);
      }
export type CreateAtlasSecretTokenMutationHookResult = ReturnType<typeof useCreateAtlasSecretTokenMutation>;
export type CreateAtlasSecretTokenMutationResult = Apollo.MutationResult<CreateAtlasSecretTokenMutation>;
export type CreateAtlasSecretTokenMutationOptions = Apollo.BaseMutationOptions<CreateAtlasSecretTokenMutation, CreateAtlasSecretTokenMutationVariables>;
export const RevokeAtlasSecretTokenDocument = gql`
    mutation RevokeAtlasSecretToken($id: ID!) {
  revokeAtlasSecretToken(id: $id) {
    id
  }
}
    `;
export type RevokeAtlasSecretTokenMutationFn = Apollo.MutationFunction<RevokeAtlasSecretTokenMutation, RevokeAtlasSecretTokenMutationVariables>;

/**
 * __useRevokeAtlasSecretTokenMutation__
 *
 * To run a mutation, you first call `useRevokeAtlasSecretTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAtlasSecretTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAtlasSecretTokenMutation, { data, loading, error }] = useRevokeAtlasSecretTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeAtlasSecretTokenMutation(baseOptions?: Apollo.MutationHookOptions<RevokeAtlasSecretTokenMutation, RevokeAtlasSecretTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeAtlasSecretTokenMutation, RevokeAtlasSecretTokenMutationVariables>(RevokeAtlasSecretTokenDocument, options);
      }
export type RevokeAtlasSecretTokenMutationHookResult = ReturnType<typeof useRevokeAtlasSecretTokenMutation>;
export type RevokeAtlasSecretTokenMutationResult = Apollo.MutationResult<RevokeAtlasSecretTokenMutation>;
export type RevokeAtlasSecretTokenMutationOptions = Apollo.BaseMutationOptions<RevokeAtlasSecretTokenMutation, RevokeAtlasSecretTokenMutationVariables>;
export const ArchivedCanvasesDocument = gql`
    query ArchivedCanvases($input: ArchivedCanvasesInput) {
  archivedCanvases(input: $input) {
    ...CanvasArchiveListing
  }
}
    ${CanvasArchiveListingFragmentDoc}`;

/**
 * __useArchivedCanvasesQuery__
 *
 * To run a query within a React component, call `useArchivedCanvasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchivedCanvasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchivedCanvasesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchivedCanvasesQuery(baseOptions?: Apollo.QueryHookOptions<ArchivedCanvasesQuery, ArchivedCanvasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchivedCanvasesQuery, ArchivedCanvasesQueryVariables>(ArchivedCanvasesDocument, options);
      }
export function useArchivedCanvasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchivedCanvasesQuery, ArchivedCanvasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchivedCanvasesQuery, ArchivedCanvasesQueryVariables>(ArchivedCanvasesDocument, options);
        }
export function useArchivedCanvasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ArchivedCanvasesQuery, ArchivedCanvasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArchivedCanvasesQuery, ArchivedCanvasesQueryVariables>(ArchivedCanvasesDocument, options);
        }
export type ArchivedCanvasesQueryHookResult = ReturnType<typeof useArchivedCanvasesQuery>;
export type ArchivedCanvasesLazyQueryHookResult = ReturnType<typeof useArchivedCanvasesLazyQuery>;
export type ArchivedCanvasesSuspenseQueryHookResult = ReturnType<typeof useArchivedCanvasesSuspenseQuery>;
export type ArchivedCanvasesQueryResult = Apollo.QueryResult<ArchivedCanvasesQuery, ArchivedCanvasesQueryVariables>;
export const ArchiveCanvas2Document = gql`
    mutation ArchiveCanvas2($id: ID!) {
  archiveCanvas2(id: $id)
}
    `;
export type ArchiveCanvas2MutationFn = Apollo.MutationFunction<ArchiveCanvas2Mutation, ArchiveCanvas2MutationVariables>;

/**
 * __useArchiveCanvas2Mutation__
 *
 * To run a mutation, you first call `useArchiveCanvas2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCanvas2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCanvas2Mutation, { data, loading, error }] = useArchiveCanvas2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveCanvas2Mutation(baseOptions?: Apollo.MutationHookOptions<ArchiveCanvas2Mutation, ArchiveCanvas2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveCanvas2Mutation, ArchiveCanvas2MutationVariables>(ArchiveCanvas2Document, options);
      }
export type ArchiveCanvas2MutationHookResult = ReturnType<typeof useArchiveCanvas2Mutation>;
export type ArchiveCanvas2MutationResult = Apollo.MutationResult<ArchiveCanvas2Mutation>;
export type ArchiveCanvas2MutationOptions = Apollo.BaseMutationOptions<ArchiveCanvas2Mutation, ArchiveCanvas2MutationVariables>;
export const UnarchiveCanvas2Document = gql`
    mutation UnarchiveCanvas2($id: ID!) {
  unarchiveCanvas2(id: $id)
}
    `;
export type UnarchiveCanvas2MutationFn = Apollo.MutationFunction<UnarchiveCanvas2Mutation, UnarchiveCanvas2MutationVariables>;

/**
 * __useUnarchiveCanvas2Mutation__
 *
 * To run a mutation, you first call `useUnarchiveCanvas2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveCanvas2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveCanvas2Mutation, { data, loading, error }] = useUnarchiveCanvas2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveCanvas2Mutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveCanvas2Mutation, UnarchiveCanvas2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveCanvas2Mutation, UnarchiveCanvas2MutationVariables>(UnarchiveCanvas2Document, options);
      }
export type UnarchiveCanvas2MutationHookResult = ReturnType<typeof useUnarchiveCanvas2Mutation>;
export type UnarchiveCanvas2MutationResult = Apollo.MutationResult<UnarchiveCanvas2Mutation>;
export type UnarchiveCanvas2MutationOptions = Apollo.BaseMutationOptions<UnarchiveCanvas2Mutation, UnarchiveCanvas2MutationVariables>;
export const CanvasVersionsPaginatedDocument = gql`
    query CanvasVersionsPaginated($input: CanvasVersionsPaginatedInput!) {
  canvasVersionsPaginated(input: $input) {
    versions {
      id
      canvasID
      name
      description
      created
      authorID
    }
    lastKey
  }
}
    `;

/**
 * __useCanvasVersionsPaginatedQuery__
 *
 * To run a query within a React component, call `useCanvasVersionsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasVersionsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasVersionsPaginatedQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCanvasVersionsPaginatedQuery(baseOptions: Apollo.QueryHookOptions<CanvasVersionsPaginatedQuery, CanvasVersionsPaginatedQueryVariables> & ({ variables: CanvasVersionsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasVersionsPaginatedQuery, CanvasVersionsPaginatedQueryVariables>(CanvasVersionsPaginatedDocument, options);
      }
export function useCanvasVersionsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasVersionsPaginatedQuery, CanvasVersionsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasVersionsPaginatedQuery, CanvasVersionsPaginatedQueryVariables>(CanvasVersionsPaginatedDocument, options);
        }
export function useCanvasVersionsPaginatedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasVersionsPaginatedQuery, CanvasVersionsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasVersionsPaginatedQuery, CanvasVersionsPaginatedQueryVariables>(CanvasVersionsPaginatedDocument, options);
        }
export type CanvasVersionsPaginatedQueryHookResult = ReturnType<typeof useCanvasVersionsPaginatedQuery>;
export type CanvasVersionsPaginatedLazyQueryHookResult = ReturnType<typeof useCanvasVersionsPaginatedLazyQuery>;
export type CanvasVersionsPaginatedSuspenseQueryHookResult = ReturnType<typeof useCanvasVersionsPaginatedSuspenseQuery>;
export type CanvasVersionsPaginatedQueryResult = Apollo.QueryResult<CanvasVersionsPaginatedQuery, CanvasVersionsPaginatedQueryVariables>;
export const CommentThreadsDocument = gql`
    query CommentThreads($canvasId: ID!) {
  commentThreads(canvasId: $canvasId) {
    ...CommentThreadFields
  }
}
    ${CommentThreadFieldsFragmentDoc}`;

/**
 * __useCommentThreadsQuery__
 *
 * To run a query within a React component, call `useCommentThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentThreadsQuery({
 *   variables: {
 *      canvasId: // value for 'canvasId'
 *   },
 * });
 */
export function useCommentThreadsQuery(baseOptions: Apollo.QueryHookOptions<CommentThreadsQuery, CommentThreadsQueryVariables> & ({ variables: CommentThreadsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentThreadsQuery, CommentThreadsQueryVariables>(CommentThreadsDocument, options);
      }
export function useCommentThreadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentThreadsQuery, CommentThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentThreadsQuery, CommentThreadsQueryVariables>(CommentThreadsDocument, options);
        }
export function useCommentThreadsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CommentThreadsQuery, CommentThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommentThreadsQuery, CommentThreadsQueryVariables>(CommentThreadsDocument, options);
        }
export type CommentThreadsQueryHookResult = ReturnType<typeof useCommentThreadsQuery>;
export type CommentThreadsLazyQueryHookResult = ReturnType<typeof useCommentThreadsLazyQuery>;
export type CommentThreadsSuspenseQueryHookResult = ReturnType<typeof useCommentThreadsSuspenseQuery>;
export type CommentThreadsQueryResult = Apollo.QueryResult<CommentThreadsQuery, CommentThreadsQueryVariables>;
export const AddCommentThreadDocument = gql`
    mutation AddCommentThread($canvasId: ID!, $pageId: ID!, $cellId: ID!, $body: String!) {
  addCommentThread(
    canvasId: $canvasId
    pageId: $pageId
    cellId: $cellId
    body: $body
  ) {
    ...CommentResponseFields
  }
}
    ${CommentResponseFieldsFragmentDoc}`;
export type AddCommentThreadMutationFn = Apollo.MutationFunction<AddCommentThreadMutation, AddCommentThreadMutationVariables>;

/**
 * __useAddCommentThreadMutation__
 *
 * To run a mutation, you first call `useAddCommentThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentThreadMutation, { data, loading, error }] = useAddCommentThreadMutation({
 *   variables: {
 *      canvasId: // value for 'canvasId'
 *      pageId: // value for 'pageId'
 *      cellId: // value for 'cellId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddCommentThreadMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentThreadMutation, AddCommentThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentThreadMutation, AddCommentThreadMutationVariables>(AddCommentThreadDocument, options);
      }
export type AddCommentThreadMutationHookResult = ReturnType<typeof useAddCommentThreadMutation>;
export type AddCommentThreadMutationResult = Apollo.MutationResult<AddCommentThreadMutation>;
export type AddCommentThreadMutationOptions = Apollo.BaseMutationOptions<AddCommentThreadMutation, AddCommentThreadMutationVariables>;
export const AddCommentToThreadDocument = gql`
    mutation AddCommentToThread($canvasId: ID!, $commentThreadId: ID!, $body: String!) {
  addCommentToThread(
    canvasId: $canvasId
    commentThreadId: $commentThreadId
    body: $body
  ) {
    ...CommentResponseFields
  }
}
    ${CommentResponseFieldsFragmentDoc}`;
export type AddCommentToThreadMutationFn = Apollo.MutationFunction<AddCommentToThreadMutation, AddCommentToThreadMutationVariables>;

/**
 * __useAddCommentToThreadMutation__
 *
 * To run a mutation, you first call `useAddCommentToThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentToThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentToThreadMutation, { data, loading, error }] = useAddCommentToThreadMutation({
 *   variables: {
 *      canvasId: // value for 'canvasId'
 *      commentThreadId: // value for 'commentThreadId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddCommentToThreadMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentToThreadMutation, AddCommentToThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentToThreadMutation, AddCommentToThreadMutationVariables>(AddCommentToThreadDocument, options);
      }
export type AddCommentToThreadMutationHookResult = ReturnType<typeof useAddCommentToThreadMutation>;
export type AddCommentToThreadMutationResult = Apollo.MutationResult<AddCommentToThreadMutation>;
export type AddCommentToThreadMutationOptions = Apollo.BaseMutationOptions<AddCommentToThreadMutation, AddCommentToThreadMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($canvasId: ID!, $commentId: ID!, $body: String!) {
  updateComment(canvasId: $canvasId, commentId: $commentId, body: $body) {
    ...CommentResponseFields
  }
}
    ${CommentResponseFieldsFragmentDoc}`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      canvasId: // value for 'canvasId'
 *      commentId: // value for 'commentId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($canvasId: ID!, $commentId: ID!) {
  deleteComment(canvasId: $canvasId, commentId: $commentId) {
    ...CommentResponseFields
  }
}
    ${CommentResponseFieldsFragmentDoc}`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      canvasId: // value for 'canvasId'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const DeleteCommentThreadDocument = gql`
    mutation DeleteCommentThread($canvasId: ID!, $commentThreadId: ID!) {
  deleteCommentThread(canvasId: $canvasId, commentThreadId: $commentThreadId) {
    ...CommentResponseFields
  }
}
    ${CommentResponseFieldsFragmentDoc}`;
export type DeleteCommentThreadMutationFn = Apollo.MutationFunction<DeleteCommentThreadMutation, DeleteCommentThreadMutationVariables>;

/**
 * __useDeleteCommentThreadMutation__
 *
 * To run a mutation, you first call `useDeleteCommentThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentThreadMutation, { data, loading, error }] = useDeleteCommentThreadMutation({
 *   variables: {
 *      canvasId: // value for 'canvasId'
 *      commentThreadId: // value for 'commentThreadId'
 *   },
 * });
 */
export function useDeleteCommentThreadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentThreadMutation, DeleteCommentThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentThreadMutation, DeleteCommentThreadMutationVariables>(DeleteCommentThreadDocument, options);
      }
export type DeleteCommentThreadMutationHookResult = ReturnType<typeof useDeleteCommentThreadMutation>;
export type DeleteCommentThreadMutationResult = Apollo.MutationResult<DeleteCommentThreadMutation>;
export type DeleteCommentThreadMutationOptions = Apollo.BaseMutationOptions<DeleteCommentThreadMutation, DeleteCommentThreadMutationVariables>;
export const ResolveCommentThreadDocument = gql`
    mutation ResolveCommentThread($canvasId: ID!, $commentThreadId: ID!) {
  resolveCommentThread(canvasId: $canvasId, commentThreadId: $commentThreadId) {
    ...CommentResponseFields
  }
}
    ${CommentResponseFieldsFragmentDoc}`;
export type ResolveCommentThreadMutationFn = Apollo.MutationFunction<ResolveCommentThreadMutation, ResolveCommentThreadMutationVariables>;

/**
 * __useResolveCommentThreadMutation__
 *
 * To run a mutation, you first call `useResolveCommentThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveCommentThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveCommentThreadMutation, { data, loading, error }] = useResolveCommentThreadMutation({
 *   variables: {
 *      canvasId: // value for 'canvasId'
 *      commentThreadId: // value for 'commentThreadId'
 *   },
 * });
 */
export function useResolveCommentThreadMutation(baseOptions?: Apollo.MutationHookOptions<ResolveCommentThreadMutation, ResolveCommentThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveCommentThreadMutation, ResolveCommentThreadMutationVariables>(ResolveCommentThreadDocument, options);
      }
export type ResolveCommentThreadMutationHookResult = ReturnType<typeof useResolveCommentThreadMutation>;
export type ResolveCommentThreadMutationResult = Apollo.MutationResult<ResolveCommentThreadMutation>;
export type ResolveCommentThreadMutationOptions = Apollo.BaseMutationOptions<ResolveCommentThreadMutation, ResolveCommentThreadMutationVariables>;
export const SaveCommentMarkInCanvasForCommenterRoleDocument = gql`
    mutation saveCommentMarkInCanvasForCommenterRole($id: ID!, $pages: [PageInput!]) {
  saveCommentMarkInCanvasForCommenterRole(id: $id, pages: $pages) {
    ...CanvasFields
  }
}
    ${CanvasFieldsFragmentDoc}`;
export type SaveCommentMarkInCanvasForCommenterRoleMutationFn = Apollo.MutationFunction<SaveCommentMarkInCanvasForCommenterRoleMutation, SaveCommentMarkInCanvasForCommenterRoleMutationVariables>;

/**
 * __useSaveCommentMarkInCanvasForCommenterRoleMutation__
 *
 * To run a mutation, you first call `useSaveCommentMarkInCanvasForCommenterRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCommentMarkInCanvasForCommenterRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCommentMarkInCanvasForCommenterRoleMutation, { data, loading, error }] = useSaveCommentMarkInCanvasForCommenterRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pages: // value for 'pages'
 *   },
 * });
 */
export function useSaveCommentMarkInCanvasForCommenterRoleMutation(baseOptions?: Apollo.MutationHookOptions<SaveCommentMarkInCanvasForCommenterRoleMutation, SaveCommentMarkInCanvasForCommenterRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCommentMarkInCanvasForCommenterRoleMutation, SaveCommentMarkInCanvasForCommenterRoleMutationVariables>(SaveCommentMarkInCanvasForCommenterRoleDocument, options);
      }
export type SaveCommentMarkInCanvasForCommenterRoleMutationHookResult = ReturnType<typeof useSaveCommentMarkInCanvasForCommenterRoleMutation>;
export type SaveCommentMarkInCanvasForCommenterRoleMutationResult = Apollo.MutationResult<SaveCommentMarkInCanvasForCommenterRoleMutation>;
export type SaveCommentMarkInCanvasForCommenterRoleMutationOptions = Apollo.BaseMutationOptions<SaveCommentMarkInCanvasForCommenterRoleMutation, SaveCommentMarkInCanvasForCommenterRoleMutationVariables>;
export const UserOrgDocument = gql`
    query UserOrg($id: ID!) {
  user(id: $id) {
    id
    organizationID
  }
}
    `;

/**
 * __useUserOrgQuery__
 *
 * To run a query within a React component, call `useUserOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrgQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserOrgQuery(baseOptions: Apollo.QueryHookOptions<UserOrgQuery, UserOrgQueryVariables> & ({ variables: UserOrgQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrgQuery, UserOrgQueryVariables>(UserOrgDocument, options);
      }
export function useUserOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrgQuery, UserOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrgQuery, UserOrgQueryVariables>(UserOrgDocument, options);
        }
export function useUserOrgSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserOrgQuery, UserOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserOrgQuery, UserOrgQueryVariables>(UserOrgDocument, options);
        }
export type UserOrgQueryHookResult = ReturnType<typeof useUserOrgQuery>;
export type UserOrgLazyQueryHookResult = ReturnType<typeof useUserOrgLazyQuery>;
export type UserOrgSuspenseQueryHookResult = ReturnType<typeof useUserOrgSuspenseQuery>;
export type UserOrgQueryResult = Apollo.QueryResult<UserOrgQuery, UserOrgQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersByEmailDocument = gql`
    query UsersByEmail($email: String!) {
  usersByEmail(email: $email) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUsersByEmailQuery__
 *
 * To run a query within a React component, call `useUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUsersByEmailQuery(baseOptions: Apollo.QueryHookOptions<UsersByEmailQuery, UsersByEmailQueryVariables> & ({ variables: UsersByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersByEmailQuery, UsersByEmailQueryVariables>(UsersByEmailDocument, options);
      }
export function useUsersByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersByEmailQuery, UsersByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersByEmailQuery, UsersByEmailQueryVariables>(UsersByEmailDocument, options);
        }
export function useUsersByEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersByEmailQuery, UsersByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersByEmailQuery, UsersByEmailQueryVariables>(UsersByEmailDocument, options);
        }
export type UsersByEmailQueryHookResult = ReturnType<typeof useUsersByEmailQuery>;
export type UsersByEmailLazyQueryHookResult = ReturnType<typeof useUsersByEmailLazyQuery>;
export type UsersByEmailSuspenseQueryHookResult = ReturnType<typeof useUsersByEmailSuspenseQuery>;
export type UsersByEmailQueryResult = Apollo.QueryResult<UsersByEmailQuery, UsersByEmailQueryVariables>;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($id: ID!, $input: UserInfoInput!) {
  updateUserInfo(id: $id, input: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const NewCanvasDocument = gql`
    mutation NewCanvas($title: String!) {
  newCanvas(
    input: {type: "Canvas", apiVersion: "v1", channel: "alpha-1", title: $title}
  )
}
    `;
export type NewCanvasMutationFn = Apollo.MutationFunction<NewCanvasMutation, NewCanvasMutationVariables>;

/**
 * __useNewCanvasMutation__
 *
 * To run a mutation, you first call `useNewCanvasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewCanvasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newCanvasMutation, { data, loading, error }] = useNewCanvasMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useNewCanvasMutation(baseOptions?: Apollo.MutationHookOptions<NewCanvasMutation, NewCanvasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewCanvasMutation, NewCanvasMutationVariables>(NewCanvasDocument, options);
      }
export type NewCanvasMutationHookResult = ReturnType<typeof useNewCanvasMutation>;
export type NewCanvasMutationResult = Apollo.MutationResult<NewCanvasMutation>;
export type NewCanvasMutationOptions = Apollo.BaseMutationOptions<NewCanvasMutation, NewCanvasMutationVariables>;
export const DuplicateCanvasDocument = gql`
    mutation DuplicateCanvas($id: ID!, $title: String!, $version: Int!) {
  duplicateCanvas(id: $id, title: $title, version: $version)
}
    `;
export type DuplicateCanvasMutationFn = Apollo.MutationFunction<DuplicateCanvasMutation, DuplicateCanvasMutationVariables>;

/**
 * __useDuplicateCanvasMutation__
 *
 * To run a mutation, you first call `useDuplicateCanvasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCanvasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCanvasMutation, { data, loading, error }] = useDuplicateCanvasMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useDuplicateCanvasMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateCanvasMutation, DuplicateCanvasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateCanvasMutation, DuplicateCanvasMutationVariables>(DuplicateCanvasDocument, options);
      }
export type DuplicateCanvasMutationHookResult = ReturnType<typeof useDuplicateCanvasMutation>;
export type DuplicateCanvasMutationResult = Apollo.MutationResult<DuplicateCanvasMutation>;
export type DuplicateCanvasMutationOptions = Apollo.BaseMutationOptions<DuplicateCanvasMutation, DuplicateCanvasMutationVariables>;
export const DuplicateCanvasV2Document = gql`
    mutation DuplicateCanvasV2($id: ID!, $title: String!) {
  duplicateCanvasV2(id: $id, title: $title)
}
    `;
export type DuplicateCanvasV2MutationFn = Apollo.MutationFunction<DuplicateCanvasV2Mutation, DuplicateCanvasV2MutationVariables>;

/**
 * __useDuplicateCanvasV2Mutation__
 *
 * To run a mutation, you first call `useDuplicateCanvasV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCanvasV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCanvasV2Mutation, { data, loading, error }] = useDuplicateCanvasV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useDuplicateCanvasV2Mutation(baseOptions?: Apollo.MutationHookOptions<DuplicateCanvasV2Mutation, DuplicateCanvasV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateCanvasV2Mutation, DuplicateCanvasV2MutationVariables>(DuplicateCanvasV2Document, options);
      }
export type DuplicateCanvasV2MutationHookResult = ReturnType<typeof useDuplicateCanvasV2Mutation>;
export type DuplicateCanvasV2MutationResult = Apollo.MutationResult<DuplicateCanvasV2Mutation>;
export type DuplicateCanvasV2MutationOptions = Apollo.BaseMutationOptions<DuplicateCanvasV2Mutation, DuplicateCanvasV2MutationVariables>;
export const MakeCanvasPublicDocument = gql`
    mutation MakeCanvasPublic($id: ID!) {
  makeCanvasPublic(id: $id) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;
export type MakeCanvasPublicMutationFn = Apollo.MutationFunction<MakeCanvasPublicMutation, MakeCanvasPublicMutationVariables>;

/**
 * __useMakeCanvasPublicMutation__
 *
 * To run a mutation, you first call `useMakeCanvasPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCanvasPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCanvasPublicMutation, { data, loading, error }] = useMakeCanvasPublicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakeCanvasPublicMutation(baseOptions?: Apollo.MutationHookOptions<MakeCanvasPublicMutation, MakeCanvasPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeCanvasPublicMutation, MakeCanvasPublicMutationVariables>(MakeCanvasPublicDocument, options);
      }
export type MakeCanvasPublicMutationHookResult = ReturnType<typeof useMakeCanvasPublicMutation>;
export type MakeCanvasPublicMutationResult = Apollo.MutationResult<MakeCanvasPublicMutation>;
export type MakeCanvasPublicMutationOptions = Apollo.BaseMutationOptions<MakeCanvasPublicMutation, MakeCanvasPublicMutationVariables>;
export const MakeCanvasNotPublicDocument = gql`
    mutation MakeCanvasNotPublic($id: ID!) {
  makeCanvasNotPublic(id: $id) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;
export type MakeCanvasNotPublicMutationFn = Apollo.MutationFunction<MakeCanvasNotPublicMutation, MakeCanvasNotPublicMutationVariables>;

/**
 * __useMakeCanvasNotPublicMutation__
 *
 * To run a mutation, you first call `useMakeCanvasNotPublicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCanvasNotPublicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCanvasNotPublicMutation, { data, loading, error }] = useMakeCanvasNotPublicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakeCanvasNotPublicMutation(baseOptions?: Apollo.MutationHookOptions<MakeCanvasNotPublicMutation, MakeCanvasNotPublicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeCanvasNotPublicMutation, MakeCanvasNotPublicMutationVariables>(MakeCanvasNotPublicDocument, options);
      }
export type MakeCanvasNotPublicMutationHookResult = ReturnType<typeof useMakeCanvasNotPublicMutation>;
export type MakeCanvasNotPublicMutationResult = Apollo.MutationResult<MakeCanvasNotPublicMutation>;
export type MakeCanvasNotPublicMutationOptions = Apollo.BaseMutationOptions<MakeCanvasNotPublicMutation, MakeCanvasNotPublicMutationVariables>;
export const MakeCanvasPublicUnlistedDocument = gql`
    mutation MakeCanvasPublicUnlisted($id: ID!) {
  makeCanvasPublicUnlisted(id: $id) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;
export type MakeCanvasPublicUnlistedMutationFn = Apollo.MutationFunction<MakeCanvasPublicUnlistedMutation, MakeCanvasPublicUnlistedMutationVariables>;

/**
 * __useMakeCanvasPublicUnlistedMutation__
 *
 * To run a mutation, you first call `useMakeCanvasPublicUnlistedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeCanvasPublicUnlistedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeCanvasPublicUnlistedMutation, { data, loading, error }] = useMakeCanvasPublicUnlistedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakeCanvasPublicUnlistedMutation(baseOptions?: Apollo.MutationHookOptions<MakeCanvasPublicUnlistedMutation, MakeCanvasPublicUnlistedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeCanvasPublicUnlistedMutation, MakeCanvasPublicUnlistedMutationVariables>(MakeCanvasPublicUnlistedDocument, options);
      }
export type MakeCanvasPublicUnlistedMutationHookResult = ReturnType<typeof useMakeCanvasPublicUnlistedMutation>;
export type MakeCanvasPublicUnlistedMutationResult = Apollo.MutationResult<MakeCanvasPublicUnlistedMutation>;
export type MakeCanvasPublicUnlistedMutationOptions = Apollo.BaseMutationOptions<MakeCanvasPublicUnlistedMutation, MakeCanvasPublicUnlistedMutationVariables>;
export const CanvasBySharedNameDocument = gql`
    query CanvasBySharedName($namespace: String!, $sharedName: String!) {
  canvasBySharedName(namespace: $namespace, sharedName: $sharedName) {
    title
    ...CanvasFields
  }
}
    ${CanvasFieldsFragmentDoc}`;

/**
 * __useCanvasBySharedNameQuery__
 *
 * To run a query within a React component, call `useCanvasBySharedNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasBySharedNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasBySharedNameQuery({
 *   variables: {
 *      namespace: // value for 'namespace'
 *      sharedName: // value for 'sharedName'
 *   },
 * });
 */
export function useCanvasBySharedNameQuery(baseOptions: Apollo.QueryHookOptions<CanvasBySharedNameQuery, CanvasBySharedNameQueryVariables> & ({ variables: CanvasBySharedNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasBySharedNameQuery, CanvasBySharedNameQueryVariables>(CanvasBySharedNameDocument, options);
      }
export function useCanvasBySharedNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasBySharedNameQuery, CanvasBySharedNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasBySharedNameQuery, CanvasBySharedNameQueryVariables>(CanvasBySharedNameDocument, options);
        }
export function useCanvasBySharedNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasBySharedNameQuery, CanvasBySharedNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasBySharedNameQuery, CanvasBySharedNameQueryVariables>(CanvasBySharedNameDocument, options);
        }
export type CanvasBySharedNameQueryHookResult = ReturnType<typeof useCanvasBySharedNameQuery>;
export type CanvasBySharedNameLazyQueryHookResult = ReturnType<typeof useCanvasBySharedNameLazyQuery>;
export type CanvasBySharedNameSuspenseQueryHookResult = ReturnType<typeof useCanvasBySharedNameSuspenseQuery>;
export type CanvasBySharedNameQueryResult = Apollo.QueryResult<CanvasBySharedNameQuery, CanvasBySharedNameQueryVariables>;
export const CanvasByVersionDocument = gql`
    query CanvasByVersion($id: ID!, $version: Int!) {
  canvasByVersion(id: $id, version: $version) {
    title
    ...CanvasFields
  }
}
    ${CanvasFieldsFragmentDoc}`;

/**
 * __useCanvasByVersionQuery__
 *
 * To run a query within a React component, call `useCanvasByVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasByVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasByVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useCanvasByVersionQuery(baseOptions: Apollo.QueryHookOptions<CanvasByVersionQuery, CanvasByVersionQueryVariables> & ({ variables: CanvasByVersionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasByVersionQuery, CanvasByVersionQueryVariables>(CanvasByVersionDocument, options);
      }
export function useCanvasByVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasByVersionQuery, CanvasByVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasByVersionQuery, CanvasByVersionQueryVariables>(CanvasByVersionDocument, options);
        }
export function useCanvasByVersionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasByVersionQuery, CanvasByVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasByVersionQuery, CanvasByVersionQueryVariables>(CanvasByVersionDocument, options);
        }
export type CanvasByVersionQueryHookResult = ReturnType<typeof useCanvasByVersionQuery>;
export type CanvasByVersionLazyQueryHookResult = ReturnType<typeof useCanvasByVersionLazyQuery>;
export type CanvasByVersionSuspenseQueryHookResult = ReturnType<typeof useCanvasByVersionSuspenseQuery>;
export type CanvasByVersionQueryResult = Apollo.QueryResult<CanvasByVersionQuery, CanvasByVersionQueryVariables>;
export const SaveCanvasDocument = gql`
    mutation SaveCanvas($id: ID!, $title: String!, $pages: [PageInput!]!) {
  saveCanvas(id: $id, title: $title, pages: $pages) {
    ...CanvasFieldsWithVersion
  }
}
    ${CanvasFieldsWithVersionFragmentDoc}`;
export type SaveCanvasMutationFn = Apollo.MutationFunction<SaveCanvasMutation, SaveCanvasMutationVariables>;

/**
 * __useSaveCanvasMutation__
 *
 * To run a mutation, you first call `useSaveCanvasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCanvasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCanvasMutation, { data, loading, error }] = useSaveCanvasMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      pages: // value for 'pages'
 *   },
 * });
 */
export function useSaveCanvasMutation(baseOptions?: Apollo.MutationHookOptions<SaveCanvasMutation, SaveCanvasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCanvasMutation, SaveCanvasMutationVariables>(SaveCanvasDocument, options);
      }
export type SaveCanvasMutationHookResult = ReturnType<typeof useSaveCanvasMutation>;
export type SaveCanvasMutationResult = Apollo.MutationResult<SaveCanvasMutation>;
export type SaveCanvasMutationOptions = Apollo.BaseMutationOptions<SaveCanvasMutation, SaveCanvasMutationVariables>;
export const SaveCanvasDraftDocument = gql`
    mutation SaveCanvasDraft($id: ID!, $title: String!, $pages: [PageInput!]!, $version: Int) {
  saveCanvasDraft(id: $id, title: $title, pages: $pages, version: $version)
}
    `;
export type SaveCanvasDraftMutationFn = Apollo.MutationFunction<SaveCanvasDraftMutation, SaveCanvasDraftMutationVariables>;

/**
 * __useSaveCanvasDraftMutation__
 *
 * To run a mutation, you first call `useSaveCanvasDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCanvasDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCanvasDraftMutation, { data, loading, error }] = useSaveCanvasDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      pages: // value for 'pages'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useSaveCanvasDraftMutation(baseOptions?: Apollo.MutationHookOptions<SaveCanvasDraftMutation, SaveCanvasDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCanvasDraftMutation, SaveCanvasDraftMutationVariables>(SaveCanvasDraftDocument, options);
      }
export type SaveCanvasDraftMutationHookResult = ReturnType<typeof useSaveCanvasDraftMutation>;
export type SaveCanvasDraftMutationResult = Apollo.MutationResult<SaveCanvasDraftMutation>;
export type SaveCanvasDraftMutationOptions = Apollo.BaseMutationOptions<SaveCanvasDraftMutation, SaveCanvasDraftMutationVariables>;
export const CanvasesDocument = gql`
    query Canvases {
  canvases {
    ...CanvasListing
  }
}
    ${CanvasListingFragmentDoc}`;

/**
 * __useCanvasesQuery__
 *
 * To run a query within a React component, call `useCanvasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanvasesQuery(baseOptions?: Apollo.QueryHookOptions<CanvasesQuery, CanvasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasesQuery, CanvasesQueryVariables>(CanvasesDocument, options);
      }
export function useCanvasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasesQuery, CanvasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasesQuery, CanvasesQueryVariables>(CanvasesDocument, options);
        }
export function useCanvasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasesQuery, CanvasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasesQuery, CanvasesQueryVariables>(CanvasesDocument, options);
        }
export type CanvasesQueryHookResult = ReturnType<typeof useCanvasesQuery>;
export type CanvasesLazyQueryHookResult = ReturnType<typeof useCanvasesLazyQuery>;
export type CanvasesSuspenseQueryHookResult = ReturnType<typeof useCanvasesSuspenseQuery>;
export type CanvasesQueryResult = Apollo.QueryResult<CanvasesQuery, CanvasesQueryVariables>;
export const PublicCanvasesDocument = gql`
    query PublicCanvases {
  publicCanvases {
    ...CanvasListing
  }
}
    ${CanvasListingFragmentDoc}`;

/**
 * __usePublicCanvasesQuery__
 *
 * To run a query within a React component, call `usePublicCanvasesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicCanvasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicCanvasesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicCanvasesQuery(baseOptions?: Apollo.QueryHookOptions<PublicCanvasesQuery, PublicCanvasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicCanvasesQuery, PublicCanvasesQueryVariables>(PublicCanvasesDocument, options);
      }
export function usePublicCanvasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicCanvasesQuery, PublicCanvasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicCanvasesQuery, PublicCanvasesQueryVariables>(PublicCanvasesDocument, options);
        }
export function usePublicCanvasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PublicCanvasesQuery, PublicCanvasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicCanvasesQuery, PublicCanvasesQueryVariables>(PublicCanvasesDocument, options);
        }
export type PublicCanvasesQueryHookResult = ReturnType<typeof usePublicCanvasesQuery>;
export type PublicCanvasesLazyQueryHookResult = ReturnType<typeof usePublicCanvasesLazyQuery>;
export type PublicCanvasesSuspenseQueryHookResult = ReturnType<typeof usePublicCanvasesSuspenseQuery>;
export type PublicCanvasesQueryResult = Apollo.QueryResult<PublicCanvasesQuery, PublicCanvasesQueryVariables>;
export const CanvasDocument = gql`
    query Canvas($id: ID!) {
  canvas(id: $id) {
    ...CanvasFieldsWithVersion
  }
}
    ${CanvasFieldsWithVersionFragmentDoc}`;

/**
 * __useCanvasQuery__
 *
 * To run a query within a React component, call `useCanvasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCanvasQuery(baseOptions: Apollo.QueryHookOptions<CanvasQuery, CanvasQueryVariables> & ({ variables: CanvasQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasQuery, CanvasQueryVariables>(CanvasDocument, options);
      }
export function useCanvasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasQuery, CanvasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasQuery, CanvasQueryVariables>(CanvasDocument, options);
        }
export function useCanvasSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasQuery, CanvasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasQuery, CanvasQueryVariables>(CanvasDocument, options);
        }
export type CanvasQueryHookResult = ReturnType<typeof useCanvasQuery>;
export type CanvasLazyQueryHookResult = ReturnType<typeof useCanvasLazyQuery>;
export type CanvasSuspenseQueryHookResult = ReturnType<typeof useCanvasSuspenseQuery>;
export type CanvasQueryResult = Apollo.QueryResult<CanvasQuery, CanvasQueryVariables>;
export const PermanentlyDeleteCanvasDocument = gql`
    mutation PermanentlyDeleteCanvas($id: ID!) {
  deleteArchivedCanvas(id: $id)
}
    `;
export type PermanentlyDeleteCanvasMutationFn = Apollo.MutationFunction<PermanentlyDeleteCanvasMutation, PermanentlyDeleteCanvasMutationVariables>;

/**
 * __usePermanentlyDeleteCanvasMutation__
 *
 * To run a mutation, you first call `usePermanentlyDeleteCanvasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermanentlyDeleteCanvasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permanentlyDeleteCanvasMutation, { data, loading, error }] = usePermanentlyDeleteCanvasMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermanentlyDeleteCanvasMutation(baseOptions?: Apollo.MutationHookOptions<PermanentlyDeleteCanvasMutation, PermanentlyDeleteCanvasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PermanentlyDeleteCanvasMutation, PermanentlyDeleteCanvasMutationVariables>(PermanentlyDeleteCanvasDocument, options);
      }
export type PermanentlyDeleteCanvasMutationHookResult = ReturnType<typeof usePermanentlyDeleteCanvasMutation>;
export type PermanentlyDeleteCanvasMutationResult = Apollo.MutationResult<PermanentlyDeleteCanvasMutation>;
export type PermanentlyDeleteCanvasMutationOptions = Apollo.BaseMutationOptions<PermanentlyDeleteCanvasMutation, PermanentlyDeleteCanvasMutationVariables>;
export const PinCanvasDocument = gql`
    mutation PinCanvas($id: ID!) {
  pinCanvas(id: $id) {
    ...CanvasFields
  }
}
    ${CanvasFieldsFragmentDoc}`;
export type PinCanvasMutationFn = Apollo.MutationFunction<PinCanvasMutation, PinCanvasMutationVariables>;

/**
 * __usePinCanvasMutation__
 *
 * To run a mutation, you first call `usePinCanvasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinCanvasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinCanvasMutation, { data, loading, error }] = usePinCanvasMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePinCanvasMutation(baseOptions?: Apollo.MutationHookOptions<PinCanvasMutation, PinCanvasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PinCanvasMutation, PinCanvasMutationVariables>(PinCanvasDocument, options);
      }
export type PinCanvasMutationHookResult = ReturnType<typeof usePinCanvasMutation>;
export type PinCanvasMutationResult = Apollo.MutationResult<PinCanvasMutation>;
export type PinCanvasMutationOptions = Apollo.BaseMutationOptions<PinCanvasMutation, PinCanvasMutationVariables>;
export const UnpinCanvasDocument = gql`
    mutation UnpinCanvas($id: ID!) {
  unpinCanvas(id: $id) {
    ...CanvasFields
  }
}
    ${CanvasFieldsFragmentDoc}`;
export type UnpinCanvasMutationFn = Apollo.MutationFunction<UnpinCanvasMutation, UnpinCanvasMutationVariables>;

/**
 * __useUnpinCanvasMutation__
 *
 * To run a mutation, you first call `useUnpinCanvasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinCanvasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinCanvasMutation, { data, loading, error }] = useUnpinCanvasMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnpinCanvasMutation(baseOptions?: Apollo.MutationHookOptions<UnpinCanvasMutation, UnpinCanvasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpinCanvasMutation, UnpinCanvasMutationVariables>(UnpinCanvasDocument, options);
      }
export type UnpinCanvasMutationHookResult = ReturnType<typeof useUnpinCanvasMutation>;
export type UnpinCanvasMutationResult = Apollo.MutationResult<UnpinCanvasMutation>;
export type UnpinCanvasMutationOptions = Apollo.BaseMutationOptions<UnpinCanvasMutation, UnpinCanvasMutationVariables>;
export const CanvasAllVersions2Document = gql`
    query CanvasAllVersions2($id: ID!, $ascending: Boolean!) {
  canvasAllVersions2(id: $id) {
    id
    canvasID
    created
    name
    description
    authorID
  }
}
    `;

/**
 * __useCanvasAllVersions2Query__
 *
 * To run a query within a React component, call `useCanvasAllVersions2Query` and pass it any options that fit your needs.
 * When your component renders, `useCanvasAllVersions2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasAllVersions2Query({
 *   variables: {
 *      id: // value for 'id'
 *      ascending: // value for 'ascending'
 *   },
 * });
 */
export function useCanvasAllVersions2Query(baseOptions: Apollo.QueryHookOptions<CanvasAllVersions2Query, CanvasAllVersions2QueryVariables> & ({ variables: CanvasAllVersions2QueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasAllVersions2Query, CanvasAllVersions2QueryVariables>(CanvasAllVersions2Document, options);
      }
export function useCanvasAllVersions2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasAllVersions2Query, CanvasAllVersions2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasAllVersions2Query, CanvasAllVersions2QueryVariables>(CanvasAllVersions2Document, options);
        }
export function useCanvasAllVersions2SuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasAllVersions2Query, CanvasAllVersions2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasAllVersions2Query, CanvasAllVersions2QueryVariables>(CanvasAllVersions2Document, options);
        }
export type CanvasAllVersions2QueryHookResult = ReturnType<typeof useCanvasAllVersions2Query>;
export type CanvasAllVersions2LazyQueryHookResult = ReturnType<typeof useCanvasAllVersions2LazyQuery>;
export type CanvasAllVersions2SuspenseQueryHookResult = ReturnType<typeof useCanvasAllVersions2SuspenseQuery>;
export type CanvasAllVersions2QueryResult = Apollo.QueryResult<CanvasAllVersions2Query, CanvasAllVersions2QueryVariables>;
export const RevertDocument = gql`
    mutation Revert($id: ID!, $version: Int!) {
  revert(id: $id, version: $version) {
    ...CanvasFieldsWithVersion
  }
}
    ${CanvasFieldsWithVersionFragmentDoc}`;
export type RevertMutationFn = Apollo.MutationFunction<RevertMutation, RevertMutationVariables>;

/**
 * __useRevertMutation__
 *
 * To run a mutation, you first call `useRevertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertMutation, { data, loading, error }] = useRevertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useRevertMutation(baseOptions?: Apollo.MutationHookOptions<RevertMutation, RevertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertMutation, RevertMutationVariables>(RevertDocument, options);
      }
export type RevertMutationHookResult = ReturnType<typeof useRevertMutation>;
export type RevertMutationResult = Apollo.MutationResult<RevertMutation>;
export type RevertMutationOptions = Apollo.BaseMutationOptions<RevertMutation, RevertMutationVariables>;
export const UpdateVersionFieldsDocument = gql`
    mutation UpdateVersionFields($canvasID: ID!, $versionID: Int!, $versionName: String!, $versionDescription: String!) {
  updateVersionFields(
    canvasID: $canvasID
    versionID: $versionID
    versionName: $versionName
    versionDescription: $versionDescription
  ) {
    ...CanvasFieldsWithVersion
  }
}
    ${CanvasFieldsWithVersionFragmentDoc}`;
export type UpdateVersionFieldsMutationFn = Apollo.MutationFunction<UpdateVersionFieldsMutation, UpdateVersionFieldsMutationVariables>;

/**
 * __useUpdateVersionFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateVersionFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVersionFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVersionFieldsMutation, { data, loading, error }] = useUpdateVersionFieldsMutation({
 *   variables: {
 *      canvasID: // value for 'canvasID'
 *      versionID: // value for 'versionID'
 *      versionName: // value for 'versionName'
 *      versionDescription: // value for 'versionDescription'
 *   },
 * });
 */
export function useUpdateVersionFieldsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVersionFieldsMutation, UpdateVersionFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVersionFieldsMutation, UpdateVersionFieldsMutationVariables>(UpdateVersionFieldsDocument, options);
      }
export type UpdateVersionFieldsMutationHookResult = ReturnType<typeof useUpdateVersionFieldsMutation>;
export type UpdateVersionFieldsMutationResult = Apollo.MutationResult<UpdateVersionFieldsMutation>;
export type UpdateVersionFieldsMutationOptions = Apollo.BaseMutationOptions<UpdateVersionFieldsMutation, UpdateVersionFieldsMutationVariables>;
export const CanvasRolesDocument = gql`
    query CanvasRoles($id: ID!) {
  canvasRoles(id: $id) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;

/**
 * __useCanvasRolesQuery__
 *
 * To run a query within a React component, call `useCanvasRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasRolesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCanvasRolesQuery(baseOptions: Apollo.QueryHookOptions<CanvasRolesQuery, CanvasRolesQueryVariables> & ({ variables: CanvasRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasRolesQuery, CanvasRolesQueryVariables>(CanvasRolesDocument, options);
      }
export function useCanvasRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasRolesQuery, CanvasRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasRolesQuery, CanvasRolesQueryVariables>(CanvasRolesDocument, options);
        }
export function useCanvasRolesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasRolesQuery, CanvasRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasRolesQuery, CanvasRolesQueryVariables>(CanvasRolesDocument, options);
        }
export type CanvasRolesQueryHookResult = ReturnType<typeof useCanvasRolesQuery>;
export type CanvasRolesLazyQueryHookResult = ReturnType<typeof useCanvasRolesLazyQuery>;
export type CanvasRolesSuspenseQueryHookResult = ReturnType<typeof useCanvasRolesSuspenseQuery>;
export type CanvasRolesQueryResult = Apollo.QueryResult<CanvasRolesQuery, CanvasRolesQueryVariables>;
export const CanvasRolesSchemaDocument = gql`
    query CanvasRolesSchema {
  canvasRolesSchema {
    name
    displayName
  }
}
    `;

/**
 * __useCanvasRolesSchemaQuery__
 *
 * To run a query within a React component, call `useCanvasRolesSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasRolesSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasRolesSchemaQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanvasRolesSchemaQuery(baseOptions?: Apollo.QueryHookOptions<CanvasRolesSchemaQuery, CanvasRolesSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasRolesSchemaQuery, CanvasRolesSchemaQueryVariables>(CanvasRolesSchemaDocument, options);
      }
export function useCanvasRolesSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasRolesSchemaQuery, CanvasRolesSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasRolesSchemaQuery, CanvasRolesSchemaQueryVariables>(CanvasRolesSchemaDocument, options);
        }
export function useCanvasRolesSchemaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasRolesSchemaQuery, CanvasRolesSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasRolesSchemaQuery, CanvasRolesSchemaQueryVariables>(CanvasRolesSchemaDocument, options);
        }
export type CanvasRolesSchemaQueryHookResult = ReturnType<typeof useCanvasRolesSchemaQuery>;
export type CanvasRolesSchemaLazyQueryHookResult = ReturnType<typeof useCanvasRolesSchemaLazyQuery>;
export type CanvasRolesSchemaSuspenseQueryHookResult = ReturnType<typeof useCanvasRolesSchemaSuspenseQuery>;
export type CanvasRolesSchemaQueryResult = Apollo.QueryResult<CanvasRolesSchemaQuery, CanvasRolesSchemaQueryVariables>;
export const CanvasRolesSchemaByShareTypeDocument = gql`
    query CanvasRolesSchemaByShareType {
  canvasRolesSchemaByShareType {
    external {
      name
      displayName
    }
    internal {
      name
      displayName
    }
  }
}
    `;

/**
 * __useCanvasRolesSchemaByShareTypeQuery__
 *
 * To run a query within a React component, call `useCanvasRolesSchemaByShareTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanvasRolesSchemaByShareTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanvasRolesSchemaByShareTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanvasRolesSchemaByShareTypeQuery(baseOptions?: Apollo.QueryHookOptions<CanvasRolesSchemaByShareTypeQuery, CanvasRolesSchemaByShareTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanvasRolesSchemaByShareTypeQuery, CanvasRolesSchemaByShareTypeQueryVariables>(CanvasRolesSchemaByShareTypeDocument, options);
      }
export function useCanvasRolesSchemaByShareTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanvasRolesSchemaByShareTypeQuery, CanvasRolesSchemaByShareTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanvasRolesSchemaByShareTypeQuery, CanvasRolesSchemaByShareTypeQueryVariables>(CanvasRolesSchemaByShareTypeDocument, options);
        }
export function useCanvasRolesSchemaByShareTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CanvasRolesSchemaByShareTypeQuery, CanvasRolesSchemaByShareTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CanvasRolesSchemaByShareTypeQuery, CanvasRolesSchemaByShareTypeQueryVariables>(CanvasRolesSchemaByShareTypeDocument, options);
        }
export type CanvasRolesSchemaByShareTypeQueryHookResult = ReturnType<typeof useCanvasRolesSchemaByShareTypeQuery>;
export type CanvasRolesSchemaByShareTypeLazyQueryHookResult = ReturnType<typeof useCanvasRolesSchemaByShareTypeLazyQuery>;
export type CanvasRolesSchemaByShareTypeSuspenseQueryHookResult = ReturnType<typeof useCanvasRolesSchemaByShareTypeSuspenseQuery>;
export type CanvasRolesSchemaByShareTypeQueryResult = Apollo.QueryResult<CanvasRolesSchemaByShareTypeQuery, CanvasRolesSchemaByShareTypeQueryVariables>;
export const ShareCanvasWithOrganizationDocument = gql`
    mutation shareCanvasWithOrganization($id: ID!, $role: String!, $sharedName: String!) {
  shareCanvasWithOrganization(id: $id, role: $role, sharedName: $sharedName) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;
export type ShareCanvasWithOrganizationMutationFn = Apollo.MutationFunction<ShareCanvasWithOrganizationMutation, ShareCanvasWithOrganizationMutationVariables>;

/**
 * __useShareCanvasWithOrganizationMutation__
 *
 * To run a mutation, you first call `useShareCanvasWithOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareCanvasWithOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareCanvasWithOrganizationMutation, { data, loading, error }] = useShareCanvasWithOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      sharedName: // value for 'sharedName'
 *   },
 * });
 */
export function useShareCanvasWithOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ShareCanvasWithOrganizationMutation, ShareCanvasWithOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareCanvasWithOrganizationMutation, ShareCanvasWithOrganizationMutationVariables>(ShareCanvasWithOrganizationDocument, options);
      }
export type ShareCanvasWithOrganizationMutationHookResult = ReturnType<typeof useShareCanvasWithOrganizationMutation>;
export type ShareCanvasWithOrganizationMutationResult = Apollo.MutationResult<ShareCanvasWithOrganizationMutation>;
export type ShareCanvasWithOrganizationMutationOptions = Apollo.BaseMutationOptions<ShareCanvasWithOrganizationMutation, ShareCanvasWithOrganizationMutationVariables>;
export const UnshareCanvasWithOrganizationDocument = gql`
    mutation UnshareCanvasWithOrganization($id: ID!) {
  unshareCanvasWithOrganization(id: $id) {
    canvasID
    roles {
      role {
        name
        permissions
      }
      serviceAccount
      serviceAccountType
    }
    sharedName
  }
}
    `;
export type UnshareCanvasWithOrganizationMutationFn = Apollo.MutationFunction<UnshareCanvasWithOrganizationMutation, UnshareCanvasWithOrganizationMutationVariables>;

/**
 * __useUnshareCanvasWithOrganizationMutation__
 *
 * To run a mutation, you first call `useUnshareCanvasWithOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnshareCanvasWithOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unshareCanvasWithOrganizationMutation, { data, loading, error }] = useUnshareCanvasWithOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnshareCanvasWithOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UnshareCanvasWithOrganizationMutation, UnshareCanvasWithOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnshareCanvasWithOrganizationMutation, UnshareCanvasWithOrganizationMutationVariables>(UnshareCanvasWithOrganizationDocument, options);
      }
export type UnshareCanvasWithOrganizationMutationHookResult = ReturnType<typeof useUnshareCanvasWithOrganizationMutation>;
export type UnshareCanvasWithOrganizationMutationResult = Apollo.MutationResult<UnshareCanvasWithOrganizationMutation>;
export type UnshareCanvasWithOrganizationMutationOptions = Apollo.BaseMutationOptions<UnshareCanvasWithOrganizationMutation, UnshareCanvasWithOrganizationMutationVariables>;
export const ShareCanvasWithUserDocument = gql`
    mutation ShareCanvasWithUser($id: ID!, $userID: ID!, $role: String!) {
  shareCanvasWithUser(id: $id, userID: $userID, role: $role) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;
export type ShareCanvasWithUserMutationFn = Apollo.MutationFunction<ShareCanvasWithUserMutation, ShareCanvasWithUserMutationVariables>;

/**
 * __useShareCanvasWithUserMutation__
 *
 * To run a mutation, you first call `useShareCanvasWithUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareCanvasWithUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareCanvasWithUserMutation, { data, loading, error }] = useShareCanvasWithUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useShareCanvasWithUserMutation(baseOptions?: Apollo.MutationHookOptions<ShareCanvasWithUserMutation, ShareCanvasWithUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareCanvasWithUserMutation, ShareCanvasWithUserMutationVariables>(ShareCanvasWithUserDocument, options);
      }
export type ShareCanvasWithUserMutationHookResult = ReturnType<typeof useShareCanvasWithUserMutation>;
export type ShareCanvasWithUserMutationResult = Apollo.MutationResult<ShareCanvasWithUserMutation>;
export type ShareCanvasWithUserMutationOptions = Apollo.BaseMutationOptions<ShareCanvasWithUserMutation, ShareCanvasWithUserMutationVariables>;
export const UnshareCanvasWithUserDocument = gql`
    mutation unshareCanvasWithUser($id: ID!, $userID: ID!) {
  unshareCanvasWithUser(id: $id, userID: $userID) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;
export type UnshareCanvasWithUserMutationFn = Apollo.MutationFunction<UnshareCanvasWithUserMutation, UnshareCanvasWithUserMutationVariables>;

/**
 * __useUnshareCanvasWithUserMutation__
 *
 * To run a mutation, you first call `useUnshareCanvasWithUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnshareCanvasWithUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unshareCanvasWithUserMutation, { data, loading, error }] = useUnshareCanvasWithUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUnshareCanvasWithUserMutation(baseOptions?: Apollo.MutationHookOptions<UnshareCanvasWithUserMutation, UnshareCanvasWithUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnshareCanvasWithUserMutation, UnshareCanvasWithUserMutationVariables>(UnshareCanvasWithUserDocument, options);
      }
export type UnshareCanvasWithUserMutationHookResult = ReturnType<typeof useUnshareCanvasWithUserMutation>;
export type UnshareCanvasWithUserMutationResult = Apollo.MutationResult<UnshareCanvasWithUserMutation>;
export type UnshareCanvasWithUserMutationOptions = Apollo.BaseMutationOptions<UnshareCanvasWithUserMutation, UnshareCanvasWithUserMutationVariables>;
export const InviteToCanvasDocument = gql`
    mutation inviteToCanvas($id: ID!, $email: String!, $role: String!) {
  inviteToCanvas(id: $id, email: $email, role: $role) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;
export type InviteToCanvasMutationFn = Apollo.MutationFunction<InviteToCanvasMutation, InviteToCanvasMutationVariables>;

/**
 * __useInviteToCanvasMutation__
 *
 * To run a mutation, you first call `useInviteToCanvasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToCanvasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToCanvasMutation, { data, loading, error }] = useInviteToCanvasMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useInviteToCanvasMutation(baseOptions?: Apollo.MutationHookOptions<InviteToCanvasMutation, InviteToCanvasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteToCanvasMutation, InviteToCanvasMutationVariables>(InviteToCanvasDocument, options);
      }
export type InviteToCanvasMutationHookResult = ReturnType<typeof useInviteToCanvasMutation>;
export type InviteToCanvasMutationResult = Apollo.MutationResult<InviteToCanvasMutation>;
export type InviteToCanvasMutationOptions = Apollo.BaseMutationOptions<InviteToCanvasMutation, InviteToCanvasMutationVariables>;
export const RevokeInviteForCanvasDocument = gql`
    mutation revokeInviteForCanvas($id: ID!, $inviteToken: String!) {
  revokeInviteForCanvas(id: $id, inviteToken: $inviteToken) {
    ...CanvasRolesFields
  }
}
    ${CanvasRolesFieldsFragmentDoc}`;
export type RevokeInviteForCanvasMutationFn = Apollo.MutationFunction<RevokeInviteForCanvasMutation, RevokeInviteForCanvasMutationVariables>;

/**
 * __useRevokeInviteForCanvasMutation__
 *
 * To run a mutation, you first call `useRevokeInviteForCanvasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInviteForCanvasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInviteForCanvasMutation, { data, loading, error }] = useRevokeInviteForCanvasMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useRevokeInviteForCanvasMutation(baseOptions?: Apollo.MutationHookOptions<RevokeInviteForCanvasMutation, RevokeInviteForCanvasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeInviteForCanvasMutation, RevokeInviteForCanvasMutationVariables>(RevokeInviteForCanvasDocument, options);
      }
export type RevokeInviteForCanvasMutationHookResult = ReturnType<typeof useRevokeInviteForCanvasMutation>;
export type RevokeInviteForCanvasMutationResult = Apollo.MutationResult<RevokeInviteForCanvasMutation>;
export type RevokeInviteForCanvasMutationOptions = Apollo.BaseMutationOptions<RevokeInviteForCanvasMutation, RevokeInviteForCanvasMutationVariables>;
export const AcceptCanvasInviteDocument = gql`
    mutation AcceptCanvasInvite($id: ID!, $inviteToken: String!) {
  acceptCanvasInvite(id: $id, inviteToken: $inviteToken)
}
    `;
export type AcceptCanvasInviteMutationFn = Apollo.MutationFunction<AcceptCanvasInviteMutation, AcceptCanvasInviteMutationVariables>;

/**
 * __useAcceptCanvasInviteMutation__
 *
 * To run a mutation, you first call `useAcceptCanvasInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptCanvasInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptCanvasInviteMutation, { data, loading, error }] = useAcceptCanvasInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useAcceptCanvasInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptCanvasInviteMutation, AcceptCanvasInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptCanvasInviteMutation, AcceptCanvasInviteMutationVariables>(AcceptCanvasInviteDocument, options);
      }
export type AcceptCanvasInviteMutationHookResult = ReturnType<typeof useAcceptCanvasInviteMutation>;
export type AcceptCanvasInviteMutationResult = Apollo.MutationResult<AcceptCanvasInviteMutation>;
export type AcceptCanvasInviteMutationOptions = Apollo.BaseMutationOptions<AcceptCanvasInviteMutation, AcceptCanvasInviteMutationVariables>;
export const UsersCurrentlyActiveDocument = gql`
    query UsersCurrentlyActive($id: ID!) {
  canvasActiveUsers(canvasID: $id) {
    user {
      ...UserFields
    }
    tabID
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUsersCurrentlyActiveQuery__
 *
 * To run a query within a React component, call `useUsersCurrentlyActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersCurrentlyActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersCurrentlyActiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUsersCurrentlyActiveQuery(baseOptions: Apollo.QueryHookOptions<UsersCurrentlyActiveQuery, UsersCurrentlyActiveQueryVariables> & ({ variables: UsersCurrentlyActiveQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersCurrentlyActiveQuery, UsersCurrentlyActiveQueryVariables>(UsersCurrentlyActiveDocument, options);
      }
export function useUsersCurrentlyActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersCurrentlyActiveQuery, UsersCurrentlyActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersCurrentlyActiveQuery, UsersCurrentlyActiveQueryVariables>(UsersCurrentlyActiveDocument, options);
        }
export function useUsersCurrentlyActiveSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersCurrentlyActiveQuery, UsersCurrentlyActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersCurrentlyActiveQuery, UsersCurrentlyActiveQueryVariables>(UsersCurrentlyActiveDocument, options);
        }
export type UsersCurrentlyActiveQueryHookResult = ReturnType<typeof useUsersCurrentlyActiveQuery>;
export type UsersCurrentlyActiveLazyQueryHookResult = ReturnType<typeof useUsersCurrentlyActiveLazyQuery>;
export type UsersCurrentlyActiveSuspenseQueryHookResult = ReturnType<typeof useUsersCurrentlyActiveSuspenseQuery>;
export type UsersCurrentlyActiveQueryResult = Apollo.QueryResult<UsersCurrentlyActiveQuery, UsersCurrentlyActiveQueryVariables>;
export const AddUserCurrentlyActiveDocument = gql`
    mutation AddUserCurrentlyActive($userID: ID!, $canvasID: ID!, $tabID: String!) {
  addUserCurrentlyActive(userID: $userID, canvasID: $canvasID, tabID: $tabID)
}
    `;
export type AddUserCurrentlyActiveMutationFn = Apollo.MutationFunction<AddUserCurrentlyActiveMutation, AddUserCurrentlyActiveMutationVariables>;

/**
 * __useAddUserCurrentlyActiveMutation__
 *
 * To run a mutation, you first call `useAddUserCurrentlyActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserCurrentlyActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserCurrentlyActiveMutation, { data, loading, error }] = useAddUserCurrentlyActiveMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      canvasID: // value for 'canvasID'
 *      tabID: // value for 'tabID'
 *   },
 * });
 */
export function useAddUserCurrentlyActiveMutation(baseOptions?: Apollo.MutationHookOptions<AddUserCurrentlyActiveMutation, AddUserCurrentlyActiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserCurrentlyActiveMutation, AddUserCurrentlyActiveMutationVariables>(AddUserCurrentlyActiveDocument, options);
      }
export type AddUserCurrentlyActiveMutationHookResult = ReturnType<typeof useAddUserCurrentlyActiveMutation>;
export type AddUserCurrentlyActiveMutationResult = Apollo.MutationResult<AddUserCurrentlyActiveMutation>;
export type AddUserCurrentlyActiveMutationOptions = Apollo.BaseMutationOptions<AddUserCurrentlyActiveMutation, AddUserCurrentlyActiveMutationVariables>;
export const GetCurrentOrganizationDocument = gql`
    query GetCurrentOrganization {
  currentOrganization {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;

/**
 * __useGetCurrentOrganizationQuery__
 *
 * To run a query within a React component, call `useGetCurrentOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentOrganizationQuery, GetCurrentOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentOrganizationQuery, GetCurrentOrganizationQueryVariables>(GetCurrentOrganizationDocument, options);
      }
export function useGetCurrentOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentOrganizationQuery, GetCurrentOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentOrganizationQuery, GetCurrentOrganizationQueryVariables>(GetCurrentOrganizationDocument, options);
        }
export function useGetCurrentOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentOrganizationQuery, GetCurrentOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCurrentOrganizationQuery, GetCurrentOrganizationQueryVariables>(GetCurrentOrganizationDocument, options);
        }
export type GetCurrentOrganizationQueryHookResult = ReturnType<typeof useGetCurrentOrganizationQuery>;
export type GetCurrentOrganizationLazyQueryHookResult = ReturnType<typeof useGetCurrentOrganizationLazyQuery>;
export type GetCurrentOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetCurrentOrganizationSuspenseQuery>;
export type GetCurrentOrganizationQueryResult = Apollo.QueryResult<GetCurrentOrganizationQuery, GetCurrentOrganizationQueryVariables>;
export const GetOrgByIdDocument = gql`
    query GetOrgByID($id: String!) {
  organization(id: $id) {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;

/**
 * __useGetOrgByIdQuery__
 *
 * To run a query within a React component, call `useGetOrgByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrgByIdQuery, GetOrgByIdQueryVariables> & ({ variables: GetOrgByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrgByIdQuery, GetOrgByIdQueryVariables>(GetOrgByIdDocument, options);
      }
export function useGetOrgByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrgByIdQuery, GetOrgByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrgByIdQuery, GetOrgByIdQueryVariables>(GetOrgByIdDocument, options);
        }
export function useGetOrgByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrgByIdQuery, GetOrgByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrgByIdQuery, GetOrgByIdQueryVariables>(GetOrgByIdDocument, options);
        }
export type GetOrgByIdQueryHookResult = ReturnType<typeof useGetOrgByIdQuery>;
export type GetOrgByIdLazyQueryHookResult = ReturnType<typeof useGetOrgByIdLazyQuery>;
export type GetOrgByIdSuspenseQueryHookResult = ReturnType<typeof useGetOrgByIdSuspenseQuery>;
export type GetOrgByIdQueryResult = Apollo.QueryResult<GetOrgByIdQuery, GetOrgByIdQueryVariables>;
export const GetUsersInOrganizationDocument = gql`
    query GetUsersInOrganization {
  usersInOrganization {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetUsersInOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUsersInOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersInOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersInOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersInOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersInOrganizationQuery, GetUsersInOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersInOrganizationQuery, GetUsersInOrganizationQueryVariables>(GetUsersInOrganizationDocument, options);
      }
export function useGetUsersInOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersInOrganizationQuery, GetUsersInOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersInOrganizationQuery, GetUsersInOrganizationQueryVariables>(GetUsersInOrganizationDocument, options);
        }
export function useGetUsersInOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersInOrganizationQuery, GetUsersInOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersInOrganizationQuery, GetUsersInOrganizationQueryVariables>(GetUsersInOrganizationDocument, options);
        }
export type GetUsersInOrganizationQueryHookResult = ReturnType<typeof useGetUsersInOrganizationQuery>;
export type GetUsersInOrganizationLazyQueryHookResult = ReturnType<typeof useGetUsersInOrganizationLazyQuery>;
export type GetUsersInOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetUsersInOrganizationSuspenseQuery>;
export type GetUsersInOrganizationQueryResult = Apollo.QueryResult<GetUsersInOrganizationQuery, GetUsersInOrganizationQueryVariables>;
export const GetAllCanvasDraftsMetadataDocument = gql`
    query GetAllCanvasDraftsMetadata($id: ID!) {
  canvasDraftsMetadata(id: $id) {
    version
    canvasID
    created
    updated
    authorID
  }
}
    `;

/**
 * __useGetAllCanvasDraftsMetadataQuery__
 *
 * To run a query within a React component, call `useGetAllCanvasDraftsMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCanvasDraftsMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCanvasDraftsMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllCanvasDraftsMetadataQuery(baseOptions: Apollo.QueryHookOptions<GetAllCanvasDraftsMetadataQuery, GetAllCanvasDraftsMetadataQueryVariables> & ({ variables: GetAllCanvasDraftsMetadataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCanvasDraftsMetadataQuery, GetAllCanvasDraftsMetadataQueryVariables>(GetAllCanvasDraftsMetadataDocument, options);
      }
export function useGetAllCanvasDraftsMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCanvasDraftsMetadataQuery, GetAllCanvasDraftsMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCanvasDraftsMetadataQuery, GetAllCanvasDraftsMetadataQueryVariables>(GetAllCanvasDraftsMetadataDocument, options);
        }
export function useGetAllCanvasDraftsMetadataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllCanvasDraftsMetadataQuery, GetAllCanvasDraftsMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllCanvasDraftsMetadataQuery, GetAllCanvasDraftsMetadataQueryVariables>(GetAllCanvasDraftsMetadataDocument, options);
        }
export type GetAllCanvasDraftsMetadataQueryHookResult = ReturnType<typeof useGetAllCanvasDraftsMetadataQuery>;
export type GetAllCanvasDraftsMetadataLazyQueryHookResult = ReturnType<typeof useGetAllCanvasDraftsMetadataLazyQuery>;
export type GetAllCanvasDraftsMetadataSuspenseQueryHookResult = ReturnType<typeof useGetAllCanvasDraftsMetadataSuspenseQuery>;
export type GetAllCanvasDraftsMetadataQueryResult = Apollo.QueryResult<GetAllCanvasDraftsMetadataQuery, GetAllCanvasDraftsMetadataQueryVariables>;
export const GetCanvasDraftDocument = gql`
    query GetCanvasDraft($id: ID!, $userID: ID!) {
  canvasDraft(id: $id, userID: $userID) {
    ...CanvasFieldsWithVersion
    updatedBy
  }
}
    ${CanvasFieldsWithVersionFragmentDoc}`;

/**
 * __useGetCanvasDraftQuery__
 *
 * To run a query within a React component, call `useGetCanvasDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCanvasDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCanvasDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetCanvasDraftQuery(baseOptions: Apollo.QueryHookOptions<GetCanvasDraftQuery, GetCanvasDraftQueryVariables> & ({ variables: GetCanvasDraftQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCanvasDraftQuery, GetCanvasDraftQueryVariables>(GetCanvasDraftDocument, options);
      }
export function useGetCanvasDraftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCanvasDraftQuery, GetCanvasDraftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCanvasDraftQuery, GetCanvasDraftQueryVariables>(GetCanvasDraftDocument, options);
        }
export function useGetCanvasDraftSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCanvasDraftQuery, GetCanvasDraftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCanvasDraftQuery, GetCanvasDraftQueryVariables>(GetCanvasDraftDocument, options);
        }
export type GetCanvasDraftQueryHookResult = ReturnType<typeof useGetCanvasDraftQuery>;
export type GetCanvasDraftLazyQueryHookResult = ReturnType<typeof useGetCanvasDraftLazyQuery>;
export type GetCanvasDraftSuspenseQueryHookResult = ReturnType<typeof useGetCanvasDraftSuspenseQuery>;
export type GetCanvasDraftQueryResult = Apollo.QueryResult<GetCanvasDraftQuery, GetCanvasDraftQueryVariables>;
export const DiscardCanvasDraftDocument = gql`
    mutation DiscardCanvasDraft($id: ID!) {
  discardCanvasDraft(id: $id) {
    ...CanvasFieldsWithVersion
  }
}
    ${CanvasFieldsWithVersionFragmentDoc}`;
export type DiscardCanvasDraftMutationFn = Apollo.MutationFunction<DiscardCanvasDraftMutation, DiscardCanvasDraftMutationVariables>;

/**
 * __useDiscardCanvasDraftMutation__
 *
 * To run a mutation, you first call `useDiscardCanvasDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardCanvasDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardCanvasDraftMutation, { data, loading, error }] = useDiscardCanvasDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscardCanvasDraftMutation(baseOptions?: Apollo.MutationHookOptions<DiscardCanvasDraftMutation, DiscardCanvasDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiscardCanvasDraftMutation, DiscardCanvasDraftMutationVariables>(DiscardCanvasDraftDocument, options);
      }
export type DiscardCanvasDraftMutationHookResult = ReturnType<typeof useDiscardCanvasDraftMutation>;
export type DiscardCanvasDraftMutationResult = Apollo.MutationResult<DiscardCanvasDraftMutation>;
export type DiscardCanvasDraftMutationOptions = Apollo.BaseMutationOptions<DiscardCanvasDraftMutation, DiscardCanvasDraftMutationVariables>;
export const GenerateSignedUploadUrlDocument = gql`
    mutation GenerateSignedUploadUrl($contentType: String!) {
  generateSignedUploadUrl(contentType: $contentType)
}
    `;
export type GenerateSignedUploadUrlMutationFn = Apollo.MutationFunction<GenerateSignedUploadUrlMutation, GenerateSignedUploadUrlMutationVariables>;

/**
 * __useGenerateSignedUploadUrlMutation__
 *
 * To run a mutation, you first call `useGenerateSignedUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSignedUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSignedUploadUrlMutation, { data, loading, error }] = useGenerateSignedUploadUrlMutation({
 *   variables: {
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGenerateSignedUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSignedUploadUrlMutation, GenerateSignedUploadUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateSignedUploadUrlMutation, GenerateSignedUploadUrlMutationVariables>(GenerateSignedUploadUrlDocument, options);
      }
export type GenerateSignedUploadUrlMutationHookResult = ReturnType<typeof useGenerateSignedUploadUrlMutation>;
export type GenerateSignedUploadUrlMutationResult = Apollo.MutationResult<GenerateSignedUploadUrlMutation>;
export type GenerateSignedUploadUrlMutationOptions = Apollo.BaseMutationOptions<GenerateSignedUploadUrlMutation, GenerateSignedUploadUrlMutationVariables>;
export const AtlasUserTokenDocument = gql`
    query AtlasUserToken {
  atlasUserToken
}
    `;

/**
 * __useAtlasUserTokenQuery__
 *
 * To run a query within a React component, call `useAtlasUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtlasUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtlasUserTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useAtlasUserTokenQuery(baseOptions?: Apollo.QueryHookOptions<AtlasUserTokenQuery, AtlasUserTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AtlasUserTokenQuery, AtlasUserTokenQueryVariables>(AtlasUserTokenDocument, options);
      }
export function useAtlasUserTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AtlasUserTokenQuery, AtlasUserTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AtlasUserTokenQuery, AtlasUserTokenQueryVariables>(AtlasUserTokenDocument, options);
        }
export function useAtlasUserTokenSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AtlasUserTokenQuery, AtlasUserTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AtlasUserTokenQuery, AtlasUserTokenQueryVariables>(AtlasUserTokenDocument, options);
        }
export type AtlasUserTokenQueryHookResult = ReturnType<typeof useAtlasUserTokenQuery>;
export type AtlasUserTokenLazyQueryHookResult = ReturnType<typeof useAtlasUserTokenLazyQuery>;
export type AtlasUserTokenSuspenseQueryHookResult = ReturnType<typeof useAtlasUserTokenSuspenseQuery>;
export type AtlasUserTokenQueryResult = Apollo.QueryResult<AtlasUserTokenQuery, AtlasUserTokenQueryVariables>;
export const OnboardingStateDocument = gql`
    query OnboardingState($userID: ID!, $featureName: OnboardingFeature!) {
  onboardingState(userID: $userID, featureName: $featureName) {
    userID
    featureName
    status
    updatedAt
    onboardingCanvasID
  }
}
    `;

/**
 * __useOnboardingStateQuery__
 *
 * To run a query within a React component, call `useOnboardingStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingStateQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      featureName: // value for 'featureName'
 *   },
 * });
 */
export function useOnboardingStateQuery(baseOptions: Apollo.QueryHookOptions<OnboardingStateQuery, OnboardingStateQueryVariables> & ({ variables: OnboardingStateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingStateQuery, OnboardingStateQueryVariables>(OnboardingStateDocument, options);
      }
export function useOnboardingStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingStateQuery, OnboardingStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingStateQuery, OnboardingStateQueryVariables>(OnboardingStateDocument, options);
        }
export function useOnboardingStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingStateQuery, OnboardingStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingStateQuery, OnboardingStateQueryVariables>(OnboardingStateDocument, options);
        }
export type OnboardingStateQueryHookResult = ReturnType<typeof useOnboardingStateQuery>;
export type OnboardingStateLazyQueryHookResult = ReturnType<typeof useOnboardingStateLazyQuery>;
export type OnboardingStateSuspenseQueryHookResult = ReturnType<typeof useOnboardingStateSuspenseQuery>;
export type OnboardingStateQueryResult = Apollo.QueryResult<OnboardingStateQuery, OnboardingStateQueryVariables>;
export const OnboardingStatesDocument = gql`
    query OnboardingStates($userID: ID!) {
  onboardingStates(userID: $userID) {
    userID
    featureName
    status
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useOnboardingStatesQuery__
 *
 * To run a query within a React component, call `useOnboardingStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingStatesQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useOnboardingStatesQuery(baseOptions: Apollo.QueryHookOptions<OnboardingStatesQuery, OnboardingStatesQueryVariables> & ({ variables: OnboardingStatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingStatesQuery, OnboardingStatesQueryVariables>(OnboardingStatesDocument, options);
      }
export function useOnboardingStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingStatesQuery, OnboardingStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingStatesQuery, OnboardingStatesQueryVariables>(OnboardingStatesDocument, options);
        }
export function useOnboardingStatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OnboardingStatesQuery, OnboardingStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingStatesQuery, OnboardingStatesQueryVariables>(OnboardingStatesDocument, options);
        }
export type OnboardingStatesQueryHookResult = ReturnType<typeof useOnboardingStatesQuery>;
export type OnboardingStatesLazyQueryHookResult = ReturnType<typeof useOnboardingStatesLazyQuery>;
export type OnboardingStatesSuspenseQueryHookResult = ReturnType<typeof useOnboardingStatesSuspenseQuery>;
export type OnboardingStatesQueryResult = Apollo.QueryResult<OnboardingStatesQuery, OnboardingStatesQueryVariables>;
export const UpdateOnboardingStatusDocument = gql`
    mutation UpdateOnboardingStatus($userID: String!, $featureName: OnboardingFeature!, $status: OnboardingStatus!) {
  updateOnboardingStatus(
    userID: $userID
    featureName: $featureName
    status: $status
  ) {
    userID
    featureName
    status
    updatedAt
    createdAt
  }
}
    `;
export type UpdateOnboardingStatusMutationFn = Apollo.MutationFunction<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>;

/**
 * __useUpdateOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingStatusMutation, { data, loading, error }] = useUpdateOnboardingStatusMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      featureName: // value for 'featureName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateOnboardingStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>(UpdateOnboardingStatusDocument, options);
      }
export type UpdateOnboardingStatusMutationHookResult = ReturnType<typeof useUpdateOnboardingStatusMutation>;
export type UpdateOnboardingStatusMutationResult = Apollo.MutationResult<UpdateOnboardingStatusMutation>;
export type UpdateOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingStatusMutation, UpdateOnboardingStatusMutationVariables>;
export const SearchDocument = gql`
    query Search($query: String!) {
  search(query: $query) {
    ...CanvasListing
  }
}
    ${CanvasListingFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables> & ({ variables: SearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export function useSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchSuspenseQueryHookResult = ReturnType<typeof useSearchSuspenseQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;