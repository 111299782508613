import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type Canvas, type CanvasID } from "~/models/canvas";

import { type LoadingStatus, type VersionID, type VersionsState } from "./types";

const initialState: VersionsState = {
	versions: {},
	orderedVersions: {},
	activeVersion: undefined,
	originalCanvas: undefined,
	loadingStatus: "complete",
	isCurrentlyInPreview: false,
};

export const versionsSlice = createSlice({
	name: "versions",
	initialState,
	reducers: {
		setCanvasVersions: (
			state: VersionsState,
			action: PayloadAction<{ canvasID: CanvasID; versions: Canvas[] }>
		) => {
			const { canvasID, versions } = action.payload;

			// store sorted in reverse order
			const sortedVersions = versions.sort((a, b) => {
				return (b.version?.id || 0) - (a.version?.id || 0);
			});
			state.orderedVersions[canvasID] = sortedVersions;

			const canvasVersions = state.versions[canvasID] || [];

			versions.forEach((canvas) => {
				if (canvas.version === undefined) {
					return;
				}

				canvasVersions[canvas.version.id] = canvas;
			});
			state.versions[canvasID] = canvasVersions;

			state.loadingStatus = "complete";
		},

		setVersion: (state: VersionsState, action: PayloadAction<{ canvas: Canvas }>) => {
			const { canvas } = action.payload;

			const canvasVersion = state.versions[canvas.id];

			if (canvasVersion === undefined) {
				console.warn("Attempted to set a version on an unseen canvas", canvas);
				return;
			}

			if (canvas.version === undefined) {
				console.warn("Attempted to set a version with a canvas with no version.", canvas);
				return;
			}

			canvasVersion[canvas.version.id] = canvas;

			const versions = state.orderedVersions[canvas.id] || [];

			for (const [i, version] of versions.entries()) {
				if (version.version?.id === canvas.version.id) {
					versions[i] = canvas;
					break;
				}
			}

			state.orderedVersions[canvas.id] = versions;
		},

		setLoadingStatus: (
			state: VersionsState,
			action: PayloadAction<{ status: LoadingStatus }>
		) => {
			state.loadingStatus = action.payload.status;
		},

		setOriginalCanvas: (
			state: VersionsState,
			action: PayloadAction<{ canvas: Canvas | undefined }>
		) => {
			state.originalCanvas = action.payload.canvas;
		},

		setActiveVersion: (
			state: VersionsState,
			action: PayloadAction<{ versionID: VersionID | undefined }>
		) => {
			state.activeVersion = action.payload.versionID;
		},

		setInPreview: (
			state: VersionsState,
			action: PayloadAction<{ isCurrentlyInPreview: boolean }>
		) => {
			state.isCurrentlyInPreview = action.payload.isCurrentlyInPreview;
		},
	},
});

export const versions = versionsSlice.reducer;
export const {
	setCanvasVersions,
	setVersion,
	setLoadingStatus,
	setOriginalCanvas,
	setActiveVersion,
	setInPreview,
} = versionsSlice.actions;
