import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type CanvasRole, type RoleShareType } from "~/models/roles";

export type RolesState = {
	canvasRoles: Record<RoleShareType, CanvasRole[]>;
	canvasRolesLastFetched: Date | null;
};

const initialState: RolesState = {
	canvasRoles: {
		external: [],
		internal: [],
	},
	canvasRolesLastFetched: null,
};

const rolesSlice = createSlice({
	name: "roles",
	initialState,
	reducers: {
		updateCanvasRoles(
			state: RolesState,
			action: PayloadAction<{ roles: RolesState["canvasRoles"] }>
		) {
			state.canvasRoles = action.payload.roles;
			state.canvasRolesLastFetched = new Date();
		},
	},
});

export const { reducer } = rolesSlice;

export const { updateCanvasRoles } = rolesSlice.actions;
