import cx from "classnames";
import type { FC } from "react";

import { LoadingSpinner } from "../LoadingSpinner";

export type PageSpinnerProps = {
	message?: string;
};

export const PageSpinner: FC<PageSpinnerProps> = ({ message = "Loading" }) => {
	return (
		<div
			className={cx(
				"flex",
				"h-full",
				"w-full",
				"self-center",
				"items-center",
				"justify-center",
				"space-x-2",
				"text-secondary",
				"grow"
			)}
		>
			<LoadingSpinner />
			<div>{message}&hellip;</div>
		</div>
	);
};
