import { type Attrs, type NodeSpec } from "prosemirror-model";

import { makeTextCellDefaultToDOMAttrs } from "~/lib/prosemirror-utils/makeTextCellDefaultToDOMAttrs";

export type ToDoItemAttrs = {
	status: "completed" | "to do";
	indent?: number;
};

export const to_do_item: NodeSpec = {
	attrs: {
		status: { default: "to do" },
		indent: { default: 0 },
	},
	content: "paragraph",
	parseDOM: [
		{
			priority: 51, // Needs higher priority than other nodes that use a "li" tag
			tag: '[data-type="to_do_item"]',
			getAttrs(node: string | HTMLElement) {
				if (typeof node === "string") {
					return false;
				}

				const status = node.getAttribute("data-status") === "to do" ? "to do" : "completed";
				const indent = Number(node.getAttribute("data-indent") || 0);

				const attrs: ToDoItemAttrs = { status, indent };

				return attrs;
			},
		},
	],
	toDOM(node) {
		const status = (node.attrs as ToDoItemAttrs).status;
		const indent = (node.attrs as ToDoItemAttrs).indent;

		const attrs: Attrs = {
			...makeTextCellDefaultToDOMAttrs(node, "p-0 m-0 list-none flex items-start"),
			"data-type": "to_do_item",
			"data-status": status,
			"data-indent": indent ?? 0,
		};

		return ["li", attrs, 0];
	},
};
