import { type ApolloClient, type FieldPolicy, type ObservableQuery } from "@apollo/client";
import { useMemo } from "react";

import { type Query, type QueryCanvasVersionsPaginatedArgs } from "~/api/generated/graphql";

import { LIST_CANVAS_VERSIONS_PAGINATED } from "./queries";

export type CacheRef = { __ref: string };
export type MergeOutput = {
	versions: CacheRef[];
	lastKey: string;
};

const getVersionFromCacheRef = (ref: CacheRef): number =>
	parseInt(ref.__ref.replace("CanvasVersionItem:", ""), 10);

const parseLastKey = (lastKey?: string): { canvasID: string; version: number } | undefined => {
	if (!lastKey) {
		return undefined;
	}

	try {
		return JSON.parse(lastKey);
	} catch (e) {
		return undefined;
	}
};

export const canvasVersionsPaginatedFieldPolicy: FieldPolicy = {
	keyArgs: (args) => args?.["input"]["canvasID"],

	// Merge the versions lists based on version  ID. This accounts for requests for new pages as well as refetches of the first page
	merge(existing: MergeOutput | undefined, incoming: MergeOutput) {
		let lastKey = "";

		const parsedExistingLastKey = parseLastKey(existing?.lastKey);
		const parsedIncomingLastKey = parseLastKey(incoming?.lastKey);

		// Always use the oldest version last key to retain the cursor
		lastKey =
			parsedExistingLastKey &&
			parsedIncomingLastKey &&
			parsedExistingLastKey.version < parsedIncomingLastKey.version
				? existing?.lastKey || ""
				: incoming?.lastKey || "";

		const versionMap = new Map<string, CacheRef>();

		// Add all the versions to the map to dedupe
		existing?.versions.forEach((version) => {
			versionMap.set(version.__ref, version);
		});
		incoming?.versions.forEach((version) => {
			versionMap.set(version.__ref, version);
		});

		// Construct a sorted array of versions
		const versions = Array.from(versionMap.values()).sort((a, b) => {
			const aVersion = getVersionFromCacheRef(a);
			const bVersion = getVersionFromCacheRef(b);

			return aVersion < bVersion ? 1 : -1;
		});

		return {
			lastKey,
			versions,
		};
	},
};

export interface CanvasVersionsAPI {
	listCanvasVersions: (
		args: QueryCanvasVersionsPaginatedArgs
	) => ObservableQuery<Query, QueryCanvasVersionsPaginatedArgs>;
}

export const useCanvasVersionsAPI = (client: ApolloClient<object>): CanvasVersionsAPI => {
	const queries: CanvasVersionsAPI = useMemo(
		() => ({
			listCanvasVersions: (args) => {
				return client.watchQuery<Query, QueryCanvasVersionsPaginatedArgs>({
					query: LIST_CANVAS_VERSIONS_PAGINATED,
					variables: args,
					fetchPolicy: "network-only",
				});
			},
		}),
		[client]
	);

	return queries;
};
