import { type Auth0Info } from "~/auth/interop/common";

import WebAuthEnvDevelopment from "../../data/auth-env-vars.web.development.json";
import WebAuthEnvProduction from "../../data/auth-env-vars.web.production.json";
import WebAuthEnvStaging from "../../data/auth-env-vars.web.staging.json";

const getWebAuthConfigs = (env: MomentAppEnv): Auth0Info => {
	switch (env) {
		case "production": {
			return WebAuthEnvProduction;
		}
		case "staging": {
			return WebAuthEnvStaging;
		}
		default: {
			return WebAuthEnvDevelopment;
		}
	}
};

export const getAuthConfigs = (env: MomentAppEnv): Auth0Info => {
	return getWebAuthConfigs(env);
};
