import { type NodeSpec } from "prosemirror-model";

export const DEFAULT_CELL_ID = "default-cell-id";
export const COMMENT_CLICK_IGNORE_CLASS = "ignore-onclickoutside-comments";

// // We want the ProseMirror nodes to be the source of truth, so we store
// // everything that GraphQL needs to know about the cell in the node's attrs.
// type derivedGqlCellInputAttrs = "type" | "code" | "nodeAttrs";
// type gqlCellInputKeys = Exclude<keyof CellInput, derivedGqlCellInputAttrs>;

export const CELL_SPEC_ATTRS: NodeSpec["attrs"] =
	// FIXME
	// & { [key in gqlCellInputKeys]: AttributeSpec; }
	{
		id: { default: DEFAULT_CELL_ID },
		cellName: { default: "" },
		pinned: { default: undefined },
		language: { default: undefined },
		version: { default: 0 },
		signoffRequired: { default: false },
		checksum_hash: { default: undefined },
		checksum_version: { default: undefined },
	};
