import type { ActiveUser } from "@moment/api-collab/api-types";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type AnyAction, type Reducer } from "redux";

import { type Canvas } from "~/models/canvas";
import { type Cell, type CellID } from "~/models/cell";
import { type Page, findPage } from "~/models/page";
import { codeEditorSlice, initialState as editorInitialState } from "~/store/code-editor/slice";

import {
	type CanvasState,
	type DraftSavingState,
	NoActiveCanvasError,
	type SavingState,
} from "./types";

const initialState: CanvasState = {
	active: undefined,
	editingCell: undefined,
	editor: editorInitialState,
	propertiesPaneLoading: false,
	saveState: {
		status: "waiting",
		message: "",
	},
	draftSaveState: {
		status: "waiting",
	},
	mode: "document",
	previewAs: undefined,
	activeUsers: [],
	activePageID: undefined,
};

export const canvasSlice = createSlice({
	name: "canvas",
	initialState,
	reducers: {
		unsetCanvas: () => {
			return initialState;
		},

		setActive: (state, action: PayloadAction<{ canvas: Canvas }>) => {
			const { canvas } = action.payload;

			state.active = {
				...canvas,
			};

			const activePage = canvas.pages.find((page) => page.id === state.activePageID);

			if (
				state.inspectingCell &&
				!activePage?.cells.find((c) => c.id == state.inspectingCell)
			) {
				state.inspectingCell = undefined;
			}
			if (state.editingCell && !activePage?.cells.find((c) => c.id == state.editingCell)) {
				state.editingCell = undefined;
			}
		},

		setTitle: (state, action: PayloadAction<{ title: string }>) => {
			const { title } = action.payload;

			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			state.active.title = title;
		},

		setArchived: (
			state,
			action: PayloadAction<{
				archived: boolean;
				canvasID: string;
			}>
		) => {
			const { archived } = action.payload;

			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			state.active.archived = archived;
		},
		setTemplate: (state, action: PayloadAction<{ isTemplate: boolean }>) => {
			const { isTemplate } = action.payload;

			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}
			state.active.isTemplate = isTemplate;
		},
		setSaveState: (state, action: PayloadAction<SavingState>) => {
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			state.saveState = action.payload;
		},

		setDraftSaveState: (state, action: PayloadAction<DraftSavingState>) => {
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			state.draftSaveState = action.payload;
		},

		setInspectingCell: (state, action: PayloadAction<{ id: CellID }>) => {
			const { id } = action.payload;
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}
			state.inspectingCell = id;
		},

		unsetInspectingCell: (state) => {
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			state.inspectingCell = undefined;
		},

		setEditingCell: (state, action: PayloadAction<{ cellID: CellID }>) => {
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			state.editingCell = action.payload.cellID;
			state.editor.open = true;
		},

		unsetEditingCell: (state) => {
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			state.editingCell = undefined;
			state.editor.open = false;
		},

		setMode: (state, action: PayloadAction<{ mode: "document" | "grid" }>) => {
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}
			state.mode = action.payload.mode;
		},

		setIsDraft: (state, action: PayloadAction<boolean>) => {
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}
			state.active.isDraft = action.payload;
		},

		setActiveUsers: (state, action: PayloadAction<{ users: ActiveUser[] }>) => {
			const { users } = action.payload;
			state.activeUsers = users;
		},

		/** Canvas Pages */

		setActivePageID: (state, action: PayloadAction<{ pageID?: string }>) => {
			const { pageID } = action.payload;

			state.activePageID = pageID;
		},

		updatePage: (state, action: PayloadAction<{ pageID: string; page: Page }>) => {
			const { pageID, page } = action.payload;

			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			const { idx } = findPage(state.active.pages, { id: pageID });

			if (idx < 0) {
				throw new Error(`invalid page id: ${pageID}`);
			} else if (state.active.pages[idx]?.archived !== false) {
				throw new Error("cannot update archived page");
			}

			state.active.pages[idx] = page;
		},

		updateCells: (state, action: PayloadAction<{ cells: Cell[] }>) => {
			const { cells } = action.payload;

			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			const { idx } = findPage(state.active.pages, { id: state.activePageID });

			if (idx < 0) {
				throw new Error(`invalid page id: ${state.activePageID}`);
			}

			const activePage = state.active.pages[idx];
			if (activePage === undefined) {
				throw new Error(`Page is undefined: ${state.activePageID}`);
			}

			activePage.cells = cells;
		},

		setPages: (state, action: PayloadAction<{ pages: Page[] }>) => {
			if (state.active === undefined) {
				throw new NoActiveCanvasError();
			}

			state.active.pages = action.payload.pages;
		},
	},
});

export const {
	setSaveState,
	setDraftSaveState,
	setTitle,
	setActive,
	setArchived,
	setTemplate,
	setInspectingCell,
	unsetInspectingCell,
	setEditingCell,
	unsetEditingCell,
	setMode,
	setIsDraft,
	setActiveUsers,
	setPages,
	setActivePageID,
} = canvasSlice.actions;

export const canvas: Reducer<CanvasState> = (
	state: CanvasState | undefined,
	action: AnyAction
): CanvasState => {
	const editor = codeEditorSlice.reducer(state?.editor, action);
	return canvasSlice.reducer({ ...initialState, ...state, editor }, action);
};
