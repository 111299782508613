import { Analytics as VercelAnalytics } from "@vercel/analytics/react";
import type { FC, PropsWithChildren } from "react";

import { isSynthetics } from "~/utils/datadog/datadog";

import { PostHog } from "./PostHog";
import { TrackPageView } from "./TrackPageView";

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
	if (isSynthetics()) {
		return <>{children}</>;
	}

	return (
		<PostHog>
			<VercelAnalytics />
			<TrackPageView />
			{children}
		</PostHog>
	);
};
