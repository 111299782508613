import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { CanvasDraftItem } from "~/api/generated/graphql";
import { type Canvas } from "~/models/canvas";

export type DraftsState = {
	data: {
		[id: Canvas["id"]]: {
			[compositeID: string]: CanvasDraftItem;
		};
	};
};

const initialState: DraftsState = {
	data: {},
};

export const getDraftID = (draft: CanvasDraftItem): string => {
	return `${draft.canvasID}-${draft.authorID}`;
};

const draftsSlice = createSlice({
	name: "drafts",
	initialState,
	reducers: {
		setDrafts(
			state: DraftsState,
			action: PayloadAction<{ id: string; data: CanvasDraftItem[] }>
		) {
			const { id, data } = action.payload;

			const drafts: DraftsState["data"][string] = {};

			data.forEach((canvas) => {
				drafts[getDraftID(canvas)] = canvas;
			});

			state.data[id] = drafts;
		},
	},
});

export const { setDrafts } = draftsSlice.actions;

export const drafts = draftsSlice.reducer;
