import type { MetadataClient } from "@moment/api-collab/metadata-client";
import type { PageLocalStorageClient } from "@moment/api-collab/page-local-storage/client";
import type { ProtocolClient } from "@moment/api-collab/protocol-client";
import { useRouter } from "next/router";
import {
	type FC,
	type PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { useLogger } from "~/components/canvas/hooks/useLogger";
import { useConfig } from "~/components/config/ConfigProvider";
import { useConfirmLeave } from "~/components/navigation/useConfirmLeave";
import { selectIsMomentUser } from "~/store/auth/selectors";
import { useAppSelector } from "~/store/hooks";
import { notImplementedFn } from "~/utils/context";
import { isProduction } from "~/utils/env";

const STEPS_AHEAD_DEBOUNCE_MS = 10 * 1000;

export interface ClientItem {
	client: MetadataClient | PageLocalStorageClient | ProtocolClient;
	dispose: () => void;
}

export interface CollabClientsContextType {
	addClient: (
		key: string,
		client: MetadataClient | PageLocalStorageClient | ProtocolClient,
		dispose: () => void
	) => void;
	removeClient: (key: string) => void;
	currentPageClientVersion: number;
	setCurrentPageClientVersion: (version: number) => void;
	stepsAhead: number;
	debouncedStepsAhead: number;
	setStepsAhead: (steps: number) => void;
	sendPings: () => void;
}

export const CollabClientsContext = createContext<CollabClientsContextType>({
	addClient: notImplementedFn("addClient", "CollabClientsContext", undefined),
	removeClient: notImplementedFn("removeClient", "CollabClientsContext", undefined),
	currentPageClientVersion: 0,
	setCurrentPageClientVersion: notImplementedFn(
		"setCurrentPageClientVersion",
		"CollabClientsContext",
		undefined
	),
	stepsAhead: 0,
	debouncedStepsAhead: 0,
	setStepsAhead: notImplementedFn("setStepsAhead", "CollabClientsContext", undefined),
	sendPings: notImplementedFn("sendPings", "CollabClientsContext", undefined),
});

export const useCollabClients = () => useContext(CollabClientsContext);

export const isCanvasRoute = (url: string) =>
	url.startsWith("/canvases/") || url.startsWith("/@") || url.startsWith("/catalog");

export function useDebouncedStepsAhead(stepsAhead: number) {
	const stepsAheadRef = useRef(stepsAhead);
	stepsAheadRef.current = stepsAhead;

	const [debouncedStepsAhead, rawSetDebouncedStepsAhead] = useState(0);
	const timerRef = useRef<number | null>(null);

	useEffect(() => {
		if (stepsAhead === 0) {
			rawSetDebouncedStepsAhead(0);
			if (timerRef.current !== null) {
				window.clearTimeout(timerRef.current);
				timerRef.current = null;
			}
			return;
		}

		if (timerRef.current !== null) {
			return;
		}

		timerRef.current = window.setTimeout(() => {
			rawSetDebouncedStepsAhead(stepsAheadRef.current);
			timerRef.current = null;
		}, STEPS_AHEAD_DEBOUNCE_MS);
	}, [stepsAhead]);

	return debouncedStepsAhead;
}

export const DEBOUNCED_STEPS_AHEAD_THRESHOLD = 30;

export const CollabClientsProvider: FC<PropsWithChildren> = ({ children }) => {
	// Hooks
	const logger = useLogger("CollabClientsProvider");
	const [clientsMap, setClientsMap] = useState<Map<string, ClientItem>>(new Map());
	const clientsMapRef = useRef(clientsMap);
	clientsMapRef.current = clientsMap;
	const [currentPageClientVersion, unsafeSetCurrentPageClientVersion] = useState<number>(0);
	const [stepsAhead, unsafeSetStepsAhead] = useState<number>(0);

	const debouncedStepsAhead = useDebouncedStepsAhead(stepsAhead);

	const router = useRouter();
	const { isCollabEditingEnabled } = useConfig();
	const isMomentUser = useAppSelector(selectIsMomentUser);

	useConfirmLeave({
		condition: stepsAhead > 0, // Need to check immediately if there are unsaved changes
		prompt: "Your changes are still being saved.",
	});

	useConfirmLeave({
		condition: debouncedStepsAhead > DEBOUNCED_STEPS_AHEAD_THRESHOLD,
		prompt: "Unable to sync your document. Please copy your changes and refresh the page.",
	});

	// Variables
	const shouldUpdateDebugInfo = isMomentUser || !isProduction;

	// Functions
	const addClient: CollabClientsContextType["addClient"] = useCallback(
		(key, client, dispose) => {
			setClientsMap((prev) => new Map(prev.set(key, { client, dispose })));
		},
		[setClientsMap]
	);

	const removeClient = useCallback(
		(key: string) => {
			setClientsMap((prev) => {
				const newMap = new Map(prev);
				newMap.delete(key);
				return newMap;
			});
		},
		[setClientsMap]
	);

	const disposeAndRemoveAllClients = useCallback(() => {
		// Always update the previous state
		setClientsMap((prev) => {
			const newMap = new Map(prev);
			newMap.forEach((client) => {
				client.dispose();
			});
			newMap.clear();
			return newMap;
		});
	}, [clientsMap, setClientsMap]);

	const setCurrentPageClientVersion = useCallback(
		(version: number) => {
			if (!shouldUpdateDebugInfo) {
				return;
			}

			unsafeSetCurrentPageClientVersion(version);
		},
		[shouldUpdateDebugInfo, unsafeSetCurrentPageClientVersion]
	);

	const setStepsAhead = useCallback(
		(steps: number) => {
			if (!shouldUpdateDebugInfo) {
				return;
			}

			unsafeSetStepsAhead(steps);
		},
		[shouldUpdateDebugInfo, unsafeSetStepsAhead]
	);
	const sendPings = useCallback(() => {
		clientsMapRef.current.forEach((clientItem, key, _map) => {
			logger.debug("sending pings for client key", {
				clientKey: key,
			});
			clientItem.client.sendPing(true);
		});
	}, [clientsMapRef]);

	const value = useMemo(
		() => ({
			addClient,
			removeClient,
			currentPageClientVersion,
			setCurrentPageClientVersion,
			stepsAhead,
			debouncedStepsAhead,
			setStepsAhead,
			sendPings,
		}),
		[
			addClient,
			removeClient,
			currentPageClientVersion,
			setCurrentPageClientVersion,
			stepsAhead,
			debouncedStepsAhead,
			setStepsAhead,
			sendPings,
		]
	);

	useEffect(() => {
		const handleRouteChange = (nextUrl: string) => {
			const isNextRouteCanvas = isCanvasRoute(nextUrl);

			if (isNextRouteCanvas) {
				return;
			}

			disposeAndRemoveAllClients();
		};

		router.events.on("routeChangeStart", handleRouteChange);

		return () => {
			router.events.off("routeChangeStart", handleRouteChange);
		};
	}, [router]);

	if (!isCollabEditingEnabled) {
		return <>{children}</>;
	}

	return <CollabClientsContext.Provider value={value}>{children}</CollabClientsContext.Provider>;
};
