import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type Canvas } from "~/models/canvas";
import { type CellInfo } from "~/models/cell";
import { type NamespacedCanvasImportInfo } from "~/runtime/cell-compiler/analysis";

import { type ImportedCanvasesState, type NamespacedImportedCanvases } from "./types";

export const initialState: ImportedCanvasesState = {
	namespacedCanvases: {},
};

export const importedCanvasesSlice = createSlice({
	name: "importedCanvases",
	initialState,
	reducers: {
		reset: () => {
			return initialState;
		},

		incrementCanvasReferences: (
			state,
			action: PayloadAction<{ importInfo: NamespacedCanvasImportInfo }>
		) => {
			const { namespace, canvasName: sharedName } = action.payload.importInfo.argument;
			const { namespacedCanvases } = state;

			const sharedCanvases = namespacedCanvases[namespace];
			const sharedCanvas = namespacedCanvases[namespace]?.[sharedName];
			if (!sharedCanvases || !sharedCanvas) {
				throw new Error(
					`Can't increment reference count of non-existent canvas @${namespace}/${sharedName}`
				);
			} else {
				sharedCanvases[sharedName] = {
					...sharedCanvas,
					references: sharedCanvas.references + 1,
				};
			}
		},

		setImportNamespacedCanvasLoading: (
			state,
			action: PayloadAction<{ importInfo: NamespacedCanvasImportInfo }>
		) => {
			const { namespace, canvasName: sharedName } = action.payload.importInfo.argument;
			const { namespacedCanvases } = state;

			const sharedCanvases = namespacedCanvases[namespace];
			const sharedCanvas = namespacedCanvases[namespace]?.[sharedName];
			if (!sharedCanvases) {
				namespacedCanvases[namespace] = {
					[sharedName]: { status: "loading", references: 1 },
				};
			} else {
				sharedCanvases[sharedName] = {
					status: "loading",
					references: sharedCanvas?.references || 1,
				};
			}
		},

		setImportNamespacedCanvasLoadError: (
			state,
			action: PayloadAction<{ importInfo: NamespacedCanvasImportInfo; errorMessage: string }>
		) => {
			const { errorMessage } = action.payload;
			const { namespace, canvasName: sharedName } = action.payload.importInfo.argument;
			const { namespacedCanvases } = state;

			const sharedCanvases = namespacedCanvases[namespace];
			const sharedCanvas = namespacedCanvases[namespace]?.[sharedName];
			if (!sharedCanvases) {
				namespacedCanvases[namespace] = {
					[sharedName]: { status: "error", message: errorMessage, references: 1 },
				};
			} else {
				sharedCanvases[sharedName] = {
					status: "error",
					message: errorMessage,
					references: sharedCanvas?.references || 1,
				};
			}
		},

		analyzeImportNamespacedCanvas: (
			state,
			action: PayloadAction<{
				importInfo: NamespacedCanvasImportInfo;
				canvas: Canvas;
				analysis: CellInfo[];
			}>
		) => {
			const { canvas, analysis } = action.payload;
			const { namespace, canvasName: sharedName } = action.payload.importInfo.argument;
			const { namespacedCanvases } = state;

			const sharedCanvases = namespacedCanvases[namespace];
			const sharedCanvas = namespacedCanvases[namespace]?.[sharedName];

			const canvasInfo = {
				canvas,
				cells: analysis.reduce(
					(acc, cellInfo) => {
						if (
							cellInfo.cell.type === "JavaScriptFunctionBodyCell" &&
							cellInfo.cell.cellName !== ""
						) {
							acc[cellInfo.cell.cellName] = cellInfo;
						}
						return acc;
					},
					{} as { [cellName: string]: CellInfo }
				),
				imports: analysis.flatMap(({ analysis }) =>
					analysis?.inspectInfo?.kind === "NamespacedCanvasImportInfo"
						? [analysis.inspectInfo]
						: []
				),
				status: "analyzed" as const,
				references: sharedCanvas?.references || 1,
			};
			if (!sharedCanvases) {
				namespacedCanvases[namespace] = {
					[sharedName]: canvasInfo,
				};
			} else {
				sharedCanvases[sharedName] = canvasInfo;
			}
		},

		removeNamespacedCanvas: (
			state,
			action: PayloadAction<{ importInfo: NamespacedCanvasImportInfo }>
		) => {
			const { importInfo } = action.payload;

			state.namespacedCanvases = _removeNamespacedCanvas(
				state.namespacedCanvases,
				importInfo
			);
		},
	},
});

const _removeNamespacedCanvas = (
	namespacedCanvases: NamespacedImportedCanvases,
	importInfo: NamespacedCanvasImportInfo
): NamespacedImportedCanvases => {
	const { namespace, canvasName: sharedName } = importInfo.argument;

	const sharedCanvases = namespacedCanvases[namespace];
	const sharedCanvas = namespacedCanvases[namespace]?.[sharedName];

	if (!sharedCanvases || !sharedCanvas) {
		return namespacedCanvases;
	}

	if (sharedCanvas.references === 1) {
		delete sharedCanvases[sharedName];
	} else {
		sharedCanvases[sharedName] = {
			...sharedCanvas,
			references: sharedCanvas.references - 1,
		};
	}

	if (Object.keys(sharedCanvases).length === 0) {
		// TODO see if we can create and return a copy of namespaceCanvases instead, might have larger implications
		// eslint-disable-next-line no-param-reassign
		delete namespacedCanvases[namespace];
	}
	return namespacedCanvases;
};

export const {
	removeNamespacedCanvas,
	//
	// These actions are only used by the thunks for imported-canvases.
	//
	analyzeImportNamespacedCanvas,
	setImportNamespacedCanvasLoadError,
	incrementCanvasReferences,
	setImportNamespacedCanvasLoading,
} = importedCanvasesSlice.actions;

export const importedCanvases = importedCanvasesSlice.reducer;
