import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { type IdToken } from "~/auth/interop";
import { isError } from "~/utils/helpers/error";
import { LOCAL_STORAGE_KEYS } from "~/utils/storage";

import type { User } from "../../models/user";
import { userLoaded } from "./actions";
import { type AuthState, AuthStates } from "./types";

export const MOMENT_AUTH_COOKIE_NAME = "mom_auth_v1";

const initialState: AuthState = {
	state: AuthStates.LoggedOut,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setTokens: (
			state: AuthState,
			action: PayloadAction<{ idToken: IdToken; accessToken: string; refreshToken: string }>
		) => {
			const { idToken, accessToken, refreshToken } = action.payload;

			state.idToken = idToken;
			state.accessToken = accessToken;
			state.refreshToken = refreshToken;
			localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, accessToken);
			Cookies.set(MOMENT_AUTH_COOKIE_NAME, accessToken);
		},
		setLoggedIn: (state: AuthState) => {
			state.state = AuthStates.LoggedIn;
		},
		setOrganization: (state: AuthState, action: PayloadAction<{ org: string }>) => {
			const { org } = action.payload;
			state.org = org;
		},
		setLoggedOut: (state: AuthState) => {
			state.state = AuthStates.LoggedOut;
			state.idToken = undefined;
			state.accessToken = undefined;
			state.refreshToken = undefined;
			state.org = undefined;
			state.profile = undefined;

			localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
			Cookies.remove(MOMENT_AUTH_COOKIE_NAME);
		},
		setFailed: (state: AuthState, action: PayloadAction<{ error: unknown }>) => {
			const { error } = action.payload;
			state.state = AuthStates.Failed;
			state.error = isError(error) ? error : new Error("Unknown authentication error");
			state.refreshToken = undefined;
			state.idToken = undefined;
			state.accessToken = undefined;
			state.profile = undefined;
		},
		setAuthenticating: (state: AuthState) => {
			state.state = AuthStates.Authenticating;
		},
		setWaitingForDesktopCallback: (
			state: AuthState,
			action: PayloadAction<{ authUrl: string }>
		) => {
			state.state = AuthStates.WaitingForDesktopCallback;
			state.authUrl = action.payload.authUrl;
		},
		setProfile: (state: AuthState, action: PayloadAction<{ profile: User }>) => {
			const { profile } = action.payload;
			state.profile = profile;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(userLoaded, (state, action) => {
			const { user } = action.payload;
			const { organizationID } = action.payload;
			state.org = organizationID;
			state.profile = user;
		});
	},
});

export const {
	setTokens,
	setLoggedIn,
	setLoggedOut,
	setAuthenticating,
	setFailed,
	setOrganization,
	setProfile,
	setWaitingForDesktopCallback,
} = authSlice.actions;

export const auth = authSlice.reducer;
