import cx from "classnames";
import type { ButtonHTMLAttributes, FC, ForwardRefRenderFunction, ReactNode } from "react";
import { forwardRef } from "react";

import { Icon, type MomentIconType } from "~/lib/Icon";
import {
	LoadingSpinner,
	type LoadingSpinnerSize,
	type LoadingSpinnerVariant,
} from "~/lib/LoadingSpinner";

import {
	buttonClassBase,
	buttonClassSegments,
	buttonClassSizes,
	buttonClassStyles,
	buttonClassToggled,
} from "./buttonClasses";

export const ButtonVariantOptions = ["normal", "negative", "positive"] as const;

export type ButtonVariant = (typeof ButtonVariantOptions)[number];

export const ButtonLevelOptions = ["primary", "secondary", "tertiary", "quaternary"] as const;

export type ButtonLevel = (typeof ButtonLevelOptions)[number];

export const ButtonSizeOptions = ["xs", "sm", "md", "lg"] as const;

export type ButtonSize = (typeof ButtonSizeOptions)[number];

export const ButtonSegmentOptions = ["left", "center", "right", "none"] as const;

export type ButtonSegment = (typeof ButtonSegmentOptions)[number];

export const ButtonIconSizeOptions = [12, 16, 20, 24] as const;

export type ButtonIconSize = (typeof ButtonIconSizeOptions)[number];

export type ButtonState = "default" | "hover" | "focus" | "active" | "disabled";

export type ButtonToggle = boolean;

export type ButtonInverse = boolean;

// The presence of children indicates a button with text,
// whereas a button without children is an icon button.
// In the latter case, we force label so that we can include
// an aria-label for accessibility.
type ButtonBaseProps = {
	variant?: ButtonVariant;
	level?: ButtonLevel;
	size?: ButtonSize;
	fullWidth?: boolean;
	icon?: MomentIconType;
	state?: ButtonState;
	toggled?: ButtonToggle;
	mono?: boolean;
	segmentPosition?: ButtonSegment;
	loading?: boolean;
	forceRounded?: boolean;
};

export type ButtonProps = Omit<
	ButtonHTMLAttributes<HTMLButtonElement>,
	// IMPORTANT: Firefox does not allow draggable buttons, so we don't allow them in general.
	| "draggable"
	| "onDrag"
	| "onDragCapture"
	| "onDragEnd"
	| "onDragEndCapture"
	| "onDragEnter"
	| "onDragEnterCapture"
	| "onDragExit"
	| "onDragExitCapture"
	| "onDragLeave"
	| "onDragLeaveCapture"
	| "onDragOver"
	| "onDragOverCapture"
	| "onDragStart"
	| "onDragStartCapture"
> &
	ButtonBaseProps &
	(
		| { "children": ReactNode; "aria-label"?: string; "label"?: string }
		| { "children"?: never; "aria-label": string; "label"?: string }
		| { "children"?: never; "aria-label"?: never; "label": string }
	);
/**
 * -------------------------------------------------------------------------------------------------
 * Button is a <button> element with standard styling, available in several variants:
 * -------------------------------------------------------------------------------------------------
 * - `primary`, which is used for the primary call to action (e.g., "save" buttons vs. "cancel")
 * - `secondary`, which is used for ancillary actions (e.g., a "buy" button for a non-primary plan)
 * - `tertiary`, which is used for actions you want to be noticed last (e.g., "cancel" buttons)
 * - `negative`, dangerous actions, deleting, saying no, etc.
 * - `icon`, which is used for actionable icons
 * - `icon-negative`, dangerous actions for actionable icons, ex: deleting
 * - `icon-segmented`, a mutually-exclusive choice in a set of side-by-side buttons
 *
 * An optional tooltip can be added on hover.
 * -------------------------------------------------------------------------------------------------
 */
const BaseButton: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
	{
		variant = "normal",
		level = "secondary",
		size = "sm",
		fullWidth = false,
		className = "",
		label,
		icon: PropIcon,
		state = "default",
		toggled = false,
		segmentPosition = "none",
		mono = false,
		loading = false,
		// forceRounded is a little hacky, but it's intended for edge cases where
		// we have an icon button (which typically is a button with an icon prop
		// and no children), but it needs to have children. An example is the comment
		// tab in the properties pane, which has an icon and a badge count child.
		forceRounded = false,
		children,
		disabled,
		...rest
	},
	ref
) => {
	const iconOnly =
		(!children && PropIcon !== undefined) || (PropIcon !== undefined && forceRounded);
	const buttonClassName = [
		buttonClassBase({ fullWidth, level }),
		buttonClassSizes(size, level, iconOnly, mono),
		buttonClassStyles(variant, level, state, loading),
		buttonClassSegments(segmentPosition),
		buttonClassToggled(toggled, variant, level),
		// TODO: Deprecate this in the future
		className,
	];

	const ariaLabel = rest["aria-label"] || label;

	const props = {
		...rest,
		...(ariaLabel ? { "aria-label": ariaLabel } : {}),
	};

	// Set loading spinner and icon settings by button size
	let loadingSpinnerSize: LoadingSpinnerSize = "sm";
	let iconSize: ButtonIconSize = 16;

	switch (size) {
		case "xs":
			loadingSpinnerSize = "xs";
			iconSize = 12;
			break;
		case "sm":
		default:
			loadingSpinnerSize = "sm";
			iconSize = 16;
			break;
		case "md":
			loadingSpinnerSize = "sm";
			iconSize = 16;
			break;
		case "lg":
			loadingSpinnerSize = "sm";
			iconSize = 16;
			break;
	}

	const DEFAULT_ICON_PROPS = { size: iconSize, className: "" };

	let spinnerVariant: LoadingSpinnerVariant = "dark";
	let spinnerVariantFixed = false;
	if (level === "primary") {
		spinnerVariant = "light";
		spinnerVariantFixed = true;
	}

	type SpinnerProps = {
		className?: string;
	};

	const Spinner: FC<SpinnerProps> = ({ className }) => {
		return (
			<div className={cx("self-center", className)}>
				<LoadingSpinner
					size={loadingSpinnerSize}
					variant={spinnerVariant}
					fixed={spinnerVariantFixed}
				/>
			</div>
		);
	};

	// If there's an icon (either alone or with text), replace it with the loading spinner
	// while loading. If there's no icon, we show the loading spinner on top of the text
	// and make the text transparent.
	const buttonContent = PropIcon ? (
		<>
			{loading ? <Spinner /> : <Icon icon={PropIcon} {...DEFAULT_ICON_PROPS} />}
			{children}
		</>
	) : (
		<>
			{loading ? (
				<div className="flex items-center justify-center text-transparent !no-underline">
					<Spinner className={cx("absolute")} />
					{children}
				</div>
			) : (
				children
			)}
		</>
	);

	return (
		<button
			type="button"
			className={cx(...buttonClassName)}
			ref={ref}
			disabled={loading || disabled}
			// IMPORTANT: Spread props here or `Button` will not work with RadixUI triggers!
			{...props}
		>
			{buttonContent}
		</button>
	);
};

export const Button = forwardRef(BaseButton);
