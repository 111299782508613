import z from "zod";

export const persistenceError = z.object({
	type: z.literal("persistence-error"),
	service: z.union([
		z.literal("pageCollab"),
		z.literal("metadataCollab"),
		z.literal("pageLocalStorageCollab"),
	]),
	error: z.string(),
});
export type MessagePersistenceError = z.infer<typeof persistenceError>;

export const makePersistenceErrorMessage = (
	error: string,
	service: MessagePersistenceError["service"]
): MessagePersistenceError => {
	return {
		type: "persistence-error",
		error,
		service,
	};
};
