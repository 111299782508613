import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type Organization } from "~/models/organizations";

import { type OrganizationsState } from "./types";

const initialState: OrganizationsState = {
	organizations: {},
};

export const organizationsSlice = createSlice({
	name: "organizations",
	initialState,
	reducers: {
		addOrganization: (
			state: OrganizationsState,
			action: PayloadAction<{
				organization: Organization;
			}>
		) => {
			const { organization } = action.payload;
			state.organizations[organization.id] = organization;
		},
	},
});

export const organizations = organizationsSlice.reducer;
export const { addOrganization } = organizationsSlice.actions;
