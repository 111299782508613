import type { FunctionComponent } from "react";
import { useEffect } from "react";
import toast, { ToastBar, Toaster, useToasterStore } from "react-hot-toast";

import { Toast } from "./Toast";
import type { ToastOptions } from "./types";

export { ToastOptions };

/**
 * Used for displaying Toast notifications at the bottom right of the screen.
 *
 * There are 3 types of notifications and 3 variants:
 *    Success (uses a "positive color" checkmark icon)
 *    Error (uses a "negative color" error icon)
 *    Info (uses no icon, just the message)
 *
 * Notifications have 3 variants that apply to all types listed above:
 *    [Default] Timed dismissible — The notification has a close "X" on the right, that when clicked dismisses the notification.
 *    Timed non-dismissible — use sparingly… no dismiss icon appears, but the notification disappears after a set time.
 *    Non-timed dismissible — use sparingly, for things a user must acknowledge. Only disappears when a user specifically closes it.
 */

export type ToastNotificationsProps = {
	limit?: number;
};

export const ToastNotifications: FunctionComponent<ToastNotificationsProps> = ({ limit = 7 }) => {
	const { toasts } = useToasterStore();

	useEffect(() => {
		toasts
			.filter((t) => t.visible) // find visible toasts
			.filter((_, i) => i >= limit) // find toasts that fall out of range
			.forEach((t) => toast.remove(t.id)); // then dismiss
	}, [toasts]);

	return (
		<Toaster
			position="bottom-right"
			// Spawn toasts from the bottom
			reverseOrder={false}
			containerStyle={{
				// Toasts are emitted 32px from the right and bottom border of the application window
				bottom: 32,
				right: 32,
			}}
			// Toasts are 24px between each another
			gutter={24}
		>
			{(t) => (
				<ToastBar
					toast={t}
					style={{
						...t.style,
						animation: t.visible ? "custom-enter 1s ease" : "custom-exit 1s ease",
					}}
				/>
			)}
		</Toaster>
	);
};

export const makeToast = ({
	message,
	timer = "timed-dismissible",
	variant = "info",
	customIcon,
	action,
}: ToastOptions) => {
	// alias for message length
	const length = message.length;

	// Duration is 50ms x number of characters in the message, bounded within 2s - 7s
	const duration =
		timer === "dismissible" ? Infinity : Math.min(7000, Math.max(50 * length, 2000));

	// create toast and assign ID to toastID
	// .custom has a side effect which sends the toast
	// to the toaster store
	const toastId = toast.custom(
		({ id, ariaProps }) => (
			<Toast
				ariaProps={ariaProps}
				message={message}
				variant={variant}
				timer={timer}
				action={action}
				customIcon={customIcon || null}
				onDismiss={() => toast.remove(id)}
			/>
		),
		{
			duration,
		}
	);

	// return reference to toast
	return {
		id: toastId,
		dismiss: () => {
			toast.remove(toastId);
		},
	};
};
