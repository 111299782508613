import type { FC } from "react";

export const LoadingSpinnerSizeOptions = ["xs", "sm", "md", "lg"] as const;
export type LoadingSpinnerSize = (typeof LoadingSpinnerSizeOptions)[number];

export const LoadingSpinnerVariantOptions = ["dark", "light", "brand"] as const;
export type LoadingSpinnerVariant = (typeof LoadingSpinnerVariantOptions)[number];

export type LoadingSpinnerProps = {
	variant?: LoadingSpinnerVariant;
	fixed?: boolean;
	size?: LoadingSpinnerSize;
};
const spinnerSizeClassnames: {
	[key in LoadingSpinnerSize]: string[];
} = {
	xs: ["h-3", "w-3"],
	sm: ["h-4", "w-4"],
	md: ["h-5", "w-5"],
	lg: ["h-6", "w-6"],
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
	variant = "dark",
	size = "sm",
	fixed = false,
}) => {
	const sizeClasses = spinnerSizeClassnames[size].join(" ");
	let variantClasses = "";
	let opacity = "opacity-50";
	switch (variant) {
		case "dark":
		default:
			variantClasses = fixed ? "text-fixed-dark-default" : "text-primary";
			break;
		case "light":
			variantClasses = fixed ? "text-fixed-light-default" : "text-inverse-primary";
			break;
		case "brand":
			variantClasses = "text-brand";
			opacity = "opacity-100";
			break;
	}

	return (
		<svg
			className={`${sizeClasses} animate-spin ${variantClasses}`}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
		>
			<circle
				className="opacity-10"
				cx="12"
				cy="12"
				r="10"
				stroke="currentColor"
				strokeWidth="4"
			></circle>
			<path
				className={opacity}
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
			></path>
		</svg>
	);
};
