import { jwtDecode } from "jwt-decode";

import { type IdToken } from ".";
import { type Auth0Info, type OAuthTokenPayload } from "./common";

export class TokenError extends Error {
	constructor(
		message: string,
		public readonly response: Response
	) {
		super(message);
	}
}

/* @internal */
/**
 * Type for the standard `fetch` function. This allows us to provide mock implementations for testing.
 */
export type Fetch = (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;

/**
 * Takes the OAuth2 `authorization_code` obtained from the login process and uses it to obtain
 * OAuth2 tokens from the authorization server.
 *
 * `authorization_code` is typically included in the query params in the URL callback given to the
 * auth server. For example, the auth server might call
 * `moment-dev://login-redirect?code=whatever`, and the `code` parameter would carry the
 * `authorization_code` provided to this function.
 *
 * If the `refresh_token` is missing, this function will throw an exception. This condition
 * generally indicates that the authorization server has been misconfigured to disallow access token
 * rotation.
 */
export async function loadTokens(
	fetch: Fetch,
	auth0Config: Auth0Info,
	loginRedirectUri: string,
	authCode: string | string[]
): Promise<OAuthTokenPayload> {
	const exchangeOptions = {
		code: authCode,
		grant_type: "authorization_code",
		client_id: auth0Config.auth0ClientId,
		redirect_uri: loginRedirectUri,
	};

	const options: RequestInit = {
		method: "POST",
		headers: { "content-type": "application/json" },
		body: JSON.stringify(exchangeOptions),
	};

	const response = await fetch(`https://${auth0Config.auth0Domain}/oauth/token`, options);

	//
	// Consider anything other than 200 OK a failure.
	//

	if (response.status !== 200) {
		throw {
			message: "failed to fetch OAuth tokens after login",
			state: response.status,
		};
	}

	const responseBody = await response.json();
	const accessToken = responseBody.access_token;
	const idToken: IdToken = jwtDecode(responseBody.id_token);
	const refreshToken = responseBody.refresh_token;

	if (refreshToken === undefined) {
		throw {
			message:
				"internal error: refresh token is missing, usually indicating the authorization server has been misconfigured",
		};
	}

	return { idToken, accessToken, refreshToken };
}

/**
 * Takes an OAuth `refresh_token` obtained from the login process and uses it to rotate the OAuth
 * access token with the authorization server.
 *
 * `refresh_token` is usually provided in response to the `authorization_code`, currently
 * implemented in the `loadTokens` function.
 */
export async function refreshAccessToken(
	fetch: Fetch,
	auth0Config: Auth0Info,
	refreshToken: string
) {
	//
	// Use refresh token to fetch a new access token.
	//
	const refreshOptions: RequestInit = {
		method: "POST",
		headers: { "content-type": "application/json" },
		body: JSON.stringify({
			grant_type: "refresh_token",
			client_id: auth0Config.auth0ClientId,
			refresh_token: refreshToken,
		}),
	};

	const response = await fetch(`https://${auth0Config.auth0Domain}/oauth/token`, refreshOptions);

	//
	// Consider anything other than 200 OK a failure.
	//

	if (response.status !== 200) {
		throw new TokenError("failed to refresh tokens", response);
	}

	//
	// Set global tokens with response.
	//

	const body = await response.json();
	const accessToken = body.access_token as string;

	const jwt: { [key: string]: string | number } = jwtDecode(body.id_token);
	const idToken = toIdToken(jwt);

	//
	// Return access token.
	//

	return { accessToken, idToken };
}

const toIdToken = (jwt: { [key: string]: string | number }): IdToken => {
	type PartialToken = {
		given_name: string;
		family_name: string;
		nickname: string;
		name: string;
		picture: string;
		locale: string;
		updated_at: string;
		email: string;
		iss: string;
		sub: string;
		aud: string;
		iat: number;
		exp: number;
	};

	const partialToken = jwt as PartialToken;

	const claims: { [key: string]: string } = {
		name: (jwt["http://api.moment.dev/name"] || "") as string,
		nickname: (jwt["http://api.moment.dev/nickname"] || "") as string,
		givenName: (jwt["http://api.moment.dev/given_name"] || "") as string,
		familyName: (jwt["http://api.moment.dev/family_name"] || "") as string,
		profilePicture: (jwt["http://api.moment.dev/picture"] || "") as string,
		email: (jwt["http://api.moment.dev/email"] || "") as string,
		loginType: (jwt["login_type"] || "") as string,
	};

	return {
		...partialToken,
		claims,
	};
};
