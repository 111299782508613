import type { IsFeatureEnabledOptions, Properties } from "posthog-js";
import { useEffect, useState } from "react";

const _posthog = import("posthog-js");
type Posthog = Awaited<typeof _posthog>["default"];

/**
 * A synchronous, deferred-loading Posthog client. All requests to Posthog are fire-and-forget. This
 * is useful for keeping bundle size down.
 */
export const posthog = {
	reset: () => void _posthog.then((ph) => ph.default.reset()),
	identify: (userId: string, traits?: Properties) =>
		void _posthog.then((ph) => ph.default.identify(userId, traits)),
	opt_out_capturing: () => void _posthog.then((ph) => ph.default.opt_out_capturing()),
	group: (groupType: string, groupKey: string, groupPropertiesToSet?: Properties | undefined) =>
		void _posthog.then((ph) => ph.default.group(groupType, groupKey, groupPropertiesToSet)),
	capture: (eventName: string, properties?: Properties | undefined) =>
		void _posthog.then((ph) => ph.default.capture(eventName, properties)),
	setPersonPropertiesForFlags: (properties: Properties) =>
		void _posthog.then((ph) => ph.default.setPersonPropertiesForFlags(properties)),
	onFeatureFlags: (
		callback: (flags: string[], variants: Record<string, string | boolean>) => void
	) => void _posthog.then((ph) => ph.default.onFeatureFlags(callback)),
	isFeatureEnabled: (featureName: string, options?: IsFeatureEnabledOptions | undefined) =>
		_posthog.then((ph) => ph.default.isFeatureEnabled(featureName, options)),
	getFeatureFlagPayload: (featureName: string) =>
		_posthog.then((ph) => ph.default.getFeatureFlagPayload(featureName)),
};

export const usePosthog = () => {
	const [ph, setPosthog] = useState<Posthog | null>(null);

	useEffect(() => {
		void _posthog.then((ph) => {
			setPosthog(ph.default);
		});
	}, []);

	return ph;
};
