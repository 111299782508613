import { type MarkSpec } from "prosemirror-model";

import { code } from "./code";
import { comment } from "./comment";
import { em } from "./em";
import { kbd } from "./kbd";
import { link } from "./link";
import { strike } from "./strike";
import { strong } from "./strong";
import { underline } from "./underline";

type MarkSpecNames = "em" | "strong" | "code" | "link" | "underline" | "strike" | "comment" | "kbd";

export const marks: {
	[val in MarkSpecNames]: MarkSpec;
} = {
	link,
	em,
	strong,
	code,
	underline,
	strike,
	kbd,
	comment,
};
