import dynamic from "next/dynamic";
import { type FC, type ReactNode, useEffect } from "react";

import { selectAuth } from "~/store/auth/selectors";
import { useAppSelector } from "~/store/hooks";
import { analytics } from "~/utils/analytics";
import { usePosthog } from "~/utils/analytics/posthog-js";

const PostHogProvider = dynamic(() => import("posthog-js/react").then((m) => m.PostHogProvider), {
	ssr: false,
});

export const PostHog: FC<{ children?: ReactNode }> = ({ children }) => {
	const auth = useAppSelector(selectAuth);

	const posthog = usePosthog();

	let api_host = "https://app.posthog.com";
	if (typeof window !== "undefined" && !window.location.hostname.includes("localhost")) {
		api_host = `${window.location.protocol}//${window.location.hostname}/ingest`;
	}

	const config = {
		api_host,
		// Enable debug mode in development
		loaded: (posthog) => {
			if (process.env.NODE_ENV === "development") posthog.debug();
		},
		ui_host: "https://app.posthog.com",
		autocapture: true,
	};

	// I am not sure why we have to do this.
	// But their docs say we have to do this: https://posthog.com/docs/advanced/proxy
	// this could be lies.
	if (config.api_host != "https://app.posthog.com") {
		config.ui_host = "https://app.posthog.com";
	}

	useEffect(() => {
		// Check that PostHog is client-side (used to handle Next.js SSR)
		if (typeof window !== "undefined" && posthog) {
			posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", config);
		}
	}, [posthog]);

	useEffect(() => {
		analytics.identify(auth.idToken?.sub, auth.profile);
	}, [auth]);

	if (!posthog) {
		return null;
	}

	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
