import { type ComponentType as CarbonComponentType } from "@carbon/icons-react";
import { type ComponentType, type FC, type HTMLAttributes } from "react";

export const DEFAULT_ICON_PROPS = { size: 16, className: "fill-icon-default" };

export const IconSizeOptions = [12, 16, 20, 24, 32] as const;
export type IconSize = (typeof IconSizeOptions)[number];
export type CustomIconType = ComponentType<HTMLAttributes<SVGElement>>;
export type MomentIconType = CarbonComponentType | CustomIconType;

export type IconProps = HTMLAttributes<SVGElement> & {
	icon: MomentIconType;
	size?: IconSize;
};

export const Icon: FC<IconProps> = ({ icon: PropIcon, ...props }) => (
	<PropIcon {...DEFAULT_ICON_PROPS} {...props} />
);
