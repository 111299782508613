import { type Attrs, type MarkSpec } from "prosemirror-model";

// :: MarkSpec A comment. Has `id` attribute. Rendered and parsed as an `<comment>` element.
const MISSING_COMMENT_THREAD_ID = "missing-commentThreadId";

export const comment: MarkSpec = {
	attrs: {
		id: {},
		commentThreadId: { default: MISSING_COMMENT_THREAD_ID }, // This default should never be used, if it is, it's a bug
	},
	inclusive: true,
	excludes: "", // Allows multiple overlapping comment marks with unique ids
	parseDOM: [
		{
			tag: "comment",
			getAttrs(node: string | HTMLElement) {
				if (!(node instanceof HTMLElement)) {
					return false;
				}

				const attrs: Attrs = {
					id: node.getAttribute("id"),
					commentThreadId: node.getAttribute("commentThreadId"),
				};

				return attrs;
			},
		},
	],
	toDOM(mark) {
		return ["comment", mark.attrs, 0];
	},
};
