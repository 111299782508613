import { type DocumentNode, type QueryHookOptions, useQuery } from "@apollo/client";

import { compressJSON } from "./compression/compressJSON";
import { decompressJSON } from "./compression/decompressJSON";

export const LOCAL_STORAGE_KEYS = {
	AUTH_TOKEN: "moment:authtoken",
	CANVAS_LAYOUT_VISUAL_DEBUG: "moment:canvas-layout-visual-debug",
	REDIRECT_STORE: "auth0:redirect-store",
	USER: "moment:user",
	PANES: "moment:panes",
	PRODUCTION_ENV_EXPIRATION: "moment:production-env-expiration",
	ATLAS_USER_TOKEN: "moment:atlas-user-token",
	ATLAS_SETUP_ACCORDION_STATE: "moment:atlas-setup-accordion-state",
	STATUS_BAR_STATE: "moment:dev-mode-bar-state",
};

export const SESSION_STORAGE_KEYS = {
	TAB_ID: "moment:tab-id",
};

export const PANE_IDS = {
	NAV_PANE: "nav-pane",
	PROPERTIES_PANE: "properties-pane",
};

export type LocalStorageKey = keyof typeof LOCAL_STORAGE_KEYS;
export type LocalStorageKeyName = (typeof LOCAL_STORAGE_KEYS)[LocalStorageKey];
export type LocalStorageItem<T> = Record<string, LocalStorageItemByID<T>>;
export type LocalStorageItemByID<T> = {
	expires: number;
	value: T;
};

const getExpires = () => {
	return Date.now() + 30 * 24 * 60 * 60 * 1000; // 30 days
};

const isExpired = <T>(item: LocalStorageItemByID<T>) => {
	// 0 denotes no expiry
	return item.expires !== 0 && item.expires < Date.now();
};

const getParsedItem = <T>(key: LocalStorageKeyName): LocalStorageItem<T> | null => {
	if (typeof window === "undefined") return null;

	// Get the current value
	const currentValue = localStorage.getItem(key) ?? compressJSON({});

	try {
		// Parse the content
		return decompressJSON<LocalStorageItem<T>>(currentValue);
	} catch (e) {
		return {};
	}
};

export const getItem = <T extends object | null>(key: LocalStorageKeyName): T | null => {
	if (typeof window === "undefined") return null;

	// Get the current value
	const currentValue = localStorage.getItem(key) ?? "null";

	try {
		// Parse the content
		return decompressJSON<T>(currentValue);
	} catch (e) {
		return null;
	}
};

export const setItem = <T extends object | null>(key: LocalStorageKeyName, value: T) => {
	if (typeof window === "undefined") return;

	try {
		const stringified = compressJSON<T>(value);
		localStorage.setItem(key, stringified);
	} catch (e) {
		// pass
	}
};

/**
 * Caches an item in local storage in a given namespace (LocalStorageKeyName)
 *
 *
 * @param key - LocalStorageKeyName, namespace to cache the item. This is stringified
 * JSON, so do not put anything in here that cannot be serialized/deserialized
 * @param id - The id of the object being cached, e.g. UserID
 * @param value - The value of the object being cached, e.g. the user to be stringified.
 * If this is coming from a network request, cache the network response, not the hydrated equivalent
 * @param [expires] - Optional expires timestamp. Default is 30 days from now. Set it to 0 for no expiry.
 
* @returns undefined
 */
export const setItemById = <T>(
	key: LocalStorageKeyName,
	id: string,
	value: T,
	expires?: number
) => {
	const parsed = getParsedItem<T>(key);

	if (!parsed) return;

	// Set the new value
	parsed[id] = {
		expires: expires ?? getExpires(),
		value,
	};

	setItem<LocalStorageItem<T>>(key, parsed);
};

/**
 * Gets the cached item in local storage in a given namespace (LocalStorageKeyName)
 * Cleans up the object from local storage if expired
 *
 *
 * @param key - LocalStorageKeyName, namespace to cache the item. This is stringified
 * JSON, so do not put anything in here that cannot be serialized/deserialized
 * @param id - The id of the object being cached, e.g. UserID
 
 * @returns value - The value of the object being cached
 */
export const getItemById = <T>(key: LocalStorageKeyName, id: string) => {
	const parsed = getParsedItem<T>(key);

	if (!parsed) return;

	const item = parsed[id];
	if (item !== undefined && isExpired(item)) {
		// Cleanup
		removeItemById(key, id);
	}

	return item;
};

export const removeItemById = (key: LocalStorageKeyName, id: string) => {
	const parsed = getParsedItem(key);

	if (!parsed) return;

	delete parsed[id];

	setItem<Record<string, unknown>>(key, parsed);
};

export const clearStorage = () => {
	if (typeof window === "undefined") return;
	localStorage.clear();
	sessionStorage.clear();
};

// Use sparingly - we don't want to cache things like canvases (big), Users are relatively small, so that's ok
export const useLocallyCachedQuery = <Query>({
	query,
	options,
	storageKey,
	id,
}: {
	query: DocumentNode;
	options?: QueryHookOptions<Query>;
	storageKey: LocalStorageKeyName;
	id: string;
}) => {
	// Get Cached Item
	const cachedItem = getItemById<NonNullable<Query>>(storageKey, id);

	// Kick off the query
	const { loading, error, data } = useQuery<Query>(query, options);

	// Set the cache when data exists
	if (data) {
		// Update local storage
		setItemById<NonNullable<Query>>(storageKey, id, data);
	}

	// Return the response item if available, otherwise fall back to the cached item if available
	return { loading, error, data: data || cachedItem?.value };
};

export const setAtlasUserToken = (orgID: string) => {
	if (typeof window === "undefined") return;

	localStorage.setItem(LOCAL_STORAGE_KEYS.ATLAS_USER_TOKEN, orgID);
};

export const unsetAtlasUserToken = () => {
	if (typeof window === "undefined") return;

	localStorage.removeItem(LOCAL_STORAGE_KEYS.ATLAS_USER_TOKEN);
};

export const getAtlasUserToken = () => {
	if (typeof window === "undefined") return;

	return localStorage.getItem(LOCAL_STORAGE_KEYS.ATLAS_USER_TOKEN);
};
