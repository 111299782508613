import Cookies from "js-cookie";

import { safeStringify } from "~/lib/json";
import { nanoid } from "~/lib/nanoid";

import { CORS, IsOriginAllowed } from "../cors";
import { type RetryOptions, Retryable } from "./retryable";

export * from "./Env";
export * from "./PagesCollabApiClient";

export const headerRequestID = "X-Request-ID";
export const headerAuth = "Authorization";

export class UnauthorizedError extends Error {}

export const accessToken = () =>
	localStorage.getItem("moment:authtoken") || Cookies.get("mom_auth_v1") || "";
const authorizationHeaderValue = (accessToken: string) => `Bearer ${accessToken}`;

export interface HttpHeaders {
	get(name: string): string | null;
}

export const post = (
	headers: Record<string, string>,
	body: unknown,
	abort?: AbortController | undefined
): RequestInit => ({
	method: "POST",
	body: safeStringify(body),
	headers,
	signal: abort?.signal || undefined,
});

export function createHttpHeaders(authToken: string | null) {
	const headers = {
		"X-Request-ID": nanoid(),
	};
	if (authToken) {
		headers[headerAuth] = authorizationHeaderValue(authToken);
	} else {
		// I don't think we should allow this.
		// If we do not have an auth token, we should fail.
		// If we need to support logged out users, we should use an anonymous token.
		throw new UnauthorizedError("No token was provided");
	}
	return headers;
}

// Get the request id from the header or return a nanoid()
export function getRequestID(headers: HttpHeaders): string {
	return headers.get(headerRequestID) ?? nanoid();
}

export function getAuthorization(headers: HttpHeaders): string {
	return headers.get(headerAuth) ?? "";
}

export interface ApiCollabConnectResponse {
	isConnectSuccessful: boolean;
	isPersistenceFailing: boolean;
}

export function makeApiCollabConnectResponse(
	isConnectSuccessful: boolean,
	isPersistenceFailing: boolean
): ApiCollabConnectResponse {
	return {
		isConnectSuccessful,
		isPersistenceFailing,
	};
}

/**
 * When a test errors out due to an export from this file, the error that is shown is:
 * ```
 * ReferenceError: Response is not defined
 * ```
 *
 * To fix this, add isomorphic-fetch to the test imports:
 * ```
 * import "isomorphic-fetch";
 * ```
 */
export class ApiCollabResponse<T> extends Response {
	// Required to get strong type-checking, for some reason. Without this,
	// `ApiCollabResponse<string>` is assignable to `ApiCollabResponse<number>`??
	_body: T;

	constructor(body: T, init?: ResponseInit | undefined) {
		super(safeStringify(body), init);
		this._body = body;
	}
}

export const response = <T>(
	status: number,
	requestHeaders: Headers,
	body: T
): ApiCollabResponse<T> => {
	// has to be lower cased.
	const origin = requestHeaders.get("origin");
	if (origin && IsOriginAllowed(origin)) {
		return new ApiCollabResponse(body, {
			status,
			headers: { ...CORS, "Access-Control-Allow-Origin": origin },
		});
	}

	return new ApiCollabResponse(body, { status, headers: CORS });
};

/*
 * This is the same strategy used in the front end (app/src/components/canvas/easel/collab/useCollabServerConfig.ts)
 */
const sanitizeRoomName = (s: string) => s.replace(/[:_]/g, "-");
export const areQueryParamsValid = (url: string, canvasID: string, pageID?: string): boolean => {
	const guessedRoomName = sanitizeRoomName(pageID ? `${canvasID}-${pageID}` : canvasID);
	return url.endsWith(guessedRoomName);
};

// Intended to canonicalize fetch errors as a NetworkError in order
// to more easily determine network errors as an exception.
// This is because different browsers return different errors.
const reformatNetworkErrors = (
	baseFetchFn: (init?: RequestInit | undefined) => Promise<Response>
): ((init?: RequestInit | undefined) => Promise<Response>) => {
	return async (init?: RequestInit | undefined) => {
		try {
			return await baseFetchFn(init);
		} catch (error) {
			if (error instanceof TypeError && error.message.startsWith("NetworkError")) {
				// Firefox
				throw new NetworkError(error);
			}
			if (error instanceof TypeError && error.message.startsWith("Failed to fetch")) {
				// Chrome
				throw new NetworkError(error);
			}
			if (error instanceof TypeError && error.message.startsWith("Load failed")) {
				// Safari
				throw new NetworkError(error);
			}
			throw error;
		}
	};
};

export const momentFetchFnWrapper = (
	baseFetchFn: (init?: RequestInit | undefined) => Promise<Response>,
	retryOptions?: RetryOptions
): ((init?: RequestInit | undefined) => Promise<Response>) => {
	return new Retryable(retryOptions).retryable(reformatNetworkErrors(baseFetchFn));
};

export class NetworkError extends Error {
	constructor(public underlyingError: unknown) {
		let message = `Unknown error ${underlyingError}`;
		if (underlyingError instanceof Error) {
			message = underlyingError.message;
		}
		super(message);
	}
}
