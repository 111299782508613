import { type DOMOutputSpec, type MarkSpec } from "prosemirror-model";

// :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
// also match `<b>` and `font-weight: bold`.

const strongDOM: DOMOutputSpec = ["strong", 0];

export const strong: MarkSpec = {
	parseDOM: [
		{ tag: "strong" },
		// This works around a Google Docs misbehavior where
		// pasted content will be inexplicably wrapped in `<b>`
		// tags with a font-weight normal.
		{
			tag: "b",
			getAttrs: (node: string | HTMLElement) => {
				if (typeof node === "string") {
					return false;
				}

				return node.style.fontWeight != "normal" && null;
			},
		},
		{
			style: "font-weight",
			getAttrs: (node: string | HTMLElement) => {
				if (typeof node !== "string") {
					return false;
				}

				// node is the value of Element.style.fontWeight

				// if value matches
				// • "bold"
				// • "bolder"
				// • 500
				// • 600
				// • 700
				// • 800
				// • 900
				// then return true

				return /^(bold(er)?|[5-9]\d{2,})$/.test(node) && null;
			},
		},
	],
	toDOM() {
		return strongDOM;
	},
};
