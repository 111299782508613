import { getAuthConfigs } from "~/auth/authconfig";

/**
 * Tokens returned by the OAuth2 authorization server.
 */
export interface OAuthTokenPayload {
	idToken?: IdToken;
	accessToken?: string;
	refreshToken?: string;
}

/**
 * OAuth2 Connect ID token. The authorization server returns this token to provide information about
 * an authenticated user.
 */
export interface IdToken {
	given_name: string;
	family_name: string;
	nickname: string;
	name: string;
	picture: string;
	locale: string;
	updated_at: string;
	iss: string;
	sub: string;
	aud: string;
	iat: number;
	exp: number;
	claims: {
		[key: string]: string;
	};
	[key: string]: unknown;
}

export interface Auth0Info {
	apiIdentifier: string;
	auth0Domain: string;
	auth0ClientId: string;
}

const makeAuth0AuthorizeUrl = (auth0: Auth0Info, loginRedirectUri: string) => {
	const { auth0Domain, apiIdentifier, auth0ClientId } = auth0;

	const queryParams = {
		audience: apiIdentifier,
		scope: "openid profile offline_access",
		response_type: "code",
		client_id: auth0ClientId,
		redirect_uri: loginRedirectUri,

		// Always trigger the Google SSO account selector. NEVER assume we know which account they'd
		// like to log into.
		prompt: "select_account",
	};

	const queryString = new URLSearchParams(queryParams);

	return `https://${auth0Domain}/authorize?${queryString.toString()}`;
};

export const loginRedirectPath = "login";

const LOCAL_BASE_URL_DEFAULT = "http://localhost:3333";
const STAGING_BASE_URL_DEFAULT = "https://app-staging.moment.dev";
const DESKTOP_APP_SCHEME = "moment-app://";

const currentBaseUrl =
	typeof window === "undefined"
		? undefined
		: `${window.location.protocol}//${window.location.host}`;

export const isDesktopAppMode = () => {
	return process.env.MOMENT_DESKTOP_APP === "true";
};

export const isServiceWorkerDisabled = () => {
	return process.env.DISABLE_SERVICE_WORKER === "true";
};

export const getWebBaseURL = (env?: MomentAppEnv): string => {
	if (isDesktopAppMode()) {
		return DESKTOP_APP_SCHEME;
	}

	// On local, force to local urls (instead of passed env)
	if (currentBaseUrl === LOCAL_BASE_URL_DEFAULT) {
		return LOCAL_BASE_URL_DEFAULT;
	}

	switch (env) {
		case "staging":
			// Allow current urls for vercel deploys, otherwise use web-staging
			return currentBaseUrl ?? STAGING_BASE_URL_DEFAULT;
		case "production":
			return "https://app.moment.dev";
		case "local":
		case "testing":
		default:
			return currentBaseUrl ?? LOCAL_BASE_URL_DEFAULT;
	}
};

const getLoginRedirectURL = (env: MomentAppEnv): string => {
	return `${getWebBaseURL(env)}/${loginRedirectPath}`;
};

const getUserHMACURL = (env: MomentAppEnv): string => {
	return `${getWebBaseURL(env)}/api/v1/user/hmac`;
};

export const getAuthURLs = (env: MomentAppEnv) => {
	const loginRedirectUri = getLoginRedirectURL(env);
	const authVars = getAuthConfigs(env);
	const tokenUrL = `https://${authVars.auth0Domain}/oauth/token`;
	const logoutUrl = `https://${authVars.auth0Domain}/v2/logout`;
	const userHmacUrl = getUserHMACURL(env);

	return {
		authorize: makeAuth0AuthorizeUrl(authVars, loginRedirectUri),
		loginRedirectUri,
		token: tokenUrL,
		logout: logoutUrl,
		userHash: userHmacUrl,
	};
};

export interface Auth {
	/**
	 * Set current URI to the Auth0 authorization URL. Once authorization is complete, Auth0 is in
	 * charge of redirecting back to the application.
	 *
	 * NOTE: This will NOT work in Electron!
	 */
	redirectToAuth0LoginPage: (state?: string) => void;

	/**
	 * Only works for Electron desktop app because in the normal web app,
	 * the IPC API will be `undefined`.
	 *
	 * Returns the auth URL that was opened in the browser.
	 */
	openAuth0LoginPageForDesktopAuth: (state?: string) => Promise<string>;

	/**
	 * Synchronously log out.
	 */
	logout: () => void;

	/**
	 * Takes the OAuth2 `authorization_code` obtained from the login process and uses it to obtain
	 * OAuth2 tokens from the authorization server.
	 *
	 * `authorization_code` is typically included in the query params in the URL callback given to
	 * the auth server. For example, the auth server might call
	 * `moment-dev:///login-redirect?code=whatever`, and the `code` parameter would carry the
	 * `authorization_code` provided to this function.
	 *
	 * If the `refresh_token` is missing, this function will throw an exception. This condition
	 * generally indicates that the authorization server has been misconfigured to disallow access
	 * token rotation.
	 */
	loadTokens: (authCode: string | string[]) => Promise<OAuthTokenPayload>;

	/**
	 * Takes an OAuth `refresh_token` obtained from the login process and uses it to rotate the
	 * OAuth access token with the authorization server.
	 *
	 * `refresh_token` is usually provided in response to the `authorization_code`, currently
	 * implemented in the `loadTokens` function.
	 */
	refreshAccessToken: (
		refreshToken: string
	) => Promise<{ idToken: IdToken; accessToken: string }>;

	/**
	 * Get or set OAuth2 refresh token in local storage (e.g., a cookie, OS keychain, etc.).
	 */
	oauth2RefreshToken?: string;
}
