import ClientTokenProduction from "<root>/data/datadog-env-vars.web.production.json";
import ClientTokenStaging from "<root>/data/datadog-env-vars.web.staging.json";

import { isLocal, isProduction } from "~/utils/env";

export const datadogLogs = import("@datadog/browser-logs").then(({ datadogLogs }) => datadogLogs);

export type LogLevel = "debug" | "info" | "warn" | "error";

export const isSynthetics = () => {
	if (typeof window !== "undefined") {
		return window._DATADOG_SYNTHETICS_BROWSER !== undefined;
	}

	return false;
};

export const initLogger = async () => {
	if (isLocal) {
		// We don't want to log to Datadog if we're developing locally
		// because it's too noisy.
		return;
	}

	(await datadogLogs).init({
		clientToken: clientToken(),
		site: "datadoghq.com",
		service: "app",
		env: process.env.APP_ENV,
		sampleRate: 100,
		silentMultipleInit: true,
		// Forward console errors.
		forwardErrorsToLogs: true,
		// We can enable this if we want to send all console logs.
		// forwardConsoleLogs: "all",
	});
};

const clientToken = (): string => {
	if (process.env.APP_ENV == "production") {
		return ClientTokenProduction.clientToken;
	}

	return ClientTokenStaging.clientToken;
};

export const log = (
	message: string,
	content?: Record<string, unknown>,
	level: LogLevel = "info"
): void => {
	async function _() {
		switch (level) {
			case "debug": {
				!isProduction && console.log(`${level.toUpperCase()} ${message}`, content);
				!isLocal && !isProduction && (await datadogLogs).logger.debug(message, content);
				break;
			}
			case "info": {
				!isProduction && console.log(`${level.toUpperCase()} ${message}`, content);
				!isLocal && (await datadogLogs).logger.info(message, content);
				break;
			}
			case "warn": {
				!isProduction && console.warn(`${level.toUpperCase()} ${message}`, content);
				!isLocal && (await datadogLogs).logger.warn(message, content);
				break;
			}
			case "error": {
				!isProduction && console.error(`${level.toUpperCase()} ${message}`, content);
				!isLocal && (await datadogLogs).logger.error(message, content);
				break;
			}
			default: {
				!isProduction && console.error("Unknown log level", { level, ...content });
				!isLocal &&
					(await datadogLogs).logger.error("Unknown log level", { level, ...content });
				!isLocal && (await datadogLogs).logger.info(message, content);
				break;
			}
		}
	}

	void _();
};
