import { createCellID } from "@moment/api-collab/prosemirror-utils";

import { type Page as PageGQL, type PageInput } from "~/api/generated/graphql";
import { UniqueSluggifier } from "~/utils/format/UniqueSluggifier";

import type { PageLike } from "./PageLike";
import {
	type Cell,
	type Heading,
	fromGQL as cellFromGQL,
	toGQL as cellToGQL,
	isProseMirrorJSONCell,
	parseHeading,
} from "./cell";

/**
 * Page is a collection of executable cells, each of which can be run to obtain a displayable
 * result.
 */
export interface Page {
	id: string;
	slug: string;
	title: string;
	authorID: string;
	cells: Cell[];
	archived?: boolean;
	latestVersion: number;
	parentPageID: string | null;
	created: string;
	updated: string;
}

export type PageMap = Record<string, Page>;

export const fromGQL = (page: PageGQL): Page => {
	return {
		id: page.id,
		title: page.title,
		slug: page.slug,
		authorID: page.authorID,
		cells: (page.cells || []).map(cellFromGQL),
		archived: page.archived,
		latestVersion: page.latestVersion,
		parentPageID: page.parentPageID,
		created: page.created,
		updated: page.updated,
	};
};

export const toGQL = (page: Page): PageInput => {
	return {
		id: page.id,
		title: page.title,
		slug: page.slug,
		authorID: page.authorID,
		cells: page.cells.map(cellToGQL),
		parentPageInput: page.parentPageID,
	};
};

export const createPageMap = (pages: Page[]): PageMap => {
	const pageMap: PageMap = {};
	pages.forEach((page) => {
		pageMap[page.slug] = page;
	});
	return pageMap;
};

const compareSearchTerms = (page: PageLike, needle: { id?: string; slug?: string }) => {
	const { id, slug } = needle;

	// Check ID first
	if (id !== undefined && page.id === id) {
		return true;
	}

	// Check slug next
	if (slug !== undefined && page.slug === slug) {
		return true;
	}

	return false;
};

export const findPage = <TPage extends PageLike>(
	haystack: TPage[],
	needle: { id?: string; slug?: string },
	options?: { filterArchived?: boolean }
): { page: TPage | undefined; idx: number } => {
	const { id, slug } = needle;
	const availablePages = options?.filterArchived
		? haystack.filter((p) => p.archived !== true)
		: haystack;

	if (id === undefined && slug === undefined) {
		// return first available page
		return { idx: 0, page: availablePages[0] };
	}

	for (let idx = 0; idx < availablePages.length; idx++) {
		const page = availablePages[idx];
		if (page !== undefined && compareSearchTerms(page, needle)) {
			return { page, idx };
		}
	}

	return { idx: -1, page: undefined };
};

export const transforms = {
	setTitle: (page: Page, title: string): Page => {
		return { ...page, title };
	},
};

export const parseHeadings = (page: Page): Heading[] => {
	const sluggifier = new UniqueSluggifier();
	const headings: Heading[] = [];
	page.cells.forEach((c) => {
		const h = parseHeading(sluggifier, c);
		if (h === undefined) {
			return;
		}
		headings.push(h);
	});
	return headings;
};

const updateCellCodeWithId = (cell: Cell, id: string) => {
	if (!isProseMirrorJSONCell(cell)) {
		return cell.code;
	}

	// parse the code to get the id
	const parsed = JSON.parse(cell.code);
	parsed.attrs = parsed.attrs || {};
	parsed.attrs.id = id;
	return JSON.stringify(parsed);
};

export const updateCellIDs = (cells: Cell[]) => {
	return cells.map((c) => {
		const newCellId = createCellID();
		return { ...c, code: updateCellCodeWithId(c, newCellId), id: newCellId };
	});
};

export const getLastUpdated = (page?: Page): Date => {
	if (page === undefined) {
		return new Date();
	}

	if (page.updated) {
		return new Date(page.updated);
	}

	if (page.created) {
		return new Date(page.created);
	}

	return new Date();
};
