import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "~/lib/prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "~/lib/prosemirror-utils/makeTextCellDefaultToDOMAttrs";

export const ordered_list: NodeSpec = {
	content: "list_item+",
	group: "block",
	attrs: {
		...CELL_SPEC_ATTRS,
		order: { default: 1 },
		tight: { default: false },
	},
	parseDOM: [
		{
			tag: "ol",
			getAttrs(p: string | HTMLElement) {
				if (!(p instanceof HTMLElement)) {
					return false;
				}

				const start = p.getAttribute("start");

				const attrs: Attrs = {
					order: start ? +start : 1,
					tight: p.hasAttribute("data-tight"),
				};

				return attrs;
			},
		},
	],
	toDOM(node: Node): DOMOutputSpec {
		const attrs = {
			...makeTextCellDefaultToDOMAttrs(node),
			"start": node.attrs["order"] == 1 ? null : node.attrs["order"],
			"data-tight": node.attrs["tight"] ? "true" : null,
		};

		return ["ol", attrs, 0];
	},
};
