export const CORS = {
	"Access-Control-Allow-Methods": "POST, GET, OPTIONS",
	"Access-Control-Allow-Headers": "*, Authorization",
	"Access-Control-Allow-Credentials": "true",
};

export const allowedOrigins = [
	"http://localhost:8080",
	"http://localhost:3000",
	"http://localhost:3333",
	"https://localhost:3333",
	"http://localhost:3334",
	"https://localhost:3334",
	"http://localhost:1999",
	"http://localhost:1984",
	"http://localhost:8089",
	"https://localhost:8089",
	"https://*.moment.dev",
	"https://*.vercel.app",
	"https://app.datadoghq.com",
	// Allow the desktop app.
	"moment-app://*",
];

// I do not love this but if someone has something better, feel free to change it.
const wildcardMatch = (origin: string, pattern: string) => {
	// Escape all regex special characters except '*'
	let regexPattern = pattern.replace(/([.+?^=!:${}()|[\]/\\])/g, "\\$1");
	// Replace '*' with '.*' to match any sequence of characters
	regexPattern = regexPattern.replace(/\*/g, ".*");
	// Create a RegExp with start and end anchors
	const regex = new RegExp(`^${regexPattern}$`);
	return regex.test(origin);
};

export const IsOriginAllowed = (origin: string) => {
	const lower = origin.toLowerCase();

	for (const o of allowedOrigins) {
		if (o.toLowerCase() === lower) {
			return true;
		}
	}

	for (const pattern of allowedOrigins) {
		if (pattern.includes("*") && wildcardMatch(lower, pattern)) {
			return true;
		}
	}

	return false;
};
