import { createAsyncThunk } from "@reduxjs/toolkit";

import { USER } from "~/api";
import { type UserQuery } from "~/api/generated/graphql";
import { getAuthConfigs } from "~/auth/authconfig";
import { type IdToken, auth } from "~/auth/interop";
import { getAuthURLs, isDesktopAppMode } from "~/auth/interop/common";
import { loadTokens } from "~/auth/interop/tokens";
import { type User, fromGQL as userFromGQL } from "~/models/user";
import { log } from "~/utils/datadog/datadog";
import { Logger } from "~/utils/logging";
import { clearStorage } from "~/utils/storage";

import { clearRecentlyViewed } from "../recently-viewed/slice";
import { type ThunkArgs } from "../store";
import { userLoaded } from "./actions";
import {
	setAuthenticating,
	setFailed,
	setLoggedIn,
	setLoggedOut,
	setTokens,
	setWaitingForDesktopCallback,
} from "./slice";
import { AuthStates } from "./types";

const auth0Info = getAuthConfigs(process.env.AUTH_ENV);

const logger = new Logger("store/auth/thunks");

export const refreshAccessToken = createAsyncThunk<
	{
		idToken: IdToken;
		accessToken: string;
	},
	void,
	ThunkArgs
>("auth/refreshAccessToken", async (_, { dispatch, getState }) => {
	const state = getState();
	const refreshToken = state.auth.refreshToken;

	try {
		if (refreshToken === undefined) {
			throw Error("refresh token is not yet defined");
		}

		const { idToken, accessToken } = await auth.refreshAccessToken(refreshToken);
		// Set tokens in local storage and cookies
		dispatch(
			setTokens({
				idToken,
				accessToken,
				refreshToken,
			})
		);

		// Re-fetch user, this will fail unless client is recreated above
		await dispatch(fetchUser());

		// Set user as logged in
		dispatch(setLoggedIn());

		return { idToken, accessToken };
	} catch (error) {
		logger.error("Failed to refresh access token", { error });

		// Continue to throw the error. The app should log you out,
		// as we cannot keep the user logged in if there is no refresh token.
		throw error;
	}
});

export const login = createAsyncThunk<void, string | undefined, ThunkArgs>(
	"auth/login",
	async (stateID, { getState, dispatch }) => {
		const state = getState();

		if (state.auth.state === AuthStates.LoggedIn) {
			return;
		}

		dispatch(setLoggedOut());

		// const refreshToken = state.auth.refreshToken;

		/* If we have a valid refresh token attempt to use it to get
		 * an access token + id token.
		 */
		// if (refreshToken !== undefined) {
		//     console.log("loging in with refresh token", { refreshToken });
		//     await dispatch(refreshAccessToken());
		//     // redirect to "/"
		//     return;
		// }

		/* Else we need to got to Auth0 and do the login process
		 */
		if (isDesktopAppMode()) {
			const authUrl = await auth.openAuth0LoginPageForDesktopAuth(stateID);
			dispatch(setWaitingForDesktopCallback({ authUrl }));
		} else {
			auth.redirectToAuth0LoginPage(stateID);
		}
	}
);

export const authenticateFromCode = createAsyncThunk<void, { code: string }, ThunkArgs>(
	"auth/authenticateFromCode",
	async ({ code }, { dispatch, getState }) => {
		const state = getState();

		if (
			state.auth.state !== AuthStates.LoggedOut &&
			state.auth.state !== AuthStates.WaitingForDesktopCallback
		) {
			logger.warn("trying to auth from code in state", { "auth state": state.auth.state });
			return;
		}

		try {
			dispatch(setAuthenticating());

			const { loginRedirectUri } = getAuthURLs(process.env.AUTH_ENV);

			const { refreshToken, idToken, accessToken } = await loadTokens(
				fetch,
				auth0Info,
				loginRedirectUri,
				code
			);

			if (refreshToken === undefined || idToken === undefined || accessToken === undefined) {
				logger.error("Invalid OAuthTokenPayload", { refreshToken, idToken, accessToken });
				throw Error("Invalid OAuthTokenPayload");
			}

			dispatch(
				setTokens({
					refreshToken,
					idToken,
					accessToken,
				})
			);
			await dispatch(fetchUser());
			dispatch(setLoggedIn());
		} catch (error) {
			logger.error("Auth from code failed!", { error });
			dispatch(setFailed({ error }));
		}
	}
);

export const logout = createAsyncThunk<void, void, ThunkArgs>(
	"auth/logout",
	async (_, { getState, dispatch }) => {
		const state = getState();

		if (state.auth.state == AuthStates.LoggedOut) {
			return;
		}

		// removes cookie from browser
		auth.logout();

		// Calling `clearStorage` does not empty the contents of the redux store,
		// we need to manually set redux auth state to 'logged out'
		dispatch(setLoggedOut());
		// we need to manually clear the recently viewed items.
		dispatch(clearRecentlyViewed());

		// ---
		// Ask Auth0 to logout user on their end
		// ---

		// get logout url endpoint from auth0 config
		const { logout } = getAuthURLs(process.env.AUTH_ENV);

		// where Auth0 should redirect to after logout
		const logoutCallbackURL = new URL(window.location.origin);
		logoutCallbackURL.pathname = "/logout";

		// redirect to Auth0 logout endpoint
		// https://auth0.com/docs/api/authentication#logout
		const logoutEndpoint = new URL(logout);
		// add Auth0 Client ID to url params
		logoutEndpoint.searchParams.append("client_id", auth0Info.auth0ClientId);
		// add redirect url to url params
		logoutEndpoint.searchParams.append("returnTo", logoutCallbackURL.href);

		// Clear storage to prevent redux from hanging over sessions
		clearStorage();

		// send user to Auth0 logout endpoint (similarly to auth.redirectToAuth0LoginPage)
		window.location.replace(logoutEndpoint.href);
	}
);

export const cancelDesktopLogin = createAsyncThunk<void, void, ThunkArgs>(
	"auth/cancelDesktopLogin",
	async (_, { dispatch }) => {
		// Removes cookie from browser.
		auth.logout();

		// Calling `clearStorage` does not empty the contents of the redux store,
		// we need to manually set redux auth state to 'logged out'.
		dispatch(setLoggedOut());
		// we need to manually clear the recently viewed items.
		dispatch(clearRecentlyViewed());

		// Clear storage to prevent redux from hanging over sessions.
		clearStorage();
	}
);

// TODO consolidate this with fetchUser in users store.
export const fetchUser = createAsyncThunk<User | undefined, void, ThunkArgs>(
	"auth/saveProfile",
	async (_, { getState, extra, dispatch }) => {
		const { client } = extra;
		const state = getState();

		const userID = state.auth.idToken?.sub;

		if (userID === undefined) {
			return;
		}

		if (client === undefined) {
			log("Failed to fetch user because client is undefined", {}, "error");
			return;
		}

		// The following adds a retry and backoff policy. Yes, it would be nice to make this into a library.
		// If someone wants to do that in a Typescript friendly way, please do!
		// This is added as on user creation, it might take more time to create the user so we are adding retries to fetching the user,
		// so we are adding retries here.
		// Note this is pretty crude. if it fails, we will only retry 3 times, and wait 500ms, 1s and 2s in between the following retries.

		const maxRetries = 3; // Set the number of retries
		const initialBackoff = 500; // Initial backoff time in milliseconds
		let attempt = 0;

		const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

		while (attempt < maxRetries) {
			try {
				const result = await client.query<UserQuery>({
					query: USER,
					variables: {
						id: userID,
					},
					fetchPolicy: "no-cache",
				});

				if (result === undefined) {
					return;
				}

				const user = userFromGQL(result.data.user);

				dispatch(
					userLoaded({
						organizationID: user.organizationID,
						user,
					})
				);

				return user;
			} catch (error) {
				attempt++;
				if (attempt >= maxRetries) {
					logger.error("Error fetching user after retries", { error, userID });
					return undefined;
				}

				const backoffTime = initialBackoff * Math.pow(2, attempt - 1); // Exponential backoff
				logger.warn(`Retry attempt ${attempt} failed, retrying in ${backoffTime} ms...`, {
					error,
				});

				await delay(backoffTime); // Delay with exponential backoff
			}
		}
	}
);
