export const DEFAULT_CACHE_DURATION = 5 * 1000 * 60; // 5m

export type CacheEntry<T> = { data: T; ts: number };

/**
 * Returns a class instance to get/set/clean cache based on expiry
 *
 * constructor
 * @param cacheDuration - (Number - Default: 5m) - Length of time to keep the data in cache
 *
 */
export class ModelCache<T> {
	private cache: { [key: string]: CacheEntry<T> } = {};
	private cacheDuration: number;

	constructor(cacheDuration: number = DEFAULT_CACHE_DURATION) {
		this.cacheDuration = cacheDuration;
		this.cache = {};
	}

	private isExpiredCacheEntry = (entry: CacheEntry<T>) =>
		entry.ts < Date.now() - this.cacheDuration;

	public getFromCache = (key: string) => {
		const cached = this.cache[key];

		if (!cached || this.isExpiredCacheEntry(cached)) {
			return undefined;
		}

		return cached.data;
	};

	public setCache = (key: string, data: T) => {
		this.cleanCache();
		this.cache[key] = { data, ts: Date.now() };
		return data;
	};

	public cleanCache = () => {
		Object.entries(this.cache).forEach(([key, entry]) => {
			if (this.isExpiredCacheEntry(entry)) {
				delete this.cache[key];
			}
		});
	};

	public useCache = async (fn: () => Promise<T>, cacheKey: string) => {
		const cached = this.getFromCache(cacheKey);

		if (cached) return cached;

		const result = await fn();

		return this.setCache(cacheKey, result);
	};
}
