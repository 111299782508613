import {
	ApolloClient,
	ApolloProvider,
	InMemoryCache,
	type NormalizedCacheObject,
} from "@apollo/client";
import { ToastNotifications } from "@moment/design-system/Toast";
import { SpeedInsights } from "@vercel/speed-insights/next";
import NextApp, { type AppContext, type AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useCallback, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Workbox } from "workbox-window";

import "@fontsource/ibm-plex-mono/latin.css";
import "@fontsource/ibm-plex-sans/latin.css";
import "@fontsource/ibm-plex-serif/latin.css";
import "@moment/design-system/main.css";
import "@xterm/xterm/css/xterm.css";

import { type ClientActionsProps } from "~/ClientActions";
import { Providers } from "~/Providers";
import { isServiceWorkerDisabled } from "~/auth/interop/common";
import { useLogger } from "~/components/canvas/hooks/useLogger";
import { LoadingScreen } from "~/components/common/loading/LoadingScreen";
import { NetworkNotification } from "~/components/common/network-status/NetworkNotification";
import { FatalAppError } from "~/components/errors/FatalAppError";
import { useOutdatedAppModal } from "~/components/outdated-app/OutdatedAppModalContext";
import { Head } from "~/components/seo/Head";
import { Intercom } from "~/intercom/intercom";
import { selectAuthState } from "~/store/auth/selectors";
import { AuthStates } from "~/store/auth/types";
import { selectEnv } from "~/store/client/selectors";
import { createClient } from "~/store/client/thunks";
import { selectIsOutdatedAppVersion } from "~/store/desktop-app/selectors";
import { setIsOutdatedAppVersion, setIsUpdateReady } from "~/store/desktop-app/slice";
import { useAppDispatch, useAppSelector } from "~/store/hooks";

export type MomentAppProps = AppProps & {
	cookies?: string;
};

const ClientActions = dynamic<ClientActionsProps>(
	() => import("~/ClientActions").then((m) => m.ClientActions),
	// turn off server side rendering for this code
	{ ssr: false }
);

const SERVICE_WORKER_REGISTRATION_SCOPE = "/";
const coreCanvases = [
	"@moment/stdlib.js",
	"@moment/aws.js",
	"@moment/github.js",
	"@moment/mermaid.js",
];
let wb: Workbox;

function registerServiceWorker() {
	if (!("serviceWorker" in navigator)) {
		console.log("navigator.serviceWorker is not available. Registration will be skipped.");
		return;
	}

	if (isServiceWorkerDisabled()) {
		console.log("Service worker registration is disabled. Will remove existing registration.");
		navigator.serviceWorker
			.getRegistration(SERVICE_WORKER_REGISTRATION_SCOPE)
			.then((reg) => reg?.unregister())
			.catch((err) =>
				console.error("Error removing service worker registration for scope /", err)
			);
		return;
	}

	if (wb) {
		return;
	}

	// The second param is the options object that is the same
	// as the one supported by navigator.serviceWorker.register().
	// https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#options
	wb = new Workbox("/sw.min.js", { scope: SERVICE_WORKER_REGISTRATION_SCOPE });

	wb.register()
		.then((_r) => {
			// Since we are not pre-caching stuff and only leveraging
			// runtime caching in our service worker, it is fine to
			// tell the service worker to skip waiting and activate
			// right away.
			//
			// If we didn't do this, the guideline for an update to
			// the service worker be activated and running depends
			// on navigation events.
			//
			// See https://developer.chrome.com/docs/workbox/handling-service-worker-updates/
			wb.messageSkipWaiting();
		})
		.catch((err) => console.error("Error registering the service worker", err));
}

const App = (props: MomentAppProps) => {
	const { pageProps } = props;

	useEffect(registerServiceWorker, []);

	return (
		<>
			<Head
				// pageProps comes in as "any" so we need to check for existence
				title={pageProps?.title ?? null}
				description={pageProps?.description ?? null}
				url={pageProps?.url ?? null}
			/>
			<ClientActions />
			<ErrorBoundary FallbackComponent={FatalAppError}>
				<Providers cookies={props.cookies}>
					<Main {...props} />
					<Intercom />
					<SpeedInsights />
				</Providers>
			</ErrorBoundary>
		</>
	);
};

// Use a variable to prevent running
// init routine for desktop app twice.
// https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
let didRunDesktopAppInit = false;

export const Main = ({ Component, pageProps }: AppProps) => {
	const dispatch = useAppDispatch();
	const authState = useAppSelector(selectAuthState);
	const env = useAppSelector(selectEnv);
	const { confirm } = useOutdatedAppModal();
	const isAppOutdatedVersion = useAppSelector(selectIsOutdatedAppVersion);

	useEffect(() => {
		if (!wb) {
			return;
		}

		// Pre-caching core canvases requires the user's auth info,
		// so don't trigger pre-caching if the user is not logged-in
		// yet.
		if (authState !== AuthStates.LoggedIn) {
			return;
		}

		// The docs for `messageSW()` state that the service worker
		// should set a response using `event.ports[0].postMessage(...)`
		// or the promise here won't resolve.
		wb.messageSW({
			type: "PRE_CACHE",
			urls: coreCanvases.map((c) => `/api/${c}?env=${env}`),
		}).catch((err) =>
			console.error("Error sending pre-caching message to the service worker", err)
		);
	}, [authState, env]);

	useEffect(() => {
		if (didRunDesktopAppInit) {
			return;
		}

		didRunDesktopAppInit = true;
		window.desktopIpcApi?.onAppIsTooOld(() =>
			dispatch(setIsOutdatedAppVersion({ isOutdatedAppVersion: true }))
		);
		window.desktopIpcApi?.onAppUpdateIsReady(() =>
			dispatch(setIsUpdateReady({ isAppUpdateReady: true }))
		);
		window.desktopIpcApi?.setWindowIsReady(true);
	}, []);

	const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>(
		undefined
	);
	const logger = useLogger("Main");

	const makeApolloClient = useCallback(async () => {
		// only make an apollo client if we are logged in
		if (authState === AuthStates.LoggedIn) {
			try {
				const client = await dispatch(createClient()).unwrap();
				setClient(client);
			} catch (e) {
				logger.error("Error creating client", { error: `${e}` });
			}
		}
	}, [env, dispatch, authState]);

	useEffect(() => {
		if (!isAppOutdatedVersion) {
			return;
		}

		confirm()
			.then((confirmed) => {
				if (!confirmed) {
					return;
				}

				window.desktopIpcApi?.restartToUpdate();
			})
			.catch((err) =>
				logger.error("Failed to show dialog for min version enforcement", {
					error: `${err}`,
				})
			);
	}, [isAppOutdatedVersion]);

	useEffect(() => {
		void makeApolloClient();
	}, [makeApolloClient]);

	// if we are logged in, wait for apollo client to be created
	// before rendering app
	if (client === undefined && authState === AuthStates.LoggedIn) {
		return (
			<div className="absolute inset-0 size-full">
				<LoadingScreen />
			</div>
		);
	}

	return (
		<ApolloProvider client={client || new ApolloClient({ cache: new InMemoryCache() })}>
			<Component {...pageProps} />
			<ToastNotifications />
			<NetworkNotification />
		</ApolloProvider>
	);
};

App.getInitialProps = async (context: AppContext) => {
	const appProps = NextApp.getInitialProps(context);

	return { ...appProps, cookies: context.ctx.req?.headers.cookie };
};

export default App;
