export const Themes = ["system", "light", "dark", "oscar-mayer"] as const;

export type Theme = (typeof Themes)[number];

export type ResolvedTheme = Exclude<Theme, "system">;

export const THEME_CLASS_MAP: Record<ResolvedTheme, string> = {
	"light": "theme-light",
	"dark": "theme-dark",
	"oscar-mayer": "theme-oscar-mayer",
};
