import { safeParse } from "~/lib/json";
import { type LoggerInterface, noopLogger } from "~/lib/logger-types";
import { type Cell, sanitizeParsedNodeAttrs } from "~/lib/prosemirror-serialization";
import { createCellID } from "~/lib/prosemirror-utils";

export * from "./activeUser";
export * from "./canvasSpecifier";
export * from "./idempotentInitRoom";
export * from "./messageTransactionBroadcast";
export * from "./messageActiveUsersBroadcast";
export * from "./messageSelectionsUpdatedBroadcast";
export * from "./messageSendSelectionRequest";
export * from "./prosemirrorClientId";
export * from "./stepsSince";
export * from "./transaction";
export * from "./logging";
export * from "./pageMetadata";
export * from "./pingpong";
export * from "./presence";
export * from "./persistenceError";
export * from "./auth";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractNodeAttrs = (cell: any, logger: LoggerInterface = noopLogger): Cell["nodeAttrs"] => {
	if (cell.nodeAttrs === null) {
		return {};
	} else if (typeof cell.nodeAttrs === "object") {
		return cell.nodeAttrs;
	}

	try {
		const parsed = safeParse(cell.nodeAttrs, logger);
		if (typeof parsed === "object") {
			return sanitizeParsedNodeAttrs(parsed);
		}
		logger.warn(
			"cell nodeAttrs are escaped or stringified. returning an empty object instead.",
			{
				rawNodeAttrs: cell.nodeAttrs,
			}
		);
		return {};
	} catch (e) {
		logger.error("Failed to parse nodeAttrs", { error: e });
		return {};
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromGQLCell = (cell: any, logger: LoggerInterface = noopLogger): Cell => {
	const nodeAttrs = extractNodeAttrs(cell, logger);
	switch (cell.type) {
		case "JavaScriptFunctionBodyCell":
		case "ProseMirrorJSONCell": {
			return {
				...cell,
				type: cell.type,
				cellName: cell.cellName || "",
				pinned: cell.pinned !== null ? cell.pinned : false,
				id: cell.id || createCellID(),
				inspectorInitiallyExpanded: false,
				nodeAttrs,
			};
		}
		default:
			return {
				...cell,
				type: "JavaScriptFunctionBodyCell",
				code: cell.code,
				cellName: cell.cellName || "",
				pinned: cell.pinned !== null ? cell.pinned : false,
				id: cell.id || createCellID(),
				inspectorInitiallyExpanded: false,
				nodeAttrs,
			};
	}
};
