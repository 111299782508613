import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

// :: NodeSpec An image (`<image>`)

export const image: NodeSpec = {
	attrs: {
		src: {},
		checksum_hash: { default: undefined },
		checksum_version: { default: undefined },
	},
	inline: true,
	group: "inline",
	parseDOM: [
		{
			tag: "img[src]",
			getAttrs(node: string | HTMLElement) {
				if (!(node instanceof HTMLElement)) {
					return false;
				}

				const attrs: Attrs = {
					src: node.getAttribute("src"),
				};

				return attrs;
			},
		},
	],
	toDOM(node: Node): DOMOutputSpec {
		const attrs = node.attrs;
		return ["img", attrs];
	},
};
