import { type UserID } from "~/models/user";

import { type RootState } from "../store";

export const selectUser = (id: UserID) => {
	return (state: RootState) => {
		return state.users.users[id];
	};
};

export const selectUserByEmail = (email: string) => {
	return (state: RootState) => {
		const userID = state.users.usersByEmail[email];
		if (userID !== undefined) {
			return state.users.users[userID];
		}
		return undefined;
	};
};
