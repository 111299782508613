import { type CanvasListingItem as CanvasListingItemGQL } from "~/api/generated/graphql";
import type { CanvasRouteTypes } from "~/utils/helpers/url";

export interface CanvasListingItem {
	id: string;
	ownerID: string;
	organizationID: string;
	createdAt: Date;
	updatedAt: Date;
	title: string;
	version: number;
	pinnedAt?: Date;
	isTemplate?: boolean;
	navigationInfo?: CanvasRouteTypes;
}

export const fromGQL = (c: CanvasListingItemGQL): CanvasListingItem => {
	let pinnedAt: Date | undefined = undefined;

	if (c.pinnedAt) {
		pinnedAt = new Date(c.pinnedAt);
	}

	return {
		id: c.id,
		ownerID: c.ownerID,
		organizationID: c.organizationID,
		title: c.title,
		version: c.version,

		createdAt: new Date(c.createdAt),
		updatedAt: new Date(c.updatedAt),
		pinnedAt,
		isTemplate: false,
	};
};
