import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { makeTextCellDefaultToDOMAttrs } from "~/lib/prosemirror-utils/makeTextCellDefaultToDOMAttrs";

export const list_item: NodeSpec = {
	defining: true,
	content: "(paragraph | bullet_list | ordered_list)+",
	attrs: {
		indent: { default: 0 },
	},
	parseDOM: [
		{
			tag: "li",
			getAttrs: (p: string | HTMLElement) => {
				if (!(p instanceof HTMLElement)) {
					return false;
				}

				const attrs: Attrs = {
					indent: Number(p.getAttribute("data-indent") || 0),
				};

				return attrs;
			},
		},
	],
	toDOM(node: Node): DOMOutputSpec {
		const attrs: Attrs = makeTextCellDefaultToDOMAttrs(node);
		return ["li", { ...attrs, "data-indent": node.attrs["indent"] }, 0];
	},
};
