import cx from "classnames";
import { type Attrs, type Node } from "prosemirror-model";

import { DEFAULT_CELL_ID } from "./constants";

/**
 *
 * @param node prosemirror node
 * @param additionalClassNames additional class names to add to the node
 * @returns DOM attributes for the text cell node
 */
export const makeTextCellDefaultToDOMAttrs = (node: Node, additionalClassNames?: string) => {
	const classNames = cx(
		additionalClassNames
		// FIXME
		// {
		// 	"pointer-events-auto": isEnabled(Flags.IframeView),
		// }
	);
	const attrs: Attrs = {
		text_cell: node.attrs["id"] !== DEFAULT_CELL_ID ? node.attrs["id"] : undefined,
		id: node.attrs["id"] !== DEFAULT_CELL_ID ? node.attrs["id"] : undefined,
		class: classNames,
	};

	return attrs;
};
