import { useRouter } from "next/router";
import { type FC, useEffect } from "react";

import { TrackEvents, analytics } from "~/utils/analytics";

export const TrackPageView: FC = () => {
	const router = useRouter();
	const { pathname } = router;

	useEffect(() => {
		if (typeof window !== "undefined") {
			const { href, pathname, hostname, search } = window.location;
			// Track page views
			analytics.track(TrackEvents.PAGE_VIEW, { href, pathname, hostname, search });
		}
	}, [pathname]);

	return null;
};
