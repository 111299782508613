export interface SEOConfig {
	baseTitle: string;
	description: string;
	themeColor: string;
	url?: string;
}

export const seoConfig: SEOConfig = {
	baseTitle: "Moment",
	description:
		"Docs that are Apps. Centralize infrastructure expertise. Charts and dashboards with live data. Buttons and widgets that do things. Write docs that are powerful internal tools and get everyone on the same page, quickly.",
	themeColor: `#04032C`,
};
