import {
	type FC,
	type PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

import { Flags, isEnabled } from "~/features";
import { ConfigKeys, getDefaultConfig, onFeatureFlags } from "~/utils/config";

export interface ConfigContextType {
	isCollabEditingEnabled: boolean;
}

const isCollabEditingDefault = getDefaultConfig(ConfigKeys.COLLAB_EDITING);

const ConfigContext = createContext<ConfigContextType>({
	isCollabEditingEnabled: isCollabEditingDefault,
});

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider: FC<PropsWithChildren> = ({ children }) => {
	const isForceDisabled = isEnabled(Flags.ForceDisableCollabEditing);
	const [isCollabEditingEnabled, setIsCollabEditingEnabled] = useState(
		isForceDisabled ? false : isCollabEditingDefault
	);

	useEffect(() => {
		if (isForceDisabled) {
			return;
		}

		onFeatureFlags(ConfigKeys.COLLAB_EDITING, {}, () => {
			// Only set value if it is different
			setIsCollabEditingEnabled((p) => (p === true ? p : true));
		});
	}, []);

	const value = useMemo(() => ({ isCollabEditingEnabled }), [isCollabEditingEnabled]);

	return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
