/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-env node */
const path = require("path");
const colors = require("tailwindcss/colors");

const BASE_DIR = path.resolve(__dirname, "..");

/** @type {import('tailwindcss').Config} */
module.exports = {
	// TODO: figure out a way to safelist all classes in dev. This currently runs out of memory
	// safelist: [
	// 	{
	// 		pattern: process.env["NODE_ENV"] === "development" ? /./ : undefined,
	// 	},
	// ],
	content: [
		/*
            Content Configuration
            --icon-
            Tailwind CSS works by scanning all of your HTML, JavaScript components, and any other
            template files for class names, then generating all of the corresponding CSS for those
            styles.

            In order for Tailwind to generate all of the CSS you need, it needs to know about every
            single file in your project that contains any Tailwind class names.
            https://tailwindcss.com/docs/content-configuration
        */
		// this is turned off for now, but we will want to enable this
		// when we separate canvas styles into their own css file
		`${BASE_DIR}/design-system/lib/**/*.{ts,tsx,js,jsx}`,
		`${BASE_DIR}/design-system/lib-canvas/**/*.{ts,tsx,js,jsx}`,
		`${BASE_DIR}/design-system/plugins/**/*.{ts,tsx,js,jsx}`,
		`${BASE_DIR}/app/src/**/*.{ts,tsx,js,jsx}`,
		`${BASE_DIR}/api-collab/lib/**/*.{ts,tsx,js,jsx}`,
	],
	safelist: [
		"border-rainbow-light-0-solid",
		"border-rainbow-light-1-solid",
		"border-rainbow-light-2-solid",
		"border-rainbow-light-3-solid",
		"border-rainbow-light-4-solid",
		"border-rainbow-light-5-solid",
		"border-rainbow-light-6-solid",
		"border-rainbow-light-7-solid",
		"border-rainbow-light-8-solid",
		"border-rainbow-light-9-solid",
		"border-rainbow-light-10-solid",
		"border-rainbow-light-11-solid",
		"border-rainbow-light-12-solid",
		"border-rainbow-light-13-solid",
		"border-rainbow-light-14-solid",
		"border-rainbow-light-15-solid",
		"border-rainbow-light-16-solid",
		"-mb-6",
	],
	theme: {
		colors: {
			// Gray is mapped to tw slate on purpose
			"gray": colors.slate,
			"red": colors.red,
			"orange": colors.orange,
			"amber": colors.amber,
			"yellow": colors.yellow,
			"lime": colors.lime,
			"green": colors.green,
			"emerald": colors.emerald,
			"teal": colors.teal,
			"cyan": colors.cyan,
			"sky": colors.sky,
			"blue": colors.blue,
			"indigo": colors.indigo,
			"violet": colors.violet,
			"purple": colors.purple,
			"fuchsia": colors.fuchsia,
			"pink": colors.pink,
			"rose": colors.rose,
			"black": colors.black,
			"white": colors.white,

			"transparent": "transparent",

			"syntax-normal": "var(--text-syntax-normal)",
			"syntax-comment": "var(--text-syntax-comment)",
			"syntax-number": "var(--text-syntax-number)",
			"syntax-keyword": "var(--text-syntax-keyword)",
			"syntax-keyword-hover": "var(--text-syntax-keyword-hover)",
			"syntax-atom": "var(--text-syntax-atom)",
			"syntax-string": "var(--text-syntax-string)",
			"syntax-error": "var(--text-syntax-error)",
			"syntax-unknown-variable": "var(--text-syntax-unknownVariable)",
			"syntax-known-variable": "var(--text-syntax-knownVariable)",
			"syntax-match-bracket": "var(--text-syntax-matchbracket)",
			"syntax-key": "var(--text-syntax-key)",
			"syntax-forbidden": "var(--text-syntax-forbidden)",
			"syntax-prototype-key": "var(--text-syntax-prototypeKey)",

			"negative": "var(--state-negative)",
			"negative-hover": "var(--state-negative-hover)",
			"negative-focus": "var(--state-negative-focus)",
			"negative-active": "var(--state-negative-active)",
			"negative-disabled": "var(--state-negative-disabled)",

			"negative-mid": "var(--state-negative-mid)",

			"negative-light": "var(--state-negative-light)",
			"negative-light-hover": "var(--state-negative-light-hover)",
			"negative-light-focus": "var(--state-negative-light-focus)",
			"negative-light-active": "var(--state-negative-light-active)",
			"negative-light-disabled": "var(--state-negative-light-disabled)",

			"positive": "var(--state-positive)",
			"positive-hover": "var(--state-positive-hover)",
			"positive-focus": "var(--state-positive-focus)",
			"positive-active": "var(--state-positive-active)",
			"positive-disabled": "var(--state-positive-disabled)",

			"positive-mid": "var(--state-positive-mid)",

			"positive-light": "var(--state-positive-light)",
			"positive-light-hover": "var(--state-positive-light-hover)",
			"positive-light-focus": "var(--state-positive-light-focus)",
			"positive-light-active": "var(--state-positive-light-active)",
			"positive-light-disabled": "var(--state-positive-light-disabled)",

			"warning": "var(--state-warning)",
			"warning-hover": "var(--state-warning-hover)",
			"warning-focus": "var(--state-warning-focus)",
			"warning-active": "var(--state-warning-active)",
			"warning-disabled": "var(--state-warning-disabled)",

			"warning-mid": "var(--state-warning-mid)",

			"warning-light": "var(--state-warning-light)",
			"warning-light-hover": "var(--state-warning-light-hover)",
			"warning-light-focus": "var(--state-warning-light-focus)",
			"warning-light-active": "var(--state-warning-light-active)",
			"warning-light-disabled": "var(--state-warning-light-disabled)",

			"info": "var(--state-info)",
			"info-hover": "var(--state-info-hover)",
			"info-focus": "var(--state-info-focus)",
			"info-active": "var(--state-info-active)",
			"info-disabled": "var(--state-info-disabled)",

			"info-mid": "var(--state-info-mid)",

			"info-light": "var(--state-info-light)",
			"info-light-hover": "var(--state-info-light-hover)",
			"info-light-focus": "var(--state-info-light-focus)",
			"info-light-active": "var(--state-info-light-active)",
			"info-light-disabled": "var(--state-info-light-disabled)",

			"brand": "var(--brand-primary)",
			"brand-hover": "var(--brand-primary-hover)",
			"brand-focus": "var(--brand-primary-focus)",
			"brand-active": "var(--brand-primary-active)",
			"brand-light": "var(--brand-primary-light)",
			"brand-disabled": "var(--brand-primary-light)",
			"brand-foreground": "var(--text-inverse-primary)",

			"brand-translucent": "var(--brand-primary-translucent)",
			"brand-translucent-hover": "var(--brand-primary-translucent-hover)",
			"brand-translucent-focus": "var(--brand-primary-translucent-focus)",
			"brand-translucent-active": "var(--brand-primary-translucent-active)",
			"brand-translucent-disabled": "var(--brand-primary-translucent-disabled)",

			"brand-translucent-dark": "var(--brand-primary-translucent-dark)",
			"brand-translucent-dark-hover": "var(--brand-primary-translucent-dark-hover)",
			"brand-translucent-dark-focus": "var(--brand-primary-translucent-dark-focus)",
			"brand-translucent-dark-active": "var(--brand-primary-translucent-dark-active)",
			"brand-translucent-dark-disabled": "var(--brand-primary-translucent-dark-disabled)",

			"light-spectrum-gray": "var(--light-spectrum-gray)",
			"light-spectrum-gray-hover": "var(--light-spectrum-gray-hover)",
			"light-spectrum-gray-focus": "var(--light-spectrum-gray-focus)",
			"light-spectrum-gray-active": "var(--light-spectrum-gray-active)",
			"light-spectrum-red": "var(--light-spectrum-red)",
			"light-spectrum-red-hover": "var(--light-spectrum-red-hover)",
			"light-spectrum-red-focus": "var(--light-spectrum-red-focus)",
			"light-spectrum-red-active": "var(--light-spectrum-red-active)",
			"light-spectrum-yellow": "var(--light-spectrum-yellow)",
			"light-spectrum-yellow-hover": "var(--light-spectrum-yellow-hover)",
			"light-spectrum-yellow-focus": "var(--light-spectrum-yellow-focus)",
			"light-spectrum-yellow-active": "var(--light-spectrum-yellow-active)",
			"light-spectrum-green": "var(--light-spectrum-green)",
			"light-spectrum-green-hover": "var(--light-spectrum-green-hover)",
			"light-spectrum-green-focus": "var(--light-spectrum-green-focus)",
			"light-spectrum-green-active": "var(--light-spectrum-green-active)",
			"light-spectrum-cyan": "var(--light-spectrum-cyan)",
			"light-spectrum-cyan-hover": "var(--light-spectrum-cyan-hover)",
			"light-spectrum-cyan-focus": "var(--light-spectrum-cyan-focus)",
			"light-spectrum-cyan-active": "var(--light-spectrum-cyan-active)",
			"light-spectrum-blue": "var(--light-spectrum-blue)",
			"light-spectrum-blue-hover": "var(--light-spectrum-blue-hover)",
			"light-spectrum-blue-focus": "var(--light-spectrum-blue-focus)",
			"light-spectrum-blue-active": "var(--light-spectrum-blue-active)",
			"light-spectrum-purple": "var(--light-spectrum-purple)",
			"light-spectrum-purple-hover": "var(--light-spectrum-purple-hover)",
			"light-spectrum-purple-focus": "var(--light-spectrum-purple-focus)",
			"light-spectrum-purple-active": "var(--light-spectrum-purple-active)",
			"light-spectrum-pink": "var(--light-spectrum-pink)",
			"light-spectrum-pink-hover": "var(--light-spectrum-pink-hover)",
			"light-spectrum-pink-focus": "var(--light-spectrum-pink-focus)",
			"light-spectrum-pink-active": "var(--light-spectrum-pink-active)",

			"dark-spectrum-gray": "var(--dark-spectrum-gray)",
			"dark-spectrum-gray-hover": "var(--dark-spectrum-gray-hover)",
			"dark-spectrum-gray-focus": "var(--dark-spectrum-gray-focus)",
			"dark-spectrum-gray-active": "var(--dark-spectrum-gray-active)",
			"dark-spectrum-red": "var(--dark-spectrum-red)",
			"dark-spectrum-red-hover": "var(--dark-spectrum-red-hover)",
			"dark-spectrum-red-focus": "var(--dark-spectrum-red-focus)",
			"dark-spectrum-red-active": "var(--dark-spectrum-red-active)",
			"dark-spectrum-yellow": "var(--dark-spectrum-yellow)",
			"dark-spectrum-yellow-hover": "var(--dark-spectrum-yellow-hover)",
			"dark-spectrum-yellow-focus": "var(--dark-spectrum-yellow-focus)",
			"dark-spectrum-yellow-active": "var(--dark-spectrum-yellow-active)",
			"dark-spectrum-green": "var(--dark-spectrum-green)",
			"dark-spectrum-green-hover": "var(--dark-spectrum-green-hover)",
			"dark-spectrum-green-focus": "var(--dark-spectrum-green-focus)",
			"dark-spectrum-green-active": "var(--dark-spectrum-green-active)",
			"dark-spectrum-cyan": "var(--dark-spectrum-cyan)",
			"dark-spectrum-cyan-hover": "var(--dark-spectrum-cyan-hover)",
			"dark-spectrum-cyan-focus": "var(--dark-spectrum-cyan-focus)",
			"dark-spectrum-cyan-active": "var(--dark-spectrum-cyan-active)",
			"dark-spectrum-blue": "var(--dark-spectrum-blue)",
			"dark-spectrum-blue-hover": "var(--dark-spectrum-blue-hover)",
			"dark-spectrum-blue-focus": "var(--dark-spectrum-blue-focus)",
			"dark-spectrum-blue-active": "var(--dark-spectrum-blue-active)",
			"dark-spectrum-purple": "var(--dark-spectrum-purple)",
			"dark-spectrum-purple-hover": "var(--dark-spectrum-purple-hover)",
			"dark-spectrum-purple-focus": "var(--dark-spectrum-purple-focus)",
			"dark-spectrum-purple-active": "var(--dark-spectrum-purple-active)",
			"dark-spectrum-pink": "var(--dark-spectrum-pink)",
			"dark-spectrum-pink-hover": "var(--dark-spectrum-pink-hover)",
			"dark-spectrum-pink-focus": "var(--dark-spectrum-pink-focus)",
			"dark-spectrum-pink-active": "var(--dark-spectrum-pink-active)",

			"rainbow-light-0-solid": "var(--rainbow-light-0-solid)",
			"rainbow-light-1-solid": "var(--rainbow-light-1-solid)",
			"rainbow-light-2-solid": "var(--rainbow-light-2-solid)",
			"rainbow-light-3-solid": "var(--rainbow-light-3-solid)",
			"rainbow-light-4-solid": "var(--rainbow-light-4-solid)",
			"rainbow-light-5-solid": "var(--rainbow-light-5-solid)",
			"rainbow-light-6-solid": "var(--rainbow-light-6-solid)",
			"rainbow-light-7-solid": "var(--rainbow-light-7-solid)",
			"rainbow-light-8-solid": "var(--rainbow-light-8-solid)",
			"rainbow-light-9-solid": "var(--rainbow-light-9-solid)",
			"rainbow-light-10-solid": "var(--rainbow-light-10-solid)",
			"rainbow-light-11-solid": "var(--rainbow-light-11-solid)",
			"rainbow-light-12-solid": "var(--rainbow-light-12-solid)",
			"rainbow-light-13-solid": "var(--rainbow-light-13-solid)",
			"rainbow-light-14-solid": "var(--rainbow-light-14-solid)",
			"rainbow-light-15-solid": "var(--rainbow-light-15-solid)",
			"rainbow-light-16-solid": "var(--rainbow-light-16-solid)",

			"rainbow-dark-0-solid": "var(--rainbow-dark-0-solid)",
			"rainbow-dark-1-solid": "var(--rainbow-dark-1-solid)",
			"rainbow-dark-2-solid": "var(--rainbow-dark-2-solid)",
			"rainbow-dark-3-solid": "var(--rainbow-dark-3-solid)",
			"rainbow-dark-4-solid": "var(--rainbow-dark-4-solid)",
			"rainbow-dark-5-solid": "var(--rainbow-dark-5-solid)",
			"rainbow-dark-6-solid": "var(--rainbow-dark-6-solid)",
			"rainbow-dark-7-solid": "var(--rainbow-dark-7-solid)",
			"rainbow-dark-8-solid": "var(--rainbow-dark-8-solid)",
			"rainbow-dark-9-solid": "var(--rainbow-dark-9-solid)",
			"rainbow-dark-10-solid": "var(--rainbow-dark-10-solid)",
			"rainbow-dark-11-solid": "var(--rainbow-dark-11-solid)",
			"rainbow-dark-12-solid": "var(--rainbow-dark-12-solid)",
			"rainbow-dark-13-solid": "var(--rainbow-dark-13-solid)",
			"rainbow-dark-14-solid": "var(--rainbow-dark-14-solid)",
			"rainbow-dark-15-solid": "var(--rainbow-dark-15-solid)",
			"rainbow-dark-16-solid": "var(--rainbow-dark-16-solid)",

			"rainbow-lighter-0-solid": "var(--rainbow-lighter-0-solid)",
			"rainbow-lighter-1-solid": "var(--rainbow-lighter-1-solid)",
			"rainbow-lighter-2-solid": "var(--rainbow-lighter-2-solid)",
			"rainbow-lighter-3-solid": "var(--rainbow-lighter-3-solid)",
			"rainbow-lighter-4-solid": "var(--rainbow-lighter-4-solid)",
			"rainbow-lighter-5-solid": "var(--rainbow-lighter-5-solid)",
			"rainbow-lighter-6-solid": "var(--rainbow-lighter-6-solid)",
			"rainbow-lighter-7-solid": "var(--rainbow-lighter-7-solid)",
			"rainbow-lighter-8-solid": "var(--rainbow-lighter-8-solid)",
			"rainbow-lighter-9-solid": "var(--rainbow-lighter-9-solid)",
			"rainbow-lighter-10-solid": "var(--rainbow-lighter-10-solid)",
			"rainbow-lighter-11-solid": "var(--rainbow-lighter-11-solid)",
			"rainbow-lighter-12-solid": "var(--rainbow-lighter-12-solid)",
			"rainbow-lighter-13-solid": "var(--rainbow-lighter-13-solid)",
			"rainbow-lighter-14-solid": "var(--rainbow-lighter-14-solid)",
			"rainbow-lighter-15-solid": "var(--rainbow-lighter-15-solid)",
			"rainbow-lighter-16-solid": "var(--rainbow-lighter-16-solid)",

			"rainbow-light-0-translucent": "var(--rainbow-light-0-translucent)",
			"rainbow-light-1-translucent": "var(--rainbow-light-1-translucent)",
			"rainbow-light-2-translucent": "var(--rainbow-light-2-translucent)",
			"rainbow-light-3-translucent": "var(--rainbow-light-3-translucent)",
			"rainbow-light-4-translucent": "var(--rainbow-light-4-translucent)",
			"rainbow-light-5-translucent": "var(--rainbow-light-5-translucent)",
			"rainbow-light-6-translucent": "var(--rainbow-light-6-translucent)",
			"rainbow-light-7-translucent": "var(--rainbow-light-7-translucent)",
			"rainbow-light-8-translucent": "var(--rainbow-light-8-translucent)",
			"rainbow-light-9-translucent": "var(--rainbow-light-9-translucent)",
			"rainbow-light-10-translucent": "var(--rainbow-light-10-translucent)",
			"rainbow-light-11-translucent": "var(--rainbow-light-11-translucent)",
			"rainbow-light-12-translucent": "var(--rainbow-light-12-translucent)",
			"rainbow-light-13-translucent": "var(--rainbow-light-13-translucent)",
			"rainbow-light-14-translucent": "var(--rainbow-light-14-translucent)",
			"rainbow-light-15-translucent": "var(--rainbow-light-15-translucent)",
			"rainbow-light-16-translucent": "var(--rainbow-light-16-translucent)",
		},
		spacing: {
			px: "1px",
			0: "0px",
			0.5: "0.125rem",
			1: "0.25rem",
			1.5: "0.375rem",
			2: "0.5rem",
			2.5: "0.625rem",
			3: "0.75rem",
			3.5: "0.875rem",
			4: "1rem",
			5: "1.25rem",
			6: "1.5rem",
			7: "1.75rem",
			8: "2rem",
			9: "2.25rem",
			10: "2.5rem",
			11: "2.75rem",
			12: "3rem",
			14: "3.5rem",
			16: "4rem",
			20: "5rem",
			24: "6rem",
			28: "7rem",
			32: "8rem",
			36: "9rem",
			40: "10rem",
			44: "11rem",
			48: "12rem",
			52: "13rem",
			56: "14rem",
			60: "15rem",
			64: "16rem",
			72: "18rem",
			80: "20rem",
			96: "24rem",
		},
		cursor: {
			"auto": "auto",
			"default": "default",
			"pointer": "pointer",
			"wait": "wait",
			"text": "text",
			"move": "move",
			"help": "help",
			"not-allowed": "not-allowed",
			"grab": "grab",
			"grabbing": "grabbing",
			"nesw-resize": "nesw-resize",
			"nwse-resize": "nwse-resize",
		},
		backgroundColor: ({ theme }) => ({
			...theme("colors"),

			"flash": "var(--bg-flash)",

			"primary-raised": "var(--bg-primary-raised)",
			"primary": "var(--bg-primary)",
			"secondary": "var(--bg-secondary)",
			"tertiary": "var(--bg-tertiary)",
			"quaternary": "var(--bg-quaternary)",
			"quinary": "var(--bg-quinary)",

			"inverse-primary": "var(--bg-inverse-primary)",
			"inverse-secondary": "var(--bg-inverse-secondary)",
			"inverse-tertiary": "var(--bg-inverse-tertiary)",
			"inverse-quaternary": "var(--bg-inverse-quaternary)",
			"inverse-quinary": "var(--bg-inverse-quinary)",

			"translucent-primary": "var(--bg-translucent-primary)",
			"translucent-secondary": "var(--bg-translucent-secondary)",
			"translucent-tertiary": "var(--bg-translucent-tertiary)",
			"translucent-quaternary": "var(--bg-translucent-quaternary)",
			"translucent-quinary": "var(--bg-translucent-quinary)",

			"inverse-translucent-primary": "var(--bg-inverse-translucent-primary)",
			"inverse-translucent-secondary": "var(--bg-inverse-translucent-secondary)",
			"inverse-translucent-tertiary": "var(--bg-inverse-translucent-tertiary)",
			"inverse-translucent-quaternary": "var(--bg-inverse-translucent-quaternary)",
			"inverse-translucent-quinary": "var(--bg-inverse-translucent-quinary)",

			/*
			 * COMMENTS
			 */
			"comment-normal": "var(--state-warning-light-hover)",
			"comment-active": "var(--state-warning-light-active)",

			/*
			 * MODAL
			 */
			"modal": "var(--bg-modal)",

			/*
			 * BUTTONS
			 */
			// Normal
			"button-normal-primary-default": "var(--button-normal-primary-default-bg)",
			"button-normal-primary-hover": "var(--button-normal-primary-hover-bg)",
			"button-normal-primary-focus": "var(--button-normal-primary-focus-bg)",
			"button-normal-primary-active": "var(--button-normal-primary-active-bg)",
			"button-normal-primary-disabled": "var(--button-normal-primary-disabled-bg)",

			"button-normal-secondary-default": "var(--button-normal-secondary-default-bg)",
			"button-normal-secondary-hover": "var(--button-normal-secondary-hover-bg)",
			"button-normal-secondary-focus": "var(--button-normal-secondary-focus-bg)",
			"button-normal-secondary-active": "var(--button-normal-secondary-active-bg)",
			"button-normal-secondary-disabled": "var(--button-normal-secondary-disabled-bg)",

			"button-normal-tertiary-default": "var(--button-normal-tertiary-default-bg)",
			"button-normal-tertiary-hover": "var(--button-normal-tertiary-hover-bg)",
			"button-normal-tertiary-focus": "var(--button-normal-tertiary-focus-bg)",
			"button-normal-tertiary-active": "var(--button-normal-tertiary-active-bg)",
			"button-normal-tertiary-disabled": "var(--button-normal-tertiary-disabled-bg)",

			"button-normal-quaternary-default": "var(--button-normal-quaternary-default-bg)",
			"button-normal-quaternary-hover": "var(--button-normal-quaternary-hover-bg)",
			"button-normal-quaternary-focus": "var(--button-normal-quaternary-focus-bg)",
			"button-normal-quaternary-active": "var(--button-normal-quaternary-active-bg)",
			"button-normal-quaternary-disabled": "var(--button-normal-quaternary-disabled-bg)",

			// Negative
			"button-negative-primary-default": "var(--button-negative-primary-default-bg)",
			"button-negative-primary-hover": "var(--button-negative-primary-hover-bg)",
			"button-negative-primary-focus": "var(--button-negative-primary-focus-bg)",
			"button-negative-primary-active": "var(--button-negative-primary-active-bg)",
			"button-negative-primary-disabled": "var(--button-negative-primary-disabled-bg)",

			"button-negative-secondary-default": "var(--button-negative-secondary-default-bg)",
			"button-negative-secondary-hover": "var(--button-negative-secondary-hover-bg)",
			"button-negative-secondary-focus": "var(--button-negative-secondary-focus-bg)",
			"button-negative-secondary-active": "var(--button-negative-secondary-active-bg)",
			"button-negative-secondary-disabled": "var(--button-negative-secondary-disabled-bg)",

			"button-negative-tertiary-default": "var(--button-negative-tertiary-default-bg)",
			"button-negative-tertiary-hover": "var(--button-negative-tertiary-hover-bg)",
			"button-negative-tertiary-focus": "var(--button-negative-tertiary-focus-bg)",
			"button-negative-tertiary-active": "var(--button-negative-tertiary-active-bg)",
			"button-negative-tertiary-disabled": "var(--button-negative-tertiary-disabled-bg)",

			"button-negative-quaternary-default": "var(--button-negative-quaternary-default-bg)",
			"button-negative-quaternary-hover": "var(--button-negative-quaternary-hover-bg)",
			"button-negative-quaternary-focus": "var(--button-negative-quaternary-focus-bg)",
			"button-negative-quaternary-active": "var(--button-negative-quaternary-active-bg)",
			"button-negative-quaternary-disabled": "var(--button-negative-quaternary-disabled-bg)",

			// Positive
			"button-positive-primary-default": "var(--button-positive-primary-default-bg)",
			"button-positive-primary-hover": "var(--button-positive-primary-hover-bg)",
			"button-positive-primary-focus": "var(--button-positive-primary-focus-bg)",
			"button-positive-primary-active": "var(--button-positive-primary-active-bg)",
			"button-positive-primary-disabled": "var(--button-positive-primary-disabled-bg)",

			"button-positive-secondary-default": "var(--button-positive-secondary-default-bg)",
			"button-positive-secondary-hover": "var(--button-positive-secondary-hover-bg)",
			"button-positive-secondary-focus": "var(--button-positive-secondary-focus-bg)",
			"button-positive-secondary-active": "var(--button-positive-secondary-active-bg)",
			"button-positive-secondary-disabled": "var(--button-positive-secondary-disabled-bg)",

			"button-positive-tertiary-default": "var(--button-positive-tertiary-default-bg)",
			"button-positive-tertiary-hover": "var(--button-positive-tertiary-hover-bg)",
			"button-positive-tertiary-focus": "var(--button-positive-tertiary-focus-bg)",
			"button-positive-tertiary-active": "var(--button-positive-tertiary-active-bg)",
			"button-positive-tertiary-disabled": "var(--button-positive-tertiary-disabled-bg)",

			"button-positive-quaternary-default": "var(--button-positive-quaternary-default-bg)",
			"button-positive-quaternary-hover": "var(--button-positive-quaternary-hover-bg)",
			"button-positive-quaternary-focus": "var(--button-positive-quaternary-focus-bg)",
			"button-positive-quaternary-active": "var(--button-positive-quaternary-active-bg)",
			"button-positive-quaternary-disabled": "var(--button-positive-quaternary-disabled-bg)",

			// Warning
			"button-warning-primary-default": "var(--button-warning-primary-default-bg)",
			"button-warning-primary-hover": "var(--button-warning-primary-hover-bg)",
			"button-warning-primary-focus": "var(--button-warning-primary-focus-bg)",
			"button-warning-primary-active": "var(--button-warning-primary-active-bg)",
			"button-warning-primary-disabled": "var(--button-warning-primary-disabled-bg)",

			"button-warning-secondary-default": "var(--button-warning-secondary-default-bg)",
			"button-warning-secondary-hover": "var(--button-warning-secondary-hover-bg)",
			"button-warning-secondary-focus": "var(--button-warning-secondary-focus-bg)",
			"button-warning-secondary-active": "var(--button-warning-secondary-active-bg)",
			"button-warning-secondary-disabled": "var(--button-warning-secondary-disabled-bg)",

			"button-warning-tertiary-default": "var(--button-warning-tertiary-default-bg)",
			"button-warning-tertiary-hover": "var(--button-warning-tertiary-hover-bg)",
			"button-warning-tertiary-focus": "var(--button-warning-tertiary-focus-bg)",
			"button-warning-tertiary-active": "var(--button-warning-tertiary-active-bg)",
			"button-warning-tertiary-disabled": "var(--button-warning-tertiary-disabled-bg)",

			"button-warning-quaternary-default": "var(--button-warning-quaternary-default-bg)",
			"button-warning-quaternary-hover": "var(--button-warning-quaternary-hover-bg)",
			"button-warning-quaternary-focus": "var(--button-warning-quaternary-focus-bg)",
			"button-warning-quaternary-active": "var(--button-warning-quaternary-active-bg)",
			"button-warning-quaternary-disabled": "var(--button-warning-quaternary-disabled-bg)",
		}),
		borderColor: ({ theme }) => ({
			...theme("colors"),
			"DEFAULT": "var(--border-primary)",

			"primary": "var(--border-interactive-normal-default)",
			"primary-hover": "var(--border-interactive-normal-hover)",
			"primary-focus": "var(--border-interactive-normal-focus)",
			"primary-active": "var(--border-interactive-normal-active)",
			"primary-disabled": "var(--border-interactive-normal-disabled)",

			"primary-solid": "var(--border-primary-solid)",
			"primary-solid-hover": "var(--border-primary-solid-hover)",
			"primary-solid-focus": "var(--border-primary-solid-focus)",
			"primary-solid-active": "var(--border-primary-solid-active)",
			"primary-solid-disabled": "var(--border-primary-solid-disabled)",

			"inverse-primary": "var(--border-inverse-primary)",
			"inverse-primary-hover": "var(--border-inverse-primary-hover)",
			"inverse-primary-focus": "var(--border-inverse-primary-focus)",
			"inverse-primary-active": "var(--border-inverse-primary-active)",
			"inverse-primary-disabled": "var(--border-inverse-primary-disabled)",

			"inverse-primary-solid": "var(--border-inverse-primary-solid)",
			"inverse-primary-solid-hover": "var(--border-inverse-primary-solid-hover)",
			"inverse-primary-solid-focus": "var(--border-inverse-primary-solid-focus)",
			"inverse-primary-solid-active": "var(--border-inverse-primary-solid-active)",
			"inverse-primary-solid-disabled": "var(--border-inverse-primary-solid-disabled)",

			"brand": "var(--brand-primary)",

			"negative": "var(--state-negative-hover)",
			"negative-light": "var(--state-negative-light-hover)",

			"positive": "var(--state-positive-hover)",
			"positive-light": "var(--state-positive-light-hover)",

			"warning": "var(--state-warning-hover)",
			"warning-light": "var(--state-warning-light-hover)",

			// These are borders made to look like the bg tokens for things like overlapping avatars that need to blend in
			"bg-primary": "var(--bg-primary)",
			"bg-secondary": "var(--bg-secondary)",
			"bg-tertiary": "var(--bg-tertiary)",
			"bg-quaternary": "var(--bg-quaternary)",
			"bg-quinary": "var(--bg-quinary)",

			"button-secondary-default": "var(--button-secondary-default-border)",
			"button-secondary-hover": "var(--button-secondary-hover-border)",
			"button-secondary-focus": "var(--button-secondary-focus-border)",
			"button-secondary-active": "var(--button-secondary-active-border)",
			"button-secondary-disabled": "var(--button-secondary-disabled-border)",

			"nav-rail-item-hover": "rgba(241,245,249,0.1)",
			"nav-rail-item-toggled": "rgba(241,245,249,0.10)",
		}),
		divideColor: ({ theme }) => ({
			...theme("colors"),
			"DEFAULT": "var(--border-primary)",

			// FIXME: Let's make all the borders the same.
			// FIXME: Add shadow to checkbox border.
			// FIXME: The secondary icon shade is weird in the "open" row expand button.
			"primary": "var(--border-primary)",
			"primary-hover": "var(--border-primary-hover)",
			"primary-focus": "var(--border-primary-focus)",
			"primary-active": "var(--border-primary-active)",
			"primary-disabled": "var(--border-primary-disabled)",

			"inverse-primary": "var(--border-inverse-primary)",
			"inverse-primary-hover": "var(--border-inverse-primary-hover)",
			"inverse-primary-focus": "var(--border-inverse-primary-focus)",
			"inverse-primary-active": "var(--border-inverse-primary-active)",
			"inverse-primary-disabled": "var(--border-inverse-primary-disabled)",
		}),
		textColor: ({ theme }) => ({
			...theme("colors"),

			"link-default": "var(--text-link-default)",
			"link-hover": "var(--text-link-hover)",
			"link-focus": "var(--text-link-focus)",
			"link-active": "var(--text-link-active)",
			"link-disabled": "var(--text-link-disabled)",

			"primary": "var(--text-primary)",
			"primary-hover": "var(--text-primary-hover)",
			"primary-focus": "var(--text-primary-focus)",
			"primary-active": "var(--text-primary-active)",
			"primary-disabled": "var(--text-primary-disabled)",

			"primary-solid": "var(--text-primary-solid)",
			"primary-solid-hover": "var(--text-primary-solid-hover)",
			"primary-solid-focus": "var(--text-primary-solid-focus)",
			"primary-solid-active": "var(--text-primary-solid-active)",
			"primary-solid-disabled": "var(--text-primary-solid-disabled)",

			"secondary": "var(--text-secondary)",
			"secondary-hover": "var(--text-secondary-hover)",
			"secondary-focus": "var(--text-secondary-focus)",
			"secondary-active": "var(--text-secondary-active)",
			"secondary-disabled": "var(--text-secondary-disabled)",

			"secondary-solid": "var(--text-secondary-solid)",
			"secondary-solid-hover": "var(--text-secondary-solid-hover)",
			"secondary-solid-focus": "var(--text-secondary-solid-focus)",
			"secondary-solid-active": "var(--text-secondary-solid-active)",
			"secondary-solid-disabled": "var(--text-secondary-solid-disabled)",

			"tertiary": "var(--text-tertiary)",
			"tertiary-hover": "var(--text-tertiary-hover)",
			"tertiary-focus": "var(--text-tertiary-focus)",
			"tertiary-active": "var(--text-tertiary-active)",
			"tertiary-disabled": "var(--text-tertiary-disabled)",

			"tertiary-solid": "var(--text-tertiary-solid)",
			"tertiary-solid-hover": "var(--text-tertiary-solid-hover)",
			"tertiary-solid-focus": "var(--text-tertiary-solid-focus)",
			"tertiary-solid-active": "var(--text-tertiary-solid-active)",
			"tertiary-solid-disabled": "var(--text-tertiary-solid-disabled)",

			"inverse-primary": "var(--text-inverse-primary)",
			"inverse-primary-hover": "var(--text-inverse-primary-hover)",
			"inverse-primary-focus": "var(--text-inverse-primary-focus)",
			"inverse-primary-active": "var(--text-inverse-primary-active)",
			"inverse-primary-disabled": "var(--text-inverse-primary-disabled)",

			"inverse-primary-solid": "var(--text-inverse-primary-solid)",
			"inverse-primary-solid-hover": "var(--text-inverse-primary-solid-hover)",
			"inverse-primary-solid-focus": "var(--text-inverse-primary-solid-focus)",
			"inverse-primary-solid-active": "var(--text-inverse-primary-solid-active)",
			"inverse-primary-solid-disabled": "var(--text-inverse-primary-solid-disabled)",

			"inverse-secondary": "var(--text-inverse-secondary)",
			"inverse-secondary-hover": "var(--text-inverse-secondary-hover)",
			"inverse-secondary-focus": "var(--text-inverse-secondary-focus)",
			"inverse-secondary-active": "var(--text-inverse-secondary-active)",
			"inverse-secondary-disabled": "var(--text-inverse-secondary-disabled)",

			"inverse-secondary-solid": "var(--text-inverse-secondary-solid)",
			"inverse-secondary-solid-hover": "var(--text-inverse-secondary-solid-hover)",
			"inverse-secondary-solid-focus": "var(--text-inverse-secondary-solid-focus)",
			"inverse-secondary-solid-active": "var(--text-inverse-secondary-solid-active)",
			"inverse-secondary-solid-disabled": "var(--text-inverse-secondary-solid-disabled)",

			"inverse-tertiary": "var(--text-inverse-tertiary)",
			"inverse-tertiary-hover": "var(--text-inverse-tertiary-hover)",
			"inverse-tertiary-focus": "var(--text-inverse-tertiary-focus)",
			"inverse-tertiary-active": "var(--text-inverse-tertiary-active)",
			"inverse-tertiary-disabled": "var(--text-inverse-tertiary-disabled)",

			"inverse-tertiary-solid": "var(--text-inverse-tertiary-solid)",
			"inverse-tertiary-solid-hover": "var(--text-inverse-tertiary-solid-hover)",
			"inverse-tertiary-solid-focus": "var(--text-inverse-tertiary-solid-focus)",
			"inverse-tertiary-solid-active": "var(--text-inverse-tertiary-solid-active)",
			"inverse-tertiary-solid-disabled": "var(--text-inverse-tertiary-solid-disabled)",

			"negative": "var(--text-negative)",
			"negative-hover": "var(--text-negative-hover)",
			"negative-focus": "var(--text-negative-focus)",
			"negative-active": "var(--text-negative-active)",
			"negative-disabled": "var(--text-negative-disabled)",

			"positive": "var(--text-positive)",
			"positive-hover": "var(--text-positive-hover)",
			"positive-focus": "var(--text-positive-focus)",
			"positive-active": "var(--text-positive-active)",
			"positive-disabled": "var(--text-positive-disabled)",

			// Always the same color, regardless of theme
			"fixed-light-default": "var(--text-fixed-light-default)",
			"fixed-light-hover": "var(--text-fixed-light-hover)",
			"fixed-light-focus": "var(--text-fixed-light-focus)",
			"fixed-light-active": "var(--text-fixed-light-active)",
			"fixed-light-disabled": "var(--text-fixed-light-disabled)",

			"fixed-dark-default": "var(--text-fixed-dark-default)",
			"fixed-dark-hover": "var(--text-fixed-dark-hover)",
			"fixed-dark-focus": "var(--text-fixed-dark-focus)",
			"fixed-dark-active": "var(--text-fixed-dark-active)",
			"fixed-dark-disabled": "var(--text-fixed-dark-disabled)",

			// Button

			// Normal
			"button-normal-primary-default": "var(--button-normal-primary-default-text)",
			"button-normal-primary-hover": "var(--button-normal-primary-hover-text)",
			"button-normal-primary-focus": "var(--button-normal-primary-focus-text)",
			"button-normal-primary-active": "var(--button-normal-primary-active-text)",
			"button-normal-primary-disabled": "var(--button-normal-primary-disabled-text)",

			"button-normal-secondary-default": "var(--button-normal-secondary-default-text)",
			"button-normal-secondary-hover": "var(--button-normal-secondary-hover-text)",
			"button-normal-secondary-focus": "var(--button-normal-secondary-focus-text)",
			"button-normal-secondary-active": "var(--button-normal-secondary-active-text)",
			"button-normal-secondary-disabled": "var(--button-normal-secondary-disabled-text)",

			"button-normal-tertiary-default": "var(--button-normal-tertiary-default-text)",
			"button-normal-tertiary-hover": "var(--button-normal-tertiary-hover-text)",
			"button-normal-tertiary-focus": "var(--button-normal-tertiary-focus-text)",
			"button-normal-tertiary-active": "var(--button-normal-tertiary-active-text)",
			"button-normal-tertiary-disabled": "var(--button-normal-tertiary-disabled-text)",

			"button-normal-quaternary-default": "var(--button-normal-quaternary-default-text)",
			"button-normal-quaternary-hover": "var(--button-normal-quaternary-hover-text)",
			"button-normal-quaternary-focus": "var(--button-normal-quaternary-focus-text)",
			"button-normal-quaternary-active": "var(--button-normal-quaternary-active-text)",
			"button-normal-quaternary-disabled": "var(--button-normal-quaternary-disabled-text)",

			// Negative
			"button-negative-primary-default": "var(--button-negative-primary-default-text)",
			"button-negative-primary-hover": "var(--button-negative-primary-hover-text)",
			"button-negative-primary-focus": "var(--button-negative-primary-focus-text)",
			"button-negative-primary-active": "var(--button-negative-primary-active-text)",
			"button-negative-primary-disabled": "var(--button-negative-primary-disabled-text)",

			"button-negative-secondary-default": "var(--button-negative-secondary-default-text)",
			"button-negative-secondary-hover": "var(--button-negative-secondary-hover-text)",
			"button-negative-secondary-focus": "var(--button-negative-secondary-focus-text)",
			"button-negative-secondary-active": "var(--button-negative-secondary-active-text)",
			"button-negative-secondary-disabled": "var(--button-negative-secondary-disabled-text)",

			"button-negative-tertiary-default": "var(--button-negative-tertiary-default-text)",
			"button-negative-tertiary-hover": "var(--button-negative-tertiary-hover-text)",
			"button-negative-tertiary-focus": "var(--button-negative-tertiary-focus-text)",
			"button-negative-tertiary-active": "var(--button-negative-tertiary-active-text)",
			"button-negative-tertiary-disabled": "var(--button-negative-tertiary-disabled-text)",

			"button-negative-quaternary-default": "var(--button-negative-quaternary-default-text)",
			"button-negative-quaternary-hover": "var(--button-negative-quaternary-hover-text)",
			"button-negative-quaternary-focus": "var(--button-negative-quaternary-focus-text)",
			"button-negative-quaternary-active": "var(--button-negative-quaternary-active-text)",
			"button-negative-quaternary-disabled":
				"var(--button-negative-quaternary-disabled-text)",

			// Positive
			"button-positive-primary-default": "var(--button-positive-primary-default-text)",
			"button-positive-primary-hover": "var(--button-positive-primary-hover-text)",
			"button-positive-primary-focus": "var(--button-positive-primary-focus-text)",
			"button-positive-primary-active": "var(--button-positive-primary-active-text)",
			"button-positive-primary-disabled": "var(--button-positive-primary-disabled-text)",

			"button-positive-secondary-default": "var(--button-positive-secondary-default-text)",
			"button-positive-secondary-hover": "var(--button-positive-secondary-hover-text)",
			"button-positive-secondary-focus": "var(--button-positive-secondary-focus-text)",
			"button-positive-secondary-active": "var(--button-positive-secondary-active-text)",
			"button-positive-secondary-disabled": "var(--button-positive-secondary-disabled-text)",

			"button-positive-tertiary-default": "var(--button-positive-tertiary-default-text)",
			"button-positive-tertiary-hover": "var(--button-positive-tertiary-hover-text)",
			"button-positive-tertiary-focus": "var(--button-positive-tertiary-focus-text)",
			"button-positive-tertiary-active": "var(--button-positive-tertiary-active-text)",
			"button-positive-tertiary-disabled": "var(--button-positive-tertiary-disabled-text)",

			"button-positive-quaternary-default": "var(--button-positive-quaternary-default-text)",
			"button-positive-quaternary-hover": "var(--button-positive-quaternary-hover-text)",
			"button-positive-quaternary-focus": "var(--button-positive-quaternary-focus-text)",
			"button-positive-quaternary-active": "var(--button-positive-quaternary-active-text)",
			"button-positive-quaternary-disabled":
				"var(--button-positive-quaternary-disabled-text)",

			"grid-guides": "var(--border-primary)",
			"grid-guides-hover": "var(--border-primary-hover)",
		}),
		placeholderColor: ({ theme }) => ({
			...theme("colors"),
			primary: "var(--text-tertiary)",
		}),
		fill: ({ theme }) => ({
			...theme("colors"),
			"current": "currentColor",

			"icon-brand-default": "var(--brand-primary)",
			"icon-brand-hover": "var(--brand-primary-hover)",
			"icon-brand-focus": "var(--brand-primary-focus)",
			"icon-brand-active": "var(--brand-primary-active)",
			"icon-brand-disabled": "var(--brand-primary-disabled)",

			"icon-brand-light": "var(--brand-primary-light)",

			"icon-default": "var(--icon-default)",
			"icon-hover": "var(--icon-hover)",
			"icon-focus": "var(--icon-focus)",
			"icon-active": "var(--icon-active)",
			"icon-disabled": "var(--icon-disabled)",

			"icon-inverse-default": "var(--icon-inverse-default)",
			"icon-inverse-hover": "var(--icon-inverse-hover)",
			"icon-inverse-focus": "var(--icon-inverse-focus)",
			"icon-inverse-active": "var(--icon-inverse-active)",
			"icon-inverse-disabled": "var(--icon-inverse-disabled)",

			"icon-negative-default": "var(--icon-negative-default)",
			"icon-negative-hover": "var(--icon-negative-hover)",
			"icon-negative-focus": "var(--icon-negative-focus)",
			"icon-negative-active": "var(--icon-negative-active)",
			"icon-negative-disabled": "var(--icon-negative-disabled)",

			"icon-positive-default": "var(--icon-positive-default)",
			"icon-positive-hover": "var(--icon-positive-hover)",
			"icon-positive-focus": "var(--icon-positive-focus)",
			"icon-positive-active": "var(--icon-positive-active)",
			"icon-positive-disabled": "var(--icon-positive-disabled)",

			"icon-warning-default": "var(--icon-warning-default)",
			"icon-warning-hover": "var(--icon-warning-hover)",
			"icon-warning-focus": "var(--icon-warning-focus)",
			"icon-warning-active": "var(--icon-warning-active)",
			"icon-warning-disabled": "var(--icon-warning-disabled)",

			// Always the same color, regardless of theme
			"icon-fixed-light-default": "var(--icon-fixed-light-default)",
			"icon-fixed-light-hover": "var(--icon-fixed-light-hover)",
			"icon-fixed-light-focus": "var(--icon-fixed-light-focus)",
			"icon-fixed-light-active": "var(--icon-fixed-light-active)",
			"icon-fixed-light-disabled": "var(--icon-fixed-light-disabled)",

			"icon-fixed-dark-default": "var(--icon-fixed-dark-default)",
			"icon-fixed-dark-hover": "var(--icon-fixed-dark-hover)",
			"icon-fixed-dark-focus": "var(--icon-fixed-dark-focus)",
			"icon-fixed-dark-active": "var(--icon-fixed-dark-active)",
			"icon-fixed-dark-disabled": "var(--icon-fixed-dark-disabled)",

			// fill-bg-* is used for fills that need to match bg tokens like carets on tooltips
			"bg-primary": "var(--bg-primary)",
			"bg-secondary": "var(--bg-secondary)",
			"bg-tertiary": "var(--bg-tertiary)",
			"bg-quaternary": "var(--bg-quaternary)",
			"bg-quinary": "var(--bg-quinary)",

			"bg-inverse-primary": "var(--bg-inverse-primary)",
			"bg-inverse-secondary": "var(--bg-inverse-secondary)",
			"bg-inverse-tertiary": "var(--bg-inverse-tertiary)",
			"bg-inverse-quaternary": "var(--bg-inverse-quaternary)",
			"bg-inverse-quinary": "var(--bg-inverse-quinary)",

			// Normal
			"icon-button-normal-primary-default": "var(--button-normal-primary-default-enhancer)",
			"icon-button-normal-primary-hover": "var(--button-normal-primary-hover-enhancer)",
			"icon-button-normal-primary-focus": "var(--button-normal-primary-focus-enhancer)",
			"icon-button-normal-primary-active": "var(--button-normal-primary-active-enhancer)",
			"icon-button-normal-primary-disabled": "var(--button-normal-primary-disabled-enhancer)",

			"icon-button-normal-secondary-default":
				"var(--button-normal-secondary-default-enhancer)",
			"icon-button-normal-secondary-hover": "var(--button-normal-secondary-hover-enhancer)",
			"icon-button-normal-secondary-focus": "var(--button-normal-secondary-focus-enhancer)",
			"icon-button-normal-secondary-active": "var(--button-normal-secondary-active-enhancer)",
			"icon-button-normal-secondary-disabled":
				"var(--button-normal-secondary-disabled-enhancer)",

			"icon-button-normal-tertiary-default": "var(--button-normal-tertiary-default-enhancer)",
			"icon-button-normal-tertiary-hover": "var(--button-normal-tertiary-hover-enhancer)",
			"icon-button-normal-tertiary-focus": "var(--button-normal-tertiary-focus-enhancer)",
			"icon-button-normal-tertiary-active": "var(--button-normal-tertiary-active-enhancer)",
			"icon-button-normal-tertiary-disabled":
				"var(--button-normal-tertiary-disabled-enhancer)",

			"icon-button-normal-quaternary-default":
				"var(--button-normal-quaternary-default-enhancer)",
			"icon-button-normal-quaternary-hover": "var(--button-normal-quaternary-hover-enhancer)",
			"icon-button-normal-quaternary-focus": "var(--button-normal-quaternary-focus-enhancer)",
			"icon-button-normal-quaternary-active":
				"var(--button-normal-quaternary-active-enhancer)",
			"icon-button-normal-quaternary-disabled":
				"var(--button-normal-quaternary-disabled-enhancer)",

			// Negative
			"icon-button-negative-primary-default":
				"var(--button-negative-primary-default-enhancer)",
			"icon-button-negative-primary-hover": "var(--button-negative-primary-hover-enhancer)",
			"icon-button-negative-primary-focus": "var(--button-negative-primary-focus-enhancer)",
			"icon-button-negative-primary-active": "var(--button-negative-primary-active-enhancer)",
			"icon-button-negative-primary-disabled":
				"var(--button-negative-primary-disabled-enhancer)",

			"icon-button-negative-secondary-default":
				"var(--button-negative-secondary-default-enhancer)",
			"icon-button-negative-secondary-hover":
				"var(--button-negative-secondary-hover-enhancer)",
			"icon-button-negative-secondary-focus":
				"var(--button-negative-secondary-focus-enhancer)",
			"icon-button-negative-secondary-active":
				"var(--button-negative-secondary-active-enhancer)",
			"icon-button-negative-secondary-disabled":
				"var(--button-negative-secondary-disabled-enhancer)",

			"icon-button-negative-tertiary-default":
				"var(--button-negative-tertiary-default-enhancer)",
			"icon-button-negative-tertiary-hover": "var(--button-negative-tertiary-hover-enhancer)",
			"icon-button-negative-tertiary-focus": "var(--button-negative-tertiary-focus-enhancer)",
			"icon-button-negative-tertiary-active":
				"var(--button-negative-tertiary-active-enhancer)",
			"icon-button-negative-tertiary-disabled":
				"var(--button-negative-tertiary-disabled-enhancer)",

			"icon-button-negative-quaternary-default":
				"var(--button-negative-quaternary-default-enhancer)",
			"icon-button-negative-quaternary-hover":
				"var(--button-negative-quaternary-hover-enhancer)",
			"icon-button-negative-quaternary-focus":
				"var(--button-negative-quaternary-focus-enhancer)",
			"icon-button-negative-quaternary-active":
				"var(--button-negative-quaternary-active-enhancer)",
			"icon-button-negative-quaternary-disabled":
				"var(--button-negative-quaternary-disabled-enhancer)",

			// Positive
			"icon-button-positive-primary-default":
				"var(--button-positive-primary-default-enhancer)",
			"icon-button-positive-primary-hover": "var(--button-positive-primary-hover-enhancer)",
			"icon-button-positive-primary-focus": "var(--button-positive-primary-focus-enhancer)",
			"icon-button-positive-primary-active": "var(--button-positive-primary-active-enhancer)",
			"icon-button-positive-primary-disabled":
				"var(--button-positive-primary-disabled-enhancer)",

			"icon-button-positive-secondary-default":
				"var(--button-positive-secondary-default-enhancer)",
			"icon-button-positive-secondary-hover":
				"var(--button-positive-secondary-hover-enhancer)",
			"icon-button-positive-secondary-focus":
				"var(--button-positive-secondary-focus-enhancer)",
			"icon-button-positive-secondary-active":
				"var(--button-positive-secondary-active-enhancer)",
			"icon-button-positive-secondary-disabled":
				"var(--button-positive-secondary-disabled-enhancer)",

			"icon-button-positive-tertiary-default":
				"var(--button-positive-tertiary-default-enhancer)",
			"icon-button-positive-tertiary-hover": "var(--button-positive-tertiary-hover-enhancer)",
			"icon-button-positive-tertiary-focus": "var(--button-positive-tertiary-focus-enhancer)",
			"icon-button-positive-tertiary-active":
				"var(--button-positive-tertiary-active-enhancer)",
			"icon-button-positive-tertiary-disabled":
				"var(--button-positive-tertiary-disabled-enhancer)",

			"icon-button-positive-quaternary-default":
				"var(--button-positive-quaternary-default-enhancer)",
			"icon-button-positive-quaternary-hover":
				"var(--button-positive-quaternary-hover-enhancer)",
			"icon-button-positive-quaternary-focus":
				"var(--button-positive-quaternary-focus-enhancer)",
			"icon-button-positive-quaternary-active":
				"var(--button-positive-quaternary-active-enhancer)",
			"icon-button-positive-quaternary-disabled":
				"var(--button-positive-quaternary-disabled-enhancer)",
		}),
		stroke: ({ theme }) => ({
			...theme("colors"),
			"current": "currentColor",

			"icon-brand-default": "var(--brand-primary)",
			"icon-brand-hover": "var(--brand-primary-hover)",
			"icon-brand-focus": "var(--brand-primary-focus)",
			"icon-brand-active": "var(--brand-primary-active)",
			"icon-brand-disabled": "var(--brand-primary-disabled)",

			"icon-brand-light": "var(--brand-primary-light)",

			"icon-default": "var(--icon-default)",
			"icon-hover": "var(--icon-hover)",
			"icon-focus": "var(--icon-focus)",
			"icon-active": "var(--icon-active)",
			"icon-disabled": "var(--icon-disabled)",

			"icon-inverse-default": "var(--icon-inverse-default)",
			"icon-inverse-hover": "var(--icon-inverse-hover)",
			"icon-inverse-focus": "var(--icon-inverse-focus)",
			"icon-inverse-active": "var(--icon-inverse-active)",
			"icon-inverse-disabled": "var(--icon-inverse-disabled)",

			"icon-negative-default": "var(--icon-negative-default)",
			"icon-negative-hover": "var(--icon-negative-hover)",
			"icon-negative-focus": "var(--icon-negative-focus)",
			"icon-negative-active": "var(--icon-negative-active)",
			"icon-negative-disabled": "var(--icon-negative-disabled)",

			"icon-positive-default": "var(--icon-positive-default)",
			"icon-positive-hover": "var(--icon-positive-hover)",
			"icon-positive-focus": "var(--icon-positive-focus)",
			"icon-positive-active": "var(--icon-positive-active)",
			"icon-positive-disabled": "var(--icon-positive-disabled)",

			"icon-warning-default": "var(--icon-warning-default)",
			"icon-warning-hover": "var(--icon-warning-hover)",
			"icon-warning-focus": "var(--icon-warning-focus)",
			"icon-warning-active": "var(--icon-warning-active)",
			"icon-warning-disabled": "var(--icon-warning-disabled)",

			// Always the same color, regardless of theme
			"icon-fixed-light-default": "var(--icon-fixed-light-default)",
			"icon-fixed-light-hover": "var(--icon-fixed-light-hover)",
			"icon-fixed-light-focus": "var(--icon-fixed-light-focus)",
			"icon-fixed-light-active": "var(--icon-fixed-light-active)",
			"icon-fixed-light-disabled": "var(--icon-fixed-light-disabled)",

			"icon-fixed-dark-default": "var(--icon-fixed-dark-default)",
			"icon-fixed-dark-hover": "var(--icon-fixed-dark-hover)",
			"icon-fixed-dark-focus": "var(--icon-fixed-dark-focus)",
			"icon-fixed-dark-active": "var(--icon-fixed-dark-active)",
			"icon-fixed-dark-disabled": "var(--icon-fixed-dark-disabled)",

			// fill-bg-* is used for fills that need to match bg tokens like carets on tooltips
			"bg-primary": "var(--bg-primary)",
			"bg-secondary": "var(--bg-secondary)",
			"bg-tertiary": "var(--bg-tertiary)",
			"bg-quaternary": "var(--bg-quaternary)",
			"bg-quinary": "var(--bg-quinary)",

			"bg-inverse-primary": "var(--bg-inverse-primary)",
			"bg-inverse-secondary": "var(--bg-inverse-secondary)",
			"bg-inverse-tertiary": "var(--bg-inverse-tertiary)",
			"bg-inverse-quaternary": "var(--bg-inverse-quaternary)",
			"bg-inverse-quinary": "var(--bg-inverse-quinary)",

			// Normal
			"icon-button-normal-primary-default": "var(--button-normal-primary-default-enhancer)",
			"icon-button-normal-primary-hover": "var(--button-normal-primary-hover-enhancer)",
			"icon-button-normal-primary-focus": "var(--button-normal-primary-focus-enhancer)",
			"icon-button-normal-primary-active": "var(--button-normal-primary-active-enhancer)",
			"icon-button-normal-primary-disabled": "var(--button-normal-primary-disabled-enhancer)",

			"icon-button-normal-secondary-default":
				"var(--button-normal-secondary-default-enhancer)",
			"icon-button-normal-secondary-hover": "var(--button-normal-secondary-hover-enhancer)",
			"icon-button-normal-secondary-focus": "var(--button-normal-secondary-focus-enhancer)",
			"icon-button-normal-secondary-active": "var(--button-normal-secondary-active-enhancer)",
			"icon-button-normal-secondary-disabled":
				"var(--button-normal-secondary-disabled-enhancer)",

			"icon-button-normal-tertiary-default": "var(--button-normal-tertiary-default-enhancer)",
			"icon-button-normal-tertiary-hover": "var(--button-normal-tertiary-hover-enhancer)",
			"icon-button-normal-tertiary-focus": "var(--button-normal-tertiary-focus-enhancer)",
			"icon-button-normal-tertiary-active": "var(--button-normal-tertiary-active-enhancer)",
			"icon-button-normal-tertiary-disabled":
				"var(--button-normal-tertiary-disabled-enhancer)",

			"icon-button-normal-quaternary-default":
				"var(--button-normal-quaternary-default-enhancer)",
			"icon-button-normal-quaternary-hover": "var(--button-normal-quaternary-hover-enhancer)",
			"icon-button-normal-quaternary-focus": "var(--button-normal-quaternary-focus-enhancer)",
			"icon-button-normal-quaternary-active":
				"var(--button-normal-quaternary-active-enhancer)",
			"icon-button-normal-quaternary-disabled":
				"var(--button-normal-quaternary-disabled-enhancer)",

			// Negative
			"icon-button-negative-primary-default":
				"var(--button-negative-primary-default-enhancer)",
			"icon-button-negative-primary-hover": "var(--button-negative-primary-hover-enhancer)",
			"icon-button-negative-primary-focus": "var(--button-negative-primary-focus-enhancer)",
			"icon-button-negative-primary-active": "var(--button-negative-primary-active-enhancer)",
			"icon-button-negative-primary-disabled":
				"var(--button-negative-primary-disabled-enhancer)",

			"icon-button-negative-secondary-default":
				"var(--button-negative-secondary-default-enhancer)",
			"icon-button-negative-secondary-hover":
				"var(--button-negative-secondary-hover-enhancer)",
			"icon-button-negative-secondary-focus":
				"var(--button-negative-secondary-focus-enhancer)",
			"icon-button-negative-secondary-active":
				"var(--button-negative-secondary-active-enhancer)",
			"icon-button-negative-secondary-disabled":
				"var(--button-negative-secondary-disabled-enhancer)",

			"icon-button-negative-tertiary-default":
				"var(--button-negative-tertiary-default-enhancer)",
			"icon-button-negative-tertiary-hover": "var(--button-negative-tertiary-hover-enhancer)",
			"icon-button-negative-tertiary-focus": "var(--button-negative-tertiary-focus-enhancer)",
			"icon-button-negative-tertiary-active":
				"var(--button-negative-tertiary-active-enhancer)",
			"icon-button-negative-tertiary-disabled":
				"var(--button-negative-tertiary-disabled-enhancer)",

			"icon-button-negative-quaternary-default":
				"var(--button-negative-quaternary-default-enhancer)",
			"icon-button-negative-quaternary-hover":
				"var(--button-negative-quaternary-hover-enhancer)",
			"icon-button-negative-quaternary-focus":
				"var(--button-negative-quaternary-focus-enhancer)",
			"icon-button-negative-quaternary-active":
				"var(--button-negative-quaternary-active-enhancer)",
			"icon-button-negative-quaternary-disabled":
				"var(--button-negative-quaternary-disabled-enhancer)",

			// Positive
			"icon-button-positive-primary-default":
				"var(--button-positive-primary-default-enhancer)",
			"icon-button-positive-primary-hover": "var(--button-positive-primary-hover-enhancer)",
			"icon-button-positive-primary-focus": "var(--button-positive-primary-focus-enhancer)",
			"icon-button-positive-primary-active": "var(--button-positive-primary-active-enhancer)",
			"icon-button-positive-primary-disabled":
				"var(--button-positive-primary-disabled-enhancer)",

			"icon-button-positive-secondary-default":
				"var(--button-positive-secondary-default-enhancer)",
			"icon-button-positive-secondary-hover":
				"var(--button-positive-secondary-hover-enhancer)",
			"icon-button-positive-secondary-focus":
				"var(--button-positive-secondary-focus-enhancer)",
			"icon-button-positive-secondary-active":
				"var(--button-positive-secondary-active-enhancer)",
			"icon-button-positive-secondary-disabled":
				"var(--button-positive-secondary-disabled-enhancer)",

			"icon-button-positive-tertiary-default":
				"var(--button-positive-tertiary-default-enhancer)",
			"icon-button-positive-tertiary-hover": "var(--button-positive-tertiary-hover-enhancer)",
			"icon-button-positive-tertiary-focus": "var(--button-positive-tertiary-focus-enhancer)",
			"icon-button-positive-tertiary-active":
				"var(--button-positive-tertiary-active-enhancer)",
			"icon-button-positive-tertiary-disabled":
				"var(--button-positive-tertiary-disabled-enhancer)",

			"icon-button-positive-quaternary-default":
				"var(--button-positive-quaternary-default-enhancer)",
			"icon-button-positive-quaternary-hover":
				"var(--button-positive-quaternary-hover-enhancer)",
			"icon-button-positive-quaternary-focus":
				"var(--button-positive-quaternary-focus-enhancer)",
			"icon-button-positive-quaternary-active":
				"var(--button-positive-quaternary-active-enhancer)",
			"icon-button-positive-quaternary-disabled":
				"var(--button-positive-quaternary-disabled-enhancer)",
		}),
		fontFamily: {
			sans: [
				"IBM Plex Sans",
				"-apple-system",
				"BlinkMacSystemFont",
				"avenir next",
				"avenir",
				"helvetica neue",
				"helvetica",
				"Ubuntu",
				"roboto",
				"noto",
				"segoe ui",
				"arial",
				"sans-serif",
			],
			serif: [
				"IBM Plex Serif",
				"Iowan Old Style",
				"Apple Garamond",
				"Baskerville",
				"Times New Roman",
				"Droid Serif",
				"Times",
				"Source Serif Pro",
				"serif",
				"Apple Color Emoji",
				"Segoe UI Emoji",
				"Segoe UI Symbol",
			],
			mono: [
				"IBM Plex Mono",
				"Menlo",
				"Consolas",
				"Monaco",
				"Liberation Mono",
				"Lucida Console",
				"monospace",
			],
		},
		fontSize: {
			"xxs": ["10px", "16px"],
			"xs": ["12px", "16px"],
			"sm": ["14px", "20px"],
			"base": ["16px", "24px"],
			"md": ["16px", "24px"],
			"lg": ["18px", "24px"],
			"xl": ["21px", "32px"],
			"2xl": ["24px", "32px"],
			"3xl": ["32px", "40px"],
			"4xl": ["56px", "72px"],
		},
		minWidth: {
			5: "1.25rem",
		},
		ringColor: ({ theme }) => ({
			...theme("colors"),
			default: "var(--focus-ring-default)",
			normal: "var(--focus-ring-default)",
			positive: "var(--focus-ring-positive)",
			negative: "var(--focus-ring-negative)",
			warning: "var(--focus-ring-warning)",
		}),
		extend: {
			spacing: {
				999: "999px",
			},
			minWidth: {
				"0": "0",
				"full": "100%",
				"xxxs": "12rem",
				"xxs": "16rem",

				"50px": "50px",
				"75px": "75px",
				"125px": "125px",
				"500px": "500px",
				"600px": "600px",
				"700px": "700px",
				"800px": "800px",
				"825px": "825px",
				"1125px": "1125px",
			},
			maxWidth: {
				"xxxs": "12rem",
				"xxs": "16rem",
				"8xl": "88rem",
				"9xl": "96rem",
				"10xl": "104rem",
				"12xl": "112rem",
				"13xl": "120rem",
				"14xl": "128rem",
				"15xl": "136rem",
				"16xl": "144rem",
				"17xl": "152rem",
				"18xl": "160rem",
				"19xl": "168rem",
				"20xl": "176rem",

				"500px": "500px",
				"600px": "600px",
				"700px": "700px",
				"800px": "800px",
				"825px": "825px",
				"1000px": "1000px",
				"1125px": "1125px",
			},
			ringWidth: {
				DEFAULT: "4px",
			},
			width: {
				"72": "18rem",
				"84": "21rem",
				"96": "24rem",
				"128": "32rem",
				"160": "44rem",

				"5px": "5px",
				"50px": "50px",
				"75px": "75px",
				"100px": "100px",
				"125px": "125px",
				"200px": "200px",
				"300px": "300px",
				"400px": "400px",
				"500px": "500px",
				"600px": "600px",
				"700px": "700px",
				"800px": "800px",
				"825px": "825px",
				"900px": "900px",
				"1000px": "1000px",
			},
			height: {
				"fit-content": "fit-content",

				"progress-bar": "0.50rem",

				"50px": "50px",
			},
			margin: {
				"-75px": "-75px",
				"-50px": "-50px",
				"-30px": "-30px",
				"-24px": "-24px",
				"-4px": "-4px",
				"1px": "1px",
				"2px": "2px",
				"3px": "3px",
				"4px": "4px",
				"6px": "6px",
				"24px": "24px",
				"50px": "50px",
				"75px": "75px",
				"100px": "100px",
				"125px": "125px",

				"-9": "-2.25rem",
				"9": "2.25rem",
			},
			padding: {
				"30px": "30px",
			},
			boxShadow: {
				"2xl": "0 25px 50px 20px rgba(0, 0, 0, 0.4)",
				"outline": "0 0 0 3px rgb(39 109 167 / 50%)",
				"card": "20px 0px 10px -10px rgba(0, 0, 0, 0.4)",
				"surround-xs": "0 0px 4px 1px rgba(0, 0, 0, 0.2)",
				"surround-sm": "0 0px 7px 1px rgba(0, 0, 0, 0.2)",
				"surround-md": "0 0px 15px 1px rgba(0, 0, 0, 0.3)",
				"glow-md": "0 0px 15px 1px rgba(88, 88, 88, 0.3)",
				"inner-md": "inset 0 2px 4px 0 rgba(0, 0, 0, 0.35)",

				"border-underline-input": "0 2px 0 var(--brand-primary)",

				"border-top": "0 -1px 0 var(--border-primary)",
				"border-right": "1px 0 0 var(--border-primary)",
				"border-bottom": "0 1px 0 var(--border-primary)",
				"border-left": "-1px 0 0 var(--border-primary)",
				"border-top-right": "1px -1px 0 0 var(--border-primary)",
				"border-bottom-right": "1px 1px 0 0 var(--border-primary)",
				"border-bottom-left": "-1px 1px 0 0 var(--border-primary)",
				"border-top-left": "-1px -1px 0 0 var(--border-primary)",

				"border-inset": "inset 0 0 0 1px var(--border-primary)",

				"border-inset-top": "inset 0 1px 0 var(--border-primary)",
				"border-inset-right": "inset -1px 0 0 var(--border-primary)",
				"border-inset-bottom": "inset 0 -1px 0 var(--border-primary)",
				"border-inset-left": "inset 1px 0 0 var(--border-primary)",
				"border-inset-top-right": "inset -1px 1px 0 0 var(--border-primary)",
				"border-inset-bottom-right": "inset -1px -1px 0 0 var(--border-primary)",
				"border-inset-bottom-left": "inset 1px -1px 0 0 var(--border-primary)",
				"border-inset-top-left": "inset 1px 1px 0 0 var(--border-primary)",

				"border-bottom-negative-mid": "inset 0 -1px 0 0 var(--state-negative-mid)",
				"border-bottom-positive-mid": "inset 0 -1px 0 0 var(--state-positive-mid)",
				"border-bottom-warning-mid": "inset 0 -1px 0 0 var(--state-warning-mid)",

				"border-default": "inset 0 0 0 1px var(--border-interactive-normal-default)",
				"border-hover": "inset 0 0 0 1px var(--border-interactive-normal-hover)",
				"border-focus": "inset 0 0 0 1px var(--border-interactive-normal-focus)",
				"border-active": "inset 0 0 0 1px var(--border-interactive-normal-active)",
				"border-disabled": "inset 0 0 0 1px var(--border-interactive-normal-disabled)",

				// When you need a dropshadow and a box-shadow based border, like InputSelect
				"border-dropshadow-default":
					"inset 0 0 0 1px var(--border-interactive-normal-default), 0 1px 3px 0 rgb(0 0 0 / 0.08), 0 1px 2px -1px rgb(0 0 0 / 0.08)",
				"border-dropshadow-hover":
					"inset 0 0 0 1px var(--border-interactive-normal-hover), 0 4px 6px -1px rgb(0 0 0 / 0.08), 0 2px 4px -2px rgb(0 0 0 / 0.08)",
				"border-dropshadow-focus":
					"inset 0 0 0 1px var(--border-interactive-normal-focus), 0 0 0 0 rgb(0 0 0 / 0.08), 0 0 0 0 rgb(0 0 0 / 0.08)",
				"border-dropshadow-active":
					"inset 0 0 0 1px var(--border-interactive-normal-active), 0 0 0 0 rgb(0 0 0 / 0.08), 0 0 0 0 rgb(0 0 0 / 0.08)",
				"border-dropshadow-disabled":
					"inset 0 0 0 1px var(--border-interactive-normal-disabled), 0 0 0 0 rgb(0 0 0 / 0.08), 0 0 0 0 rgb(0 0 0 / 0.08)",

				"border-brand-light": "inset 0 0 0 1px var(--brand-primary-light)",
				"border-brand-dark": "inset 0 0 0 1px var(--brand-primary-dark)",

				"border-brand-default": "inset 0 0 0 1px var(--brand-primary)",
				"border-brand-hover": "inset 0 0 0 1px var(--brand-primary-hover)",
				"border-brand-focus": "inset 0 0 0 1px var(--brand-primary-focus)",
				"border-brand-active": "inset 0 0 0 1px var(--brand-primary-active)",
				"border-brand-disabled": "inset 0 0 0 1px var(--brand-primary-disabled)",

				"border-bottom-brand-default": "0 1px 0 0 var(--brand-primary)",

				"border-secondary-default":
					"inset 0 0 0 1px var(--button-outlined-secondary-default-border)",
				"border-secondary-hover":
					"inset 0 0 0 1px var(--button-outlined-secondary-hover-border)",
				"border-secondary-focus":
					"inset 0 0 0 1px var(--button-outlined-secondary-focus-border)",
				"border-secondary-active":
					"inset 0 0 0 1px var(--button-outlined-secondary-active-border)",
				"border-secondary-disabled":
					"inset 0 0 0 1px var(--button-outlined-secondary-disabled-border)",

				/* Border classes for buttons */
				"border-normal-default": "inset 0 0 0 1px var(--border-interactive-normal-default)",
				"border-normal-default-raised":
					"inset 0 1px 0 1px var(--border-interactive-fixed-light), inset 0 -1px 0 0 var(--border-interactive-fixed-dark), 0 3px 6px -1px rgb(0 0 0 / 0.12), 0 1px 2px 0 rgb(0 0 0 / 0.15)",
				"border-normal-hover": "inset 0 0 0 1px var(--border-interactive-normal-hover)",
				"border-normal-hover-raised":
					"inset 0 0 0 1px var(--border-interactive-fixed-light), inset 0 -1px 0 0 var(--border-interactive-fixed-dark), 0 2px 4px -2px rgb(0 0 0 / 0.12), 0 1px 2px 0 rgb(0 0 0 / 0.15)",
				"border-normal-focus": "inset 0 0 0 1px var(--brand-primary-dark)",
				"border-normal-active": "inset 0 0 0 1px var(--brand-primary-dark)",
				"border-normal-disabled":
					"inset 0 0 0 1px var(--border-interactive-normal-disabled)",

				"border-negative-light": "inset 0 0 0 1px var(--state-negative-light)",

				"border-negative-default":
					"inset 0 0 0 1px var(--border-interactive-negative-default)",
				"border-negative-default-raised":
					"inset 0 1px 0 1px var(--border-interactive-fixed-light), inset 0 -1px 0 0 var(--border-interactive-fixed-dark), 0 3px 6px -1px rgb(0 0 0 / 0.12), 0 1px 2px 0 rgb(0 0 0 / 0.15)",
				"border-negative-hover": "inset 0 0 0 1px var(--border-interactive-negative-hover)",
				"border-negative-hover-raised":
					"inset 0 0 0 1px var(--border-interactive-fixed-light), inset 0 -1px 0 0 var(--border-interactive-fixed-dark), 0 2px 4px -2px rgb(0 0 0 / 0.12), 0 1px 2px 0 rgb(0 0 0 / 0.15)",
				"border-negative-focus": "inset 0 0 0 1px var(--border-interactive-negative-focus)",
				"border-negative-active":
					"inset 0 0 0 1px var(--border-interactive-negative-active)",
				"border-negative-disabled":
					"inset 0 0 0 1px var(--border-interactive-negative-disabled)",

				"border-positive-light": "inset 0 0 0 1px var(--state-positive-light)",

				"border-positive-default":
					"inset 0 0 0 1px var(--border-interactive-positive-default)",
				"border-positive-default-raised":
					"inset 0 1px 0 1px var(--border-interactive-fixed-light), inset 0 -1px 0 0 var(--border-interactive-fixed-dark), 0 3px 6px -1px rgb(0 0 0 / 0.12), 0 1px 2px 0 rgb(0 0 0 / 0.15)",
				"border-positive-hover": "inset 0 0 0 1px var(--border-interactive-positive-hover)",
				"border-positive-hover-raised":
					"inset 0 0 0 1px var(--border-interactive-fixed-light), inset 0 -1px 0 0 var(--border-interactive-fixed-dark), 0 2px 4px -2px rgb(0 0 0 / 0.12), 0 1px 2px 0 rgb(0 0 0 / 0.15)",
				"border-positive-focus": "inset 0 0 0 1px var(--border-interactive-positive-focus)",
				"border-positive-active":
					"inset 0 0 0 1px var(--border-interactive-positive-active)",
				"border-positive-disabled":
					"inset 0 0 0 1px var(--border-interactive-positive-disabled)",

				"border-warning-default":
					"inset 0 0 0 1px var(--border-interactive-warning-default)",
				"border-warning-default-raised":
					"inset 0 1px 0 1px var(--border-interactive-fixed-light), inset 0 -1px 0 0 var(--border-interactive-fixed-dark), 0 3px 6px -1px rgb(0 0 0 / 0.12), 0 1px 2px 0 rgb(0 0 0 / 0.15)",
				"border-warning-hover": "inset 0 0 0 1px var(--border-interactive-warning-hover)",
				"border-warning-hover-raised":
					"inset 0 0 0 1px var(--border-interactive-fixed-light), inset 0 -1px 0 0 var(--border-interactive-fixed-dark), 0 2px 4px -2px rgb(0 0 0 / 0.12), 0 1px 2px 0 rgb(0 0 0 / 0.15)",
				"border-warning-focus": "inset 0 0 0 1px var(--border-interactive-warning-focus)",
				"border-warning-active": "inset 0 0 0 1px var(--border-interactive-warning-active)",
				"border-warning-disabled":
					"inset 0 0 0 1px var(--border-interactive-warning-disabled)",

				"nav-rail-item-hover":
					"inset 0 -1px 2px 0 rgba(241,245,249,0.15), 0 -2px 6px 1px rgba(241,245,249,0.05)",
				"nav-rail-item-toggled":
					"inset 0 -1px 2px 0 rgba(241,245,249,0.15), 0 -2px 6px 1px rgba(241,245,249,0.05)",
			},
			borderWidth: {
				3: "3px",
			},
			zIndex: {
				"base": "0",
				"canvas-base": "5",
				"surface": "10",
				"cell": "20",
				"component-flat": "30",
				"component-raised": "40",
				"app-toggles": "50",
				"app-toggles-expanded": "51",
				"dialog": "60",
				"tooltip": "80", // Tooltip needs to be above dialog, peer to popover
				"popover": "80", // Popover needs to be above dialog
				"draggable": "90",
			},
			screens: {
				"3xl": "2048px",
			},
			keyframes: {
				slideDownAndFade: {
					from: { opacity: 0, transform: "translateY(-8px)" },
					to: { opacity: 1, transform: "translateY(0)" },
				},
				slideLeftAndFade: {
					from: { opacity: 0, transform: "translateX(8px)" },
					to: { opacity: 1, transform: "translateX(0)" },
				},
				slideUpAndFade: {
					from: { opacity: 0, transform: "translateY(8px)" },
					to: { opacity: 1, transform: "translateY(0)" },
				},
				slideRightAndFade: {
					from: { opacity: 0, transform: "translateX(-8px)" },
					to: { opacity: 1, transform: "translateX(0)" },
				},
				appearAndFadeOut: {
					"0%,66%": { opacity: 1 },
					"100%": { opacity: 0 },
				},
			},
			animation: {
				slideDownAndFade: "slideDownAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)",
				slideLeftAndFade: "slideLeftAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)",
				slideUpAndFade: "slideUpAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)",
				slideRightAndFade: "slideRightAndFade 150ms cubic-bezier(0.16, 1, 0.3, 1)",
				appearAndFadeOut: "appearAndFadeOut 1.5s cubic-bezier(0.16, 1, 0.3, 1)",
			},
		},
	},
	plugins: [
		require("./plugins/insetBorderPlugin/index.js"),
		require("@tailwindcss/container-queries"),
	],
};
