import {
	type Attrs,
	type DOMOutputSpec,
	type Node,
	type NodeSpec,
	type ParseRule,
} from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "~/lib/prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "~/lib/prosemirror-utils/makeTextCellDefaultToDOMAttrs";

// :: NodeSpec A heading textblock, with a `level` attribute that
// should hold the number 1 to 6. Parsed and serialized as `<h1>` to
// `<h6>` elements.

export const heading: NodeSpec = {
	attrs: {
		...CELL_SPEC_ATTRS,
		level: {
			default: 1,
		},
		expandedState: {
			default: "open",
		},
	},
	content: "inline*",
	group: "block",
	defining: true,
	parseDOM: Array.from({ length: 6 }).map((_, i) => {
		const level = i + 1;
		const tag = `h${level}`;

		const rule: ParseRule = {
			tag,
			getAttrs(node: string | HTMLElement) {
				if (typeof node === "string") {
					return {};
				}

				const expandedState = node.getAttribute("data-expanded-state") || "open";

				const attrs: Attrs = {
					level,
					expandedState,
				};

				return attrs;
			},
		};

		return rule;
	}),
	toDOM(node: Node) {
		const level = node.attrs["level"];
		const tag = `h${level}`;

		const attrs: Attrs = {
			...makeTextCellDefaultToDOMAttrs(node),
			"data-expanded-state": node.attrs["expandedState"],
		};

		const dom: DOMOutputSpec = [tag, attrs, 0];

		return dom;
	},
};
