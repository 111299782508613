export enum Flags {
	BackgroundRefresh = "BACKGROUND_REFRESH",
	CanvasLayoutVisualDebug = "CANVAS_LAYOUT_VISUAL_DEBUGGING",
	CanvasPreviewAs = "PREVIEW_AS",
	CanvasReleases = "CANVAS_RELEASES",
	CollabServiceConnectionCalloutBanner = "COLLAB_SERVICE_CONNECTION_CALLOUT_BANNER",
	Comments = "COMMENTS",
	DraftSaving = "DRAFT_SAVING",
	ForceDisableCollabEditing = "FORCE_DISABLE_COLLAB_EDITING",
	ForceDisableChecksumDebugging = "FORCE_DISABLE_CHECKSUM_DEBUGGING",
	IframeView = "IFRAME_VIEW",
	InspectPaneHelpMenu = "INSPECT_PANE_HELP_MENU",
	LittleTriangles = "LITTLE_TRIANGLES",
	OnboardingDevTools = "ONBOARDING_DEV_TOOLS",
	OpenSearch = "OPENSEARCH",
	SettingsAtlasForceSetup = "FORCE_ATLAS_SETUP",
	SpookyOwl = "SPOOKY_OWL",
	TestFlagFalse = "TEST_FLAG_FALSE",
	TestFlagTrue = "TEST_FLAG_TRUE",
	ThemeingAllowDarkMode = "THEMING",
	UseCellValueHook = "USE_CELL_VALUE_HOOK",
}

export type FeatureFlag = {
	[key in Flags]: boolean;
};
