import { v4 as uuidV4 } from "uuid";
import z from "zod";

export const pongMessage = z.object({
	type: z.literal("pong"),
	uuid: z.string(),
});
export type PongMessage = z.infer<typeof pongMessage>;
export const makePongMessage = (pingMessage: PingMessage) => {
	return {
		type: "pong",
		uuid: pingMessage.uuid,
	};
};

export const pingMessage = z.object({
	type: z.literal("ping"),
	uuid: z.string(),
	debug: z.boolean().optional(),
});
export type PingMessage = z.infer<typeof pingMessage>;
export const makePingMessage = (debug = false) => {
	return {
		type: "ping",
		uuid: uuidV4(),
		debug,
	};
};
