import { type Properties } from "posthog-js";

import type { Organization } from "~/api/generated/graphql";
import type { User } from "~/models/user";
import { isSynthetics } from "~/utils/datadog/datadog";
import { Logger } from "~/utils/logging";

import type { AnalyticsInterface, TrackEvents } from "./interface";
import { posthog } from "./posthog-js";

export * from "./interface";

const captureBlockList = [
	"auth0|6386c7ceaa6a0d0c07f0a0e5", // This user is test@moment.dev and used for browser alarms.
];

const logger = new Logger("analytics");

// Any users that should not be tracked should be added to this list.
//
// Includes:
// - moment.dev email accounts
const shouldOptOutUser = (user?: User): boolean => user?.email.endsWith("@moment.dev") ?? false;

// Currently, only posthog is supported. Add any other analytics provider calls to the methods below.
class Analytics {
	public reset(): void {
		try {
			posthog.reset();
		} catch (e) {
			logger.error("error in posthog.reset", { error: `${e}` });
		}
		this._logDebug("reset");
	}

	public identify(userId: string, user?: User | undefined): void {
		if (isSynthetics()) {
			return;
		}

		const data = {
			email: user?.email,
			name: user?.name,
			id: userId,
			organization: user?.organizationID,
		};

		try {
			posthog.identify(userId, data);

			if (shouldOptOutUser(user)) {
				posthog.opt_out_capturing();
			}
		} catch (e) {
			logger.error("error in posthog.identify", { error: `${e}` });
		}

		this._logDebug("identify", data);

		// IMPORTANT: Keep Posthog bills down! No user ID means test account. Discard capturing. If
		// we ever need to start tracking anonymous and not-logged-in users, we will need to change
		// this. But BE CAREFUL! Don't let the bots run up the bill!
		if (captureBlockList.includes(userId) || !userId) {
			posthog.opt_out_capturing();
		}
	}

	public group(org: Organization): void {
		const data = {
			"company name": org.name,
			"company domains": org.domainNames,
		};

		try {
			posthog.group("company id", org.id, {});
		} catch (e) {
			logger.error("error in posthog.group", { error: `${e}` });
		}

		this._logDebug("group", data);
	}

	public track(event: TrackEvents, data: Properties): void {
		if (isSynthetics()) {
			return;
		}

		try {
			posthog.capture(event, data);
		} catch (e) {
			logger.error("error in posthog.capture", { error: `${e}` });
		}

		this._logDebug("track", { event, ...data });
	}

	private _logDebug(message: string, data?: Record<string, unknown>): void {
		if (process.env.NODE_ENV === "development") {
			logger.debug(message, data);
		}
	}
}

export const analytics: AnalyticsInterface = new Analytics();
