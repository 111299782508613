import Cookies from "js-cookie";

import { getAuthConfigs } from "~/auth/authconfig";

import { type Auth, type OAuthTokenPayload, getAuthURLs } from "./common";
import { loadTokens, refreshAccessToken } from "./tokens";

const refreshTokenCookieName = "refreshToken";

const authUrls = getAuthURLs(process.env.AUTH_ENV);
const auth0Info = getAuthConfigs(process.env.AUTH_ENV);

class WebAuth implements Auth {
	redirectToAuth0LoginPage(state?: string) {
		if (process.env.MOMENT_DESKTOP_APP === "true") {
			return;
		}
		const url = new URL(authUrls.authorize);
		if (state) {
			url.searchParams.append("state", state);
		}
		window.location.assign(url);
	}

	async openAuth0LoginPageForDesktopAuth(state?: string): Promise<string> {
		if (!window.desktopIpcApi) {
			throw new Error("This method cannot be used for the web version.");
		}

		const url = new URL(authUrls.authorize);
		if (state) {
			url.searchParams.append("state", state);
		}
		await window.desktopIpcApi.openAuthorizationUrl(url.toString());

		return url.toString();
	}

	logout() {
		Cookies.remove(refreshTokenCookieName);
	}

	async loadTokens(authCode: string | string[]): Promise<OAuthTokenPayload> {
		const { loginRedirectUri } = getAuthURLs(process.env.AUTH_ENV);
		const tokens = await loadTokens(fetch, auth0Info, loginRedirectUri, authCode);
		this.oauth2RefreshToken = tokens.refreshToken;
		return tokens;
	}

	refreshAccessToken(refreshToken: string) {
		return refreshAccessToken(fetch, auth0Info, refreshToken);
	}

	get oauth2RefreshToken(): string | undefined {
		return Cookies.get(refreshTokenCookieName);
	}

	set oauth2RefreshToken(refreshToken: string | undefined) {
		if (refreshToken === undefined) {
			return;
		}

		Cookies.set(refreshTokenCookieName, refreshToken);
	}
}

export const webAuth = new WebAuth();
