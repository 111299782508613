import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { type ClientState } from "./types";

// Cookie is used to determine app env for server side props.
export const MOMENT_APP_ENV_COOKIE_NAME = "moment_app_env";

// When we start in local mode we actually want to point to the staging database
export const defaultAppEnv = (() => {
	const cookieEnv = Cookies.get(MOMENT_APP_ENV_COOKIE_NAME) as MomentAppEnv;
	if (cookieEnv !== undefined) {
		return cookieEnv;
	}

	let env = process.env.APP_ENV;
	switch (process.env.APP_ENV) {
		case "local":
			// If auth is set to local, we need to call the local backend.
			env = process.env.AUTH_ENV == "local" ? "local" : "staging";
			break;
		case undefined:
			env = "staging";
			break;
		default:
			env = process.env.APP_ENV;
			break;
	}
	Cookies.set(MOMENT_APP_ENV_COOKIE_NAME, env);
	return env;
})();

export const initialState: ClientState = {
	env: defaultAppEnv,
	xOrganizationHeaderValue: undefined,
};

export const clientSlice = createSlice({
	name: "client",
	initialState,
	reducers: {
		setEnv: (state, action: PayloadAction<{ env: NonNullable<MomentAppEnv> }>) => {
			const { env } = action.payload;
			state.env = env;
			Cookies.set(MOMENT_APP_ENV_COOKIE_NAME, env);
		},

		setXOrganizationHeaderValue: (
			state,
			action: PayloadAction<{ xOrganizationHeaderValue: string | undefined }>
		) => {
			const { xOrganizationHeaderValue } = action.payload;
			state.xOrganizationHeaderValue = xOrganizationHeaderValue;
		},
	},
});

export const { setEnv, setXOrganizationHeaderValue } = clientSlice.actions;
export const client = clientSlice.reducer;
