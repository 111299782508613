export const localMomentDomain = "localhost:8080";
export const stagingMomentDomain = "api-staging.moment.dev";
export const prodMomentDomain = "api.moment.dev";

export const API_URLS = {
	staging: `https://${stagingMomentDomain}`,
	production: `https://${prodMomentDomain}`,
	testing: `http://${localMomentDomain}`,
	local: `http://${localMomentDomain}`,
	dev: `http://${localMomentDomain}`,
	unknown: "",
} as const;

export type Env = keyof typeof API_URLS;

export function getEnvFromString(envStr: string): keyof typeof API_URLS {
	let key: keyof typeof API_URLS;
	for (key in API_URLS) {
		if (key === envStr) {
			return key;
		}
	}
	return "unknown";
}
