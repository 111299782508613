import NextHead from "next/head";
import { type FC } from "react";

export type RobotsProps = Record<never, never>;

export const Robots: FC<RobotsProps> = () => {
	return (
		<NextHead>
			{/* Where robots file can be found */}
			<link rel="robots" href="/robots.txt" />

			{/* Control the behavior of search engine crawling and indexing */}
			<meta name="robots" key="robots" content="index, follow" />
			<meta name="googlebot" key="googlebot" content="index, follow" />

			{/* Tells Google not to show the sitelinks search box */}
			<meta name="google" key="google:nositelinkssearchbox" content="nositelinkssearchbox" />

			{/* Tells Google not to provide a translation for this document */}
			<meta name="google" key="google:notranslate" content="notranslate" />
		</NextHead>
	);
};
