import {
	type FC,
	type ReactNode,
	type RefObject,
	createContext,
	createRef,
	useContext,
	useMemo,
	useState,
} from "react";

import { notImplementedFn } from "~/utils/context";

type Portal = RefObject<HTMLDivElement | null>;
export interface TopBarContextStateType {
	portal?: Portal;
	setTopBarPortal: (portal: Portal) => void;
}

const TopBarContext = createContext<TopBarContextStateType>({
	portal: createRef(),
	setTopBarPortal: notImplementedFn("setPortal", "TopBarContext", undefined),
});

export const useTopBarContext = () => useContext(TopBarContext);

export const TopBarProvider: FC<{ children?: ReactNode }> = ({ children }) => {
	const [portal, setPortal] = useState<Portal>();

	const setTopBarPortal = (portal: Portal) => {
		if (!portal.current) {
			return;
		}

		setPortal(portal);
	};

	const value = useMemo(() => ({ portal, setTopBarPortal }), [portal, setTopBarPortal]);
	return <TopBarContext.Provider value={value}>{children}</TopBarContext.Provider>;
};
