import z from "zod";

/**
 * A zod re-implementation of the immer `Patch` type.
 */
export const patch = z.object({
	op: z.union([z.literal("replace"), z.literal("remove"), z.literal("add")]),
	path: z.array(z.union([z.string(), z.number()])),
	value: z.optional(z.unknown()),
});

/**
 * A zod re-implementation of the immer `Patch` type.
 */
export type Patch = z.infer<typeof patch>;
