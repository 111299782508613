import { useCallback, useEffect, useState } from "react";

import { createAtlasProxyFetchHttp } from "~/api/atlas";
import { selectEnv } from "~/store/client/selectors";
import { useAppSelector } from "~/store/hooks";
import type { Env } from "~/utils/common";

import { AtlasEndpoints } from "./endpoints";
import {
	AccessTokensMetadataResponse,
	type AdapterConfigType,
	type AdapterType,
	Adapters,
	type InstanceType,
	Instances,
	type InstancesType,
	type RawInstanceType,
} from "./schema";

export * from "./schema";

export const MOMENT_TEST_CLUSTER_NAME = "moment-k8s-test-cluster";

const getAtlasProxyFetchForEnv = (env: Env) => createAtlasProxyFetchHttp(AtlasEndpoints[env]);

export const useAtlasInstances = ({
	filterTestAdapters,
}: {
	filterTestAdapters?: boolean;
}): [InstancesType, Error | undefined] => {
	const clientEnv = useAppSelector(selectEnv);
	const [instances, setInstances] = useState<InstancesType>();
	const [error, setError] = useState<Error>();

	const getInstances = useCallback(
		async function () {
			try {
				setInstances(await getAtlasInstances(clientEnv, filterTestAdapters));
			} catch (e) {
				setError(e as Error);
			}
		},
		[clientEnv, filterTestAdapters]
	);

	useEffect(() => {
		void getInstances();
	}, []);

	return [instances, error];
};

export const AtlasProxyFetchForEnv: Record<
	string,
	ReturnType<typeof createAtlasProxyFetchHttp>
> = Object.keys(AtlasEndpoints).reduce((acc, env) => {
	acc[env] = createAtlasProxyFetchHttp(AtlasEndpoints[env]);
	return acc;
}, {});

export const atlasProxyFetchWithEnv = (env: Env, route: unknown, init?: unknown) => {
	const atlasProxyFetch = getAtlasProxyFetchForEnv(env);
	if (!atlasProxyFetch) {
		throw new Error(`No atlas proxy fetch for env ${env}`);
	}
	return atlasProxyFetch(route, init);
};

const configSortFn = (a: AdapterConfigType | InstanceType, b: AdapterConfigType | InstanceType) => {
	if (a.config.metadata.name !== undefined && b.config.metadata.name !== undefined) {
		return a.config.metadata.name.localeCompare(b.config.metadata.name);
	}
	return a.config.kind.localeCompare(b.config.kind);
};

const adapterSortFn = (a: AdapterType, b: AdapterType) =>
	a.metadata.name.localeCompare(b.metadata.name);

export const getAtlasInstances = async (env: Env, filterTestAdapters = true) => {
	const response = await atlasProxyFetchWithEnv(env, "/v1alpha1/instances");

	if (!response.ok) {
		const body = await response.text();
		throw new Error(`Failed to fetch instances: ${response.status} ${body}`);
	}

	const json = await response.json();
	const sorted = json.map((instance: RawInstanceType) => ({
		...instance,
		adapters: instance.adapters?.sort(configSortFn),
	}));

	// Filter out the test cluster
	const filtered = filterTestAdapters
		? sorted.filter((instance) => instance.config.metadata.name !== MOMENT_TEST_CLUSTER_NAME)
		: sorted;

	// Validate with zod
	return Instances.parse(filtered);
};

export const getAtlasControlPlanes = async (env: Env) => {
	const response = await atlasProxyFetchWithEnv(env, "/v1alpha1/controlplanes");

	if (!response.ok) {
		const body = await response.text();
		throw new Error(`Failed to fetch control planes: ${response.status} ${body}`);
	}

	const json = await response.json();

	// Validate with zod
	return Instances.parse(json);
};

export const getAtlasConfig = async (env: Env, controlPlaneID: string) => {
	const response = await atlasProxyFetchWithEnv(
		env,
		"/v1/admin/config?controlplaneID=" + controlPlaneID
	);

	if (!response.ok) {
		const body = await response.text();
		throw new Error(`Failed to fetch config: ${response.status} ${body}`);
	}

	const json = await response.json();
	const sorted = json.sort(adapterSortFn);

	// Validate with zod
	return Adapters.parse(sorted);
};

export const updateAtlasConfig = async (
	env: Env,
	controlPlaneID: string,
	config: AdapterType[]
) => {
	const response = await atlasProxyFetchWithEnv(
		env,
		`/v1/admin/config?controlplaneID=${controlPlaneID}`,
		{
			method: "POST",
			body: JSON.stringify(config),
			headers: {
				"Content-Type": "application/json",
			},
		}
	);

	if (!response.ok) {
		const body = await response.text();
		throw new Error(`Failed to update config: ${response.status} ${body}`);
	}

	const json = await response.json();
	const sorted = json.sort(adapterSortFn);

	// Validate with zod
	return Adapters.parse(sorted);
};

export const getAtlasControlPlanesWithConfig = async (env: Env) => {
	const data = await getAtlasControlPlanes(env);
	if (data === undefined) {
		return [];
	}

	const controlPlanes = data.sort(configSortFn);

	const promises = controlPlanes.map(async (controlPlane) => {
		const config = await getAtlasConfig(env, controlPlane.config.metadata.uid);
		return {
			...controlPlane,
			adapters: config.map((adapter) => ({
				config: { ...adapter },
			})),
		};
	});

	const updatedControlPlanes = await Promise.all(promises);

	return Instances.parse(updatedControlPlanes);
};

export const getAtlasAccessTokenMetadata = async (env: Env) => {
	const response = await atlasProxyFetchWithEnv(env, "/v1/admin/accesstokens/metadata");

	if (!response.ok) {
		const body = await response.text();
		throw new Error(`Failed to get atlas access token metadata : ${response.status} ${body}`);
	}

	const json = await response.json();

	return AccessTokensMetadataResponse.parse(json);
};
