import type { Organization } from "~/models/organizations";
import type { User } from "~/models/user";

export enum TrackEvents {
	PAGE_VIEW = "pageView",

	// Personal Workspace Limit
	PERSONAL_WORKSPACE_LIMIT_DIALOG_SHOWN = "personalWorkspaceLimitDialogShown",
	PERSONAL_WORKSPACE_LIMIT_DIALOG_DISMISSED = "personalWorkspaceLimitDialogDismissed",
	PERSONAL_WORKSPACE_LIMIT_DIALOG_LINK_CLICKED = "personalWorkspaceLimitDialogLinkClicked",

	// Atlas Adapters Add/Edit/Delete
	ATLAS_CONTROL_PLANES_LOAD_FAILED = "atlasAdapterGetFailed",
	ATLAS_ADAPTER_DELETED = "atlasAdapterDeleted",
	ATLAS_ADAPTER_DELETE_FAILED = "atlasAdapterDeleteFailed",
	ATLAS_ADAPTER_ADDED = "atlasAdapterAdded",
	ATLAS_ADAPTER_ADD_FAILED = "atlasAdapterAddFailed",
	ATLAS_ADAPTER_EDITED = "atlasAdapterEdited",
	ATLAS_ADAPTER_EDIT_FAILED = "atlasAdapterEditFailed",

	// Atlas Adapter Limit Dialog
	ATLAS_ADAPTER_LIMIT_DIALOG_SHOWN = "atlasAdapterLimitDialogShown",
	ATLAS_ADAPTER_LIMIT_DIALOG_DISMISSED = "atlasAdapterLimitDialogDismissed",
	ATLAS_ADAPTER_LIMIT_DIALOG_LINK_CLICKED = "atlasAdapterLimitDialogLinkClicked",

	// Share Modal - Invite By Email
	SHARE_MODAL_INVITE_BY_EMAIL_CLICKED = "shareModalInviteByEmailClicked",
	SHARE_MODAL_INVITE_USER_SUBMITTED = "shareModalInviteUserSubmitted",
	SHARE_MODAL_INVITE_BY_EMAIL_SUBMITTED = "shareModalInviteByEmailSubmitted",
	SHARE_MODAL_INVITE_BY_EMAIL_DISMISSED = "shareModalInviteByEmailDismissed",
	SHARE_MODAL_INVITE_BY_EMAIL_ACCEPTED = "shareModalInviteByEmailAccepted", // TODO add usage
}

type Properties = {
	[x: string]: unknown;
};

export interface AnalyticsInterface {
	reset(): void;
	identify(userId?: string, user?: User): void;
	group(org: Organization): void;
	track(event: TrackEvents, data?: Properties): void;
}
