import { z } from "zod";

export const AdapterKind = z.union([
	z.literal("HTTP"),
	z.literal("AWS"),
	z.literal("Moment"),
	z.literal("Postgres"), // name and databaseurl
	z.literal("Kubernetes"), // don't worry about this
	z.literal("Echo"), // don't worry about thiis
]);
export type AdapterKindType = z.infer<typeof AdapterKind>;

export const HttpAdapterName = z.union([
	z.literal("brex"),
	z.literal("datadog"),
	z.literal("github"),
	z.literal("heroku"),
	z.literal("linear"),
	z.literal("stripe"),
	z.literal("vercel"),
	z.literal("mailchimp"),
	z.literal("pagerduty"),
	z.literal("argocd"),
]);

export type HttpAdapterNameType = z.infer<typeof HttpAdapterName>;

// We should try to use the Kind if we have a unique API in atlas,
// Otherwise, fetch the HTTP adapter name.
export type AdapterNameOrKindType = AdapterKindType | HttpAdapterNameType;

export const BaseAdapterConfig = z.object({
	kind: AdapterKind,
	apiVersion: z.string(),
	metadata: z.object({
		name: z.string(),
	}),
	spec: z.object({}).optional(),
});
export type BaseAdapterConfigType = z.infer<typeof BaseAdapterConfig>;

export const AdapterHeader = z.object({
	name: z.string(),
	value: z.string(),
});
export type AdapterHeaderType = z.infer<typeof AdapterHeader>;
export const HTTPAdapterConfig = BaseAdapterConfig.extend({
	kind: z.literal("HTTP"),
	spec: z.object({
		baseUrl: z.string().optional(),
		headers: z.array(AdapterHeader).optional(),
	}),
});
export type HTTPAdapterConfigType = z.infer<typeof HTTPAdapterConfig>;

export function isAtlasHttpAdapterType(obj: unknown): obj is HTTPAdapterConfigType {
	return HTTPAdapterConfig.safeParse(obj).success;
}

const AWSProfile = z.object({
	name: z.string().optional(),
	accessKeyId: z.string().optional(),
	secretAccessKey: z.string().optional(),
	sessionToken: z.string().optional(),

	// Postgres
	databaseUrl: z.string().optional(),
});
export type AWSProfileType = z.infer<typeof AWSProfile>;
export const AWSAdapterConfig = BaseAdapterConfig.extend({
	kind: z.literal("AWS"),
	spec: z.object({
		profiles: z.array(AWSProfile),
	}),
});
export type AWSAdapterConfigType = z.infer<typeof AWSAdapterConfig>;

export const MomentAdapterConfig = BaseAdapterConfig.extend({
	kind: z.literal("Moment"),
	spec: z.object({
		catalog: z.object({
			github: z.object({
				accessToken: z.string().optional(),
				baseUrl: z.string().optional(),
			}),
		}),
	}),
});
export type MomentAdapterConfigType = z.infer<typeof MomentAdapterConfig>;

export const PostgresAdapterConfig = BaseAdapterConfig.extend({
	kind: z.literal("Postgres"),
	spec: z.object({
		profiles: z.array(z.object({ name: z.string(), databaseUrl: z.string() })),
	}),
});
export type PostgresAdapterConfigType = z.infer<typeof PostgresAdapterConfig>;

export const KubernetesAdapterConfig = BaseAdapterConfig.extend({
	kind: z.literal("Kubernetes"),
});
export type KubernetesAdapterConfigType = z.infer<typeof KubernetesAdapterConfig>;

export const EchoAdapterConfig = BaseAdapterConfig.extend({
	kind: z.literal("Echo"),
});

export const Adapter = z.discriminatedUnion("kind", [
	HTTPAdapterConfig,
	AWSAdapterConfig,
	MomentAdapterConfig,
	PostgresAdapterConfig,
	KubernetesAdapterConfig,
	EchoAdapterConfig,
]);

export type AdapterType = z.infer<typeof Adapter>;

export const Adapters = z.array(Adapter);

export const RawInstance = z.object({
	config: z.object({
		kind: z.string(), // e.g. "Atlas", which is probably not used, TODO: Remove or use
		apiVersion: z.string(),
		metadata: z.object({
			name: z.string(),
			uid: z.string(),
		}),
	}),
	adapters: z.array(z.object({ config: Adapter })).optional(),
});
export type RawInstanceType = z.infer<typeof RawInstance>;

export const InstanceMetadata = z.object({
	name: z.string(),
	uid: z.string(),
});

const Backoff = z
	.object({
		minInterval: z.number().optional(),
		maxInterval: z.number().optional(),
		factor: z.number().optional(),
		jitter: z.boolean().optional(),
	})
	.optional();

const ModuleRef = z.object({
	moduleRef: z.object({
		kind: z.string(),
		apiVersion: z.string(),
		name: z.string(),
	}),
});

const Module = z.object({
	kind: z.string(),
	apiVersion: z.string(),
	metadata: z.object({
		name: z.string(),
	}),
	spec: z.object({
		Storage: z.object({
			S3: z
				.object({
					basePath: z.string(),
					bucket: z.string(),
					region: z.string(),
				})
				.optional(),
			Disk: z
				.object({
					path: z.string(),
				})
				.optional(),
		}),
		cacheExpiration: z.number(),
	}),
});

const Limit = z.object({ limit: z.number(), softLimit: z.boolean() });
export type LimitType = z.infer<typeof Limit>;

export const Instance = z.object({
	config: z.object({
		kind: z.string(), // e.g. "Atlas", which is probably not used, TODO: Remove or use
		apiVersion: z.string(),
		metadata: InstanceMetadata,
		spec: z
			.object({
				owner: z.string().optional(),
				identityProvider: z
					.record(
						z.string(),
						z.object({
							publicKey: z.string(),
							audience: z.string(),
							issuer: z.string(),
						})
					)
					.optional(),
				exposedPorts: z
					.object({
						ingressHttpPort: z.number().optional(),
						adminPort: z.number().optional(),
					})
					.optional(),
				gatewayRegistration: z
					.object({
						registrationHostpath: z.string().optional(),
						insecure: z.boolean().optional(),
						connectionTimeout: z.number().optional(),
						connectionRetries: z.number().optional(),
						backoff: Backoff,
					})
					.optional(),
				apiAdapters: z
					.array(
						z.object({
							adapterRef: z.object({
								kind: z.string(),
								apiVersion: z.string(),
								name: z.string(),
							}),
						})
					)
					.optional(),
				controlPlaneRegistration: z
					.object({
						apiVersion: z.string(),
						registrationHostpath: z.string(),
						insecure: z.boolean(),
						connectionTimeout: z.number(),
						connectionRetries: z.number().optional(),
						backoff: Backoff,
						modules: z.array(ModuleRef).optional(),
					})
					.optional(),
				modules: z.array(ModuleRef).optional(),
			})
			.optional(),
	}),
	adapters: z
		.array(
			z.object({
				config: Adapter,
				constraint: z.any(),
			})
		)
		.optional(),
	Modules: z.array(Module).optional(),
	limits: z.array(z.record(z.string(), Limit)).optional(),
});

export type InstanceType = z.infer<typeof Instance>;

export const Instances = z.array(Instance).optional();
export type InstancesType = z.infer<typeof Instances>;

export const AdapterConfig = z.object({
	config: Adapter,
	constraint: z.any(),
});
export type AdapterConfigType = z.infer<typeof AdapterConfig>;

export const AccessTokenMetadata = z.object({
	id: z.string(),
	organizationID: z.string(),
	version: z.string(),
});
export type AccessTokenMetadataType = z.infer<typeof AccessTokenMetadata>;

export const AccessTokensMetadataResponse = z.object({
	accesstokensMetadata: z.array(AccessTokenMetadata).optional(),
	errors: z
		.array(
			z.object({
				metadata: InstanceMetadata,
				reason: z.string(),
			})
		)
		.optional(),
});
export type AccessTokensMetadataResponseType = z.infer<typeof AccessTokensMetadataResponse>;
