import z from "zod";

import { presenceSelection } from "./messageSendSelectionRequest";
import { type PresenceSelection } from "./presence";

export const messageSelectionsUpdatedBroadcast = z.object({
	type: z.literal("selections-updated-broadcast"),
	selections: z.array(presenceSelection),
});
export type MessageSelectionsUpdatedBroadcast = z.infer<typeof messageSelectionsUpdatedBroadcast>;

export function makeMessageSelectionsUpdatedBroadcast(
	selections: PresenceSelection[]
): MessageSelectionsUpdatedBroadcast {
	return {
		type: "selections-updated-broadcast",
		selections,
	};
}
