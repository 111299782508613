import z from "zod";

import { type MessageRequestUnauthorized } from "~/lib/api-types/auth";

import { type PresenceSelection } from "./presence";

export const presenceSelection = z.object({
	anchor: z.number(),
	head: z.number(),
	timestamp: z.number().optional(),
	userID: z.string(),
	tabID: z.string(),
	name: z.string(),
	lastVersionSeen: z.number(),
});
export type Selection = z.infer<typeof presenceSelection>;

export const messageSendSelectionRequest = z.object({
	type: z.literal("send-selection-request"),
	selection: presenceSelection,
});
export type MessageSendSelectionRequest = z.infer<typeof messageSendSelectionRequest>;

export function makeMessageSendSelectionRequest(
	selection: PresenceSelection
): MessageSendSelectionRequest {
	return { type: "send-selection-request", selection };
}

export const messageSendSelectionResponseAccepted = z.object({
	type: z.literal("send-selection-response-accepted"),
	selections: z.array(presenceSelection),
});
export type MessageSendSelectionResponseAccepted = z.infer<
	typeof messageSendSelectionResponseAccepted
>;

export function makeMessageSendSelectionResponseAccepted(
	selections: PresenceSelection[]
): MessageSendSelectionResponseAccepted {
	return { type: "send-selection-response-accepted", selections };
}

export const messageSendSelectionResponseRejected = z.object({
	type: z.literal("send-selection-response-rejected"),
	error: z.string(),
});
export type MessageSendSelectionResponseRejected = z.infer<
	typeof messageSendSelectionResponseRejected
>;

export function makeMessageSendSelectionResponseRejected(
	error: string
): MessageSendSelectionResponseRejected {
	return { type: "send-selection-response-rejected", error };
}

export type SendSelectionResponse =
	| MessageSendSelectionResponseAccepted
	| MessageSendSelectionResponseRejected
	| MessageRequestUnauthorized;
