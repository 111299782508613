import { type DOMOutputSpec, type NodeSpec } from "prosemirror-model";

// :: NodeSpec A hard line break, represented in the DOM as `<br>`.

const brDOM: DOMOutputSpec = ["br"];

export const hard_break: NodeSpec = {
	inline: true,
	group: "inline",
	selectable: false,
	parseDOM: [{ tag: "br" }],
	toDOM() {
		return brDOM;
	},
};
