import { atlasProxyFetchWithEnv } from "~/models/atlas";
import type { Env } from "~/utils/common";
import { assembleQueryString } from "~/utils/helpers/url";

import { ModelCache } from "./cache";

export interface PagerDutyService {
	id: string;
	escalation_policy: {
		id: string;
	};
}

export type PagerDutyOnCall = {
	user: PagerDutyUser;
	escalation_level?: number;
};

export type PagerDutyOnCalls = PagerDutyOnCall[];

export interface PagerDutySchedule {
	id: string;
}

export interface PagerDutyEscalationRule {
	targets: PagerDutySchedule[];
}
export interface PagerDutyEscalationPolicy {
	id: string;
	escalation_rules: PagerDutyEscalationRule[];
}

export interface PagerDutyUser {
	email: string;
}

export interface PagerDutyIncident {
	id: string;
	status?: string;
}

const BASE_URL = "/v1/apis/http/pagerduty";

const serviceCache = new ModelCache<PagerDutyService>();
export const getService = async (env: Env, serviceId: string) =>
	serviceCache.useCache(async () => {
		const response = await atlasProxyFetchWithEnv(env, `${BASE_URL}/services/${serviceId}`);
		const { service } = await response.json();

		return service;
	}, `${serviceId}`);

const onCallsCache = new ModelCache<PagerDutyOnCalls>();
export const getOnCalls = async (env: Env, escalationPolicyId: string) =>
	onCallsCache.useCache(async () => {
		const queryString = assembleQueryString({
			"escalation_policy_ids[]": escalationPolicyId,
			"include[]": "users",
		});

		const response = await atlasProxyFetchWithEnv(env, `${BASE_URL}/oncalls${queryString}`);
		const { oncalls } = await response.json();

		// Only return primary oncalls for now
		const filtered = oncalls.filter((oncall: PagerDutyOnCall) => oncall.escalation_level === 1);

		return filtered;
	}, `${escalationPolicyId}`);

const escalationPolicyCache = new ModelCache<PagerDutyEscalationPolicy>();
export const getEscalationPolicy = async (env: Env, escalationPolicyId: string) =>
	escalationPolicyCache.useCache(async () => {
		const response = await atlasProxyFetchWithEnv(
			env,
			`${BASE_URL}/escalation_policies/${escalationPolicyId}`
		);
		const { escalation_policy } = await response.json();

		return escalation_policy;
	}, `${escalationPolicyId}`);

const scheduleCache = new ModelCache<PagerDutyUser[]>();
export const getScheduleUsers = async (env: Env, scheduleId: string) =>
	scheduleCache.useCache(async () => {
		const response = await atlasProxyFetchWithEnv(
			env,
			`${BASE_URL}/schedules/${scheduleId}/users`
		);
		const { users } = await response.json();

		return users;
	}, `${scheduleId}`);

// 5s cache timeout - TODO poll for this info
const incidentsCache = new ModelCache<PagerDutyIncident[]>(5000);
export const getIncidents = async (env: Env, serviceId: string) =>
	incidentsCache.useCache(async () => {
		const queryString = assembleQueryString({ "service_ids[]": serviceId });
		const response = await atlasProxyFetchWithEnv(env, `${BASE_URL}/incidents?${queryString}`);
		const { incidents } = await response.json();

		return incidents.filter((incident: PagerDutyIncident) => incident.status !== "resolved");
	}, `${serviceId}`);

export const getOncallsForService = async (env: Env, serviceId: string) => {
	const service = await getService(env, serviceId);
	return getOnCalls(env, service.escalation_policy.id);
};

// Currently uses escalation_policy rules and schedules, TODO add support for teams feature
export const getTeamForService = async (env: Env, serviceId: string) => {
	const service = await getService(env, serviceId);
	const escalationPolicy = await getEscalationPolicy(env, service.escalation_policy.id);
	// Only use primary rule for now
	const scheduleId = escalationPolicy.escalation_rules[0]?.targets[0]?.id;

	if (!scheduleId) {
		return;
	}

	return await getScheduleUsers(env, scheduleId);
};
