import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { OnboardingFeature, type OnboardingState } from "~/api/generated/graphql";

export type OnboardingSliceState = {
	status: Record<OnboardingFeature, OnboardingState | null | undefined>;
};

const initialState: OnboardingSliceState = {
	status: {
		// All features are undefined by default and must be present in the state
		[OnboardingFeature.FirstLogin]: undefined,
	},
};

export const onboardingSlice = createSlice({
	name: "onboardingState",
	initialState,
	reducers: {
		setOnboardingState(
			state,
			action: PayloadAction<{
				featureName: OnboardingFeature;
				newState: OnboardingState | null;
			}>
		) {
			const { featureName, newState } = action.payload;
			state.status[featureName] = newState;
		},
	},
});

export const onboardingState = onboardingSlice.reducer;
export const { setOnboardingState: setOnboardingState } = onboardingSlice.actions;
