import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { type Canvas } from "~/models/canvas";
import type { CanvasRouteTypes } from "~/utils/helpers/url";

import { RECENTLY_VIEWED_CONFIG } from "./config";
import { type RecentlyViewedState } from "./types";

const initialState: RecentlyViewedState = {
	canvases: [],
	canvasNavigationInfos: [],
};

const recentlyViewedSlice = createSlice({
	name: "recentlyViewed",
	initialState,
	reducers: {
		setCanvasViewed: (
			state: RecentlyViewedState,
			action: PayloadAction<{
				canvasId: Canvas["id"];
				navigationInfo: CanvasRouteTypes | null;
			}>
		) => {
			const { canvasId } = action.payload;

			state.canvases = [...new Set([canvasId, ...state.canvases])].slice(
				0,
				RECENTLY_VIEWED_CONFIG.maxHistory
			);

			const canvasNavigationInfos: Record<string, CanvasRouteTypes | null> = {};
			state.canvasNavigationInfos?.forEach((info) => {
				canvasNavigationInfos[info.canvasId] = info.navigationInfo;
			});
			canvasNavigationInfos[canvasId] = action.payload.navigationInfo;

			state.canvasNavigationInfos = state.canvases.map((id) => ({
				canvasId: id,
				navigationInfo: canvasNavigationInfos[id] || {
					type: "CanvasById" as const,
					id: canvasId,
				},
			}));
		},

		clearRecentlyViewed: (state: RecentlyViewedState) => {
			state.canvases = [];
			state.canvasNavigationInfos = [];
		},

		removeRecentlyViewed: (
			state: RecentlyViewedState,
			action: PayloadAction<{ canvasId: Canvas["id"] }>
		) => {
			const input = action.payload.canvasId;
			const index = state.canvases.findIndex((id) => id === input);

			if (index > -1) {
				state.canvases.splice(index, 1);
			}

			const navigationInfoIndex = state.canvasNavigationInfos?.findIndex(
				(info) => info.canvasId === input
			);

			if (navigationInfoIndex && navigationInfoIndex > -1) {
				state.canvasNavigationInfos?.splice(navigationInfoIndex, 1);
			}
		},
	},
});

export const { setCanvasViewed, clearRecentlyViewed, removeRecentlyViewed } =
	recentlyViewedSlice.actions;

export const recentlyViewed = recentlyViewedSlice.reducer;
