import { useEffect } from "react";

type UseConfirmLeaveProps = {
	condition: boolean;
	prompt: string;
};

export const useConfirmLeave = ({ condition, prompt }: UseConfirmLeaveProps) => {
	useEffect(() => {
		const confirmHandler = (e: BeforeUnloadEvent) => {
			if (condition) {
				e.preventDefault();
				// eslint-disable-next-line no-param-reassign
				e.returnValue = prompt;
				return prompt;
			}
		};

		window.addEventListener("beforeunload", confirmHandler);

		return () => {
			/* clean up process */
			window.removeEventListener("beforeunload", confirmHandler);
		};
	}, [condition, prompt]);
};
