import { type IdToken } from "~/auth/interop";
import { type User } from "~/models/user";

export enum AuthStates {
	LoggedIn = "logged-in",
	LoggedInNoOrg = "logged-in-no-org",
	LoggedOut = "logged-out",
	Failed = "failed",
	Authenticating = "authenticating",
	WaitingForDesktopCallback = "waiting-for-desktop-callback",
}

export type AuthState = {
	state: AuthStates;
	error?: Error;
	org?: string;
	idToken?: IdToken;
	profile?: User;
	refreshToken?: string;
	accessToken?: string;
	/**
	 * This is only set when the app is running
	 * in a desktop context.
	 */
	authUrl?: string;
};
