import NextHead from "next/head";
import { type FC } from "react";

export type TwitterProps = {
	title: string;
	description: string;
	card_type: "summary" | "summary_large_image" | "player" | "app";
	creator_url: HttpString | HttpsString;
	creator_username: StringWithPrefix<"@">;
	image_url: string;
	image_alt: string;
};

export const Twitter: FC<TwitterProps> = ({
	title,
	description,
	card_type,
	creator_url,
	creator_username,
	image_url,
	image_alt,
}) => {
	return (
		<NextHead>
			{/* Twitter Open Graph meta */}
			<meta name="twitter:card" key="twitter:card" content={card_type} />
			<meta name="twitter:title" key="twitter:title" content={title} />
			<meta name="twitter:description" key="twitter:description" content={description} />
			<meta name="twitter:site" key="twitter:site" content={creator_url} />
			<meta name="twitter:creator" key="twitter:creator" content={creator_username} />
			<meta name="twitter:image" key="twitter:image" content={image_url} />
			<meta name="twitter:image:alt" key="twitter:image:alt" content={image_alt} />

			{/* disallow Twitter from using your site's info for personalization purposes */}
			<meta name="twitter:dnt" key="twitter:dnt" content="on" />
		</NextHead>
	);
};
