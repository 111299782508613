import { type HttpHeaders, getRequestID, headerRequestID } from "~/lib/http";
import { type BaseLogFunc, type LogFunc, type LoggerInterface } from "~/lib/logger-types";
import { LogTimer, type Timer } from "~/lib/logger/timer";

import { log } from "./logger";

export { type Timer } from "./timer";

// These are monitored log strings
export const METADATA_PERSISTENCE_FAILURE_LOG = "failed to persist metadata";
export const PAGE_PERSISTENCE_FAILURE_LOG = "failed to persist page";
export const CATCH_ALL_ERROR_LOG = "catch all error";

export const getTimer = (logger: LoggerInterface): Timer => {
	return new LogTimer(logger);
};

/**
 * Javascript Class for use in non-react contexts
 *
 * @param moduleName - string, name of the module to log
 * @returns Logger - class instance with debug, info, warn, and error methods
 */
export class Logger implements LoggerInterface {
	private readonly _moduleName: string;
	private readonly _env: string;
	private readonly _metadata: Record<string, unknown>;

	constructor(env: string, moduleName: string, metadata: Record<string, unknown> = {}) {
		this._env = env;
		this._moduleName = moduleName;
		this._metadata = metadata;
	}

	private readonly internalLogger: BaseLogFunc = (level, message, content) => {
		content = {
			...this._metadata,
			...content,
			env: this._env,
		};

		log(this._moduleName, message, content, level);
	};

	public initializeMetadata(metadata: Record<string, unknown>) {
		Object.assign(this._metadata, metadata);
	}

	public addRequestHeaders(httpHeaders: HttpHeaders) {
		this._metadata[headerRequestID] = getRequestID(httpHeaders);
	}

	public resetRequestHeader() {
		delete this._metadata[headerRequestID];
	}

	public debug: LogFunc = (message, content) => {
		this.internalLogger("debug", message, content);
	};

	public info: LogFunc = (message, content) => {
		this.internalLogger("info", message, content);
	};

	public warn: LogFunc = (message, content) => {
		this.internalLogger("warn", message, content);
	};

	public error: LogFunc = (message, content) => {
		this.internalLogger("error", message, content);
	};
}
