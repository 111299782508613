import { type Attrs, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "~/lib/prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "~/lib/prosemirror-utils/makeTextCellDefaultToDOMAttrs";

export const to_do_list: NodeSpec = {
	content: "to_do_item*",
	group: "block",
	attrs: { ...CELL_SPEC_ATTRS },
	parseDOM: [
		{
			// Needs higher priority than other nodes that use a "ul" tag
			priority: 51,
			tag: '[data-type="todo_list"]',
		},
	],
	toDOM(_node: Node) {
		const attrs: Attrs = {
			...makeTextCellDefaultToDOMAttrs(_node, "to-do"),
			"data-type": "todo_list",
		};

		return ["ul", attrs, 0];
	},
};
