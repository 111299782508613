import router from "next/router";
import {
	type FC,
	type PropsWithChildren,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { useCreateServiceDetail } from "~/components/canvas/easel/service-detail/useCreateServiceDetail";
import { useResponsive } from "~/components/canvas/hooks/useResponsive";
import { PROPERTIES_PANE_STATE_PARAM } from "~/components/navigation/constants";
import { Flags, isEnabled } from "~/features";
import { selectIsMomentUser } from "~/store/auth/selectors";
import {
	selectCanvasHasCommentPermissions,
	selectCanvasHasWritePermissions,
} from "~/store/canvas/selectors";
import {
	type PropertiesPaneState,
	type PropertiesPaneStateValues,
	isPane,
} from "~/store/canvas/types";
import { useAppSelector } from "~/store/hooks";
import { LOCAL_STORAGE_KEYS, PANE_IDS, getItemById, setItemById } from "~/utils/storage";

import { PropertiesPaneContext, type State } from "./context";

const PULSE_DURATION = 750; // intentionally shorter than the 1s pulse animation
const DEFAULT_PANE_STATE_WRITE: PropertiesPaneState = { pane: "components" };
const DEFAULT_PANE_STATE_READ: PropertiesPaneState = { pane: undefined };

export const PropertiesPaneProvider: FC<PropsWithChildren> = ({ children }) => {
	const hasWritePermissions = useAppSelector(selectCanvasHasWritePermissions);
	const [paneState, _setPaneState] = useState<PropertiesPaneState>(
		hasWritePermissions ? DEFAULT_PANE_STATE_WRITE : DEFAULT_PANE_STATE_READ
	);
	const [paneTogglesState, setPaneTogglesState] = useState<
		Record<(typeof PropertiesPaneStateValues)[number], boolean>
	>({
		inspect: false,
		components: false,
		integrations: false,
		comments: false,
		serviceDetail: false,
		history: false,
		releases: false,
		cellDependencies: false,
		help: false,
	});
	// Ref is needed to avoid races between the state and the setPane function
	const paneTogglesStateRef = useRef(paneTogglesState);
	paneTogglesStateRef.current = paneTogglesState;
	const [pulsedPane, setPulsedPane] = useState<PropertiesPaneState>({ pane: undefined });
	const { isSm } = useResponsive();
	const hasCommentPermissions = useAppSelector(selectCanvasHasCommentPermissions);
	const serviceDetail = useCreateServiceDetail();
	const isMomentUser = useAppSelector(selectIsMomentUser);
	const pulseTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

	const paneEnabledState: Record<(typeof PropertiesPaneStateValues)[number], boolean> = useMemo(
		() => ({
			inspect: hasWritePermissions,
			components: hasWritePermissions,
			integrations: hasWritePermissions,
			comments: hasCommentPermissions && isEnabled(Flags.Comments),
			serviceDetail:
				hasWritePermissions && serviceDetail.activeServiceDefinition !== undefined,
			history: hasWritePermissions,
			releases: isEnabled(Flags.CanvasReleases),
			cellDependencies: true,
			help: isEnabled(Flags.InspectPaneHelpMenu),
		}),
		[
			hasWritePermissions,
			hasCommentPermissions,
			serviceDetail.activeServiceDefinition,
			isMomentUser,
		]
	);

	const paneStateRef = useRef(paneState);
	paneStateRef.current = paneState;

	const setPaneState = useCallback((p: PropertiesPaneState) => {
		if (isSm) {
			setItemById(LOCAL_STORAGE_KEYS.PANES, PANE_IDS.PROPERTIES_PANE, p, 0);
		}

		//
		// IMPORTANT: If you change this, check whether the highlights are removed in the following
		// scenarios:
		//
		//   1. Highlight a cell (text range, compute cell), click the comment button, then close
		//      the pane.
		//   2. Highlight a cell, click the comment button, then click the cancel button in the
		//      comment UI.
		//   3. Highlight a cell, click the comment button, then highlight a different cell and
		//      click that comment button.
		//   4. Commenting on a cell and then switching panes cancels the highlight.
		//   5. Do all of the above for comments on text ranges, the cell controls, and on compute
		//      cells.
		//
		//

		const commentsPaneCurrentlyOpen = paneStateRef.current.pane === "comments";
		const closingPane = p.pane === undefined;
		const cancellingCommentOnCell = p.pane === "comments" && !p.metadata;
		const changingPanes = p.pane !== "comments";
		if (
			commentsPaneCurrentlyOpen &&
			(closingPane || cancellingCommentOnCell || changingPanes)
		) {
			paneStateRef.current.metadata?.unHighlightCommentMark?.cb?.();
		}

		//
		// Set the state.
		//

		_setPaneState(p);
	}, []);

	const closePane = useCallback(() => {
		setPaneState({ pane: undefined });
	}, [setPaneState]);

	const setPane = useCallback(
		(p: PropertiesPaneState) => {
			const { pane } = p;
			if (pane === paneState.pane) {
				setPulsedPane(p);
				pulseTimeoutRef.current = setTimeout(() => {
					setPulsedPane({ pane: undefined });
				}, PULSE_DURATION);
			}

			setPaneState(p);
		},
		[paneState.pane]
	);

	const togglePane = useCallback(
		(p: PropertiesPaneState) => {
			const { pane } = p;
			const isPaneCurrent = paneStateRef.current.pane === pane;

			setPaneState(isPaneCurrent ? { pane: undefined } : p);
		},
		[setPaneState]
	);

	const togglePaneToggle = useCallback(
		(pane: (typeof PropertiesPaneStateValues)[number]) => {
			setPaneTogglesState((prev) => {
				const newToggleState = !prev[pane];

				if (newToggleState) {
					setPane({ pane });
				} else if (paneState.pane === pane) {
					// Close if currently open and setting state to false
					closePane();
				}

				return {
					...prev,
					[pane]: newToggleState,
				};
			});
		},
		[paneState.pane, closePane, setPane]
	);

	const context: State = useMemo(
		() => ({
			paneState,
			closePane,
			setPane,
			togglePane,
			paneEnabledState,
			pulsedPane,
			paneTogglesState,
			togglePaneToggle,
		}),
		[
			paneState,
			closePane,
			setPane,
			togglePane,
			paneEnabledState,
			pulsedPane,
			togglePaneToggle,
			paneTogglesState,
		]
	);

	useEffect(() => {
		// On mount, set the default pane state for sm screens and above
		if (isSm) {
			const localStoragePaneState = getItemById<PropertiesPaneState>(
				LOCAL_STORAGE_KEYS.PANES,
				PANE_IDS.PROPERTIES_PANE
			);

			const { [PROPERTIES_PANE_STATE_PARAM]: paneToOpen } = router.query;
			// We don't want to close the pane if paneToOpen = undefined, so ignore undefined here
			if (paneToOpen && isPane(paneToOpen)) {
				setPane({ pane: paneToOpen });
			} else if (
				localStoragePaneState?.value.pane &&
				paneEnabledState[localStoragePaneState.value.pane]
			) {
				setPane(localStoragePaneState?.value);
			}
		}

		return () => {
			clearTimeout(pulseTimeoutRef.current);
		};
	}, []);

	return (
		<PropertiesPaneContext.Provider value={context}>{children}</PropertiesPaneContext.Provider>
	);
};
