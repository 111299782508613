import { createSelector } from "@reduxjs/toolkit";

import { type RootState } from "../store";
import { AuthStates } from "./types";

export const selectUserID = (state: RootState) => {
	return state.auth.profile?.id;
};

export const selectOrgID = (state: RootState) => {
	const idTokenOrg = state.auth.idToken?.["org"] as string;
	return idTokenOrg || state.auth.org;
};

export const selectIsMomentUser = createSelector(
	[selectOrgID],
	(orgID) => orgID === "org_JViWr7f3J" || orgID === "moment.dev"
);

export const selectAuth = (state: RootState) => {
	return state.auth;
};

export const selectIDToken = (state: RootState) => {
	return state.auth.idToken;
};

export const selectProfile = (state: RootState) => {
	return state.auth.profile;
};

export const selectCurrentUserEmailAddress = (state: RootState) => {
	const email = state.auth.idToken?.["http://api.moment.dev/email"];
	return String(email);
};

export const selectIsCypressUser = createSelector(
	[selectCurrentUserEmailAddress],
	(email) => email === "engineering+e2e-test@moment.dev"
);

export const selectAuthState = (state: RootState) => {
	if (state.auth.state === AuthStates.LoggedIn && !state.auth.org) {
		return AuthStates.LoggedInNoOrg;
	}

	return state.auth.state;
};

export const selectAuthToken = (state: RootState) => {
	if (state.auth.state === AuthStates.LoggedIn && !state.auth.org) {
		return undefined;
	}
	return state.auth.accessToken;
};

export const selectAuthError = (state: RootState) => {
	return state.auth.error;
};

export const selectAuthUrl = (state: RootState) => {
	return state.auth.authUrl;
};
