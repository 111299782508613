import { useCallback, useEffect, useState } from "react";

import { fetchService } from "~/models/serviceCatalog";
import { type ServiceCatalogType, type ServiceType } from "~/models/serviceCatalog/schema";
import { type GetServiceDetailReturnArgMatches } from "~/runtime/cell-compiler/analysis/getServiceDetailPattern";
import { serviceNameFromUrlRoute } from "~/runtime/global-moment/getServiceDetailsFromUrlRoute";
import { selectOrderedComputeCells } from "~/store/cells/selectors";
import { selectEnv } from "~/store/client/selectors";
import { useAppSelector } from "~/store/hooks";

export type GetServiceCatalogFunc = () => Promise<ServiceCatalogType | undefined>;

export type GetServiceFunc = (name?: string) => Promise<ServiceType | undefined>;

export const useCreateServiceDetail = () => {
	const clientEnv = useAppSelector(selectEnv);
	const [loading, setLoading] = useState(false);
	const [serviceDefinitionMap, setServiceDefinitionMap] = useState<
		Record<string, ServiceType> | undefined
	>({});
	const [selectedServiceDefinition, setSelectedServiceDefinition] = useState<string>();
	const [error, setError] = useState("");
	const [showSidebar, setShowSidebar] = useState(true);
	const activeServiceDefinition: ServiceType | undefined =
		selectedServiceDefinition && serviceDefinitionMap
			? serviceDefinitionMap[selectedServiceDefinition]
			: undefined;
	const computeCells = useAppSelector(selectOrderedComputeCells);

	const getServiceDefinition: GetServiceFunc = useCallback(
		async (name) => {
			setLoading(true);
			setError("");
			const errorMessage = `Could not find service "${name}" `;

			if (name === undefined) {
				setError(errorMessage);
				setLoading(false);
				return;
			}

			// This try/catch is necessary to make sure the loading state is set correctly below
			try {
				const service = await fetchService(clientEnv, name);

				if (!service) {
					setError(errorMessage);
					setLoading(false);
					return;
				}

				setServiceDefinitionMap((s) => ({
					...s,
					// definition.id is checked above, but TS is complaining inside this arrow function
					[name]: service,
				}));

				setLoading(false);

				return service;
			} catch (e) {
				setError(errorMessage);
				setLoading(false);
			}
		},
		[setServiceDefinitionMap, setError, setLoading]
	);

	const removeServices = useCallback(
		(keys: string[]) => {
			const serviceDefinitionMapCopy = { ...serviceDefinitionMap };

			keys.forEach((key) => {
				delete serviceDefinitionMapCopy[key];

				if (selectedServiceDefinition === key) {
					setSelectedServiceDefinition(undefined);
				}
			});

			setServiceDefinitionMap(
				Object.keys(serviceDefinitionMapCopy).length ? serviceDefinitionMapCopy : undefined
			);
		},
		[setServiceDefinitionMap, setSelectedServiceDefinition, serviceDefinitionMap]
	);

	const getServiceDetailArgsFromComputeCells = () => {
		// Assemble map of service details from compute cells in canvas
		let services: GetServiceDetailReturnArgMatches = {};
		computeCells.forEach((cell) => {
			services = {
				...services,
				...cell.analysis?.serviceDetails,
			};
		});

		let fetchServiceDetailsFromUrlRoute = false;
		for (const cell of computeCells) {
			if (cell.analysis?.fetchServiceDetailsFromUrlRoute) {
				fetchServiceDetailsFromUrlRoute = true;
				break;
			}
		}

		// Check the list against the current list of service definitions
		const servicesToRemove: string[] = [];
		const currentServiceIds = Object.values(serviceDefinitionMap || {}).map(
			(s) => s.metadata.name
		);

		currentServiceIds.forEach((id) => {
			if (
				id &&
				!Object.values(services)
					.map(({ name }) => {
						if (!name.value) {
							return;
						}

						return name.value;
					})
					.includes(id)
			) {
				servicesToRemove.push(id);
			}
		});

		removeServices(servicesToRemove);

		// Get the service details for each service
		Object.values(services).forEach((args) => getServiceDefinition(args.name.value));

		const serviceName = serviceNameFromUrlRoute();
		if (fetchServiceDetailsFromUrlRoute && serviceName) {
			void getServiceDefinition(serviceName);
		}
	};

	useEffect(() => {
		getServiceDetailArgsFromComputeCells();
	}, [computeCells]);

	useEffect(() => {
		if (!serviceDefinitionMap) return;

		const keys = Object.keys(serviceDefinitionMap);

		if (!selectedServiceDefinition) {
			setSelectedServiceDefinition(keys[0]);
		}
	}, [selectedServiceDefinition, serviceDefinitionMap]);

	return {
		loading,
		getServiceDefinition,
		serviceDefinitionMap,
		setSelectedServiceDefinition,
		activeServiceDefinition,
		removeServices,
		showSidebar: showSidebar && activeServiceDefinition !== undefined,
		setShowSidebar,
		error,
	};
};
