import z from "zod";

import { type ActiveUser, activeUser } from "./activeUser";

//
// Active User Broadcast Broadcast for metadata-server.
//

export const metadataMessageActiveUsersBroadcast = z.object({
	type: z.literal("active-users-broadcast"),
	activeUsers: z.array(activeUser),
});
export type MetadataMessageActiveUsersBroadcast = z.infer<
	typeof metadataMessageActiveUsersBroadcast
>;

export function makeMetadataMessageActiveUsersBroadcast(
	activeUsers: ActiveUser[]
): MetadataMessageActiveUsersBroadcast {
	return { type: "active-users-broadcast", activeUsers };
}
