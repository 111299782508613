import { type AnyAction } from "@reduxjs/toolkit";
import { type Dispatch } from "redux";

import { Logger } from "~/utils/logging";

const logger = new Logger("store/middlewares");

export const exceptionHandler = (log: boolean) => {
	return () => (next: Dispatch) => (action: AnyAction) => {
		try {
			return next(action);
		} catch (error) {
			if (log) {
				logger.error("exceptionHandler: Redux error", { action, error });
			}
			throw error;
		}
	};
};
