import z from "zod";

import { stateTransaction } from "./StateTransaction";

export const transactionProcessedSuccess = z.object({
	type: z.literal("success"),
	transaction: stateTransaction,
});
export type TransactionProcessedSuccess = z.infer<typeof transactionProcessedSuccess>;

export const transactionProcessedReplacement = z.object({
	type: z.literal("replacement"),
	transaction: stateTransaction,
});
export type TransactionProcessedReplacement = z.infer<typeof transactionProcessedReplacement>;

export const transactionProcessedConflict = z.object({
	type: z.literal("error"),
	error: z.literal("conflict"),
	message: z.string(),
	transaction: stateTransaction,
});
export type TransactionProcessedConflict = z.infer<typeof transactionProcessedConflict>;

export const transactionProcessedValidationError = z.object({
	type: z.literal("error"),
	error: z.literal("validation-failure"),
	message: z.string(),
	transaction: stateTransaction,
});
export type TransactionProcessedValidationError = z.infer<
	typeof transactionProcessedValidationError
>;

export const transactionProcessedUnknownError = z.object({
	type: z.literal("error"),
	error: z.literal("unknown"),
	message: z.string(),
	transaction: stateTransaction,
});
export type TransactionProcessedUnknownError = z.infer<typeof transactionProcessedUnknownError>;
export const transactionProcessedPersistenceFailing = z.object({
	type: z.literal("error"),
	error: z.literal("persistence-failing"),
	message: z.string(),
	transaction: stateTransaction,
});
export const transactionProcessedError = z.discriminatedUnion("error", [
	transactionProcessedConflict,
	transactionProcessedValidationError,
	transactionProcessedUnknownError,
	transactionProcessedPersistenceFailing,
]);
export type TransactionProcessedError = z.infer<typeof transactionProcessedError>;

export const transactionProcessedEvent = z.union([
	transactionProcessedSuccess,
	transactionProcessedError,
]);
export type TransactionProcessedEvent = z.infer<typeof transactionProcessedEvent>;
export type BroadcastTransactionProcessedEvent = z.infer<typeof transactionProcessedSuccess>;
