import { type JsonType } from "posthog-js";
import { useEffect, useState } from "react";

import { Logger } from "~/utils/logging";

import { posthog } from "../analytics/posthog-js";

type Properties = {
	[x: string]: unknown;
};

/**
 * Configure new keys for feature flags here.
 * @see https://posthog.com/docs/feature-flags/creating-feature-flags
 *
 * Make sure there's a feature flag value for each environment
 * (Development, Staging, and Production) in posthog.
 *
 */
export enum ConfigKeys {
	ONBOARDING_CANVAS = "onboardingCanvas",

	PERSONAL_WORKSPACE_CANVAS_LIMIT = "personalOrgCanvasLimit",

	COLLAB_EDITING = "collabEditing",

	ENABLE_OPENSEARCH = "enableOpensearch",
}

const APP_ENV = process.env.APP_ENV;

const DEFAULT_CONFIG: Record<NonNullable<MomentAppEnv>, Record<ConfigKeys, JsonType>> = {
	local: {
		onboardingCanvas: null,
		personalOrgCanvasLimit: 100,
		collabEditing: true,
		enableOpensearch: false,
	},
	staging: {
		onboardingCanvas: null,
		personalOrgCanvasLimit: 100,
		collabEditing: true,
		enableOpensearch: true,
	},
	production: {
		onboardingCanvas: null,
		personalOrgCanvasLimit: 100,
		collabEditing: true,
		enableOpensearch: false,
	},
	testing: {
		onboardingCanvas: null,
		personalOrgCanvasLimit: 100,
		collabEditing: true,
		enableOpensearch: false,
	},
};

const logger = new Logger("config");

export const getConfig = async (key: ConfigKeys) => {
	const fallback = DEFAULT_CONFIG[APP_ENV ?? "local"][key];

	try {
		const payload = await posthog.getFeatureFlagPayload(key);

		if (payload === undefined) {
			if (APP_ENV !== "local") {
				logger.info("getConfig: empty payload, returning fallback", {
					key,
					APP_ENV,
					fallback,
				});
			}
			return fallback;
		}

		return payload;
	} catch (e) {
		logger.error("getConfig: Error in getFeatureFlagPayload, returning fallback", {
			error: `${e}`,
			key,
			APP_ENV,
			fallback,
		});
		return fallback;
	}
};

export const useGetConfig = (key: ConfigKeys) => {
	const [config, setConfig] = useState<JsonType | null>(null);
	useEffect(() => {
		void (async () => {
			setConfig(await getConfig(key));
		})();
	}, [key]);

	return config;
};

export const getDefaultConfig = (key: ConfigKeys) => {
	return Boolean(DEFAULT_CONFIG[APP_ENV ?? "local"][key]);
};

// Okay, so unsure if this is ideal.
// What I noticed is that posthog will get the default value first when calling just `isFeatureEnabled`,
// even if we know who the user is. Then it will get the specific cohort value on a second call.
// This does not work with useMemo or useEffect. However, we do not want to be calling posthog on every render.
// onFeatureFlags get called once all the flags are loaded, which guarantees that we get the cohort value.
export const onFeatureFlags = (key: ConfigKeys, properties: Properties, onEnabled: () => void) => {
	// this ensures the properties that the feature flag is based on are loaded
	const fallback = getDefaultConfig(key);
	posthog.setPersonPropertiesForFlags(properties);
	posthog.onFeatureFlags(async () => {
		let result = fallback;

		try {
			const payload = await posthog.isFeatureEnabled(key, { send_event: true });
			result = payload ?? fallback;

			if (result) {
				onEnabled();
			}
		} catch (e) {
			logger.error("isFeatureEnabled: Error in isFeatureEnabled, returning false", {
				error: `${e}`,
				key,
			});
		}
	});
};
