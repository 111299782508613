import { type DOMOutputSpec, type MarkSpec } from "prosemirror-model";

const kbdDOM: DOMOutputSpec = [
	"kbd",
	{
		class: [
			"px-1",
			"text-secondary",
			"text-sm",
			"font-mono",
			"font-normal",
			"border",
			"rounded",
			"uppercase",
		].join(" "),
	},
	0,
];

export const kbd: MarkSpec = {
	inclusive: false,
	parseDOM: [{ tag: "kbd" }],
	toDOM() {
		return kbdDOM;
	},
};
