import { z } from "zod";

// Service Definition Schema
export const Kind = z.enum(["Service", "Group"]);

export const Links = z
	.array(
		z.object({
			label: z.string({
				required_error: "link label is required",
				invalid_type_error: "link label must be a string",
			}),
			url: z.string({
				required_error: "link url is required",
				invalid_type_error: "link url must be a string",
			}),
		})
	)
	.optional();

export const Tags = z.array(z.string()).optional().nullable();

export const Metadata = z.object({
	name: z.string({
		required_error: "name is required",
		invalid_type_error: "name must be a string",
	}),
	description: z.string().optional(),
	tags: Tags,
	links: Links,
});

export const Contact = z.array(
	z.object({
		type: z.enum(["slack"]).optional(),
		workspace: z.string().optional(),
		channels: z
			.array(
				z.object({
					channel: z.string().optional(),
					id: z.string().optional(),
				})
			)
			.optional(),
	})
);

export const User = z.object({
	name: z.string().optional(),
	email: z.string().optional(),
});

export const Users = z.array(User);

export const Owners = z
	.array(
		z.object({
			groupRef: z.object({
				name: z.string(),
				members: Users,
			}),
		})
	)
	.optional();

export const OncallMembers = z.array(
	z.object({
		user: z.object({ email: z.string() }),
	})
);

export const Oncall = z
	.array(
		z.object({
			domain: z.string().optional(),
			serviceId: z.string().optional(),
			type: z.string().optional(),
			members: OncallMembers.optional(),
			incidents: z.array(z.object({ id: z.string() })).optional(),
		})
	)
	.optional();

export const Repo = z.object({
	type: z.string().optional(),
	org: z.string().optional(),
	path: z.string().optional(),
	repo: z.string().optional(),
});

export const Service = z.object({
	apiVersion: z.string().optional(),
	kind: Kind.optional(),
	metadata: Metadata,
	spec: z.object({
		lifecycle: z.enum(["production", "staging"]).optional(),
		contact: Contact.optional(),
		oncall: Oncall,
		owners: Owners,
		repos: z.array(Repo).optional(),
		environments: z.any().optional(),
	}),
});

export const Group = z.object({
	apiVersion: z.string().optional(),
	kind: Kind.optional(),
	metadata: Metadata.optional(),
	spec: z
		.object({
			contact: Contact,
			users: Users,
		})
		.optional(),
});

// Service Definitions Schema
export const ServiceCatalog = z.object({
	services: z.array(Service).optional(),
	groups: z.array(Group).optional(),
});

export type ServiceType = z.infer<typeof Service>;
export type ServiceCatalogType = z.infer<typeof ServiceCatalog>;
export type RepoType = z.infer<typeof Repo>;
export type LinksType = z.infer<typeof Links>;
export type ContactType = z.infer<typeof Contact>;
export type OwnersType = z.infer<typeof Owners>;
export type OncallType = z.infer<typeof Oncall>;
export type TagsType = z.infer<typeof Tags>;
export type OncallMembersType = z.infer<typeof OncallMembers>;
