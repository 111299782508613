import type { Env } from "~/utils/common";

import { type GetServiceDetails, getServiceDetails } from "./getServiceDetails";

export const getServiceDetailsFromUrlRoute = async (env: Env): ReturnType<GetServiceDetails> => {
	const serviceName = serviceNameFromUrlRoute();
	if (!serviceName) {
		throw new Error(
			`\`serviceDetails.fromUrlRoute\` requires page to be at route /catalog/services/<serviceName>, got ${window.location.pathname}`
		);
	}

	return getServiceDetails({ env, name: serviceName });
};

export type CatalogRouteInfo =
	| { type: "catalogIndexPage" }
	| { type: "catalogServicePage"; serviceName: string };

export const catalogInfoFromUrlRoute = (): CatalogRouteInfo | undefined => {
	// URL of the form: /catalog/services/<serviceName>/...
	//
	// Note that there may be a page slug at the end of the URL, so there may be stuff
	// after the `serviceName`.
	const [empty, catalog, services, serviceName] = window.location.pathname.split("/");
	if (empty !== "" || catalog !== "catalog") {
		return;
	}

	if (!services && !serviceName) {
		return { type: "catalogIndexPage" };
	} else if (services && serviceName) {
		return { type: "catalogServicePage", serviceName };
	}

	return undefined;
};

export const serviceNameFromUrlRoute = (): string | undefined => {
	// URL of the form: /catalog/services/<serviceName>/...
	//
	// Note that there may be a page slug at the end of the URL, so there may be stuff
	// after the `serviceName`.
	const [empty, catalog, services, serviceName] = window.location.pathname.split("/");
	if (empty !== "" || catalog !== "catalog" || services !== "services" || !serviceName) {
		return;
	}

	return serviceName;
};
