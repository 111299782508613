import { combineReducers } from "redux";

import { auth } from "./auth/slice";
import { canvas } from "./canvas/slice";
import { canvases } from "./canvases/canvases";
import { cells } from "./cells/slice";
import { client } from "./client/slice";
import { codeEditor } from "./code-editor/slice";
import { desktopApp } from "./desktop-app/slice";
import { drafts } from "./drafts/slice";
import { importedCanvases } from "./imported-canvases/slice";
import { onboardingState } from "./onboarding/slice";
import { organizations } from "./organizations/slice";
import { permissions } from "./permissions/slice";
import { recentlyViewed } from "./recently-viewed/slice";
import { reducer as roles } from "./roles/slice";
import { users } from "./users/slice";
import { versions } from "./versions/slice";

export const reducer = combineReducers({
	auth,
	canvas,
	canvases,
	cells,
	client,
	codeEditor,
	desktopApp,
	importedCanvases,
	organizations,
	permissions,
	recentlyViewed,
	users,
	versions,
	drafts,
	roles,
	onboardingState,
});
