import { type Organization } from "~/models/organizations";

import { type RootState } from "../store";

export const selectOrganization = (orgID?: string) => {
	return (state: RootState): Organization | undefined => {
		if (orgID === undefined) {
			return;
		}

		return state.organizations.organizations[orgID];
	};
};
