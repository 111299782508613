import z from "zod";

export const pageMetadata = z.object({
	id: z.string(),
	slug: z.string(),
	title: z.string(),
	archived: z.boolean(),
	authorID: z.string(),
	canvasID: z.string(),
	created: z.string(),
	updated: z.string(),
	parentPageID: z.string().nullable(),
});

export type PageMetadata = z.infer<typeof pageMetadata>;
