import { PluginKey } from "prosemirror-state";

export type PresenceSelection = {
	anchor: number;
	head: number;
	timestamp?: number;
	userID: string;
	tabID: string;
	name: string;
	lastVersionSeen: number;
};
export const presencePluginKey = new PluginKey<PresenceSelection[]>("presence");
