import { gql } from "@apollo/client";

/*
 * Fragments
 *
 */

export const CELL_FIELDS = gql`
	fragment CellFields on Cell {
		type
		cellName
		code
		signoffRequired
		pinned
		id
		version
		nodeAttrs
	}
`;

export const USER_FIELDS = gql`
	fragment UserFields on User {
		id
		organizationID
		name
		nickname
		givenName
		familyName
		profilePicture
		intercomUserHash
		email
		updatedAt
		alternateIDs {
			google
			github
		}
	}
`;

export const CANVAS_FIELDS = gql`
	${USER_FIELDS}
	${CELL_FIELDS}
	fragment CanvasFields on Canvas {
		type
		apiVersion
		channel
		id
		title
		created
		updated
		updatedBy
		pages {
			id
			title
			slug
			authorID
			canvasID
			created
			updated
			archived
			cells {
				...CellFields
			}
			latestVersion
			parentPageID
		}
		owner
		organization
		archived
		pinnedAt
		isTemplate
		isDraft
		sharedName {
			sharedName
			namespace
			organizationID
		}
		version {
			id
			name
			created
			autosave
			description
			author {
				...UserFields
			}
		}
		atlasUserToken
	}
`;

export const CANVAS_FIELDS_WITH_VERSION = gql`
	${CANVAS_FIELDS}
	${USER_FIELDS}
	fragment CanvasFieldsWithVersion on Canvas {
		...CanvasFields
		version {
			id
			name
			created
			autosave
			description
			author {
				...UserFields
			}
		}
	}
`;

export const CANVAS_ROLES_FIELDS = gql`
	fragment CanvasRolesFields on CanvasRoles {
		canvasID
		roles {
			role {
				name
				description
				displayName
				permissions
				inviteDescription
			}
			serviceAccount
			serviceAccountType
			sharedName
			recipient
			inviteToken
			unlisted
			externalOrganizationID
		}
		sharedName
	}
`;

export const ORGANIZATION_FIELDS = gql`
	fragment OrganizationFields on Organization {
		id
		name
		domainNames
		owner
	}
`;

/*
 * User queries and mutations
 */

export const USER_ORG = gql`
	query UserOrg($id: ID!) {
		user(id: $id) {
			id
			organizationID
		}
	}
`;

export const USER = gql`
	${USER_FIELDS}
	query User($id: ID!) {
		user(id: $id) {
			...UserFields
		}
	}
`;

export const USERS_BY_EMAIL = gql`
	${USER_FIELDS}
	query UsersByEmail($email: String!) {
		usersByEmail(email: $email) {
			...UserFields
		}
	}
`;

export const SET_USER_INFO = gql`
	${USER_FIELDS}
	mutation UpdateUserInfo($id: ID!, $input: UserInfoInput!) {
		updateUserInfo(id: $id, input: $input) {
			...UserFields
		}
	}
`;

/*
 * Core canvas queries and mutations
 */

export const NEW_CANVAS = gql`
	mutation NewCanvas($title: String!) {
		newCanvas(input: { type: "Canvas", apiVersion: "v1", channel: "alpha-1", title: $title })
	}
`;

export const DUPLICATE_CANVAS = gql`
	mutation DuplicateCanvas($id: ID!, $title: String!, $version: Int!) {
		duplicateCanvas(id: $id, title: $title, version: $version)
	}
`;

export const DUPLICATE_CANVAS_V2 = gql`
	mutation DuplicateCanvasV2($id: ID!, $title: String!) {
		duplicateCanvasV2(id: $id, title: $title)
	}
`;

export const MAKE_CANVAS_PUBLIC = gql`
	${CANVAS_ROLES_FIELDS}
	mutation MakeCanvasPublic($id: ID!) {
		makeCanvasPublic(id: $id) {
			...CanvasRolesFields
		}
	}
`;

export const MAKE_CANVAS_NOT_PUBLIC = gql`
	${CANVAS_ROLES_FIELDS}
	mutation MakeCanvasNotPublic($id: ID!) {
		makeCanvasNotPublic(id: $id) {
			...CanvasRolesFields
		}
	}
`;

export const MAKE_CANVAS_PUBLIC_UNLISTED = gql`
	${CANVAS_ROLES_FIELDS}
	mutation MakeCanvasPublicUnlisted($id: ID!) {
		makeCanvasPublicUnlisted(id: $id) {
			...CanvasRolesFields
		}
	}
`;

export const CANVAS_BY_SHARED_NAME = gql`
	${CANVAS_FIELDS}
	query CanvasBySharedName($namespace: String!, $sharedName: String!) {
		canvasBySharedName(namespace: $namespace, sharedName: $sharedName) {
			title
			...CanvasFields
		}
	}
`;

export const CANVAS_BY_VERSION = gql`
	${CANVAS_FIELDS}
	query CanvasByVersion($id: ID!, $version: Int!) {
		canvasByVersion(id: $id, version: $version) {
			title
			...CanvasFields
		}
	}
`;

export const SAVE_CANVAS = gql`
	${CANVAS_FIELDS_WITH_VERSION}
	mutation SaveCanvas($id: ID!, $title: String!, $pages: [PageInput!]!) {
		saveCanvas(id: $id, title: $title, pages: $pages) {
			...CanvasFieldsWithVersion
		}
	}
`;

export const SAVE_CANVAS_DRAFT = gql`
	mutation SaveCanvasDraft($id: ID!, $title: String!, $pages: [PageInput!]!, $version: Int) {
		saveCanvasDraft(id: $id, title: $title, pages: $pages, version: $version)
	}
`;

export const CanvasListing = gql`
	fragment CanvasListing on CanvasesOutput {
		lastKey
		canvases {
			id
			organizationID
			ownerID
			title
			createdAt
			updatedAt
			pinnedAt
			version
		}
	}
`;

export const CANVASES = gql`
	${CanvasListing}
	query Canvases {
		canvases {
			...CanvasListing
		}
	}
`;

export const PUBLIC_CANVASES = gql`
	${CanvasListing}
	query PublicCanvases {
		publicCanvases {
			...CanvasListing
		}
	}
`;

export const CANVAS = gql`
	${CANVAS_FIELDS_WITH_VERSION}
	query Canvas($id: ID!) {
		canvas(id: $id) {
			...CanvasFieldsWithVersion
		}
	}
`;

export const PERMANENTLY_DELETE_CANVAS = gql`
	mutation PermanentlyDeleteCanvas($id: ID!) {
		deleteArchivedCanvas(id: $id)
	}
`;

export const PIN_CANVAS = gql`
	${CANVAS_FIELDS}
	mutation PinCanvas($id: ID!) {
		pinCanvas(id: $id) {
			...CanvasFields
		}
	}
`;

export const UNPIN_CANVAS = gql`
	${CANVAS_FIELDS}
	mutation UnpinCanvas($id: ID!) {
		unpinCanvas(id: $id) {
			...CanvasFields
		}
	}
`;

export const ALL_CANVAS_VERSIONS = gql`
	${CANVAS_FIELDS_WITH_VERSION}
	query CanvasAllVersions2($id: ID!, $ascending: Boolean!) {
		canvasAllVersions2(id: $id) {
			id
			canvasID
			created
			name
			description
			authorID
		}
	}
`;

export const REVERT_CANVAS_VERSION = gql`
	${CANVAS_FIELDS_WITH_VERSION}
	mutation Revert($id: ID!, $version: Int!) {
		revert(id: $id, version: $version) {
			...CanvasFieldsWithVersion
		}
	}
`;

export const UPDATE_CANVAS_VERSION_FIELDS = gql`
	${CANVAS_FIELDS_WITH_VERSION}
	mutation UpdateVersionFields(
		$canvasID: ID!
		$versionID: Int!
		$versionName: String!
		$versionDescription: String!
	) {
		updateVersionFields(
			canvasID: $canvasID
			versionID: $versionID
			versionName: $versionName
			versionDescription: $versionDescription
		) {
			...CanvasFieldsWithVersion
		}
	}
`;

/*
 * Canvas roles queries and mutations
 */

export const CANVAS_ROLES = gql`
	${CANVAS_ROLES_FIELDS}
	query CanvasRoles($id: ID!) {
		canvasRoles(id: $id) {
			...CanvasRolesFields
		}
	}
`;

export const CANVAS_ROLES_SCHEMA = gql`
	query CanvasRolesSchema {
		canvasRolesSchema {
			name
			displayName
		}
	}
`;

export const CANVAS_ROLES_SCHEMA_BY_SHARE_TYPE = gql`
	query CanvasRolesSchemaByShareType {
		canvasRolesSchemaByShareType {
			external {
				name
				displayName
			}
			internal {
				name
				displayName
			}
		}
	}
`;

export const SHARE_CANVAS_WITH_ORG = gql`
	${CANVAS_ROLES_FIELDS}
	mutation shareCanvasWithOrganization($id: ID!, $role: String!, $sharedName: String!) {
		shareCanvasWithOrganization(id: $id, role: $role, sharedName: $sharedName) {
			...CanvasRolesFields
		}
	}
`;

export const UNSHARE_CANVAS_WITH_ORG = gql`
	mutation UnshareCanvasWithOrganization($id: ID!) {
		unshareCanvasWithOrganization(id: $id) {
			canvasID
			roles {
				role {
					name
					permissions
				}
				serviceAccount
				serviceAccountType
			}
			sharedName
		}
	}
`;

export const SHARE_CANVAS_WITH_USER = gql`
	${CANVAS_ROLES_FIELDS}
	mutation ShareCanvasWithUser($id: ID!, $userID: ID!, $role: String!) {
		shareCanvasWithUser(id: $id, userID: $userID, role: $role) {
			...CanvasRolesFields
		}
	}
`;

export const UNSHARE_CANVAS_WITH_USER = gql`
	${CANVAS_ROLES_FIELDS}
	mutation unshareCanvasWithUser($id: ID!, $userID: ID!) {
		unshareCanvasWithUser(id: $id, userID: $userID) {
			...CanvasRolesFields
		}
	}
`;

export const INVITE_TO_CANVAS = gql`
	${CANVAS_ROLES_FIELDS}
	mutation inviteToCanvas($id: ID!, $email: String!, $role: String!) {
		inviteToCanvas(id: $id, email: $email, role: $role) {
			...CanvasRolesFields
		}
	}
`;

export const REVOKE_INVITE_FOR_CANVAS = gql`
	${CANVAS_ROLES_FIELDS}
	mutation revokeInviteForCanvas($id: ID!, $inviteToken: String!) {
		revokeInviteForCanvas(id: $id, inviteToken: $inviteToken) {
			...CanvasRolesFields
		}
	}
`;

export const ACCEPT_CANVAS_INVITE = gql`
	mutation AcceptCanvasInvite($id: ID!, $inviteToken: String!) {
		acceptCanvasInvite(id: $id, inviteToken: $inviteToken)
	}
`;

export const ACTIVE_USERS = gql`
	${USER_FIELDS}
	query UsersCurrentlyActive($id: ID!) {
		canvasActiveUsers(canvasID: $id) {
			user {
				...UserFields
			}
			tabID
		}
	}
`;

export const ADD_USER_CURRENTLY_ACTIVE = gql`
	mutation AddUserCurrentlyActive($userID: ID!, $canvasID: ID!, $tabID: String!) {
		addUserCurrentlyActive(userID: $userID, canvasID: $canvasID, tabID: $tabID)
	}
`;

export const GET_CURRENT_ORGANIZATION = gql`
	${ORGANIZATION_FIELDS}
	query GetCurrentOrganization {
		currentOrganization {
			...OrganizationFields
		}
	}
`;

export const GET_ORG_BY_ID = gql`
	${ORGANIZATION_FIELDS}
	query GetOrgByID($id: String!) {
		organization(id: $id) {
			...OrganizationFields
		}
	}
`;

export const GET_USERS_IN_ORG = gql`
	${USER_FIELDS}
	query GetUsersInOrganization {
		usersInOrganization {
			...UserFields
		}
	}
`;

export const GET_ALL_CANVAS_DRAFTS_METADATA = gql`
	query GetAllCanvasDraftsMetadata($id: ID!) {
		canvasDraftsMetadata(id: $id) {
			version
			canvasID
			created
			updated
			authorID
		}
	}
`;

export const GET_CANVAS_DRAFT = gql`
	${CANVAS_FIELDS_WITH_VERSION}
	query GetCanvasDraft($id: ID!, $userID: ID!) {
		canvasDraft(id: $id, userID: $userID) {
			...CanvasFieldsWithVersion
			updatedBy
		}
	}
`;

export const DISCARD_CANVAS_DRAFT = gql`
	${CANVAS_FIELDS_WITH_VERSION}
	mutation DiscardCanvasDraft($id: ID!) {
		discardCanvasDraft(id: $id) {
			...CanvasFieldsWithVersion
		}
	}
`;

export const GENERATE_SIGNED_UPLOAD_URL = gql`
	mutation GenerateSignedUploadUrl($contentType: String!) {
		generateSignedUploadUrl(contentType: $contentType)
	}
`;

export const ATLAS_USER_TOKEN = gql`
	query AtlasUserToken {
		atlasUserToken
	}
`;

/**
 *
 * Onboarding Queries and Mutations
 *
 */

export const ONBOARDING_STATE = gql`
	query OnboardingState($userID: ID!, $featureName: OnboardingFeature!) {
		onboardingState(userID: $userID, featureName: $featureName) {
			userID
			featureName
			status
			updatedAt
			onboardingCanvasID
		}
	}
`;

export const ONBOARDING_STATES = gql`
	query OnboardingStates($userID: ID!) {
		onboardingStates(userID: $userID) {
			userID
			featureName
			status
			updatedAt
			createdAt
		}
	}
`;

export const UPDATE_ONBOARDING_STATUS = gql`
	mutation UpdateOnboardingStatus(
		$userID: String!
		$featureName: OnboardingFeature!
		$status: OnboardingStatus!
	) {
		updateOnboardingStatus(userID: $userID, featureName: $featureName, status: $status) {
			userID
			featureName
			status
			updatedAt
			createdAt
		}
	}
`;

/**
 *
 * Prototype: Search Queries
 *
 */

export const SEARCH = gql`
	${CanvasListing}
	query Search($query: String!) {
		search(query: $query) {
			...CanvasListing
		}
	}
`;
