import cx from "classnames";
import { type FC, type HTMLAttributes, type PropsWithChildren } from "react";

import { THEME_CLASS_MAP, type Theme } from "./types";

export type ForceThemeProps = HTMLAttributes<HTMLDivElement> & {
	theme: Theme;
};

export const ForceTheme: FC<PropsWithChildren<ForceThemeProps>> = ({
	children,
	theme,
	className,
	...props
}) => (
	<div className={cx(className, THEME_CLASS_MAP[theme])} {...props}>
		{children}
	</div>
);
