import z from "zod";

export const checksum = z.object({
	version: z.string(),
	hash: z.string(),
	extras: z
		.object({
			debug: z.array(z.unknown().optional()).optional(),
		})
		.optional(),
});

export type Checksum = z.infer<typeof checksum>;
