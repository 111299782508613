import type { Step } from "prosemirror-transform";

import {
	type CanvasSpecifier,
	type MessageIdempotentInitRoomRequest,
	type MessageInitRoomResponseAccepted,
	type MessageStepsSinceResponse,
	type MessageTransactionRequest,
	type MessageTransactionResponse,
	type PresenceSelection,
	type ProseMirrorClientId,
	makeMessageSendSelectionRequest,
	makeMessageStepsSinceRequest,
	makeMessageStepsSinceResponseRejected,
	messageIdempotentInitRoomResponseAccepted,
	messageSendSelectionResponseAccepted,
	messageStepsSinceResponse,
	messageTransactionResponse,
} from "~/lib/api-types";
import { type Checksum } from "~/lib/api-types/checksum";
import { type Clock } from "~/lib/clock";
import { accessToken, createHttpHeaders, post } from "~/lib/http/index";

import { UnauthorizedError } from "../http";

export type PagesCollabApiClientClosedResponse = {
	type: "closed";
	error: string;
};

const SELECTION_REQUEST_DEBOUNCE_MILLIS = 100;

export class PagesCollabApiClient {
	private open = true;
	private lastRequestTimeMillis = 0;

	constructor(
		private fetchFn: (init?: RequestInit | undefined) => Promise<Response>,
		private clock: Clock,
		private accessTokenFn: () => string = accessToken,
		private sendSelectionDebounceMillis = SELECTION_REQUEST_DEBOUNCE_MILLIS
	) {}

	public async initRoom(
		canvasSpecifier: CanvasSpecifier,
		pageSlug: string
	): Promise<MessageInitRoomResponseAccepted | PagesCollabApiClientClosedResponse> {
		if (!this.open) {
			return { type: "closed", error: "Aborting initRoom. client is closed" };
		}

		const response = await this.fetchFn(
			post(
				createHttpHeaders(this.accessTokenFn()),
				makeMessageIdempotentInitRoomRequest(canvasSpecifier, pageSlug)
			)
		);

		if (response.status === 401) {
			throw new UnauthorizedError();
		}

		const json = await response.json();
		return messageIdempotentInitRoomResponseAccepted.parseAsync(json);
	}

	public async transaction(
		lastVersionSeen: number,
		steps: readonly Step[],
		prosemirrorClientId: ProseMirrorClientId,
		connectionId: string,
		checksum: Checksum | undefined
	): Promise<MessageTransactionResponse | PagesCollabApiClientClosedResponse> {
		if (!this.open) {
			return { type: "closed", error: "Aborting transaction. client is closed" };
		}

		const response = await this.fetchFn(
			post(
				createHttpHeaders(this.accessTokenFn()),
				makeMessageTransactionRequest(
					lastVersionSeen,
					steps,
					prosemirrorClientId,
					connectionId,
					checksum
				)
			)
		);

		if (response.status === 401) {
			throw new UnauthorizedError();
		}

		const json = await response.json();
		return messageTransactionResponse.parseAsync(json);
	}

	public async stepsSince(
		version: number
	): Promise<MessageStepsSinceResponse | PagesCollabApiClientClosedResponse> {
		if (!this.open) {
			return { type: "closed", error: "Aborting stepsSince. client is closed" };
		}

		// Abort any pending stepsSince requests.
		// Once aborted, you cannot reuse it again. so we need to assign to a new abort.
		const response = await this.fetchFn(
			post(createHttpHeaders(this.accessTokenFn()), makeMessageStepsSinceRequest(version))
		);

		if (response.status === 401) {
			throw new UnauthorizedError();
		}

		const json = await response.json();
		return messageStepsSinceResponse.parseAsync(json);
	}

	public close() {
		this.open = false;
	}

	public async sendSelection(selection: PresenceSelection) {
		if (!this.open) {
			return makeMessageStepsSinceResponseRejected(
				"Aborting sendSelection. client is closed"
			);
		}

		const timeElapsed = this.clock.nowInMillis() - this.lastRequestTimeMillis;
		if (timeElapsed < this.sendSelectionDebounceMillis) {
			return makeMessageStepsSinceResponseRejected(
				"Aborting sendSelection. Debouncing requests"
			);
		}
		// Sending selections aren't super important.
		// Just avoid issuing another request within the time interval.
		this.lastRequestTimeMillis = this.clock.nowInMillis();

		const response = await this.fetchFn(
			post(
				createHttpHeaders(this.accessTokenFn()),
				makeMessageSendSelectionRequest(selection)
			)
		);

		if (response.status === 401) {
			throw new UnauthorizedError();
		}

		const json = await response.json();
		return messageSendSelectionResponseAccepted.parseAsync(json);
	}
}

function makeMessageIdempotentInitRoomRequest(
	canvasSpecifier: CanvasSpecifier,
	pageSlug: string
): MessageIdempotentInitRoomRequest {
	return { type: "idempotent-init-room-request", canvasSpecifier, pageSlug };
}

export const makeMessageTransactionRequest = (
	lastVersionSeen: number,
	steps: readonly Step[],
	prosemirrorClientId: ProseMirrorClientId,
	connectionId: string,
	checksum?: Checksum | undefined
): MessageTransactionRequest => {
	return {
		type: "transaction-request",
		lastVersionSeen,
		steps: steps?.map((step) => step.toJSON()),
		prosemirrorClientId,
		connectionId,
		checksum,
	};
};
