import z from "zod";

export const messageRequestUnauthorized = z.object({
	type: z.literal("request-unauthorized"),
	version: z.optional(z.number()),
});
export type MessageRequestUnauthorized = z.infer<typeof messageRequestUnauthorized>;

export function makeMessageRequestUnauthorized(version?: number): MessageRequestUnauthorized {
	return { type: "request-unauthorized", version };
}
