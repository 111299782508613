import { getAtlasUserToken } from "~/utils/storage";

import { getAccessToken } from "../client";

/**
 * A standard error returned from Atlas, and enriched by the frontend with an `adapterGuess`.
 *
 * `adapaterGuess` is not precise because we can't (yet) tell from the callsite which API an HTTP
 * adapter is trying to direct itself to. So we "guess" by looking at the name.
 */
export interface AtlasError {
	apiVersion: "v1";
	atlasErrorId: string;
	documentationUrl: string;
	message: string;
	adapterGuess?: string;
}

export type Options = {
	cachePolicy: string;
};

export function isAtlasError(o: unknown): o is AtlasError {
	return typeof o?.["atlasErrorId"] === "string";
}

export const createAtlasProxyFetchHttp =
	(url: string, options: Options = { cachePolicy: "max-age=60" }) =>
	//
	// we use `unknown` here because we can't enforce typechecking
	// after the app is compiled
	//
	// using unknown ensures that we can adequately typecheck and
	// provide errors if users pass in incompatible data
	//
	(route: unknown, init?: unknown) => {
		const [req, adapterGuess] = setupRequest(route, url, init);

		const userToken = getAtlasUserToken() ?? "";

		return httpFetch(req.requestURL, adapterGuess, userToken, req.authToken, options, req.init);
	};

export const createAtlasProxyFetch =
	(baseUrl: string, options: Options = { cachePolicy: "max-age=60" }) =>
	//
	// we use `unknown` here because we can't enforce typechecking
	// after the app is compiled
	//
	// using unknown ensures that we can adequately typecheck and
	// provide errors if users pass in incompatible data
	//
	(route: unknown, init?: unknown) => {
		const [req, adapterGuess] = setupRequest(route, baseUrl, init);

		const userToken = getAtlasUserToken() ?? "";

		if (req.requestURL.pathname.startsWith("/v1/ws")) {
			return websocketFetch(req.requestURL, userToken, req.authToken, req.init);
		} else {
			return httpFetch(
				req.requestURL,
				adapterGuess,
				userToken,
				req.authToken,
				options,
				req.init
			);
		}
	};

export const createAtlasProxyFetchWithOpts =
	(baseUrl: string) =>
	//
	// we use `unknown` here because we can't enforce typechecking
	// after the app is compiled
	//
	// using unknown ensures that we can adequately typecheck and
	// provide errors if users pass in incompatible data
	//
	(options: Options) => {
		(route: unknown, init?: unknown) => {
			const [req, adapterGuess] = setupRequest(route, baseUrl, init);

			const userToken = getAtlasUserToken() ?? "";

			if (req.requestURL.pathname.startsWith("/v1/ws")) {
				return websocketFetch(req.requestURL, userToken, req.authToken, req.init);
			} else {
				return httpFetch(
					req.requestURL,
					adapterGuess,
					userToken,
					req.authToken,
					options,
					req.init
				);
			}
		};
	};

const setupRequest = (route: unknown, baseUrl: string, init?: unknown) => {
	if (typeof route !== "string") {
		throw new Error(`route must be a string but received ${typeof route}`);
	}

	const split = route.split("/");
	let adapterGuess: string | undefined;
	if (split[0] === "" && split[1] === "v1" && split[2] === "apis") {
		if (split[3] === "http") {
			// NOTE: This is a best guess. You could in theory have an adapter that's called Vercel,
			// but is really for OpenAI, for example.
			adapterGuess = split[4];
		} else {
			adapterGuess = split[3];
		}
	}

	// RequestInit does not exist as a value/class and is only a typescript type
	if ((init !== undefined && typeof init !== "object") || init === null) {
		throw new Error(`init must be an object or undefined but received ${typeof init}`);
	}

	const authToken = getAccessToken();

	// construct a URL for the request
	let requestURL;

	// Accept URLs of the form `/v1/apis/github/user?foo=bar` or
	// `https://atlas.moment.dev/v1/apis/github/user?foo=bar`.
	if (route.startsWith("https://")) {
		requestURL = new URL(route);
	} else {
		// Allows for search params as well
		requestURL = new URL(`${baseUrl}${route}`);
	}

	return [{ authToken, requestURL, init }, adapterGuess] as const;
};

const httpFetch = async (
	requestURL: URL,
	adapterGuess: string | undefined,
	userToken: string,
	authToken: string,
	options: Options,
	init?: object
) => {
	const authHeader: [string, string] = ["Atlas-Accesstoken", authToken];
	const userTokenHeader: [string, string] = ["Atlas-User-Token", userToken];

	const request = new Request(
		requestURL.href,
		Object.assign(
			{
				method: "GET",
			},
			{ ...init }
		)
	);

	request.headers.set(...authHeader);
	request.headers.set(...userTokenHeader);

	// Set the cache policy based on options if the user did not explicitly added a header.
	if (
		request.headers.get("Cache-Control") === null ||
		request.headers.get("Cache-Control") === ""
	) {
		request.headers.set("Cache-Control", options.cachePolicy);
	}

	const response = await fetch(request);

	if (!response.ok) {
		// attempt to extract an Atlas error from the response

		let atlasError;
		try {
			// we clone the response because you can only
			// consume the body once and we don't want to
			// consume the body of the one we return
			const respCopy = response.clone();
			// throw the response object if Atlas error is returned
			const json = await respCopy.json();
			if (
				json.atlasErrorId !== undefined &&
				json.atlasErrorId !== null &&
				json.atlasErrorId !== ""
			) {
				atlasError = json;
				atlasError.adapterGuess = adapterGuess;
			}
		} catch (err) {
			/* if we can't parse JSON then continue */
		}

		if (atlasError) {
			throw atlasError;
		}
	}

	return response;
};

const websocketFetch = (requestURL: URL, orgID: string, authToken: string, init?: object) => {
	return new Promise((resolve, reject) => {
		const wsURL = new URL(`wss://${requestURL.host}${requestURL.pathname}`);
		const ws = new WebSocket(wsURL);

		let result = {};

		ws.onopen = () => {
			const authMessage = JSON.stringify({
				accessToken: authToken,
			});
			ws.send(authMessage);
		};

		ws.onclose = () => {
			resolve(result);
		};

		ws.onerror = (err) => {
			reject(err);
		};

		ws.addEventListener("message", (event) => {
			if (
				event.data ===
				JSON.stringify({
					status: "auth-success",
				})
			) {
				ws.send(JSON.stringify(init || {}));
			} else {
				result = event.data;
			}
		});
	});
};
