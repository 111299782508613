import { gql } from "@apollo/client";

export const LIST_CANVAS_VERSIONS_PAGINATED = gql`
	query CanvasVersionsPaginated($input: CanvasVersionsPaginatedInput!) {
		canvasVersionsPaginated(input: $input) {
			versions {
				id
				canvasID
				name
				description
				created
				authorID
			}
			lastKey
		}
	}
`;
