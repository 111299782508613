import { type DOMOutputSpec, type MarkSpec } from "prosemirror-model";

// :: MarkSpec An <em />. Rendered and parsed as an `<em>` element.

const emDOM: DOMOutputSpec = ["em", 0];

export const em: MarkSpec = {
	parseDOM: [{ tag: "i" }, { tag: "em" }, { style: "font-style=italic" }],
	toDOM() {
		return emDOM;
	},
};
