import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { Logger } from "~/utils/logging";

import { setLoggedOut } from "../auth/slice";
import { refreshAccessToken } from "../auth/thunks";
import { type ThunkArgs } from "../store";

const apolloClient = import("~/api/client").then(({ apolloClient }) => apolloClient);
const logger = new Logger("store/client/thunks");

export const createClient = createAsyncThunk<
	ApolloClient<NormalizedCacheObject>,
	undefined,
	ThunkArgs
>("client/createClient", async (_, { extra, getState, dispatch }) => {
	const { client: clientState } = getState();

	const client = (await apolloClient)(clientState.env, async (input, init) => {
		const response = await fetch(input, init);

		//
		// On unauthorized, try refreshing access tokens.
		//
		if (response.status === 401) {
			try {
				const { accessToken } = await dispatch(refreshAccessToken()).unwrap();

				return fetch(input, {
					...init,
					headers: {
						...init?.headers,
						authorization: `Bearer ${accessToken}`,
					},
				});
			} catch (e) {
				logger.warn("Failed to refresh access token, must reauthenticate", { error: e });
				dispatch(setLoggedOut());
				throw new Error("Failed to refresh access token, must reauthenticate");
			}
		} else {
			return response;
		}
	});

	Object.assign(extra, { client });

	return client;
});
