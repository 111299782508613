import z from "zod";

import { type Checksum, checksum } from "~/lib/api-types/checksum";

import { messageRequestUnauthorized } from "./auth";
import { prosemirrorClientId } from "./prosemirrorClientId";

//
// Transaction request.
//

/**
 * A request to the server to accept a text editing transaction, and broadcast it to all connected
 * clients.
 */
export const messageTransactionRequest = z.object({
	type: z.literal("transaction-request"),
	lastVersionSeen: z.number(),
	steps: z.array(z.unknown()),
	prosemirrorClientId,
	connectionId: z.string(),
	checksum: checksum.optional(),
});
export type MessageTransactionRequest = z.infer<typeof messageTransactionRequest>;

//
// Transaction response.
//

/**
 * A response from the server that a transaction has been accepted.
 */
export const messageTransactionResponseAccepted = z.object({
	type: z.literal("transaction-response-accepted"),
	version: z.number(),
	checksum: checksum.optional(),
});
export type MessageTransactionResponseAccepted = z.infer<typeof messageTransactionResponseAccepted>;

export function makeMessageTransactionResponseAccepted(
	version: number,
	checksum?: Checksum | undefined
): MessageTransactionResponseAccepted {
	return { type: "transaction-response-accepted", version, checksum };
}

/**
 * A response from the server that a transaction has been rejected. This will require the client to
 * attempt to fetch the most recent version of the document, and then rebase any local state on top
 * of it.
 */
export const messageTransactionResponseRejected = z.object({
	type: z.literal("transaction-response-rejected"),
	lastVersionSeen: z.number(),
	error: z.union([
		z.literal("conflict"),
		z.literal("unknown"),
		z.literal("persistence-failing"),
		z.literal("checksum-error"),
	]),
	message: z.string(),
});
export type MessageTransactionResponseRejected = z.infer<typeof messageTransactionResponseRejected>;

export function makeMessageTransactionResponseRejected(
	lastVersionSeen: number,
	error: "conflict" | "unknown" | "persistence-failing" | "checksum-error",
	message: string
): MessageTransactionResponseRejected {
	return { type: "transaction-response-rejected", lastVersionSeen, error, message };
}

/**
 * A response from the server that a transaction has been accepted or rejected.
 */
export const messageTransactionResponse = z.union([
	messageTransactionResponseAccepted,
	messageTransactionResponseRejected,
	messageRequestUnauthorized,
]);
export type MessageTransactionResponse = z.infer<typeof messageTransactionResponse>;
