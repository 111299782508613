import { type LogLevel, log } from "~/utils/datadog/datadog";

export type LogFunc = (message: string, content?: Record<string, unknown>) => void;

export type BaseLogFunc = (
	level: LogLevel,
	message: string,
	content?: Record<string, unknown>
) => void;

/**
 * Javascript Class for use in non-react contexts
 *
 *
 * @param moduleName - string, name of the module to log
 
* @returns Logger - class instance with debug, info, warn, and error methods
 */
export class Logger {
	private moduleNamePrefix: string;

	constructor(moduleName: string) {
		this.moduleNamePrefix = `[${moduleName}] `;
	}

	private baseLogger: BaseLogFunc = (level, message, content) =>
		log(`${this.moduleNamePrefix}${message}`, { level, ...content }, level);

	public debug: LogFunc = (message, content) => this.baseLogger("debug", message, content);

	public info: LogFunc = (message, content) => this.baseLogger("info", message, content);

	public warn: LogFunc = (message, content) => this.baseLogger("warn", message, content);

	public error: LogFunc = (message, content) => this.baseLogger("error", message, content);
}
