import { type DOMOutputSpec, type MarkSpec } from "prosemirror-model";

const uDOM: DOMOutputSpec = ["u", 0];

export const underline: MarkSpec = {
	parseDOM: [{ tag: "u" }, { style: "text-decoration=underline" }],
	toDOM() {
		return uDOM;
	},
};
