import { Wifi, WifiOff } from "@carbon/icons-react";
import { makeToast } from "@moment/design-system/Toast";
import { useEffect, useRef } from "react";

import { useNetworkStatus } from "./useNetworkStatus";

type Toast = ReturnType<typeof makeToast>;

export const NetworkNotification = () => {
	const { status, prevStatus } = useNetworkStatus();

	const onlineToast = useRef<Toast>();
	const offlineToast = useRef<Toast>();

	useEffect(() => {
		if (status === "offline") {
			offlineToast.current = makeToast({
				message: "Network connection lost. Your changes cannot be saved.",
				timer: "dismissible",
				variant: "error",
				customIcon: <WifiOff size={20} />,
			});
		}

		if (status === "online" && prevStatus === "offline") {
			onlineToast.current = makeToast({
				message: "Network connection restored.",
				timer: "timed-dismissible",
				variant: "success",
				customIcon: <Wifi size={20} />,
			});
		}

		return () => {
			// cleanup
			onlineToast.current?.dismiss();
			onlineToast.current = undefined;

			offlineToast.current?.dismiss();
			offlineToast.current = undefined;
		};
	}, [status, prevStatus]);

	return null;
};
