import { useState } from "react";

export const useDialog = (initialState = false) => {
	const [isOpen, setIsOpen] = useState<boolean>(initialState);

	const openDialog = () => {
		setIsOpen(true);
	};

	const closeDialog = () => {
		setIsOpen(false);
	};

	const toggleDialog = () => {
		setIsOpen(!open);
	};

	return { openDialog, closeDialog, toggleDialog, isOpen };
};
