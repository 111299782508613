import { type RootState } from "../store";

export const selectUserPermissions = (state: RootState) => {
	return state.permissions.userPermissions;
};

export const selectInviteInputs = (state: RootState) => {
	return state.permissions.inviteInputs;
};

export const selectGlobalPermissions = (state: RootState) => {
	return state.permissions.globalPermissions;
};

export const selectOrgPermissions = (state: RootState) => {
	return state.permissions.orgPermissions;
};
