import { type Maybe, type User as UserGQL } from "~/api/generated/graphql";

export type UserID = string;
export interface User {
	id: UserID;
	organizationID: string;
	name: string;
	nickname: string;
	givenName: string;
	familyName: string;
	profilePicture: string;
	email: string;
	updatedAt: string;
	alternateIDs: Maybe<AlternateIDs>;
	intercomUserHash: string;
}

export type CanvasUser = Omit<User, "email" | "updatedAt" | "intercomUserHash">;

export type AlternateIDs = {
	google: Maybe<string>;
	github: Maybe<string>;
};

export const fromGQL = (user: UserGQL): User => {
	// This is currently just a straight mapping. Add any additional logic here.
	return { ...user };
};

export const canvasUserFromUser = (user?: User): CanvasUser | undefined => {
	if (user === undefined) {
		return user;
	}

	const {
		id,
		organizationID,
		name,
		nickname,
		givenName,
		familyName,
		profilePicture,
		alternateIDs,
	} = user;
	return {
		id,
		organizationID,
		name,
		nickname,
		givenName,
		familyName,
		profilePicture,
		alternateIDs: {
			google: alternateIDs?.google || null,
			github: alternateIDs?.github || null,
		},
	};
};
