import { type Attrs, type DOMOutputSpec, type Node, type NodeSpec } from "prosemirror-model";

import { CELL_SPEC_ATTRS } from "~/lib/prosemirror-utils/constants";
import { makeTextCellDefaultToDOMAttrs } from "~/lib/prosemirror-utils/makeTextCellDefaultToDOMAttrs";

// :: NodeSpec A plain paragraph textblock. Represented in the DOM
// as a `<p>` element.

export const paragraph: NodeSpec = {
	content: "inline*",
	group: "block",
	attrs: {
		// Known problems with placeholderText:
		//   * There's no icon made for this, so it's currently using TextFont in the toolbar.
		//   * There's no way to edit the placeholder text after the fact. You can replace it but not edit.
		//   * If backspacing in an empty placeholder text that is AFTER another empty placeholder text,
		//     the current (later in the document) placeholder text is retained, which feels surprising
		//     (e.g. it seems like it should delete the cell that the cursor was in, not the one before.
		placeholderText: {
			// the default text (⌘P to insert data and components, or start typing)
			// should only be shown when the block is selected, handled separately
			// but specified placeholder text should always show when empty
			default: "",
		},
		...CELL_SPEC_ATTRS,
	},
	parseDOM: [
		{
			tag: "p",
			getAttrs: (node: string | HTMLElement) => {
				if (typeof node === "string") {
					return false;
				}

				if (!(node instanceof HTMLElement)) {
					return false;
				}

				return {
					placeholderText: node.getAttribute(`data-placeholder-text`) ?? "",
				};
			},
		},
	],
	toDOM(node: Node) {
		const attrs: Attrs = {
			...(node.attrs["placeholderText"]
				? { [`data-placeholder-text`]: node.attrs["placeholderText"] }
				: undefined),
			...makeTextCellDefaultToDOMAttrs(node),
		};
		const pDOM: DOMOutputSpec = ["p", attrs, 0];
		return pDOM;
	},
};
